<template>
  <header
    v-if="isReady"
    class="container-fluid bg-primary sticky-top"
    :class="{ 'change-height-on-scroll': showCompactHeader }"
  >
    <DesktopHeader
      v-if="headerData"
      :header-items="headerData"
      :show-compact-header="showCompactHeader"
      @togglemenu="(value) => menuStateChange(value)"
    />
    <MobileHeader
      v-if="headerData && $q.screen.width < 992"
      :header-items="headerData"
      :menu-state="menustate"
      @togglemenu="(value) => menuStateChange(value)"
    />
  </header>
  <header
    v-else
    class="container-fluid bg-primary sticky-top header-skeleton"
  />
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { useQuasar } from 'quasar'
import { useGlobalValuesStore } from '../stores/globalValuesStore.js'

const $q = useQuasar()
const globalStore = useGlobalValuesStore();

const menustate = ref(true)
const headerData = ref(null)
const isReady = ref(false)

const route = useRoute();

const productRoutes = ['/balesetbiztositas', '/lakas', '/lakas/lakasbiztositas', '/lakas/minositett-fogyasztobarat-otthonbiztositas', '/kotelezo-biztositas', '/casco-biztositas', '/minicasco-biztositas', '/lopascasco-biztositas', '/utasbiztositas']

const emit = defineEmits(['preventScroll'])

const scrollPosition = ref(0)

const showCompactHeader = computed(() => {

  if(productRoutes.includes(route.path)) {
    if(globalStore.getShowCompactHeaderOnProductPages){
      return false;
    }
    return true;
  }
  else{
    return scrollPosition.value > 30
  }
})

const showDesktopHeader = computed(() => {
  return headerData.value && ($q.screen.lg || $q.screen.xl || $q.screen.xxl)
})

const showMobileHeader = computed(() => {
  return headerData.value && ($q.screen.xs || $q.screen.sm || $q.screen.md)
})

onMounted(async () => {
  window.addEventListener('scroll', handleScroll, { passive: true })

  let response = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Contents/Header'
  )
  headerData.value = response.data

  if (headerData.value) {
    isReady.value = true
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

function handleScroll(e) {
  scrollPosition.value = window.scrollY
}

function menuStateChange(value) {
  emit('preventScroll', value)
  menustate.value = value
}
</script>

<script>
export default {
  name: 'HeaderComponent',
}
</script>

<style lang="scss" scoped>
$height-transition: 0.4s;

:deep(.container-xxl) {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (max-width: $breakpoint-md-max) {
  :deep(.container-xxl) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@media screen and (min-width: $breakpoint-md) {
  :deep(#navbarDesktop) {
    height: 4.5rem;
    transition: height $height-transition;
  }
}

:deep(.search-bar) {
  padding: 0 1rem;
  width: 100%;
}

:deep(.navbar-buttons) {
  .btn {
    box-shadow: 0rem 0.625rem 0.9375rem #d5525e;
    border-radius: $half;
    letter-spacing: 0;
    font-weight: $font-weight-medium;
    text-transform: none;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 2.5rem;
    background-color: $N-200;
    svg {
      margin-right: $half;
    }
    span {
      letter-spacing: 0 !important;
    }
  }
  .btn-icon {
    min-width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $normal + $small;
  }
}
:deep(button.navbar-toggler) {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-radius: $half;
  padding: 0;
  margin: 0;
  svg {
    fill: white;
  }
}
:deep(a.nav-.link, .navbar-dark .navbar-nav .nav-link) {
  border-radius: 0.375rem;
  &:hover {
    background-color: $R-400;
    color: white;
  }
  &.dropdown-toggle.show {
    background-color: $R-600;
    color: white;
  }
}
:deep(.dropdown-menu[data-bs-popper]) {
  top: 4rem;
  padding: 0.75rem 1rem;
  border-radius: $normal * 0.75;
  border-width: 0;
  box-shadow: $box-shadow-md;
  min-width: 30.5rem;
  li {
    border-bottom: 1px solid $N-500;
    .dropdown-item {
      font-weight: $font-weight-medium;
      font-size: $font;
      line-height: 1.5;
      padding: 1rem;
      color: $dark;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      svg {
        color: $secondary;
      }
      &:hover {
        background-color: transparent;
        color: #4e95fb;
      }
    }
    &:last-child {
      border-bottom-width: 0;
    }
    ul.submenu {
      padding: 0;
      li {
        padding: 0 1rem;
        margin-bottom: 1rem;
        list-style: none;
        border-width: 0;
        a {
          color: $dark;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        a:hover {
          background-color: transparent;
          color: #4e95fb;
        }
        svg {
          color: $secondary;
        }
      }
    }
  }
}
:deep(.navbar-dark .navbar-nav .nav-link) {
  color: white;
  font-weight: $font-weight-bold;
  height: 2.5rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.navbar-dark .navbar-nav li.nav-item) {
  margin-right: $normal;
}
:deep(.navbar-dark .navbar-nav li.nav-item:last-child) {
  margin-right: 0;
}
:deep(.dropdown-toggle::after) {
  display: none;
}
@media screen and (max-width: $breakpoint-lg-max) {
  :deep(.navbar-dark .navbar-nav li.nav-item) {
    margin-right: $small;
  }
  :deep(.navbar-dark .navbar-nav li.nav-item:last-child) {
    margin-right: 0;
  }
  :deep(.navbar-buttons) {
    display: flex;
    align-items: center;
    .btn {
      min-width: 9.375rem;
    }
    .btn-icon {
      margin-right: $normal - $small !important;
    }
  }
}
:deep(.mobile-menu) {
  background-color: $N-200;
  .navbar-nav .nav-link {
    color: $dark;
    font-weight: $font-weight-medium;
    height: 2.5rem;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: $font-weight-bold;
  }
  .nav-item {
    border-bottom: 1px solid $N-500;
    &:last-child {
      border-width: 0;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu[data-bs-popper] {
    border-radius: 0;
    box-shadow: none;
    padding: 0 1rem;
    min-width: unset;
    .dropdown-item {
      padding: 0.75rem 0.5rem;
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      font-weight: $font-weight-bold;
    }
    ul.dropdown-menu {
      padding: 0.25rem 0.5rem;
    }
    .submenu {
      padding: 0rem 0.5rem;
      li {
        padding: 0;
      }
    }
  }
  .caret {
    color: $primary;
  }
  .search-bar {
    padding: 1rem;
  }
}
:deep(.navbar-buttons-mobile) {
  .btn-icon {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
    padding: 0;
    margin: 0 1rem 0 0;
  }
}
:deep(.navbar-light .navbar-nav .nav-link:hover, .navbar-light
    .navbar-nav
    .nav-link:focus) {
  color: $dark;
}
:deep(.search-bar) {
  .q-input__details {
    padding: 0;
    margin: 0;
    min-height: 0;
    .q-messages {
      min-height: 0;
    }
  }
  .q-input {
    .q-field__field {
      min-height: 0;
      height: 2.5rem;
      padding: 0;
    }
    .q-field__input {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      font-weight: $font-weight-base;
      color: $D-400;
    }
  }
}
:deep(.navbar-dark .navbar-toggler) {
  color: transparent;
  border-color: transparent;
}
:deep(.icon-holder) {
  width: 100%;
  height: 100%;
  min-width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $half;
  &.close {
    background-color: $R-600;
  }
}

:deep(.q-btn__content > *) {
  width: auto;
}
:deep(.q-btn:before, .q-btn--actionable.q-btn--standard:active:before, .q-btn--actionable.q-btn--standard.q-btn--active:before) {
  box-shadow: none;
}

:deep(#navbarMobile) {
  .q-field__control,
  .q-field__native,
  .q-field__append,
  .q-field__control-container,
  .q-field--outlined .q-field__control:after,
  .q-field--outlined .q-field__control:before {
    max-height: 2.5rem;
    padding: 0;
  }
  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-normal !important;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    color: $dark;
  }
  .q-field * {
    color: $dark;
  }
  .q-input .q-placeholder,
  .q-placeholder::-webkit-input-placeholder {
    color: $dark !important;
    opacity: 100 !important;
  }
}

.change-height-on-scroll {
  @media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl-max) {
    height: 3.5rem;
    transition: height $height-transition;
    :deep(#navbarDesktop) {
      height: 3.5rem;
      transition: height $height-transition;
    }
  }
}

.header-skeleton{
  height: 4.5rem;
  width: 100%;
}
</style>
