<template>
  <div class="marketistatement-input-holder">
    <p class="marketingstatement-paragraph">
      {{ text }}
    </p>
    <div class="marketingstatement-checkbox">
      <CheckboxInput
        :id="id"
        v-model:model-value="checkboxValue"
      >
        Megadom a hozzájárulásomat
      </CheckboxInput>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: '',
    required: true,
  },
  id: {
    type: String,
    required: true,
    default: 'KALK_000'
  },
  modelValue:{
    type: Boolean,
    default: false,
  }
});

onMounted(() => {
  checkboxValue.value = props.modelValue;
});

const emit = defineEmits(['update:modelValue']);

const checkboxValue = ref(false);

watch(
  () => checkboxValue.value,
  (value) => {
    emit('update:modelValue', value);
  }
)

</script>
<style lang="scss" scoped>
.marketistatement-input-holder {
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .marketingstatement-paragraph {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4a567c;
  }
  .marketingstatement-checkbox {
    :deep(.q-checkbox) {
      margin: 0 !important;
      .q-checkbox__label {
        height: 1.25rem;
        display: flex;
        align-items: center;
      }
      .q-item__label {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem !important;
        color: $D-700;
      }
    }
  }
}
</style>
