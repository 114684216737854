import axios from 'axios'

import { createRouter, createWebHistory } from 'vue-router'
import navigationHelper from '../utils/infrastructure/navigationHelper';
import HomeView from '@/views/HomeView.vue'

let isSalesPortal = window.location.href.indexOf('ertekesites.genertel.hu') > 0

let routeArray = []

// RESP PORTÁL
// Alapértelmezetten false, ha a domain ertekesites.genertel.hu, akkor true
if (isSalesPortal === false) {
  routeArray = [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '',
      name: 'emptypathhome',
      component: HomeView,
    },
    {
      path: '/utasbiztositas',
      name: 'utasbiztositas',
      component: () => import('../views/ProductViews/UtasbiztositasView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/balesetbiztositas',
      name: 'balesetbiztositas',
      component: () => import('../views/ProductViews/BalesetbiztositasView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/kotelezo-biztositas',
      name: 'kgfb',
      component: () => import('../views/ProductViews/KgfbView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/lakas',
      name: 'lakasEloszto',
      component: () => import('../views/ProductViews/LakasElosztoView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/lakas/lakasbiztositas',
      name: 'lakas',
      component: () => import('../views/ProductViews/LakasbiztositasView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/lakas/minositett-fogyasztobarat-otthonbiztositas',
      name: 'lakasmfo',
      component: () => import('../views/ProductViews/LakasbiztositasMFOView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/casco-biztositas',
      name: 'casco',
      component: () => import('../views/ProductViews/CascoView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/lopascasco-biztositas',
      name: 'lopascasco',
      component: () => import('../views/ProductViews/LopasCascoView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/minicasco-biztositas',
      name: 'minicasco',
      component: () => import('../views/ProductViews/MiniCascoView.vue'),
      meta:{
        hasViewReady : true
      } 
    },
    {
      path: '/utasbiztositas/utasbiztositas-kalkulator/kezdes',
      name: 'utasbiztositascalculator',
      component: () =>
        import('../views/ProductViews/UtasbiztositasViewCalculator.vue'),
    },
    {
      path: '/balesetbiztositas/balesetbiztositas-kalkulator/kezdes',
      name: 'balesetbiztositascalculator',
      component: () =>
        import('../views/ProductViews/BalesetbiztositasViewCalculator.vue'),
    },
    {
      path: '/kotelezo-biztositas/kotelezo-biztositas-kalkulator/kezdes',
      name: 'kgfbcalculator',
      component: () => import('../views/ProductViews/KgfbViewCalculator.vue'),
    },
    {
      path: '/lakas/lakasbiztositas-kalkulator/kezdes/POC',
      name: 'lakasbiztositascalculatorPOC',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/PoCPage.vue'),
    },
    {
      path: '/lakas/lakasbiztositas-kalkulator/koszonjuk',
      name: 'lakasbiztositascalculatorthankyou',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/ThankYouPage.vue'),
    },
    {
      path: '/lakas/lakasbiztositas-kalkulator/mentes',
      name: 'lakasbiztositascalculatorsave',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/SavePage.vue'),
    },
    {
      path: '/lakas/lakasbiztositas-kalkulator/sikeres-mentes',
      name: 'lakasbiztositascalculatorsavesuccess',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/SaveSuccessPage.vue'),
    },
    {
      path: '/lakas/MFO-lakasbiztositas-kalkulator/mentes',
      name: 'MFOlakasbiztositascalculatorsave',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/SavePage.vue'),
    },
    {
      path: '/lakas/MFO-lakasbiztositas-kalkulator/sikeres-mentes',
      name: 'MFOlakasbiztositascalculatorsavesuccess',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/SaveSuccessPage.vue'),
    },
    {
      path: '/lakas/lakasbiztositas-kalkulator',
      name: 'lakasbiztositascalculator',
      component: () =>
        import('../views/ProductViews/LakasbiztositasViewCalculator.vue'),
    },
    {
      path: '/lakas/lakasbiztositas-kalkulator/alapadatok',
      name: 'lakasbiztositasWizard',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/WizardManager.vue'),
    },
    {
      path: '/lakas/lakasbiztositas-kalkulator/:pageName',
      name: 'lakasbiztositascalculatorSubpage',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/WizardManager.vue'),
    },
    {
      path: '/lakas/MFO-lakasbiztositas-kalkulator',
      name: 'MFOlakasbiztositasWizard',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/WizardManager.vue'),
    },
    {
      path: '/lakas/MFO-lakasbiztositas-kalkulator/:pageName',
      name: 'MFOlakasbiztositascalculatorSubpage',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/WizardManager.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/lakas',
      name: 'EmailConfirmationGT',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/mfo',
      name: 'EmailConfirmationMFO',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/lakas/sikeres',
      name: 'EmailConfirmationGTSuccessful',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/mfo/sikeres',
      name: 'EmailConfirmationMFOSuccessful',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/lakas/sikertelen',
      name: 'EmailConfirmationGTUnsuccessful',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/mfo/sikertelen',
      name: 'EmailConfirmationMFOUnsuccessful',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/lakas/sikeres-ujra',
      name: 'EmailConfirmationGTMultipleSuccessful',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/ugyfelszolgalat/email-megerosites/mfo/sikeres-ujra',
      name: 'EmailConfirmationMFOMultipleSuccessful',
      component: () =>
        import('../views/Wizards/Household/Sitebuild/EmailConfirmation.vue'),
    },
    {
      path: '/casco-biztositas/casco-biztositas-kalkulator/kezdes',
      name: 'cascocalculator',
      component: () => import('../views/ProductViews/CascoViewCalculator.vue'),
    },
    {
      path: '/lopascasco-biztositas/lopascasco-biztositas-kalkulator/kezdes',
      name: 'lopascascocalculator',
      component: () =>
        import('../views/ProductViews/LopasCascoViewCalculator.vue'),
    },
    {
      path: '/minicasco-biztositas/minicasco-biztositas-kalkulator/kezdes',
      name: 'minicascocalculator',
      component: () =>
        import('../views/ProductViews/MinicascoViewCalculator.vue'),
    },
    {
      path: '/kotelezo-biztositas/zoldkartya',
      name: 'zoldkartyaIgenyles',
      component: () => import('../views/MiscViews/MTPLGreenCardView.vue'),
    },
    {
      path: '/honlapterkep',
      name: 'honlapterkep',
      component: () => import('../views/MiscViews/SiteMapView.vue'),
    },
    {
      path: '/jogi-nyilatkozat',
      name: 'jogiNyilatkozat',
      component: () => import('../views/MiscViews/LegalNoticeView.vue'),
    },
    {
      path: '/500',
      name: '500',
      component: () => import('../views/MiscViews/Error500View.vue'),
    },
    {
      path: '/rolunk',
      name: 'rolunk',
      component: () => import('../views/MiscViews/AboutUsView.vue'),
    },
    {
      path: '/promociok',
      name: 'promociok',
      component: () => import('../views/MiscViews/PromotionsView.vue'),
    },
    {
      path: '/cegadatok',
      name: 'cegadatok',
      component: () => import('../views/MiscViews/CompanyDetailsView.vue'),
    },
    {
      path: '/gyakori-kerdesek',
      name: 'gyik',
      component: () => import('../views/MiscViews/FAQView.vue'),
    },
    {
      path: '/husegprogram',
      name: 'husegprogram',
      component: () => import('../views/MiscViews/LoyaltyProgramView.vue'),
    },
    {
      path: '/elektronikusalairas',
      name: 'elektronikusAlairas',
      component: () => import('../views/MiscViews/ElectronicSignatureView.vue'),
    },
    {
      path: '/elektronikusalairas-dinamikus-oldal',
      name: 'elektronikusAlairasDinamikusOldal',
      component: () =>
        import('../views/MiscViews/ElectronicSignatureDynamicView.vue'),
    },
    {
      path: '/sfcr-jelentes',
      name: 'SFCRJelentes',
      component: () => import('../views/MiscViews/SFCRReportView.vue'),
    },
    {
      path: '/mnb-penzugyi-navigator-tanacsado',
      name: 'penzugyiNavigatorTanacsadas',
      component: () =>
        import('../views/MiscViews/FinancialNavigatorAdviceView.vue'),
    },
    {
      path: '/kalkulalj-es-nyerj',
      name: 'nyeremenyjatek',
      component: () => import('../views/MiscViews/GameView.vue'),
    },
    {
      path: '/kotelezo-biztositas/dijellenorzes',
      name: 'insuranceFeeControl',
      component: () => import('../views/InsuranceFeeControlView.vue'),
    },
    {
      path: '/mnb-penzugyi-navigator',
      name: 'penzugyiNavigator',
      component: () => import('../views/MiscViews/FinancialNavigatorView.vue'),
    },
    {
      path: '/hatarozatok-vegzesek',
      name: 'hatarozatokVegzesek',
      component: () => import('../views/MiscViews/RegulationsView.vue'),
    },
    {
      path: '/kapcsolodo-jogszabalyok',
      name: 'jogszabalyok',
      component: () => import('../views/MiscViews/RelatedLawsView.vue'),
    },
    {
      path: '/casco-biztositas/partner-szervizek',
      name: 'partnerSzervizek',
      component: () => import('../views/MiscViews/PartnerGaragesView.vue'),
    },
    {
      path: '/szemelyes-ugyfelszolgalat',
      name: 'szemelyesUgyfelszolgalat',
      component: () =>
        import('../views/MiscViews/PersonalCustomerServiceView.vue'),
    },
    {
      path: '/adatvedelmi-nyilatkozat',
      name: 'adatvedelmiNyilatkozat',
      component: () => import('../views/MiscViews/PrivacyPolicyView.vue'),
    },
    {
      path: '/panaszbejelentes/sikertelen',
      name: 'complaintSummaryUnsuccesful',
      component: () => import('../views/Complaint/ComplaintSummaryView.vue'),
    },
    {
      path: '/panaszkezeles/panaszbejelentes',
      name: 'complaint',
      component: () => import('../views/Complaint/ComplaintView.vue'),
    },
    {
      path: '/panaszkezeles/panaszbejelentes/koszonjuk',
      name: 'complaintSuccess',
      component: () => import('../views/Complaint/ComplaintSuccessView.vue'),
    },
    {
      path: '/panaszkezeles/panaszbejelentes/adatellenorzes',
      name: 'complaintSummary',
      component: () => import('../views/Complaint/ComplaintSummaryView.vue'),
    },
    {
      path: '/panaszbejelentes/sikertelen',
      name: 'complaintSummaryUnsuccesful',
      component: () => import('../views/Complaint/ComplaintSummaryView.vue'),
    },
    {
      path: '/panaszkezeles',
      name: 'complaintHandling',
      component: () =>
        import('../views/ComplaintHandling/ComplaintHandlingView.vue'),
    },
    {
      path: '/karbejelentes',
      name: 'damageReport',
      component: () => import('../views/DamageReport/DamageReportView.vue'),
    },
    {
      path: '/keresesi-eredmenyek',
      name: 'keresesTalalat',
      component: () => import('../views/MiscViews/SearchResultView.vue'),
    },
    {
      path: '/umbraco/preview',
      name: 'preview',
      component: () => import('../views/PreviewView.vue'),
    },
    {
      path: '/:id(\\d+)' + '.aspx',
      name: 'previewIframe',
      component: () => import('../views/PreviewView.vue'),
    },
    {
      path: '/:id(\\d+)' + 'aspx',
      name: 'previewIframewodot',
      component: () => import('../views/PreviewView.vue'),
    },
    {
      path: '/letoltheto-dokumentumok',
      name: 'letolthetoDokumentumok',
      component: () =>
        import('../views/MiscViews/DownloadableDocumentsView.vue'),
    },
    {
      path: '/nem-tamogatott-bongeszo',
      name: 'nem-tamogatott-bongeszo',
      component: () => import('../views/MiscViews/InternetExplorerView.vue'),
    },
    {
      path: '/visszahivast-kerek',
      name: 'phoneCallback',
      component: () => import('../views/MiscViews/PhoneCallBackView.vue'),
    },
    {
      path: '/:pathMatch(.*)visszahivast-kerek/koszonjuk',
      name: 'phoneCallbackThankYouAll',
      component: () => import('../components/iframes/PhoneCallbackSuccessIFrame.vue'),
    },
    {
      path: '/kapcsolat/uzenet-iras/koszonjuk',
      name: 'messageSentThankYou',
      component: () =>
        import('../views/MiscViews/CustomerServiceSuccesView.vue'),
    },

    {
      path: '/kupon',
      name: 'kupon-lekerdezes',
      component: () => import('../views/MiscViews/KuponLekerdezesView.vue'),
    },
    {
      path: '/kupon/talalat-ertek',
      name: 'kupon-lekerdezes-talalat-ertek',
      component: () =>
        import('../views/MiscViews/KuponLekerdezesEredmenyView.vue'),
    },
    {
      path: '/lopascasco-biztositas/kepfeltoltes',
      name: 'lopascascobiztositas-kepfeltoltes',
      component: () =>
        import('../views/DamageReport/TheftCascoContractView.vue'),
    },
    {
      path: '/lopascasco-biztositas/kepfeltoltes/eredmeny/:ajanlatszam',
      name: 'lopascascobiztositas-kepfeltoltes-eredmeny',
      component: () =>
        import('../views/DamageReport/TheftCascoContractSuccessView.vue'),
    },
    {
      path: '/lopascasco-biztositas/kepfeltoltes/koszonjuk',
      name: 'lopascascobiztositas-kepfeltoltes-koszonjuk',
      component: () =>
        import('../views/DamageReport/TheftCascoContractUploadSuccessView.vue'),
    },
    {
      path: '/kulso-ertekesites/visszahivast-kerek',
      name: 'phoneCallbackKulsoErtekesites',
      component: () => import('../views/MiscViews/KulsoErtekesites.vue'),
    },
    {
      path: '/dijfizetes',
      name: 'creditCardPayment',
      component: () =>
        import('../views/MiscViews/BankkartyasDijfizetesView.vue'),
    },
    {
      path: '/dijfizetes/egyenleg',
      name: 'creditCardPaymentBalance',
      component: () =>
        import('../views/MiscViews/BankkartyasDijfizetesEgyenlegView.vue'),
    },
    {
      path: '/dijfizetes/egyenleg-nopayment',
      name: 'creditCardPaymentBalanceNoPayment',
      component: () =>
        import(
          '../views/MiscViews/BankkartyasDijfizetesEgyenlegNopaymentView.vue'
        ),
    },
    {
      path: '/dijfizetes/egyenleg-inprogress',
      name: 'creditCardPaymentBalanceInProgress',
      component: () =>
        import(
          '../views/MiscViews/BankkartyasDijfizetesEgyenlegInprogressView.vue'
        ),
    },
    {
      path: '/dijfizetes/varakozo',
      name: 'creditCardPaymentBalanceLoader',
      component: () =>
        import(
          '../views/MiscViews/BankkartyasDijfizetesEgyenlegLoaderView.vue'
        ),
    },
    {
      path: '/dijfizetes/koszonjuk',
      name: 'creditCardPaymentSuccess',
      component: () =>
        import(
          '../views/MiscViews/BankkartyasDijfizetesEgyenlegSuccessView.vue'
        ),
    },
    {
      path: '/dijfizetes/sikertelen',
      name: 'creditCardPaymentError',
      component: () =>
        import('../views/MiscViews/BankkartyasDijfizetesEgyenlegErrorView.vue'),
    },

    {
      path: '/akadalymentes',
      name: 'accessible',
      component: () => import('../views/AccessibleViews/AccessibleView.vue'),
      meta: {
        layout: 'Accessible',
      },
    },
    {
      path: '/akadalymentes/rolunk',
      name: 'accessibleAbout',
      component: () =>
        import('../views/AccessibleViews/AccessibleAboutView.vue'),
      meta: {
        layout: 'Accessible',
      },
    },
    {
      path: '/akadalymentes/kapcsolat',
      name: 'accessibleContact',
      component: () =>
        import('../views/AccessibleViews/AccessibleContactView.vue'),
      meta: {
        layout: 'Accessible',
      },
    },
    {
      path: '/akadalymentes/szolgaltatasi-terkep',
      name: 'accessibleServicesMap',
      component: () =>
        import('../views/AccessibleViews/AccessibleServicesMapView.vue'),
      meta: {
        layout: 'Accessible',
      },
    },
    {
      path: '/akadalymentes/jelnyelvi-tolmacs',
      name: 'accessibleSignLanguage',
      component: () =>
        import('../views/AccessibleViews/AccessibleSignLanguageView.vue'),
      meta: {
        layout: 'Accessible',
      },
    },
    {
      path: '/akadalymentes/biztositasok',
      name: 'accessibleInsurances',
      component: () =>
        import('../views/AccessibleViews/AccessibleInsurancesView.vue'),
      meta: {
        layout: 'Accessible',
      },
    },
    {
      path: '/akadalymentes/siket-es-nagyothallo-ugyfelek',
      name: 'accessibleDeafAndHardOfHearing',
      component: () =>
        import(
          '../views/AccessibleViews/AccessibleDeafAndHardOfHearingView.vue'
        ),
      meta: {
        layout: 'Accessible',
      },
    },
    {
      path: '/akadalymentes/ugyintezes',
      name: 'accessibleCoustemerService',
      component: () =>
        import('../views/AccessibleViews/AccessibleCoustemerServiceView.vue'),
      meta: {
        layout: 'Accessible',
      },
    },

    {
      path: '/kapcsolat',
      name: 'customerServiceNavigator',
      component: () =>
        import('../views/MiscViews/CustomerServiceNavigatorView.vue'),
    },
    {
      path: '/kapcsolat/visszahivas',
      name: 'kapcsolat-visszahivas',
      component: () =>
        import('../views/MiscViews/CustomerServicePhoneCallBackView.vue'),
    },
    {
      path: '/kapcsolat/visszahivas/koszonjuk',
      name: 'contactphone-callback-thank-you',
      component: () =>
        import('../views/MiscViews/CustomerServiceSuccesView.vue'),
    },
    {
      path: '/visszahivast-kerek/koszonjuk ',
      name: 'callback-thank-you',
      component: () =>
        import('../views/MiscViews/CustomerServiceSuccesView.vue'),
    },
    {
      path: '/kapcsolat/uzenet-iras',
      name: 'uzenetIras',
      component: () =>
        import('../views/MiscViews/CustomerServiceMessageSendView.vue'),
    },
    {
      path: '/segelyhivoszamok',
      name: 'segelyhivoszamok',
      component: () => import('../views/MiscViews/EmergencyNumbersView.vue'),
    },
    // {
    //   path: '/cookie-kezeles-old',
    //   name: 'cookieManagementSubpage',
    //   component: () => import('../views/MiscViews/CookieInformationView.vue'),
    // },
    {
      path: '/sutikezeles-tajekoztato',
      name: 'cookieManagementSubpage',
      component: () => import('../views/MiscViews/CookieInformationGeneratedView.vue'),
    },
    {
      path: '/szerzodes-felmondasa',
      name: 'terminationOfContractView',
      component: () =>
        import('../views/TerminationOfContract/TerminationOfContractView.vue'),
    },
    {
      path: '/szerzodes-felmondasa/koszonjuk',
      name: 'terminationOfContractSuccessView',
      component: () =>
        import(
          '../views/TerminationOfContract/TerminationOfContractSuccessView.vue'
        ),
    },
    {
      path: '/impresszum',
      name: 'impressView',
      component: () => import('../views/MiscViews/ImpressView.vue'),
    },
    {
      path: '/ingyenes-biztositasok',
      name: 'freeInsurancesView',
      component: () => import('../views/MiscViews/FreeInsurancesView.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/MiscViews/Error404View.vue'),
      meta:{
        hasViewReady : true
      } 
    },
  ]
}
// ÉRTÉKESÍTÉS PORTÁL ( IFRAME )
else {
  routeArray = [
    {
      path: '/:dynamicSegment/visszahivast-kerek',
      name: 'PhoneCallbackIFrame',
      component: () => import('../components/iframes/PhoneCallbackIFrame.vue'),
    },
    {
      path: '/:dynamicSegment/visszahivast-kerek/koszonjuk',
      name: 'phoneCallbackThankYouIFrame',
      component: () =>
        import('../components/iframes/PhoneCallbackSuccessIFrame.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../views/MiscViews/Error404View.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/MiscViews/Error404View.vue'),
    },
  ]
}

//add sandbox view in nonProd only
if (!import.meta.env.PROD) {
  routeArray.push({
    path: '/sandbox',
    name: 'sandbox',
    component: () => import('../views/SandboxView.vue'),
  })
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    //If the user navigated from a page to the same target page with top:0 scrollBehavior parameter 
    //the page would scroll up to the top and then down to the target anchor position
    //But... with the condition below the page does not navigate to the same page from the header menu and the browser sets a wrong position after haeder menu navigation. 
    // if(from.path != to.path) 
    return { top: 0 }
  },
  routes: routeArray,
})

// Minden egyes navigáció előtt lefut
router.beforeEach(async (to, from) => {
  if (from.name !== undefined && from.name.toLowerCase() === 'preview') {
    await axios
      .get(
        import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
          `/Preview/GetIdByUrl?url=${to.fullPath}`
      )
      .then((response) => {
        if (to.params.id !== from.query.id && to.name !== from.name) {
          router.replace({
            name: 'preview',
            query: { id: response.data.id },
            params: { url: to.fullPath },
            force: true,
          })
        }
      })
  }

  if (
    from.path === '/lakas/lakasbiztositas-kalkulator/mentes' &&
    to.path.includes('/lakas/lakasbiztositas-kalkulator')
  ) {
    to.params.fromSave = true
  }
  if (
    from.path === '/lakas/lakasbiztositas-kalkulator/sikeres-mentes' &&
    to.path.includes('/lakas/lakasbiztositas-kalkulator')
  ) {
    to.params.fromSave = true
  }
  if (
    from.path === '/lakas/lakasbiztositas-kalkulator/adatellenorzes' &&
    to.path.includes('/lakas/lakasbiztositas-kalkulator')
  ) {
    to.params.fromSixthPage = true
  }
  // Szándékosan maradt le az await, mert a title beállítása másodlagos, így is elég gyorsan megjelenik, nem kell hogy lassítsa a navigációt
  axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        `/Contents/HeadTitle?url=${to.path}`
    )
    .then((response) => {
      document.title = response.data.headTitle
    })
})

router.afterEach((to, from) => {
  navigationHelper.handleAnchor(router.currentRoute.value)
});

export default router
