<!-- eslint-disable -->
<template>
  <div :key="modelValue.zipCode.text + isLoading">
    <div v-if="!isLoading" class="row">
      <div class="col-12 col-lg-6">
        <ZipCodeInput
          id="CS_120"
          v-model="modelValue.zipCode.text"
          :model-value="modelValue.zipCode.text"
          :label="
            fields && fields.zipCode.label !== ''
              ? fields.zipCode.label
              : 'Irányítószám'
          "
          :tooltip-content="fields ? fields.zipCode.tooltip : ''"
          validation-rules="VAL_100|VAL_111"
          @settlement-name="getSettlementName"
          :max-length="zipCodeLength"

        />
      </div>
      <div class="col-12 col-lg-6">
        <SelectInput
          id="CS_121"
          v-model="streetName"
          :label="
            fields && fields.streetName.label !== ''
              ? fields.streetName.label
              : 'Közterület neve'
          "
          validation-rules="VAL_100|VAL_467"
          :options="streetOptions"
          :tooltip-content="fields ? fields.streetName.tooltip : ''"
          :is-autocomplete="true"
          :init-option-value="streetName"
          :is-readonly="streetOptions.length === 0"
          input-type="street-name"
          :max-length="streetNameLength"
          custom-class="no-dropdown-arrow no-placeholder-value select-address"
          :add-new-value="true"
          :is-address-input="true"
        />
      </div>
    </div>
    <div v-if="!isLoading" class="row">
      <div class="col-md-6 col-xs-12">
        <SelectInput
          id="CS_122"
          v-model="streetType"
          validation-rules="VAL_100"
          :label="
            fields && fields.streetType.label !== ''
              ? fields.streetType.label
              : 'Közterület típusa'
          "
          :tooltip-content="fields ? fields.streetType.tooltip : ''"
          :options="streetTypeOptions"
          :is-autocomplete="true"
          :init-option-value="streetType"
          :is-readonly="streetTypeOptions.length === 0"
          input-type="text-with-space"
          :max-length="streetTypeLength"
          custom-class="no-dropdown-arrow no-placeholder-value select-address"
          :is-address-input="false"
        />
      </div>
      <div class="col-md-6 col-xs-12">
        <TextInput
          :key="houseNumber"
          id="CS_123"
          v-model="houseNumber"
          :label="
            fields && fields.houseNumber.label !== ''
              ? fields.houseNumber.label
              : 'Házszám'
          "
          validation-rules="VAL_100"
          :tooltip-content="fields ? fields.houseNumber.tooltip : ''"
          :max-length="houseNumberLength"
          input-type="house-number"
          :init-input-value="houseNumber"
        />
      </div>
    </div>
    <div class="page__box__group page__box__group--mobile">
      <div>
        <TextInput
          :key="building"
          id="CS_124"
          v-model="building"
          :label="
            fields && fields.building.label !== ''
              ? fields.building.label
              : 'Épület'
          "
          :tooltip-content="fields ? fields.building.tooltip : ''"
          :max-length="7"
          :init-input-value="building"
        />
        <TextInput
          :key="staircase"
          id="CS_125"
          v-model="staircase"
          :label="
            fields && fields.staircase.label !== ''
              ? fields.staircase.label
              : 'Lépcsőház'
          "
          :tooltip-content="fields ? fields.staircase.tooltip : ''"
          :max-length="3"
          :init-input-value="staircase"
        />
      </div>
      <div>
        <TextInput
          :key="floor"
          id="CS_126"
          v-model="floor"
          :label="
            fields && fields.floor.label !== '' ? fields.floor.label : 'Emelet'
          "
          :tooltip-content="fields ? fields.floor.tooltip : ''"
          :max-length="2"
          :init-input-value="floor"
        />
        <TextInput
          :key="door"
          id="CS_127"
          v-model="door"
          :label="
            fields && fields.door.label !== '' ? fields.door.label : 'Ajtó'
          "
          :tooltip-content="fields ? fields.door.tooltip : ''"
          :max-length="5"
          :init-input-value="door"
        />
      </div>
    </div>
    <div class="page__box__group page__box__group--tablet">
      <div>
        <div>
          <TextInput
            :key="building"
            id="CS_124"
            v-model="building"
            :label="
              fields && fields.building.label !== ''
                ? fields.building.label
                : 'Épület'
            "
            :tooltip-content="fields ? fields.building.tooltip : ''"
            :max-length="7"
            :init-input-value="building"
          />
          <TextInput
            :key="staircase"
            id="CS_125"
            v-model="staircase"
            :label="
              fields && fields.staircase.label !== ''
                ? fields.staircase.label
                : 'Lépcsőház'
            "
            :tooltip-content="fields ? fields.staircase.tooltip : ''"
            :max-length="3"
            :init-input-value="staircase"
          />
        </div>
        <div>
          <TextInput
            :key="floor"
            id="CS_126"
            v-model="floor"
            :label="
              fields && fields.floor.label !== ''
                ? fields.floor.label
                : 'Emelet'
            "
            :tooltip-content="fields ? fields.floor.tooltip : ''"
            :max-length="2"
            :init-input-value="floor"
          />
          <TextInput
            :key="door"
            id="CS_127"
            v-model="door"
            :label="
              fields && fields.door.label !== '' ? fields.door.label : 'Ajtó'
            "
            :tooltip-content="fields ? fields.door.tooltip : ''"
            :max-length="5"
            :init-input-value="door"
          />
        </div>
      </div>
    </div>
    <div class="page__box__group page__box__group--desktop">
      <div class="row">
        <div class="col-3">
          <TextInput
            :key="building"
            id="CS_124"
            v-model="building"
            :label="
              fields && fields.building.label !== ''
                ? fields.building.label
                : 'Épület'
            "
            :tooltip-content="fields ? fields.building.tooltip : ''"
            :max-length="7"
            :init-input-value="building"
          />
        </div>
        <div class="col-3">
          <TextInput
            :key="staircase"
            id="CS_125"
            v-model="staircase"
            :label="
              fields && fields.staircase.label !== ''
                ? fields.staircase.label
                : 'Lépcsőház'
            "
            :tooltip-content="fields ? fields.staircase.tooltip : ''"
            :max-length="3"
            :init-input-value="staircase"
          />
        </div>
        <div class="col-3">
          <TextInput
            :key="floor"
            id="CS_126"
            v-model="floor"
            :label="
              fields && fields.floor.label !== ''
                ? fields.floor.label
                : 'Emelet'
            "
            :tooltip-content="fields ? fields.floor.tooltip : ''"
            :max-length="2"
            :init-input-value="floor"
          />
        </div>
        <div class="col-3">
          <TextInput
            :key="door"
            id="CS_127"
            v-model="door"
            :label="
              fields && fields.door.label !== '' ? fields.door.label : 'Ajtó'
            "
            :tooltip-content="fields ? fields.door.tooltip : ''"
            :max-length="5"
            :init-input-value="door"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import axios from 'axios'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
    required: true,
  },
  fields: {
    type: Object,
    default: () => {},
    required: false,
  },
  zipCodeLength: {
    type: Number,
    default: 30,
    required: false,
  },
  streetNameLength: {
    type: Number,
    default: 60,
    required: false,
  },
  streetTypeLength: {
    type: Number,
    default: 30,
    required: false,
  },
  houseNumberLength: {
    type: Number,
    default: 7,
    required: false,
  },
})

const emit = defineEmits(['setZipCode', 'setAddressData'])
const streetOptions = ref([])
const streetTypeOptions = ref([])
const isLoading = ref(true)

const streetName = ref(null)
const streetType = ref(null)
const houseNumber = ref(null)
const building = ref(null)
const staircase = ref(null)
const floor = ref(null)
const door = ref(null)

const propModelValue = computed(() => {
  return props.modelValue
})

const propFields = computed(() => {
  return props.fields
})

onMounted(async () => {
  if (
    props.modelValue.zipCode.zipCode !== null &&
    props.modelValue.zipCode.zipCode !== ''
  ) {
    await getPublicDomains(
      props.modelValue.zipCode.zipCode,
      props.modelValue.zipCode.settlement
    )
  }
  await getPublicPlaces()

  let streetTypeId = props.modelValue.streetType
  streetTypeId =
    streetTypeId != null
      ? streetTypeOptions.value.find((x) => x.label === streetTypeId).value
      : ''

  streetName.value = props.modelValue.streetName
  
  if (streetOptions.value !== undefined) {
    if (streetOptions.value !== null) {
      streetOptions.value.forEach((item) => {
        const streetNameIndexOf = streetOptions.value.find(
          (element) => element.value === props.modelValue.streetName
        )
        if (streetNameIndexOf === undefined) {
          streetOptions.value.push({
            value: props.modelValue.streetName,
            label: props.modelValue.streetName,
          })
        }
      })
    }
  }
  streetType.value = streetTypeId
  houseNumber.value = props.modelValue.houseNumber
  building.value = props.modelValue.building
  staircase.value = props.modelValue.staircase
  floor.value = props.modelValue.floor
  door.value = props.modelValue.door

  isLoading.value = false
})

function getSettlementName(zipData) {
  emit('setZipCode', zipData)
  if (zipData.zipCode !== null) {
    getPublicDomains(zipData.zipCode, zipData.settlement)
    getPublicPlaces()
  } else {
    streetName.value = null
    streetType.value = null
    houseNumber.value = null
    building.value = null
    staircase.value = null
    floor.value = null
    door.value = null
    streetOptions.value = []
    streetTypeOptions.value = []
  }
  emitAddressData()
}

const getPublicDomains = async (zip, settlement) => {
  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Addresses/GetPublicDomains',
      {
        params: { zipCode: zip, settlement: settlement },
      }
    )
    .then((response) => {
      if (response.data.addresses !== undefined) {
        if (response.data.addresses !== null) {
          streetOptions.value = []
          response.data.addresses.forEach((item) => {
            const streetNameIndexOf = streetOptions.value.find(
              (element) => element.value === item.streetName
            )

            if (streetNameIndexOf === undefined) {
              streetOptions.value.push({
                value: item.streetName,
                label: item.streetName,
              })
            }
          })
        }
      }
    })
}

const getPublicPlaces = async () => {
  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Addresses/GetPublicPlaces'
    )
    .then((response) => {
      if (response.data.responseData !== undefined) {
        if (response.data.responseData !== null) {
          streetTypeOptions.value = []
          response.data.responseData.forEach((item) => {
            streetTypeOptions.value.push({
              value: item.code,
              label: item.name,
            })
          })
        }
      }
    })
}

function emitAddressData() {
  let streetTypeLabel = streetTypeOptions.value.find(
    (x) => x.value === streetType.value
  )
  streetTypeLabel = streetTypeLabel !== undefined ? streetTypeLabel.label : null

  emit(
    'setAddressData',
    streetName.value,
    streetTypeLabel,
    houseNumber.value,
    building.value,
    staircase.value,
    floor.value,
    door.value
  )
}

watch(
  [streetName, streetType, houseNumber, building, staircase, floor, door],
  () => {
    emitAddressData()
  }
)
</script>

<style lang="scss" scoped>
.row {
  --bs-gutter-x: 1rem;
}
.page {
  &__header {
    h1 {
      margin-bottom: 0.5rem;
      font-size: 2rem;
      line-height: 2.5rem;
      @media (max-width: $breakpoint-md-max) {
        font-size: 1.75rem;
        line-height: 2.25rem;
      }
      @media (max-width: $breakpoint-xs) {
        margin-bottom: 0;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 1.25rem;

      @media (min-width: $breakpoint-xl) {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: $D-500;
    font-size: 0.875rem;
    line-height: 1.25rem;

    &--highlighted {
      color: $secondary;
    }
  }

  &__box {
    padding: 1rem 1rem 0 1rem;
    background: #f7f7fb;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 0.75rem;

    @media (min-width: 767.98px) {
      padding: 2rem 2rem 1rem 2rem;
      flex-direction: row;
    }

    @media (min-width: $breakpoint-xl-max) {
      padding: 2.5rem 2.5rem 1.5rem 2.5rem;
      flex-direction: row;
    }

    :deep(.q-field__bottom.row.items-start.q-field__bottom--animated) {
      min-height: 1rem !important;
    }
    &--detailed-description {
      padding: 2.5rem;

      @media (max-width: $breakpoint-md-max) {
        padding: 2rem;
      }

      @media (max-width: $breakpoint-xs-max) {
        padding: 1rem;
      }
    }

    &--no-inputs {
      padding: 2.5rem;
    }

    &__name {
      min-width: 15rem;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: left;
      color: #1d2c5b;

      @media (min-width: $breakpoint-xs) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    &__inputs {
      width: 100%;

      .text-input {
        &--hidden {
          @media (min-width: $breakpoint-xl) {
            display: none;
          }
        }
      }
    }

    &__group {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }

    &--declarer & {
      &__group {
        span {
          color: $D-500;
          position: relative;
          bottom: 0.625rem;
          font-size: 0.75rem;
          line-height: 1.25rem;

          @media (min-width: $breakpoint-xs) {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        div:first-child {
          display: flex;
          align-items: center;
          gap: 1rem;

          > .text-input {
            width: 4.5rem;
          }
        }

        .text-input {
          width: 100%;
        }
      }
    }

    &--signatory & {
      &__group {
        display: none;

        &--mobile {
          @media (max-width: 767.98px) {
            display: block;
          }

          > div {
            display: flex;
            gap: 1rem;

            .text-input {
              width: 50%;
            }
          }
        }

        &--tablet {
          @media (min-width: 767.98px) and (max-width: $breakpoint-xl-max) {
            display: block;
          }

          > div {
            display: flex;
            gap: 1rem;

            .text-input {
              width: 50%;
              display: block;
            }

            > div {
              display: flex;
              gap: 1rem;
            }
          }
        }

        &--desktop {
          @media (min-width: $breakpoint-xl) {
            display: block;
            width: 100%;
          }
          > div {
            > div:first-of-type {
              padding-right: 0.5rem;
            }
            > div:last-of-type {
              padding-left: 0.5rem;
            }

            > div:not(:first-of-type):not(:last-of-type) {
              padding-left: 0.5rem;
              padding-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.addres-inputs {
  display: flex;
  gap: 0.5rem;
}
.padding-street-type {
  padding-right: 0.5rem;
  @media (min-width: 993px) {
    padding-left: 0.5rem;
  }
}

.padding-housenumber {
  padding-left: 0.5rem;
}

.padding-street-name {
  @media (min-width: 993px) {
    padding-right: 0.5rem;
  }
}

.kozterulet-tipusa {
  :deep(.q-field__append.q-field__marginal.row.no-wrap.items-center) {
    width: 0;
  }

  :deep(.q-field__control.relative-position.row.no-wrap.bg-white, .q-field--standout
      .q-field__control) {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
}
.select-address {
  :deep(.q-field__label) {
    white-space: nowrap;
  }
}
</style>
