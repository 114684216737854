<template>
  <div class="document-holder-container">
    <div 
      v-if="!isSafari"
      class="all-documents-holder"
    >
      <p class="all-documents-paragraph">
        Az alábbi gomb segítségével egyben letöltheti az összes dokumentumot,
        ezután nyilatkozhat tartalmuk megismeréséről, a feltételek
        elfogadásáról.
      </p>
      <q-btn 
        class="download-btn"
      >
        <PhIconManager
          width="1rem"
          height="1rem"
          ph-icon="ph-file-arrow-down"
        />
        <div
          class="btn-title"
          @click="DownloadAllDocumentum"
        >
          Összes dokumentum letöltése
        </div>
      </q-btn>
    </div>
    <div v-if="!isSafari">
      <CheckboxInput
        id="KALK_292"
        class="allagree-checkbox-holder"
        validation-rules="VAL_372"
        @update:model-value="AcceptAllStatements"
      >
        Összes elfogadása
      </CheckboxInput>
    </div>
    <div class="documentum-holder">
      <p class="documentum-paragraph">
        Az ajánlat megtételét megelőzően a vonatkozó biztosítási feltételeket
        megismertem és elfogadom, valamint a megkötendő biztosítási szerződéssel
        összefüggésben a biztosítási ajánlaton és a biztosítási feltételekben
        rögzített tájékoztatást kaptam.
      </p>
      <q-btn
        class="download-btn"
        @click="DownloadAcceptCondition"
      >
        <PhIconManager
          width="1rem"
          height="1rem"
          ph-icon="ph-file-arrow-down"
        />
        <div class="btn-title">
          Biztosítási feltételek letöltése
        </div>
      </q-btn>
      <div>
        <CheckboxInput
          id="KALK_214"
          v-model:model-value="viewModel.AcceptCondition.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_156|VAL_161|VAL_166"
        >
          Elfogadom
        </CheckboxInput>
      </div>
    </div>
    <div class="documentum-holder">
      <p class="documentum-paragraph">
        Kijelentem, hogy a biztosítási ajánlat megtétele előtt megismertem a
        Lakásbiztosításra vonatkozó Biztosítási Termékismertetőt.
      </p>
      <q-btn
        class="download-btn"
        @click="DownloadProductGuide"
      >
        <PhIconManager
          width="1rem"
          height="1rem"
          ph-icon="ph-file-arrow-down"
        />
        <div class="btn-title">
          TERMÉKISMERTETŐ LETÖLTÉSE
        </div>
      </q-btn>
      <div>
        <CheckboxInput
          id="KALK_213"
          v-model:model-value="viewModel.ProductGuide.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_154|VAL_159|VAL_164"
        >
          Kijelentem
        </CheckboxInput>
      </div>
    </div>
    <div class="documentum-holder">
      <p class="documentum-paragraph">
        Kijelentem, hogy a biztosítási ajánlat megtétele előtt a biztosítási
        igényeimet, szükségleteimet felmérték és a javasolt biztosítási termék
        összhangban van az igényfelmérés során általam megadott adatokkal.
      </p>
      <div>
        <CheckboxInput
          id="KALK_216"
          v-model:model-value="viewModel.ClaimSurvey.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_371|VAL_372|VAL_373"
        >
          Kijelentem
        </CheckboxInput>
      </div>
    </div>
    <div class="documentum-holder">
      <p class="document-subtitle">
        Felhívjuk a figyelmét, hogy a biztosító a biztosítási szerződésekre
        vonatkozó általános feltételekben, az Egyéb rendelkezések között, a
        14/D. fejezetében foglalta össze a Polgári Törvénykönyvtől és a szokásos
        szerződési gyakorlattól lényegesen eltérő rendelkezéseket.
      </p>
      <p class="documentum-paragraph">
        A biztosítási szerződésekre vonatkozó általános feltételeknek a Polgári
        Törvénykönyvtől és a szokásos szerződési gyakorlattól lényegesen eltérő
        rendelkezéseit tartalmazó fejezeteiben foglaltakat kifejezetten
        elfogadom.
      </p>
      <div>
        <CheckboxInput
          id="KALK_215"
          v-model:model-value="viewModel.CivilCode.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_157|VAL_162|VAL_216"
        >
          Elfogadom
        </CheckboxInput>
      </div>
    </div>
    <div
      v-if="!viewModel.Packages.Start.Selected.Value"
      class="documentum-holder"
    >
      <p class="documentum-paragraph">
        Felhívjuk a figyelmet arra, hogy a biztosítási szerződésből eredő
        igények 2 év elteltével elévülnek. A jelen nyilatkozat megtételével, a
        fenti külön tájékoztatást követően, kifejezetten elfogadom a szerződésre
        irányadó elévülési szabályokat.
      </p>
      <div>
        <CheckboxInput
          id="KALK_237"
          v-model:model-value="viewModel.OutdateStatement.Value"
          class="allagree-checkbox-holder"
          validation-rules="VAL_100_CHECKBOX_DOCUMENTS"
        >
          Elfogadom
        </CheckboxInput>
      </div>
    </div>
    <div class="documentum-holder">
      <p class="document-subtitle">
        Felhívjuk a figyelmét, hogy a biztosítási ajánlatot, annak beérkezését
        követő 15 napon belül a biztosító jogosult indoklás nélkül
        visszautasítani abban az esetben is, amennyiben a fenti időszak alatt
        biztosítási esemény következik be. Tájékoztatjuk, hogy a biztosító az
        ajánlat elfogadásához egyedi kockázatelbírást végez.
      </p>
    </div>
  </div>
</template>
<script setup>
import { inject, onMounted, ref } from 'vue'
import { defineRule } from 'vee-validate'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import {
  customDefineRule
} from '@/utils/validations/customDefineRule'

const viewModel = inject('viewModel')
const converter = inject('converter')
const { callValidate } = inject('wizard')


const isSafari = ref(false)

onMounted(() =>{
 if((!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari'))){
    isSafari.value = true
 }
})

function AcceptAllStatements(value) {
  if (value) {
    viewModel.value.AcceptCondition.Value = true
    viewModel.value.ProductGuide.Value = true
    viewModel.value.ClaimSurvey.Value = true
    viewModel.value.CivilCode.Value = true
    viewModel.value.OutdateStatement.Value = true
  } else {
    viewModel.value.AcceptCondition.Value = false
    viewModel.value.ProductGuide.Value = false
    viewModel.value.ClaimSurvey.Value = false
    viewModel.value.CivilCode.Value = false
    viewModel.value.OutdateStatement.Value = false

  }
}

async function DownloadAcceptCondition() {
  const link = document.createElement('a')
  downloadCalled.value = true
  if(viewModel.value.Packages.SelectedPackageId === 0)
  {
    link.href = viewModel.value.MFOAcceptConditionDocumentUrl
  }
  else{
    link.href = viewModel.value.GTLakasAcceptConditionDocumentUrl
  }
  link.setAttribute('target', '_blank')
  link.click()
  viewModel.value.HasAcceptConditionOpened.Value = true
  await callValidate()
  downloadCalled.value = false
}

async function DownloadProductGuide() {
  const link = document.createElement('a')
  downloadCalled.value = true
  if(viewModel.value.Packages.SelectedPackageId === 0)
  {
    link.href = viewModel.value.MFOProductGuideDocumentUrl
  }
  else{
    link.href = viewModel.value.GTLakasProductGuideDocumentUrl
  }
  link.setAttribute('target', '_blank')
  link.click()
  viewModel.value.HasProductGuideOpened.Value = true
  await callValidate()
  downloadCalled.value = false
}
const downloadCalled = ref(false)
function DownloadAllDocumentum() {
  DownloadAcceptCondition()
  DownloadProductGuide()
  viewModel.value.HasAcceptConditionOpened.Value = true
  viewModel.value.HasProductGuideOpened.Value = true
}

customDefineRule('VAL_156', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_156
  } else {
    return true
  }
})

customDefineRule('VAL_161', (value) => {
  if (
    value === true &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_161
  } else {
    return true
  }
})

customDefineRule('VAL_166', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value !== undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === true) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_166
  } else {
    return true
  }
})

customDefineRule('VAL_154', (value) => {
  if (
    value === false &&
    (viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_154
  } else {
    return true
  }
})

customDefineRule('VAL_159', (value) => {
  if (
    value === true &&
    (viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false)
  ) {
    return ValidationMessages.VAL_159
  } else {
    return true
  }
})

customDefineRule('VAL_164', (value) => {
  if (
    value === false &&
    (viewModel.value.HasProductGuideOpened.Value !== undefined ||
      viewModel.value.HasProductGuideOpened.Value === true) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_164
  } else {
    return true
  }
})

customDefineRule('VAL_371', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false)
  ) {
    return ValidationMessages.VAL_371
  } else {
    return true
  }
})

customDefineRule('VAL_372', (value) => {
  if (
    value === true &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false ||
      viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false)
  ) {
    return ValidationMessages.VAL_372
  } else {
    return true
  }
})

customDefineRule('VAL_373', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value !== undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === true) &&
    (viewModel.value.HasProductGuideOpened.Value !== undefined ||
      viewModel.value.HasProductGuideOpened.Value === true) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_373
  } else {
    return true
  }
})

customDefineRule('VAL_157', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value === undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === false) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_157
  } else {
    return true
  }
})

customDefineRule('VAL_162', (value) => {
  if (
    value === true &&
    (viewModel.value.HasProductGuideOpened.Value === undefined ||
      viewModel.value.HasProductGuideOpened.Value === false)
  ) {
    return ValidationMessages.VAL_162
  } else {
    return true
  }
})

customDefineRule('VAL_216', (value) => {
  if (
    value === false &&
    (viewModel.value.HasAcceptConditionOpened.Value !== undefined ||
      viewModel.value.HasAcceptConditionOpened.Value === true) && downloadCalled.value == false
  ) {
    return ValidationMessages.VAL_216
  } else {
    return true
  }
})
customDefineRule('VAL_100_CHECKBOX_DOCUMENTS', (value) => {
    if ((value == false || value == null) && downloadCalled.value == false) {
      return ValidationMessages.VAL_100
    }
    return true
})
</script>
<style lang="scss" scoped>
.document-holder-container {
  background: #ebebf5;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 34rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.all-documents-paragraph {
  padding-bottom: 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.download-btn {
  min-height: unset;
  padding: 0;
  border-radius: 0.375rem;
  align-items: none;
  width: fit-content;
  :deep(.q-btn__content) {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    border: 0.0625rem solid #4e95fb;
    border-radius: 0.375rem;
    background-color: white;
    padding: 0.25rem 0.75rem;
    .btn-title {
      width: auto;
      color: #4e95fb;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    svg {
      width: auto;
      max-width: unset;
      color: #4e95fb;
    }
  }
}
.allagree-checkbox-holder {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  align-items: center;
  margin: 0 !important;
}
.documentum-holder {
  background: #ffffff;
  box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
    0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  .documentum-paragraph {
    margin: 0;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4a567c;
  }
  .document-subtitle {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4a567c;
    margin: 0 !important;
  }
  :deep(.q-field__inner){
    padding-bottom: 0;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .document-holder-container {
    width: 100%;
  }
}
</style>
