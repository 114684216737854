<template>
  <component :is="markup">
    <slot />
  </component>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  htag: {
    type: String,
    required: true,
  },
})

const markup = ref(props.htag)
</script>

<script>
export default {
  name: 'TitleElement',
}
</script>
