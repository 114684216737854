<template>
  <div>
    <Toggle
      v-model="value"
      :disabled="isDisabled"
      class="toggle-genertel"
      @change="$emit('update:model', value)"
    >
      <template #label="{ checked, classList }">
        <PhIconManager
          v-if="checked"
          ph-icon="ph-check"
          :class="classList.label"
          :size="16"
          color="#FFFFFF"
        />
        <PhIconManager
          v-if="!checked"
          ph-icon="ph-x"
          :class="classList.label"
          :size="16"
          color="#FFFFFF"
        />
      </template>
    </Toggle>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import Toggle from '@vueform/toggle'

const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },

  model: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['update:model'])

const value = ref(null)

onBeforeMount(() => {
  value.value = props.model
})

function change() {
}
</script>

<style src="@vueform/toggle/themes/default.scss"></style>
