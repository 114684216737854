<template>
  <div
    v-if="hasAlert"
    class="alert alert-secondary alert-dismissible fade show"
    role="alert"
  >
    <div      
      class="alert-content"
    >
      <div class="alert-date">
        {{ time }}
      </div>
      <div class="alert-title">
        {{ textData.title }}
      </div>
      <div
        v-sanitize="textData.description"
        class="alert-desc"
      />
    </div>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    >
      <PhIconManager
        ph-icon="ph-x"
        :size="16"
        color="#4E95FB"
      />
    </button>
  </div>
  <div
    v-if="isLoading"
    class="breaking-news-skeleton alert alert-secondary show"
  />
</template>

<script setup>
import { ref, onMounted, computed} from 'vue'
import axios from 'axios'

const props = defineProps({
	route: {
		type: String,
		required: true
	}
})

const textData = ref(null)
const hasAlert = ref(false)

const time = computed(() => {
  if (textData.value) {
	return textData.value.date.replace('T', ' ')
  }
  return ""
})

onMounted(async () => {
      await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Contents/GetAlert',
		{ params: { route: props.route } }
    )
    .then((response) => {
		if(response.status == 200)
		{
			textData.value = response.data
			hasAlert.value = !textData.value.hideAlert
		}
    })
})

</script>

<script>
export default {
	name: 'BreakingNewsModule',
}
</script>
<style lang="scss" scoped>
.alert {
	margin-bottom: 0;
}

.alert-secondary {
	background-color: $N-200;
	padding: $double;
	.alert-content {
		.alert-date {
			font-weight: 400;
			font-size: 0.75rem;
			line-height: 1.25rem;
			color: $N-800;
			margin-bottom: 0.875rem;
			padding: 0;
		}
		.alert-title {
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 2rem;
			color: $dark;
			margin-bottom: 0.125rem;
		}
		.alert-desc {
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 1.25rem;
			color: $dark;
			.text {
				display: inline;
				width: 100%;
			}
			:deep(a) {
				color: $secondary;
				display: inline;
			}
		}
	}
	.btn-close {
		padding: 0;
		width: 2rem;
		height: 2rem;
		border: 1px solid $secondary;
		border-radius: 0.375rem;
		color: $secondary;
		background: none;
		margin: $double;
	}
}

@media only screen and (max-width: $breakpoint-xs-max) {
	.alert-secondary {
		padding: $normal;
		.alert-content {
			.alert-date {
				font-size: 0.75rem;
				line-height: 1.25rem;
				margin-bottom: 0.25rem;
			}
			.alert-title {
				font-size: 1.25rem;
				line-height: 1.75rem;
			}
			.alert-desc {
				font-weight: 400;
				font-size: 0.75rem;
				line-height: 1.25rem;
				margin-bottom: $normal;
				.text {
					max-width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					display: block;
				}
			}
		}
		.btn-close {
			width: 1.5rem;
			height: 1.5rem;
			margin: $normal;
		}
	}
}

.breaking-news-skeleton{
	height: 9.375rem;
}

@media screen and (max-width: $breakpoint-md-max) {
.breaking-news-skeleton{
	height: 10.9375rem;
}
}

@media screen and (max-width: $breakpoint-xs-max) {
.breaking-news-skeleton{
	height: 12rem;
}
}
</style>
