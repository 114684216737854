import { defineStore } from 'pinia'

export const useCalculatorStore = defineStore('calculator', {
  state: () => {
    return {
      showAnnualFee: true,
      showCart: false,      
      household: {
        insuranceType: null,
        buildingType: null,
        estateZipCode: null,
        couponId: null,
      },
      thankYouPage: {
        clientId: null,
        offerNumber: null,
        product: null,
        documentId: null,
        selected: null,
        payment: null,
        emailVerificationDeadline: null,
        estateZipCode: null,
        isMFO: null,
      },
      hasPushedMarketingStatementAlready: false,
      savedMode: false,
      marketingStatement: null,
      actualGame: null,
      uniqueGame: null,
      validCoupon: false,
      metaData: {
        Additionals: null,
        thankspageDatalayerService: null,
        Packages: null,
      },
    }
  },

  getters: {
    getShowAnnualFee(state) {
      return state.showAnnualFee
    },
    getShowCart(state) {
      return state.showCart
    },
    getHousehold(state) {
      return state.household
    },
    getThankYouPage(state) {
      return state.thankYouPage
    },
    getHasPushedMarketingStatementAlready(state) {
      return state.hasPushedMarketingStatementAlready
    },
    getSavedMode(state) {
      return state.savedMode
    },
    getMarketingStatement(state){
      return state.marketingStatement
    },
    getActualGame(state){
      return state.actualGame
    },
    getUniqueGame(state){
      return state.uniqueGame
    },
    getValidCoupon(state){
      return state.validCoupon
    },
    getMetaData(state) {
      return state.metaData
    }
  },

  actions: {
    setShowAnnualFee(value) {
      this.showAnnualFee = value
    },
    setShowCart(value) {
      this.showCart = value
    },
    sethousehold(insuranceType, buildingType, estateZipCode,couponId) {
      ;(this.household.insuranceType = insuranceType),
        (this.household.buildingType = buildingType),
        (this.household.estateZipCode = estateZipCode),
        (this.household.couponId = couponId)
    },
    setThankYouPage(
      clientId,
      offerNumber,
      product,
      documentId,
      selected,
      payment,
      emailVerificationDeadline,
      estateZipCode,
      isMFO
    ) {
      ;(this.thankYouPage.clientId = clientId),
        (this.thankYouPage.offerNumber = offerNumber),
        (this.thankYouPage.product = product),
        (this.thankYouPage.documentId = documentId),
        (this.thankYouPage.selected = selected),
        (this.thankYouPage.payment = payment),
        (this.thankYouPage.emailVerificationDeadline =
          emailVerificationDeadline),
        (this.thankYouPage.estateZipCode = estateZipCode),
        (this.thankYouPage.isMFO = isMFO)
      },
    setHasPushedMarketingStatementAlready(value) {
      this.hasPushedMarketingStatementAlready = value
    },
    setSavedMode(value) {
      this.savedMode = value
    },
    setMarketingStatement(value){
      this.marketingStatement = value
    },
    setActualGame(value){
      this.actualGame = value
    },
    setUniqueGame(value){
      this.uniqueGame = value
    },
    setValidCoupon(value){
      this.validCoupon = value
    },
    setMetaData(Additionals,thankspageDatalayerService,Packages) {
      ;(this.metaData.Additionals = Additionals),
        (this.metaData.thankspageDatalayerService = thankspageDatalayerService),
        (this.metaData.Packages = Packages)
    },
  },
})
