<template>
  <MFODesktopPackageRowLayout>
    <template #first>
      <SubmodusTitle
        :icon-size="16"
        :tooltip-content="texts.vtxt_kalk221_tooltipContent"
        :tooltip-title="'Összes melléképület ingóságának becsült értéke'"
      >
        Összes melléképület ingóságának becsült értéke
      </SubmodusTitle>
    </template>
    <template #second>
      <NumberInputPackage
        id="KALK_221_Extra"
        v-model="viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value"
        :maxlength="21"
        validation-rules="VAL_361"
        :init-input-value="
          viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value ==
            undefined
            ? viewModel.SteadingPersonalytySumValue.Value
            : viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value
        "
        steading-personalyty-sum-value
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="
          extraChanged(
            viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #third>
      <NumberInputPackage
        id="KALK_221_Komfort"
        v-model="viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value"
        :maxlength="21"
        validation-rules="VAL_361"
        :init-input-value="
          viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value ==
            undefined
            ? viewModel.SteadingPersonalytySumValue.Value
            : viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value
        "
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="
          komfortChanged(
            viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #fourth>
      <NumberInputPackage
        id="KALK_221_StartPlus"
        v-model="viewModel.Packages.StartPlus.SteadingPersonalEstateSumBO.Value"
        :maxlength="21"
        validation-rules="VAL_361"
        :init-input-value="
          viewModel.Packages.StartPlus.SteadingPersonalEstateSumBO.Value ==
            undefined
            ? viewModel.SteadingPersonalytySumValue.Value
            : viewModel.Packages.StartPlus.SteadingPersonalEstateSumBO.Value
        "
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="
          startPlusChanged(
            viewModel.Packages.StartPlus.SteadingPersonalEstateSumBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #fifth>
      <NumberInputPackage
        id="KALK_221_Start"
        v-model="viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value"
        :maxlength="21"
        validation-rules="VAL_361"
        :init-input-value="
          viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value ==
            undefined
            ? viewModel.SteadingPersonalytySumValue.Value
            : viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value
        "
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        :is-disabled="isMFODisabled"
        postfix="Ft"
        :revalidate="revalidate"
        @value-changed="
          startChanged(
            viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
  </MFODesktopPackageRowLayout>
</template>
<script setup>
import { inject,onMounted,ref } from 'vue'

const texts = inject('texts')
let viewModel = inject('viewModel')
const emit = defineEmits(['calculate'])
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')


onMounted(() => {
  setInitinputValues()
})

const revalidate  = ref(false)

function revalidateinput(){
  revalidate.value = true
}

function startChanged(value) {
  if (viewModel.value.MNBMode == undefined) {
    viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.SteadingPersonalytySumValue.Value = value
  }
  emit('calculate')
}

function startPlusChanged(value) {
  viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.SteadingPersonalytySumValue.Value = value

  emit('calculate')
}

function komfortChanged(value) {
  viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.SteadingPersonalytySumValue.Value = value

  emit('calculate')
}
function extraChanged(value) {
  viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value = value
  viewModel.value.SteadingPersonalytySumValue.Value = value

  emit('calculate')
}

function setInitinputValues() {
  if (viewModel.value.SteadingPersonalytySumValue.Value) {
    viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value =
      viewModel.value.SteadingPersonalytySumValue.Value
    viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value =
      viewModel.value.SteadingPersonalytySumValue.Value
    viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value =
      viewModel.value.SteadingPersonalytySumValue.Value
    viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value =
      viewModel.value.SteadingPersonalytySumValue.Value
  } else {
    viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value = 0
    viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value = 0
    viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value = 0
    viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value = 0
  }
}
</script>
<style lang="scss" scoped></style>
