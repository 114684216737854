<template>
  <div class="service-holder">
    <div class="service-title-holder">
      <div class="icon-holder text-primary">
        <PhIconManager
          :ph-icon="service.icon"
          :size="40"
        />
      </div>
      <div class="text-holder">
        <div class="title-holder">
          <Title
            htag="h2"
            class="m-0"
          >
            {{ service.title }}
          </Title>
          <Tooltip
            class="customerservice-tooltip"
            :tooltip-content="service.tooltip"
            tooltip-placement="bottom"
            custom-class="customerservice-tooltip"
          />
        </div>
        <div
          v-sanitize="service.description"
          class="description-holder"
        />
      </div>
    </div>

    <div
      v-if="service.link"
      class="button-holder"
    >
      <q-btn
        id="CS1"
        class="btn btn-secondary btn-dense gtmContactButtons"
        @click="navigateToSite"
      >
        <div class="button-content text-white">
          <span>{{ service.linkText }}</span>
          <PhIconManager
            ph-icon="ph-caret-right"
            :size="16"
          />
        </div>
      </q-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  service: {
    type: Object,
    default: () => {
      return {
        name: 'Telefonszámok megtekintése',
        title: 'Telefonszámok megtekintése',
        isAdditionalContactDetails: false,
        description: '',
        icon: '',
        tooltip: '',
        linkText: '',
        link: '',
        alreadyUserDialog: '',
      }
    },
    required: true,
  },
})

const router = useRouter()
const emit = defineEmits(['openDialog'])

function navigateToSite() {
  if (props.service.alreadyUserDialog) {
    emit('openDialog')
  } else {
    if (props.service.link.indexOf('https://') >= 0) {
      window.open(props.service.link, '_self')
    } else {
      router.push({ path: props.service.link })
    }
  }
}
</script>

<style lang="scss" scoped>
.customerservice-section {
  padding-top: 5rem !important;
  padding-bottom: 2.5rem !important;
  .grid-container {
    display: grid;
    grid-template-columns: $onethird $onethird $onethird;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .grid-item {
      padding: 0.5rem;
    }
  }
  .section-content {
    padding: 2rem 2.5rem;
    background: #f7f7fb;
    border-radius: 0.75rem;
  }
  .title-holder {
    margin-bottom: 0.5rem;

    h1 {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.5rem;

      color: #1d2c5b;
    }
  }

  .subtitle-holder {
    margin-bottom: 5rem;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #1d2c5b;
  }

  .grid-item:last-child.grid-item .service-holder {
    background: #eef0f7;
    height: auto;
    border: 1px solid #e6e8f3;
    border-radius: 0.75rem;
    .text-holder {
      margin-bottom: 0;
      .title-holder {
        margin-bottom: 1.75rem;
      }
    }
  }
  .service-holder {
    background: #ffffff;
    border: 1px solid #eef0f7;
    border-radius: 0.75rem;
    padding: 2rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title-holder {
      display: flex;
    }
    .service-title-holder {
      .icon-holder {
        margin-bottom: 0.5rem;
        line-height: 1.5rem;
      }
      .text-holder {
        margin-bottom: 1rem;
        color: #1d2c5b;
        .address-title {
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.5rem;
        }
        .address-holder {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
      h2,
      h4 {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1d2c5b;
      }
      .description-holder {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #4a567c;
        :deep(strong) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
    .button-holder {
      .btn {
        padding: 0.5rem 1rem;
        border-radius: 0.375rem;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1rem;
        display: flex;
        min-height: 2rem;

        svg {
          margin-left: $half;
        }
        span {
          letter-spacing: 0 !important;
        }
        .button-content {
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
    }
    :deep(.customerservice-tooltip) {
      a {
        color: #1d2c5b;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .customerservice-section {
    padding: 2.5rem 0 !important;
    .section-content {
      padding-bottom: 1.25rem;
    }
    .container-xxl {
      padding: 0 2rem !important;
    }

    .grid-container {
      grid-template-columns: 100%;
      margin: -0.25rem;
      .grid-item {
        padding: 0.25rem;
      }
    }

    .title-holder {
      h1 {
        font-size: 1.75rem;
        line-height: 2.25rem;
      }
    }
    .subtitle-holder {
      margin-bottom: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .section-content {
      padding: 1.5rem 1rem;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .customerservice-section {
    padding: 2rem 0 !important;

    .container-xxl {
      padding: 0 1rem !important;
    }

    .section-content {
      padding-bottom: 1.25rem;
    }
    .title-holder {
      margin-bottom: 0.25rem;
      h1 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .subtitle-holder {
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1.5rem;
    }

    .service-holder {
      .service-title-holder {
        .title-holder {
          margin-bottom: 0.5rem;
        }
        .description-holder {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #4a567c;
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
