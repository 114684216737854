<template>
  <div class="calculator-buttons">
    <q-btn
      class="btn btn-secondary btn-outline btn-back"
      @click="closeModal"
    >
      Mégsem
    </q-btn>
    <q-btn
      class="btn btn-secondary"
      @click="applyChanges"
    >
      Kész
    </q-btn>
  </div>
</template>
<script setup>
const emit = defineEmits(['closeModal', 'applyChanges'])

function closeModal() {
  emit('closeModal');
}

function applyChanges() {
  emit('applyChanges');
}

</script>
<style lang="scss" scoped>
.calculator-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 5rem;
  .btn-secondary {
    padding: .75rem 1.25rem;

    :deep(.q-btn__content) {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .btn-back {
    padding: .625rem 1.125rem;
  }
}

@media screen and (max-width: $breakpoint-lg-max) {
  .calculator-buttons {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .calculator-buttons {
    margin-top: 2rem;
    flex-direction: column-reverse;
    .btn-secondary {
      width: 100%;
    }
  }
}
</style>
