<template>
  <div
    v-if="!isLoading"
    class="container-fluid google-score-holder"
  >
    <div
      v-if="contentData.selectedModule.googleRating"
      class="container gs-container"
    >
      <div class="google-score">
        <div class="logo-holder">
          <img
            :src="'/assets/images/genertel-logo-dark.svg'"
            width="112"
            height="32"
            alt="Genertel logo"
          >
        </div>
        <div class="wrapperzg">
          <Tooltip
            :tooltip-content="contentData.tooltip"
            :icon-size="24"
          />
          <div
            v-sanitize="contentData.title"
            class="small-text"
          />
          <PhIconManager
            ph-icon="ph-star"
            class="star"
            :size="32"
            color="#F7B13C"
            weight="fill"
          />
          <a
            :href="contentData.ratingUrl"
            target="_blank"
          >
            <div
              v-sanitize="
                ratingData
                  ? ratingData.averageRating
                  : contentData.scoreFallback
              "
              class="score"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper'

const isLoading = ref(true)
const contentData = ref(null)
const ratingData = ref(null)

const props = defineProps({
  insuranceType: {
    type: String,
    default: '',
    required: true,
  },
})

const insuranceType = ref(props.insuranceType)
const emit = defineEmits(['selectedModule'])

onMounted(async () => {
  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Contents/GoogleRating',
      {
        params: { id: insuranceType.value },
      }
    )
    .then((response) => {
      contentData.value = response.data
      emit('selectedModule', contentData.value.selectedModule.googleRating)
      isLoading.value = false
    })

/* TODO: implement it after Google Rating API backend
  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/GoogleRating/GoogleRating'
    )
    .then((response) => {
      ratingData.value = response.data
      isLoading.value = false
    })
    .catch((error) => {
      if (error.response) {
        isLoading.value = false
      } else if (error.request) {
        isLoading.value = false
      } else {
        isLoading.value = false
      }
    }) */
})

const componentUrl = import.meta.url
</script>

<script>
export default {
  name: 'GoogleScore',
}
</script>
<style lang="scss">
.google-score-holder {
  position: absolute;
  .container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;
  }
}
.google-score {
  padding: 0.75rem 1rem;
  width: 12.375rem;
  height: 6.625rem;
  background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
  border: 1px solid #ffffff;
  box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06), 0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  .logo-holder {
    padding-bottom: $half;
    border-bottom: 0.125rem solid $N-500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapperzg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: $half;
    .cursor-pointer.all-pointer-events {
      margin-left: 0;
      a {
        color: $D-700;
      }
    }
    .small-text {
      font-weight: 600;
      font-size: 0.625rem;
      line-height: 0.875rem;
      color: $dark;
      max-width: 3rem;
    }
    .score {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      color: $D-500;
    }
  }
}
@media screen and (max-width: $breakpoint-xl-max) {
  .gs-container {
    max-width: calc(100% - 4rem) !important;
  }
}
</style>
