<template>
  <div class="cookie-module">
    <div class="cookie-content-holder">
      <div
        v-if="!showCookieDetails"
        class="module-title"
      >
        A weboldal “sütiket” használ.
      </div>
      <div
        v-if="showCookieDetails"
        class="module-title mb-24px"
      >
        Sütik beállítása
      </div>
      <div
        v-if="!showCookieDetails"
        class="module-content"
      >
        Tájékoztatjuk, hogy oldalunkon úgynevezett sütiket (cookies) használunk,
        azért, hogy szolgáltatásainkat még inkább az igényeidre szabhassuk.
        Amennyiben beleegyezel a sütik használatába, válaszd a sütik
        elfogadását. Emelett lehetőséged van, hogy egyénileg válaszd ki, milyen
        típusú sütiket engedélyezel. <a href="#">További információ</a>
      </div>
      <div
        v-if="showCookieDetails"
        class="module-content"
      >
        <q-expansion-item
          v-model="mandatoryCookieAccordion"
          :duration="100"
          header-class="cookie-accordion-header"
          expand-icon-class="cookie-accordion-icon"
          expand-icon-toggle
        >
          <template #header>
            <div class="cookie-accordion-header-inner">
              <div class="holder-left">
                <div class="cookie-accordion-toggle-holder">
                  <ToggleInput
                    v-model:model="mandatoryCookie"
                    :is-disabled="true"
                  />
                </div>
                <div class="cookie-accordion-title-holder">
                  Feltétlenül szükséges sütik
                </div>
              </div>
              <div class="holder-right">
                <q-btn
                  class="cookie-accordion-button-holder"
                  @click="mandatoryCookieAccordion = !mandatoryCookieAccordion"
                >
                  <div class="button-content">
                    <span>Részletek</span>
                    <PhIconManager
                      v-if="mandatoryCookieAccordion == false"
                      ph-icon="ph-caret-down"
                      :size="16"
                    />
                    <PhIconManager
                      v-else
                      ph-icon="ph-caret-up"
                      :size="16"
                    />
                  </div>
                </q-btn>
              </div>
            </div>
          </template>
          <q-card class="cookie-accordion-desc-holder">
            <q-card-section>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <q-expansion-item
          v-model="usageHelperCookieAccordion"
          :duration="100"
          header-class="cookie-accordion-header"
          expand-icon-class="cookie-accordion-icon"
          expand-icon-toggle
        >
          <template #header>
            <div class="cookie-accordion-header-inner">
              <div class="holder-left">
                <div class="cookie-accordion-toggle-holder">
                  <ToggleInput v-model:model="usageHelperCookie" />
                </div>
                <div class="cookie-accordion-title-holder">
                  Használatot támogató sütik
                </div>
              </div>
              <div class="holder-right">
                <q-btn
                  class="cookie-accordion-button-holder"
                  @click="
                    usageHelperCookieAccordion = !usageHelperCookieAccordion
                  "
                >
                  <div class="button-content">
                    <span>Részletek</span>
                    <PhIconManager
                      v-if="usageHelperCookieAccordion == false"
                      ph-icon="ph-caret-down"
                      :size="16"
                    />
                    <PhIconManager
                      v-else
                      ph-icon="ph-caret-up"
                      :size="16"
                    />
                  </div>
                </q-btn>
              </div>
            </div>
          </template>
          <q-card class="cookie-accordion-desc-holder">
            <q-card-section>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <q-expansion-item
          v-model="statisticsCookieAccordion"
          :duration="100"
          header-class="cookie-accordion-header"
          expand-icon-class="cookie-accordion-icon"
          expand-icon-toggle
        >
          <template #header>
            <div class="cookie-accordion-header-inner">
              <div class="holder-left">
                <div class="cookie-accordion-toggle-holder">
                  <ToggleInput v-model:model="statisticsCookie" />
                </div>
                <div class="cookie-accordion-title-holder">
                  Statisztikai sütik
                </div>
              </div>
              <div class="holder-right">
                <q-btn
                  class="cookie-accordion-button-holder"
                  @click="
                    statisticsCookieAccordion = !statisticsCookieAccordion
                  "
                >
                  <div class="button-content">
                    <span>Részletek</span>
                    <PhIconManager
                      v-if="statisticsCookieAccordion == false"
                      ph-icon="ph-caret-down"
                      :size="16"
                    />
                    <PhIconManager
                      v-else
                      ph-icon="ph-caret-up"
                      :size="16"
                    />
                  </div>
                </q-btn>
              </div>
            </div>
          </template>
          <q-card class="cookie-accordion-desc-holder">
            <q-card-section>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <q-expansion-item
          v-model="marketingCookieAccordion"
          :duration="100"
          header-class="cookie-accordion-header"
          expand-icon-class="cookie-accordion-icon"
          expand-icon-toggle
        >
          <template #header>
            <div class="cookie-accordion-header-inner">
              <div class="holder-left">
                <div class="cookie-accordion-toggle-holder">
                  <ToggleInput v-model:model="marketingCookie" />
                </div>
                <div class="cookie-accordion-title-holder">
                  Marketing sütik
                </div>
              </div>
              <div class="holder-right">
                <q-btn
                  class="cookie-accordion-button-holder"
                  @click="marketingCookieAccordion = !marketingCookieAccordion"
                >
                  <div class="button-content">
                    <span>Részletek</span>
                    <PhIconManager
                      v-if="marketingCookieAccordion == false"
                      ph-icon="ph-caret-down"
                      :size="16"
                    />
                    <PhIconManager
                      v-else
                      ph-icon="ph-caret-up"
                      :size="16"
                    />
                  </div>
                </q-btn>
              </div>
            </div>
          </template>
          <q-card class="cookie-accordion-desc-holder">
            <q-card-section>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet.
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </div>
    </div>
    <div
      class="cookie-button-holder"
      :class="showCookieDetails ? 'cookie-button-holder-mobile' : ''"
    >
      <q-btn
        class="btn btn-secondary"
        @click="acceptCookies"
      >
        {{ buttonLabel }}
      </q-btn>
      <q-btn
        v-if="!showCookieDetails"
        class="btn btn-outline btn-secondary"
        @click="showCookieDetails = !showCookieDetails"
      >
        Sütik beállítása
      </q-btn>
      <q-btn
        v-if="showCookieDetails"
        class="btn btn-outline btn-secondary"
        @click="acceptCookies"
      >
        Kijelölt sütik elfogadása
      </q-btn>
      <q-btn
        v-if="showCookieDetails"
        class="btn btn-outline btn-secondary btn-infomration"
      >
        További információ
      </q-btn>
    </div>
  </div>
</template>
<script setup>
import { useQuasar } from 'quasar'
import { ref, computed } from 'vue'
import ToggleInput from '../inputs/ToggleInput.vue'

const emit = defineEmits(['cookieAccepted'])
const showCookieDetails = ref(false)
const mandatoryCookie = ref(true)
const mandatoryCookieAccordion = ref(false)
const usageHelperCookie = ref(false)
const usageHelperCookieAccordion = ref(false)
const statisticsCookie = ref(false)
const statisticsCookieAccordion = ref(false)
const marketingCookie = ref(false)
const marketingCookieAccordion = ref(false)

const $q = useQuasar()

const acceptCookies = () => {
  $q.cookies.set('genertel-cookie-law', true, {
    expires: 365,
    path: '/',
  })
  if (usageHelperCookie.value) {
    $q.cookies.set('usageHelperCookie', true, {
      expires: 365,
      path: '/',
    })
  }
  if (statisticsCookie.value) {
    $q.cookies.set('statisticsCookie', true, {
      expires: 365,
      path: '/',
    })
  }
  if (marketingCookie.value) {
    $q.cookies.set('marketingCookie', true, {
      expires: 365,
      path: '/',
    })
  }
  emit('cookieAccepted', true)
}

const buttonLabel = computed(() => {
  return showCookieDetails.value ? 'Minden süti elfogadása' : 'Sütik elfogadása'
})
</script>
<style lang="scss" scoped>
.cookie-module {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0rem 0.5rem 2rem rgba(26, 26, 26, 0.24);
  z-index: 9999;
  width: 100%;
  a {
    color: $secondary;
  }
  .cookie-content-holder {
    margin-right: 2.5rem;
    width: 100%;

    .module-title {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #1d2c5b;
      margin-bottom: 0.125rem;
    }
    .module-content {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #1d2c5b;

      :deep(.cookie-accordion-header) {
        background-color: #ffffff;
        border: 1px solid #e6e8f3;
        border-radius: 0.25rem;
        padding: 1rem;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        .cookie-accordion-header-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .holder-left,
          .holder-right {
            display: inline-flex;
            width: auto;
            align-items: center;
          }
          .cookie-accordion-button-holder {
            border: 1px solid #e6e8f3;
            border-radius: 0.25rem;
            text-transform: uppercase;
            padding: 0.25rem 0.75rem;
            color: #828ba8;
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1rem;

            svg {
              margin-left: 0.25rem;
            }
            .button-content {
              align-items: center;
              display: flex;
              justify-content: center;
            }
          }
          .cookie-accordion-title-holder {
            margin-left: 1rem;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }
      }

      :deep(.cookie-accordion-icon) {
        display: none;
      }
      .cookie-accordion-desc-holder {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #4a567c;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border: 1px solid #e6e8f3;
        border-top: 0;
      }
      :deep(.q-expansion-item) {
        margin-bottom: 0.5rem;
      }
    }
  }
  .cookie-button-holder {
    display: flex;
    flex-direction: column;
    .btn {
      padding: 0.5rem 0.75rem;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1rem;
      border-radius: 0.375rem;
    }
    .btn-secondary {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      min-width: 9.4375rem;
    }

    .btn-secondary:last-child {
      margin-bottom: 0;
    }
  }
  .cookie-button-holder.cookie-button-holder-mobile {
    .btn-secondary {
      min-width: 12.375rem;
    }
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .cookie-module {
    padding: 1.5rem;

    flex-direction: column;
    align-items: unset;
    .cookie-content-holder {
      margin-right: 0;
      margin-bottom: 1rem;
    }
    .cookie-button-holder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .btn {
        width: 100%;
      }
      .btn-secondary {
        margin-right: 0.5rem;

        margin-bottom: 0;
      }
      .btn-secondary:last-child {
        margin-right: 0;
      }
    }
    .cookie-button-holder.cookie-button-holder-mobile {
      .btn-secondary {
        margin-bottom: 0;
        margin-right: 0;
        order: 3;
        &.btn-outline {
          margin-right: 0.5rem;
          order: 2;

          &.btn-infomration {
            order: 1;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-xs-max) {
  .cookie-module {
    padding: 1.5rem 1rem;

    .cookie-content-holder {
      .module-title {
        margin-bottom: 1rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      .module-content {
        font-size: 0.75rem;
        :deep(.cookie-accordion-header) {
          padding: 0.5rem;

          .cookie-accordion-header-inner {
            .cookie-accordion-title-holder {
              font-size: 0.75rem;
            }
          }
        }

        .cookie-accordion-desc-holder {
          font-size: 0.75rem;
        }
      }
    }
    .cookie-button-holder {
      .btn {
        padding: 0.25rem 0.75rem;
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }
    .cookie-button-holder.cookie-button-holder-mobile {
      flex-direction: column;

      .btn-secondary {
        text-transform: unset;
        margin-bottom: 0;

        align-items: center;

        order: 3;
        &.btn-outline {
          margin-bottom: 1rem;
          margin-right: 0;
          order: 2;

          &.btn-infomration {
            order: 1;
          }
        }
      }
    }
  }
}
</style>
