<template>
  <div class="phone-input row">
    <div class="text-dark text-sm pre-dial">
      06
    </div>
    <div class="area-code">
      <div class="text-input">
        <q-input
          :id="id"
          v-model="dialingCode"
          mask="##"
          standout
          :error="!!veeErrors[0]"
          :label="areaCodeLabel"
          bg-color="white"
          maxlength="2"
          @blur="
            handleChange($event), handleInfoWarningMessages(fullTelephoneNumber),inputChanged(value), areaCodeBlur()
          "
          @focus="setMetaTouched"
        >
          <template #label>
            {{ areaCodeLabel }}
          </template>
        </q-input>
      </div>
    </div>
    <div class="phone-number">
      <div class="text-input">
        <q-input
          :id="id"
          v-model="chunkPhoneNumber"
          mask="#######"
          standout
          :error="!!veeErrors[0]"
          :label="label"
          bg-color="white"
          :maxlength="maxLength"
          @blur="
            handleChange($event), handleInfoWarningMessages(fullTelephoneNumber), inputChanged(value), chunkPhoneNumberBlur()
          "
          @focus="setMetaTouched"
        >
          <template #label>
            {{ label }}
            <Tooltip :tooltip-content="tooltipContent" />
          </template>
        </q-input>
      </div>
    </div>
    <div
      v-if="veeErrors[0]"
      class="q-field__inner q-field--with-bottom"
    >
      <div class="q-field__bottom row items-start q-field__bottom--animated">
        <div class="q-field__messages col">
          <ValidationMessage
            :messages="veeErrors"
            :info-messages="infoMessages"
            :warning-messages="warningMessages"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject } from 'vue'
import { useField, defineRule } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { customDefineRule } from '../../utils/validations/customDefineRule'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  mask: {
    type: String,
    required: false,
    default: '',
  },

  label: {
    type: String,
    required: true,
  },

  areaCodeLabel: {
    type: String,
    required: true,
  },

  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },

  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  warningMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  modelValue: {
    type: String,
    required: false,
    default: '0',
  },
  displayType: {
    type: String,
    required: false,
    default: 'flex',
  },
  initInputValue: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue', 'setPhoneNumber'])

const removeValueIfNotValid = inject('_removeValueIfNotValid')

const isFirstInputChange = ref(0)
const infoMessages = ref(null)
const warningMessages = ref(null)
const phonePrecode = ref('06')
const dialingCode = ref(null)
const chunkPhoneNumber = ref(null)
const maxLength = ref(7)
const chunkPhoneNumberBlured = ref(false)

onMounted(() => {
  if (props.initInputValue !== '' && props.initInputValue !== null) {
    const regexThirdChar = /^.{2}[1]/

    let dialingCodeInit = ''
    let chunkPhoneNumberInit = ''

    if (props.initInputValue.match(regexThirdChar)) {
      dialingCodeInit = '1'
      chunkPhoneNumberInit = props.initInputValue.substring(3)
    } else {
      dialingCodeInit = props.initInputValue.substring(2, 4)
      chunkPhoneNumberInit = props.initInputValue.substring(4)
    }
    dialingCode.value = dialingCodeInit
    chunkPhoneNumber.value = chunkPhoneNumberInit
  }
  customDefineRule('VAL_100_PHONE_CUSTOM', (value) => {
    if (isFirstInputChange.value == 1) {
      return true
    }   
    if (    
      !dialingCode.value ||
      !dialingCode.value.length ||
      !chunkPhoneNumber.value ||
      dialingCode.value == 'null' ||
      chunkPhoneNumber.value == 'null' ||
      dialingCode.value == 'null' ||
      chunkPhoneNumber.value == 'null' ||
      !chunkPhoneNumber.value.length
    ) {
      return ValidationMessages.VAL_100
    }
    return true
  })
})


async function validateQForm() {
  // run vee-validate validations
  await veeValidate()

  if (veeErrors.value.length > 0) {
    if(removeValueIfNotValid.value && !meta.valid && meta.touched){
      dialingCode.value = null
      chunkPhoneNumber.value = null
      return true;
    }
  } else {
    return true
  }
}

function areaCodeBlur(){
  isFirstInputChange.value+=2;
  const regexDialingCode = /^(?=([237][01]|1|50))/g
  const isValid = regexDialingCode.test(dialingCode.value)
  if (!isValid) {
    maxLength.value = 6
  } else {
    maxLength.value = 7
  }
}
function chunkPhoneNumberBlur(){
  isFirstInputChange.value++;
  chunkPhoneNumberBlured.value = true
}
function inputChanged() {
  isFirstInputChange.value++;
  emit('update:modelValue', fullTelephoneNumber)
  emit('setPhoneNumber', fullTelephoneNumber)
  validateQForm()
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules
  )
}

const fullTelephoneNumber = computed(() => {
  return phonePrecode.value + dialingCode.value + chunkPhoneNumber.value
})

const validationRules = computed(() => {
  return `VAL_100_PHONE_CUSTOM|VAL_105:${dialingCode.value};${chunkPhoneNumber.value};${isFirstInputChange.value};${chunkPhoneNumberBlured.value}`
})

function setMetaTouched() {
  meta.touched = true
}

const {
  handleChange,
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(props.id, validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
</script>

<style lang="scss" scoped>
.phone-input.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .q-field__inner {
    margin-top: -1rem;
  }
  .text-dark {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
  }
  .area-code {
    display: flex;
    width: 4.5rem;
    margin-right: 0.5rem;
  }
  .phone-number {
    flex: 1;
    .q-input {
      flex: 1;
    }
  }
  :deep(.q-field--standout) {
    &.q-field * {
      color: $dark !important;
      border-width: 0 !important;
    }

    .q-field__control:before {
      background-color: transparent;
    }

    &.q-field--highlighted .q-field__control {
      box-shadow: none;
    }

    .q-field__control {
      border-radius: $half !important;
      padding: 0 1rem !important;
      box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
      border: 1px solid #f5f4f8 !important;
    }

    .q-field__label {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      font-weight: $font-weight-medium;
    }

    .q-field--float {
      .q-field__label {
        transform: translateY(-40%) scale(10 / 12) !important;
      }
    }

    .q-field__native,
    .q-field__prefix,
    .q-field__suffix,
    .q-field__input {
      font-weight: $font-weight-bold !important;
    }

    .q-field__append {
      background: transparent !important;
      width: auto !important;
    }
  }

  :deep(.q-field--standout:hover) {
    .q-field__control {
      border: 1px solid $border-hover !important;
      background-color: $N-100 !important;
    }
  }

  :deep(.q-field--standout.q-field--focused) {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }

  :deep(.q-field--focused:hover) {
    .q-field__control {
      background-color: white !important;
      border: 1px solid $border-active !important;
    }
  }

  :deep(.q-field--float:hover) {
    .q-field__control {
      border: 1px solid $border-data-hover !important;
      background-color: $input-data-bg-hover !important;
    }
  }
  :deep(.q-field--focused:hover) {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }

  :deep(.q-field--error) {
    & * {
      color: $input-text-validation !important;
    }

    .q-field__control {
      border: 1px solid $border-validation !important;
      background-color: white !important;
    }
  }

  :deep(.q-field--standout) {
    &.q-field.q-field--error * {
      color: $input-text-validation !important;
    }
  }

  :deep(.q-field--standout.q-field .q-field__append) {
    background: transparent !important;
    width: auto !important;

    svg,
    svg * {
      color: $N-600 !important;
    }
  }
  :deep(.q-field--error.q-field--with-bottom) {
    padding-bottom: 0rem !important;
  }
}
</style>
