<template>
  <div class="calculator-additional-item">
    <div
      class="additional-name-row"
      :class="underConstrucionToggle ? 'under-consturction' : ''"
    >
      <div class="input-row">
        <div class="checkbox-holder">
          <q-checkbox
            :id="id"
            v-model="value"
            :disable="isDisabled || isAdditionalDisabled"
            @update:model-value="inputChanged"
          />
          <div 
            class="additional-name" 
            :class="isAdditionalDisabled ? 'disabled':''"
          >
            <TitleTooltipModal
              htag="h3"
              class="additional-title"
              :tooltip-title="tooltipTitle"
              :tooltip-content="tooltipcontent"
            >
              {{ title }}
            </TitleTooltipModal>
          </div>
        </div>
        <CalculatorRecommendationTooltip
          :tooltip-content="recomandationtooltip"
          :title="recomendationtitle"
        />
      </div>
      <div
        v-if="underConstrucionToggle"
        class="warning"
      >
        Az Építés-, szerelésbiztosítás kiegészítő fedezetet töröltük az
        ajánlatából. Kérjük, vegye figyelembe, hogy amennyiben az ingatlan
        építés, (valamint építési engedélyhez vagy építési tevékenység
        bejelentéséhez kötött) bővítés, átépítés, felújítás alatt áll, kár
        esetén csak akkor szolgáltat a biztosító, ha ezt a kiegészítő
        biztosítást igénybe vette.
      </div>
    </div>
    <div
      v-if="$slots.package"
      class="more-package"
    >
      <div class="more-button">
        <q-btn
          v-if="!open"
          :ripple="false"
          :unelevated="true"
          @click="open = !open"
        >
          Részletek megtekintése

          <PhIconManager
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-arrow-up"
            class="arrow-down"
          />
        </q-btn>
        <q-btn
          v-if="open"
          :ripple="false"
          :unelevated="true"
          @click="open = !open"
        >
          Részletek bezárása
          <PhIconManager
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-arrow-up"
          />
        </q-btn>
      </div>
      <div
        v-if="open"
        class="more-content"
      >
        <slot name="package" />
      </div>
    </div>
    <slot
      v-if="$slots.single"
      name="single"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, watch, inject } from 'vue'
const { callAdditionals } = inject('wizard')

const value = ref(null)
const underconstruction = ref(props.underConstrucion)
const underConstrucionToggle = ref(false)

const props = defineProps({
  title: {
    type: String,
    required: true,
  },

  tooltipcontent: {
    type: String,
    required: false,
  },
  tooltipTitle: {
    type: String,
    required: false,
  },
  recomendationtitle: {
    type: String,
    required: false,
  },
  recomandationtooltip: {
    type: String,
    required: false,
  },
  package: {
    type: Boolean,
    required: false,
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  underConstrucion: {
    type: String,
  },
  initInputValue: {
    type: Boolean,
    default: null,
  },
    isDisabled: {
    type: Boolean,
  },
  isAdditionalDisabled: {
    type: Boolean,
  },
})
const open = ref(false)
const emit = defineEmits(['update:modelValue'])

async function inputChanged() {
  if (underconstruction.value && value.value == false) {
    underConstrucionToggle.value = true
  } else {
    underConstrucionToggle.value = false
  }
  emit('update:modelValue', value.value)
}
watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
  }
)

onMounted(() => {
  if (props.initInputValue !== null) {
    value.value = props.initInputValue
  }
})
</script>
<style lang="scss" scoped>
.calculator-additional-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .checkbox-holder {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
  .additional-name-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    :deep(.q-checkbox) {
      align-self: center;
      margin: 0 !important;
      width: 1.25rem;
      .q-checkbox__inner {
        margin: 0;
      }
    }
  }
  .q-checkbox.disabled{
    :deep(.q-checkbox__bg){
      background-color: #EEF0F7;
      color: #EEF0F7;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;
      .q-checkbox__indet{
        color: #EEF0F7;
      }
    }

  }
  .additional-name.disabled{
    opacity: 1 !important;
    .additional-title{
      color: #B4B9CB;
    }
    :deep(.title-tooltip){
      color: #1D2C5B !important;
    }
  }
  .under-consturction {
    flex-direction: column;
    .warning {
      padding-top: 1rem;
      color: $D-300;
    }
  }
  .additional-title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    word-break: break-word;
  }
}
.more-button {
  :deep(.q-btn) {
    padding: 0;
  }
  :deep(.q-btn__content) {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $secondary;
    text-transform: none;
    svg {
      width: auto;
      margin-left: 0.5rem;
    }
  }
  .q-btn {
    min-height: unset;
  }
}
.more-content {
  padding-top: 1rem;
}
.arrow-down {
  scale: -1;
}
.input-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: $breakpoint-lg-max) {
  .calculator-additional-item {
    .checkbox-holder {
      gap: 1rem;
    }
  }
}
</style>
