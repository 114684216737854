<template>
  <div class="container footer-menu d-none d-lg-flex d-xxl-flex">
    <div class="row">
      <div
        v-for="columnItem in footerItems"
        :key="columnItem.title"
        class="col"
      >
        <div class="nav-title">
          {{ columnItem.title }}
        </div>
        <nav class="navbar footer-nav">
          <ul>
            <li
              v-for="item in columnItem.items"
              :key="item.title"
              class="nav-item"
            >
              <NavigableLink
                :link-object="item"
                class="nav-link"
                :class="{ gtmClaimMenuItem : item.internalLink == '/karbejelentes' }"
              >
                {{ item.title }}
              </NavigableLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  footerItems: {
    type: Array,
    required: true,
    default: () => [],
  },
})
</script>

<script>
export default {
  name: 'DesktopFooter',
}
</script>
