<template>
  <DescriptionModuleHtml
    v-if="isReady"
    :module-title="moduleTitle"
    :module-sub-title="moduleSubTitle"
  >
    <template #html>
      <div v-if="!showPhoneCallSuccessComponent">
        <div class="form-title">
          Érdekli? Visszahívjuk!
        </div>
        <q-form ref="callbackMobileInputFormRef">
          <TextInput
            id="MINI_118"
            v-model:model-value="houseFormModelMobile.teljesNev"
            :label="textFullName.label"
            :tooltip-content="textFullName.tooltip"
            validation-rules="VAL_100|VAL_104|VAL_103"
          />
          <PhoneInput
            id="MINI_119_MINI_120"
            v-model:model-value="houseFormModelMobile.telefonszam"
            :label="textPhoneNumber.label"
            :area-code-label="textAreaCode.label"
            :tooltip-content="textPhoneNumber.tooltip"
          />
          <TextInput
            id="MINI_121"
            v-model:model-value="houseFormModelMobile.megjegyzes"
            :label="textDescription.label"
            :tooltip-content="textDescription.tooltip"
          />
          <p class="t-xs text-left mb-8px mt-16px">
            Van már társaságunknál élő biztosítása?
          </p>
          <Row12 class="text-left mb-16px">
            <RadioInput
              id="MINI_122_MINI_123"
              v-model:model-value="houseFormModelMobile.nyilatkozat"
              :options="statementOptions"
              validation-rules="VAL_100_RADIO"
            />
          </Row12>
        </q-form>
        <Row12 class="text-left mt-3">
          <paragraph-read-more
            :module-content="descriptionObject.htmlText"
            :more-information-text="descriptionObject.moreInformation"
            show-more-information-button="TRUE"
          />
        </Row12>
        <q-btn
          class="btn btn-secondary btn-block"
          @click="submitCallback"
        >
          Visszahívást kérek
        </q-btn>
      </div>
      <PhoneCallbackSuccessComponent v-else />
    </template>
  </DescriptionModuleHtml>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import productViewHelper from '../../utils/products/productViewHelper.js'
import optionValues from '../../utils/minicalculatorOptionsValues/optionValues'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { focusFirstValidation } from '../../utils/validations/validationFocus.js'
import { useGlobalValuesStore } from '../../stores/globalValuesStore'

const globalValues = useGlobalValuesStore()

const props = defineProps({
  moduleTitle: {
    type: String,
    default: '',
  },
  moduleSubTitle: {
    type: String,
    default: '',
  },
  minicalculatorFields: {
    type: Object,
    required: true,
  },
  isIframe: {
    type: Boolean,
    default: false,
  },
  campaignName: {
    type: String,
    default: '',
  },
  apiUrl: {
    type: String,
    default: '/CustomerService/PostCustomerServicePhoneForm'
  }
})

const callbackMobileInputFormRef = ref({})
const houseFormModelMobile = ref(optionValues.getHomeValuesMobileTablet())
const statementOptions = ref([])
const isReady = ref(false)

const showLoadingIndicator = inject('showLoadingIndicator')

const textFullName = ref({
  text: '',
  label: '',
})
const textAreaCode = ref({
  text: '',
  label: '',
})
const textPhoneNumber = ref({
  text: '',
  label: '',
})
const textDescription = ref({
  text: '',
  label: '',
})

const router = useRouter()

const emit = defineEmits(['callbackSubmitted'])

const descriptionObject = ref(null)

const showPhoneCallSuccessComponent = ref(false)

onMounted(async () => {
  textFullName.value.label = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'label',
    'MINI_118'
  )
  textFullName.value.tooltip = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'tooltip',
    'MINI_118'
  )
  textAreaCode.value.label = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'label',
    'MINI_119'
  )
  textAreaCode.value.tooltip = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'tooltip',
    'MINI_119'
  )
  textPhoneNumber.value.label = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'label',
    'MINI_120'
  )
  textPhoneNumber.value.tooltip = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'tooltip',
    'MINI_120'
  )
  textDescription.value.label = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'label',
    'MINI_121'
  )
  textDescription.value.tooltip = productViewHelper.getTextValue(
    props.minicalculatorFields,
    'tooltip',
    'MINI_121'
  )
  statementOptions.value.push(
    {
      label: productViewHelper.getTextValue(
        props.minicalculatorFields,
        'label',
        'MINI_122'
      ),
      value: true,
    },
    {
      label: productViewHelper.getTextValue(
        props.minicalculatorFields,
        'label',
        'MINI_123'
      ),
      value: false,
    }
  )

  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Contents/GetCustomerServicePhonePageData'
    )
    .then((response) => {
      descriptionObject.value = response.data.descriptionModule[0]
    })

  isReady.value = true
})

function submitCallback() {
  callbackMobileInputFormRef.value.validate().then(
    async (success) => {
      if (success) {
        const body = {
          name: houseFormModelMobile.value.teljesNev,
          phoneNumber: houseFormModelMobile.value.telefonszam,
          subject: 'Lakásbiztosítás',
          comment: houseFormModelMobile.value.megjegyzes,
          isCustomer: houseFormModelMobile.value.nyilatkozat,
          campaignName: props.campaignName,
        }

        showLoadingIndicator.value = true
        await axios
          .post(
            import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
              props.apiUrl,
            body
          )
          .then((response) => {
            if (response.status === 200 || response.status === 201)
              if (!props.isIframe) {
                let customerServicePhoneFormData = {
                  phoneNumber: body.phoneNumber,
                  comment: body.comment,
                }
                globalValues.setCustomerServiceThankYouUserDisplayData(
                  customerServicePhoneFormData
                )
                showPhoneCallSuccessComponent.value = true
                showLoadingIndicator.value = false
              } else {
                showLoadingIndicator.value = false
                emit(
                  'callbackSubmitted',
                  houseFormModelMobile.value.telefonszam
                )
              }
          })
      }
      focusFirstValidation()
    },
    (error) => {
      alert('Váratlan hiba történt!')
      throw new Error(error)
    }
  )
}
</script>

<style></style>
