<template>
  <div
    v-if="!isLoading"
    class="calculator-address"
  >
    <div class="address-choice">
      <q-btn-toggle
        v-model="model"
        class="address-type-choice-input"
        toggle-color="choice"
        :options="options"
      >
        <template #one>
          <div
            :id="options[0].id"
            class="choice-btn"
          >
            <Title
              :htag="'h4'"
              class="btn-title"
            >
              {{ options[0].name }}
            </Title>
          </div>
        </template>

        <template #two>
          <div class="choice-btn">
            <Title
              :id="options[1].id"
              :htag="'h4'"
              class="btn-title"
            >
              {{ options[1].name }}
            </Title>
          </div>
        </template>

        <template
          v-if="options.length > 2"
          #three
        >
          <div
            :id="options[2].id"
            class="choice-btn"
          >
            <Title
              :htag="'h4'"
              class="btn-title"
            >
              {{ options[2].name }}
            </Title>
          </div>
        </template>
      </q-btn-toggle>
    </div>
    <div class="address-inputs-container">
      <div
        v-if="isMailingAddress"
        class="input-holder"
      >
        <TextInput
          :id="ids.idMailingAddressName"
          v-model="personName"
          :label="'Levelezőpartner teljes neve'"
          :validation-rules="validations.validationsMailingAddressName"
          :init-input-value="
            initAddressData.initMailingAddressName || personName
          "
          :max-length="60"
          :mode-value="personName"
        />
      </div>

      <div class="input-holder">
        <ZipCodeInput
          :id="ids.idZip"
          :key="zip_validators"
          v-model="zipCode"
          :model-value="initAddressData.initZip || zipCode"
          :label="'Település és irányítószám'"
          :validation-rules="zip_validators"
          :disable="readOnlyZip"
          :init-input-value="initAddressData.initZip"
          :po-zip="model === 2"
          @settlement-name="getSettlementName"
        />
      </div>
      <div
        v-if="model === 1"
        class="input-holder address-area"
      >
        <div class="first-row">
          <SelectInput
            :id="ids.idStreetName"
            v-model="streetName"
            :label="'Közterület neve'"
            :validation-rules="validations.validationsStreetName"
            :options="streetOptions"
            :is-autocomplete="true"
            :init-option-value="initAddressData.initStreetName || streetName"
            :is-readonly="streetOptions.length === 0"
            input-type="street-name"
            :max-length="30"
            custom-class="no-dropdown-arrow no-placeholder-value"
            :add-new-value="true"
            :is-address-input="true"
          />
        </div>
        <div class="second-row">
          <SelectInput
            :id="ids.idStreetType"
            v-model="streetType"
            :validation-rules="validations.validationsStreetType"
            :label="'Közterület típusa'"
            :options="streetTypeOptions"
            :is-autocomplete="true"
            :init-option-value="initAddressData.initStreetType || streetType"
            :is-readonly="streetTypeOptions.length === 0"
            input-type="text-with-space"
            :max-length="20"
            custom-class="no-dropdown-arrow no-placeholder-value"
            class="street-type"
          />
          <TextInput
            :id="ids.idHouseNumber"
            v-model="houseNumber"
            :label="'Házszám'"
            :validation-rules="validations.validationsHouseNumber"
            :max-length="7"
            input-type="house-number"
            :init-input-value="initAddressData.initHouseNumber || houseNumber"
            class="house-number"
          />
        </div>
      </div>
      <div
        v-if="model === 1"
        class="input-holder address-building"
      >
        <div class="first-row">
          <TextInput
            :id="ids.idBuilding"
            v-model="building"
            :label="'Épület'"
            :max-length="7"
            :init-input-value="initAddressData.initBuilding || building"
            :validation-rules="validations.validationsBuilding"
          />
          <TextInput
            :id="ids.idStaircase"
            v-model="staircase"
            :label="'Lépcsőház'"
            :max-length="3"
            :validation-rules="validations.validationsStaircase"
            :init-input-value="initAddressData.initStaircase || staircase"
          />
        </div>
        <div
          v-if="model === 1"
          class="second-row"
        >
          <TextInput
            :id="ids.idFloor"
            v-model="floor"
            :label="'Emelet'"
            :max-length="2"
            :validation-rules="validations.validationsFloor"
            :init-input-value="initAddressData.initFloor || floor"
          />
          <TextInput
            :id="ids.idDoor"
            v-model="door"
            :label="'Ajtó'"
            :max-length="5"
            :validation-rules="validations.validationsDoor"
            :init-input-value="initAddressData.initDoor || door"
          />
        </div>
      </div>
      <div
        v-if="model === 2"
        class="input-holder"
      >
        <TextInput
          :id="ids.idMailboxNumber"
          v-model="mailboxNumber"
          :init-input-value="initAddressData.initMailboxNumber"
          :model-value="mailboxNumber"
          :label="'Postafiók száma'"
          :max-length="6"
          :validation-rules="
            validations.validationsMailboxNumber || mailboxNumber
          "
          mask="######"
        />
      </div>
      <div
        v-if="model === 3"
        class="input-holder"
      >
        <TextInput
          :id="ids.idTopographical"
          v-model="topographicalNumber"
          label="Helyrajzi szám"
          validation-rules="VAL_100"
          :init-input-value="
            initAddressData.initTopographical || topographicalNumber
          "
          :max-length="24"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import axios from 'axios'

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  ids: {
    type: Object,
    default: () => ({
      idMailingAddressName: 'KALK_000',
      idZip: 'KALK_001',
      idStreetName: 'KALK_002',
      idStreetType: 'KALK_003',
      idTopographical: 'KALK_004',
      idHouseNumber: 'KALK_005',
      idBuilding: 'KALK_006',
      idStaircase: 'KALK_007',
      idFloor: 'KALK_008',
      idDoor: 'KALK_009',
      idMailboxNumber: 'KALK_010',
    }),
  },
  validations: {
    type: Object,
    default: () => ({
      validationsMailingAddressName: '',
      validationsZip: '',
      validationsStreetName: '',
      validationsStreetType: '',
      validationsTopographical: '',
      validationsHouseNumber: '',
      validationsBuilding: '',
      validationsStaircase: '',
      validationsFloor: '',
      validationsDoor: '',
      validationsMailboxNumber: '',
    }),
  },
  initAddressData: {
    type: Object,
    default: () => ({
      initPersonName: '',
      initMailingAddressName: '',
      initZip: null,
      initStreetName: '',
      initStreetType: '',
      initTopographical: '',
      initHouseNumber: '',
      initBuilding: '',
      initStaircase: '',
      initFloor: '',
      initDoor: '',
      initMailboxNumber: '',
    }),
  },
  readOnlyZip: {
    type: Boolean,
    default: false,
  },
  isMailingAddress: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits([
  'setAddressData',
  'setZipCodeData',
  'setMailingData',
  'setTopographicalData',
])

const model = ref(1)

const personName = ref(null)
const streetName = ref(null)
const streetOptions = ref([])
const streetType = ref(null)
const streetTypeOptions = ref([])
const houseNumber = ref(null)
const building = ref(null)
const staircase = ref(null)
const floor = ref(null)
const door = ref(null)
const zipCode = ref(null)
const topographicalNumber = ref(null)
const mailboxNumber = ref(null)

const isLoading = ref(true)

const zip_validators = computed(() => {
  return `VAL_100|VAL_111:${model.value}`;
})

onMounted(async () => {
  await getPublicPlaces()
  if (
    props.initAddressData.initZip != null &&
    props.initAddressData.initZip != undefined
  ) {
    zipCode.value = props.initAddressData.initZip
    if (zipCode.value.includes(' - ')) {
      const hyphenIndex = zipCode.value.indexOf(' - ')
      const firstPart = zipCode.value.substring(0, hyphenIndex)
      const secondPart = zipCode.value.substring(hyphenIndex + 3)
      await getPublicDomains(firstPart, secondPart)
    } else if (zipCode.value.includes('-')) {
      const hyphenIndex = zipCode.value.indexOf('-')
      const firstPart = zipCode.value.substring(0, hyphenIndex)
      const secondPart = zipCode.value.substring(hyphenIndex + 1)
      await getPublicDomains(firstPart, secondPart)
    }
  }
  if (
    props.initAddressData.initPersonName !== null &&
    props.initAddressData.initPersonName !== undefined
  ) {
    personName.value = props.initAddressData.initPersonName
  }
  if (
    props.initAddressData.initStreetName !== null &&
    props.initAddressData.initStreetName !== undefined
  ) {
    streetName.value = props.initAddressData.initStreetName

    if (streetOptions.value !== undefined) {
    if (streetOptions.value !== null) {
      streetOptions.value.forEach((item) => {
        const streetNameIndexOf = streetOptions.value.find(
          (element) => element.value === props.initAddressData.initStreetName
        )
        if (streetNameIndexOf === undefined) {
          streetOptions.value.push({
            value: props.initAddressData.initStreetName,
            label: props.initAddressData.initStreetName,
          })
        }
      })
    }
  }
  }

  if (
    props.initAddressData.initStreetType !== null &&
    props.initAddressData.initStreetType !== undefined
  ) {
    streetType.value = props.initAddressData.initStreetType
  }
  if (
    props.initAddressData.initTopographical !== null &&
    props.initAddressData.initTopographical !== undefined
  ) {
    topographicalNumber.value = props.initAddressData.initTopographical
  }
  if (
    props.initAddressData.initHouseNumber !== null &&
    props.initAddressData.initHouseNumber !== undefined
  ) {
    houseNumber.value = props.initAddressData.initHouseNumber
  }
  if (
    props.initAddressData.initBuilding !== null &&
    props.initAddressData.initBuilding !== undefined
  ) {
    building.value = props.initAddressData.initBuilding
  }
  if (
    props.initAddressData.initStaircase !== null &&
    props.initAddressData.initStaircase !== undefined
  ) {
    staircase.value = props.initAddressData.initStaircase
  }
  if (
    props.initAddressData.initFloor !== null &&
    props.initAddressData.initFloor !== undefined
  ) {
    floor.value = props.initAddressData.initFloor
  }
  if (
    props.initAddressData.initDoor !== null &&
    props.initAddressData.initDoor !== undefined
  ) {
    door.value = props.initAddressData.initDoor
  }
  if (
    props.initAddressData.initMailboxNumber !== null &&
    props.initAddressData.initMailboxNumber !== undefined
  ) {
    mailboxNumber.value = props.initAddressData.initMailboxNumber
  }
  if (
    props.initAddressData.initStreetName !== null &&
    props.initAddressData.initStreetName !== undefined
  ) {
    model.value = 1
  } else if (
    props.initAddressData.initMailboxNumber !== null &&
    props.initAddressData.initPersonName !== undefined
  ) {
    model.value = 2
  } else if (
    topographicalNumber.value !== null &&
    topographicalNumber.value !== undefined
  ) {
    model.value = 3
  }
  isLoading.value = false
})

function getSettlementName(zipData) {
  if (zipData.zipCode !== null) {
    getPublicDomains(zipData.zipCode, zipData.settlement)
  } else {
    streetName.value = null
    streetType.value = null
    houseNumber.value = null
    building.value = null
    staircase.value = null
    floor.value = null
    door.value = null
    streetOptions.value = []
  }
}

const getPublicDomains = async (zip, settlement) => {
  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Addresses/GetPublicDomains',
      {
        params: { zipCode: zip, settlement: settlement },
      }
    )
    .then((response) => {
      if (response.data.addresses !== undefined) {
        if (response.data.addresses !== null) {
          streetOptions.value = []
          response.data.addresses.forEach((item) => {
            const streetNameIndexOf = streetOptions.value.find(
              (element) => element.value === item.streetName
            )

            if (streetNameIndexOf === undefined) {
              streetOptions.value.push({
                value: item.streetName,
                label: item.streetName,
              })
            }
          })
        }
      }
    })
}

const getPublicPlaces = async () => {
  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Addresses/GetPublicPlaces'
    )
    .then((response) => {
      if (response.data.responseData !== undefined) {
        if (response.data.responseData !== null) {
          streetTypeOptions.value = []
          response.data.responseData.forEach((item) => {
            streetTypeOptions.value.push({
              value: item.code,
              label: item.name,
            })
          })
        }
      }
    })
}

function emitAddressData() {
  let streetTypeLabel = streetTypeOptions.value.find(
    (x) => x.value === streetType.value
  )
  streetTypeLabel = streetTypeLabel !== undefined ? streetTypeLabel.label : ''

  emit(
    'setAddressData',
    personName.value,
    streetName.value,
    streetType.value,
    houseNumber.value,
    building.value,
    staircase.value,
    floor.value,
    door.value
  )
}

function emitMailingData() {
  emit('setMailingData', personName.value, mailboxNumber.value)
}

function emitZipCodeData() {
  emit('setZipCodeData', zipCode.value)
}

function emitTopographicalData() {
  emit('setTopographicalData', personName.value, topographicalNumber.value)
}

watch(
  [
    personName,
    streetName,
    streetType,
    houseNumber,
    building,
    staircase,
    floor,
    door,
  ],
  () => {
    emitAddressData()
  }
)

watch([personName, mailboxNumber], () => {
  emitMailingData()
})

watch(zipCode, () => {
  emitZipCodeData()
})

watch([personName, topographicalNumber], () => {
  emitTopographicalData()
})

watch(model, () => {
  if (model.value === 1) {
    emitAddressData()
  } else if (model.value === 2) {
    emitMailingData()
  } else {
    emitTopographicalData()
  }
})
</script>
<style lang="scss" scoped>
.address-area {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  .first-row,
  .second-row {
    width: 100%;
    flex: 1;
  }
  .second-row {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    .text-input {
      width: 100%;
    }
    .house-number,
    .street-type {
      width: 50%;
    }
  }
}

.address-building {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
  .first-row,
  .second-row {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    .text-input {
      width: 100%;
    }
  }
}
.address-choice {
  background: #e3e2ea;
  box-shadow: $box-shadow-inset;
  border-radius: 0.75rem;
  padding: 0.25rem;
  margin-bottom: 1.5rem;
}
.address-type-choice-input {
  min-height: 3rem;
  display: flex !important;
  flex-direction: row !important;
  gap: 0.5rem;
  height: 100%;
  :deep(button.q-btn) {
    border-radius: 0.75rem;
    padding: 0;
    flex: 1;
  }
  box-shadow: none;
  .choice-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background: #ecebef;
    box-shadow: $box-shadow;
    border-radius: 0.75rem;
    height: 100%;

    .btn-title {
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $dark;
      margin: 0;
      letter-spacing: normal;
      text-transform: none;
    }
  }
  :deep(.bg-choice) {
    background: transparent;
    .choice-btn {
      color: $blue;
      background: white;
    }
    .btn-title {
      color: $blue;
    }
  }
}
.address-inputs-container {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: $breakpoint-lg-max) {
  .address-area {
    flex-direction: column;
    gap: 1.5rem;
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .address-building {
    flex-direction: column;
    gap: 1.5rem;
  }
}
@media screen and (max-width: $breakpoint-xs-max) {
  .address-area {
    .second-row {
      flex-direction: column;
      gap: 1.5rem;
      .house-number,
      .street-type {
        width: 100%;
      }
    }
  }
}
</style>
