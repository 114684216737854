import { defineStore } from 'pinia'
import householdViewModel from '../utils/wizard/householdViewModel';
import optionsObject from '../utils/wizard/householdOptions';

export const useHouseholdWizardStore = defineStore('householdWizard', {
  state: () => {
    return {
      calculatorBaseUrl: '/lakas/lakasbiztositas-kalkulator',
      mnbCalculatorBaseUrl: '/lakas/MFO-lakasbiztositas-kalkulator',
      pagePaths: ['/alapadatok','/vagyontargyak','/csomagvalasztas','/kiegeszitok','/szerzodeskotes','/adatellenorzes'],
      currentPage: '/alapadatok',
      viewModel: null,
      texts: null,
      houseHoldPageTitles: new Map([
        [1, 'Alapadatok'],
        [2, 'Vagyontárgyak'],
        [3, 'Csomagválasztás'],
        [4, 'Kiegészítő biztosítások'],
        [5, 'Szerződéskötés'],
        [6, 'Adatok ellenőrzése'],
      ]),
      pocViewModel: {
        Poc1: {
          IsDisabled: false,          
        },
        Poc2: {
          IsDisabled: false,
        },
        Poc3: {
          IsDisabled: false,
        },
        Poc4: {
          IsDisabled: false,
        },
        Poc5: {
          IsDisabled: false,
        },
        Poc6: {
          IsDisabled: false,
        },
        Poc7: {
          IsDisabled: false,
        },
        Poc8: {
          IsDisabled: false,
        },
        Poc9: {
          IsDisabled: false,
        },
        Poc10: {
          IsDisabled: false,          
        }
      },
      selectOptions: optionsObject,
      isMFODisabled: false,
      isGTDisabled:false,
      steadingList: [],
      SkipInsuranceAmountValidations: false,
      sixthPageEditUsed: false,
      fromSavedModeOuthouseRepeater: false,
      fromCalculator: false,
      fromSave: false,
    }
  },

  getters: {
    getCurrentPage(state) {
      return state.currentPage
    },
    getPagePaths(state) {
      return state.pagePaths
    },
    getNextPage(state) {
      const indexOfCurrentPage = state.pagePaths.indexOf(state.currentPage)

      if (
        indexOfCurrentPage > -1 &&
        indexOfCurrentPage < state.pagePaths.length - 1
      ) {
        return `${state.calculatorBaseUrl}${
          state.pagePaths[indexOfCurrentPage + 1]
        }`
      }
      return null
    },
    getMNBNextPage(state) {
      const indexOfCurrentPage = state.pagePaths.indexOf(state.currentPage)
            
      if (
        indexOfCurrentPage > -1 &&
        indexOfCurrentPage < state.pagePaths.length - 1
      ) {
        return `${state.mnbCalculatorBaseUrl}${
          state.pagePaths[indexOfCurrentPage + 1]
        }`
      }
      return null
    },
    getPreviousPage(state) {
      const indexOfCurrentPage = state.pagePaths.indexOf(state.currentPage)      
      if (indexOfCurrentPage > 0) {
        return `${state.calculatorBaseUrl}${
          state.pagePaths[indexOfCurrentPage - 1]
        }`
      }
      return null
    },
    getMNBPreviousPage(state) {
      const indexOfCurrentPage = state.pagePaths.indexOf(state.currentPage)      
      if (indexOfCurrentPage > 0) {
        return `${state.mnbCalculatorBaseUrl}${
          state.pagePaths[indexOfCurrentPage - 1]
        }`
      }
      return null
    },
    getpagePathsCounter(state) {
      const indexOfCurrentPage = state.pagePaths.indexOf(state.currentPage)
      return indexOfCurrentPage
    },
    getViewModel(state) {
      return state.viewModel
    },
    getTexts(state) {
      return state.texts
    },
    getHouseholdPageTitle(state){
      return state.houseHoldPageTitles
    },
    getIsMFODisabled(state) {
      return state.isMFODisabled
    },
    getIsGTDisabled(state) {
      return state.isGTDisabled
    },
    getSteadingList(state) {
      return state.steadingList
    },
    getSkipInsuranceAmountValidations(state) {
      return state.SkipInsuranceAmountValidations
    },
    getSixthPageEditUsed(state) {
      return state.sixthPageEditUsed
    },
    getFromSavedModeOuthouseRepeater(state) {
      return state.fromSavedModeOuthouseRepeater
    },
    getFromCalculator(state) {
      return state.fromCalculator
    },
    getFromSave(state) {
      return state.fromSave
    },
  },
  mutations: {},

  actions: {
    setViewModel(value) {
      this.viewModel = value
    },
    setTexts(value) {
      this.texts = value
    },
    setCurrentPage(path) {
      const indexOfPath = this.pagePaths.indexOf(`/${path}`)
      try {
        this.currentPage = this.pagePaths[indexOfPath]
      } catch (e) {
        return null
      }
    }, 
    setIsMFODisabled(value) {
      this.isMFODisabled = value
    },
    setIsGTDisabled(value) {
      this.isGTDisabled = value
    },
    setSteadingList(value) {
      this.steadingList = value
    },
    setSkipInsuranceAmountValidations(value) {
      this.SkipInsuranceAmountValidations = value
    },
    setSixthPageEditUsed(value) {
      this.sixthPageEditUsed = value
    },
    setFromSavedModeOuthouseRepeater(value) {
      this.fromSavedModeOuthouseRepeater = value
    },
    setFromCalculator(value) {
      this.fromCalculator = value
    },
    setFromSave(value) {
      this.fromSave = value
    },
  },
})
