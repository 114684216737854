<template>
  <div class="modal-btn-holder">
    <q-btn
      id="KALK_133_MODAL"
      class="btn btn-secondary btn-outline btn-back"
      @click="valueablesDialogOpen = true"
    >
      Kiszámolom részletesen
    </q-btn>
  </div>

  <Dialog
    :key="valueablesDialogOpen"
    class="calculator-modal"
    :open="valueablesDialogOpen"
  >
    <q-card-section>
      <div class="dialogtitle-holder">
        <div class="dialog-title">
          Értéktárgyak
        </div>
        <div class="dialog-close-button">
          <q-btn
            v-close-popup
            @click="closeModal"
          >
            <PhIconManager
              ph-icon="ph-x"
              color="#828BA8"
            />
          </q-btn>
        </div>
      </div>
      <div
        v-if="false"
        class="dialog-text"
      />
      <q-form ref="inputRefValuablesModal">
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Nemesfémek, ékszerek, drágakövek
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_134"
                :key="validationRules"
                v-model:model-value="localJewleryValue"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                :max-length="20"
                :label="'Kérjük adja meg a becsült összeget!'"
                :postfix="'Ft'"
                :validation-rules="validationRules"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Antik bútorok és antik tárgyak, képzőművészeti alkotások
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_135"
                :key="validationRules"
                v-model:model-value="localAntiquesValue"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                :max-length="20"
                :label="'Kérjük adja meg a becsült összeget!'"
                :postfix="'Ft'"
                :validation-rules="validationRules"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Valódi szőrmék, kézi csomózású szőnyegek, különleges textíliák
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_136"
                :key="validationRules"
                v-model:model-value="localFursAndCarpetsValue"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                :max-length="20"
                :label="'Kérjük adja meg a becsült összeget!'"
                :postfix="'Ft'"
                :validation-rules="validationRules"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Gyűjtemények
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_137"
                :key="validationRules"
                v-model:model-value="localCollectionsValue"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                :max-length="20"
                :label="'Kérjük adja meg a becsült összeget!'"
                :postfix="'Ft'"
                :validation-rules="validationRules"
              />
            </div>
          </template>
        </Row66Sm>
      </q-form>
      <ModalButtons
        class="modal-btns"
        @close-modal="closeModal"
        @apply-changes="applyChanges"
      />
    </q-card-section>
  </Dialog>
</template>
<script setup>
import { ref, inject, onMounted, computed, watch } from 'vue'
import { focusFirstValidation } from '../../utils/validations/validationFocus'

const viewModel = inject('viewModel')
const converter = inject('converter')
const valueablesDialogOpen = ref(false)

const emits = defineEmits(['updateValuablesSumValue'])

const localJewleryValue = ref(0)
const localAntiquesValue = ref(0)
const localFursAndCarpetsValue = ref(0)
const localCollectionsValue = ref(0)

function closeModal() {
  valueablesDialogOpen.value = false
}

watch(
  () => valueablesDialogOpen.value,
  (value) => {
    if (value) {
      localJewleryValue.value = viewModel.value.JewelryValue.Value ?? 0
      localAntiquesValue.value = viewModel.value.AntiquesValue.Value ?? 0
      localFursAndCarpetsValue.value =
        viewModel.value.FursAndCarpetsValue.Value ?? 0
      localCollectionsValue.value = viewModel.value.CollectionsValue.Value ?? 0
    }
  }
)

async function applyChanges() {
  const isValid = await inputRefValuablesModal.value.validate()
  if (isValid) {
    updateValuablesSumValue()
    closeModal()   
  } else {
    focusFirstValidation()
  }
}

const inputRefValuablesModal = ref(null)

const validationRules = computed(() => {
  const jsonTarget = {
    val1: localJewleryValue.value,
    val2: localAntiquesValue.value,
    val3: localFursAndCarpetsValue.value,
    val4: localCollectionsValue.value,
  }
  const convertedValue = converter(jsonTarget)
  return `VAL_364_MODULE:${convertedValue}`
})

function updateValuablesSumValue(value) {
  viewModel.value.JewelryValue.Value = localJewleryValue.value
  viewModel.value.AntiquesValue.Value = localAntiquesValue.value
  viewModel.value.FursAndCarpetsValue.Value = localFursAndCarpetsValue.value
  viewModel.value.CollectionsValue.Value = localCollectionsValue.value

  emits('updateValuablesSumValue')
}
</script>
<style lang="scss" scoped>
.dialog-close-button{
  width: 1.5rem;
}
</style>
