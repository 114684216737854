<template>
  <div class="documents">
    <div
      v-for="document in documents"
      :key="document.documentId"
      class="document"
    >
      <span class="document__title">{{ document.title }}</span>
      <NavigableDocumentLink
        :class="document.internalLink ? 'internal-link' : 'external-link'"
        :link-object="document"
      >
        <template v-if="document.internalLink">
          <PhIconManager
            ph-icon="ph-file-arrow-down"
            :size="16"
          />
          <div>PDF</div>
        </template>
        <template v-if="document.externalLink">
          <div>LINK</div>
          <PhIconManager
            ph-icon="ph-arrow-right"
            :size="16"
          />
        </template>
      </NavigableDocumentLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  documents: {
    type: Object,
    required: true,
    default: () => {},
  },
})
</script>

<script>
export default {
  name: 'DocumentList',
}
</script>

<style lang="scss" scoped>
.document {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
    0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0.25rem;
  padding: 1rem;

  @media (min-width: $breakpoint-xs) {
    padding: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-size: 0.75rem;
    color: $D-500;
    padding-right: 1rem;

    @media (min-width: $breakpoint-xs) {
      font-size: 0.875rem;
    }
  }
}
.external-link {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    display: flex;
    gap: 0.25rem;
  }
.internal-link {
    border-radius: 0.375rem;
    border: 1px solid var(--primary-blue-500, #4e95fb);
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    font-size: 0.75rem;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }
</style>
