<template>
  <div class="col col-12 col-lg-6">
    <div class="card module-claims">
      <div class="card-header p-0 b-0 bg-white">
        <div class="icon-holder">
          <img
            :src="`${imageUrlHelper.getImageUrl('/Wrench.svg', componentUrl)}`"
            alt="alt"
            width="56"
            height="56"
          >
        </div>
        <div class="card-title">
          <Title
            class="small"
            htag="h2"
          >
            {{ title }}
          </Title>
          <div class="administration-text-holder">
            <p class="text-dark t-sm p-0 m-0">
              {{ subTitle }}
            </p>
          </div>
        </div>
      </div>
      <ul class="reset-list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="btn-holder"
        >
          <q-btn
            :to="item.internalLink ? item.internalLink : null"
            :href="item.externalLink ? item.externalLink : none"
            class="btn btn-service shadow-none w-100 gtmClaimMenuItem"
          >
            <div class="icon-holder">
              <PhIconManager
                :ph-icon="`ph-${item.icon}`"
                color="#ed4353"
              />
            </div>
            <div class="btn-content">
              <Title
                htag="h3"
                class="btn-text"
              >
                {{ item.title }}
              </Title>
              <div class="btn-caret">
                <PhIconManager
                  ph-icon="ph-caret-right"
                  :size="16"
                />
              </div>
            </div>
          </q-btn>
        </li>
      </ul>
      <router-link
        :to="'/karbejelentes'"
        class="d-flex text-secondary read-more align-self-end text-bold gtmClaimAllElementsButton"
      >
        <div class="text-sm">
          Tovább a kárbejelentés felületre
        </div>
        <div>
          <PhIconManager
            ph-icon="ph-arrow-right"
            color="#4E95FB"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { VueScreenSizeMixin } from 'vue-screen-size'
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper'

const props = defineProps({
  items: {
    type: Object,
    required: true,
  },

  title: {
    type: String,
    required: true,
  },

  subTitle: {
    type: String,
    required: true,
  },
})

const componentUrl = import.meta.url
</script>

<script>
export default {
  name: 'ClaimsModule',
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: $breakpoint-md-max) {
  .module-claims {
    a.read-more {
      align-self: flex-start !important;
    }
    h2.small {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 0.25rem;
    }
  }
}
@media screen and (max-width: $breakpoint-xs-max) {
  .administration-section {
    h2.small {
      font-weight: 600 !important;
      font-size: 1.125rem !important;
      line-height: 1.75rem !important;
    }
  }
}
</style>
