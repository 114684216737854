<template>
  <q-select
    :id="id"
    v-model="displayValue"
    :options="options"
    standout
    :error="!!veeErrors[0]"
    virtual-scroll-item-size="32"
    bg-color="white"
    label-slot
    emit-value
    map-options
    use-input
    behavior="menu"
    :loading="loadingItems"
    input-debounce="500"
    @filter="filterFn"
    @update:model-value="
      handleChange($event),
      handleInfoWarningMessages(value),
      inputChanged(value)
    "
  >
    <template #label>
      {{ label }}
      <Tooltip :tooltip-content="tooltipContent" />
    </template>
    <template #error>
      <ValidationMessage
        :messages="veeErrors"
        :info-messages="infoMessages"
        :warning-messages="warningMessages"
      />
    </template>
  </q-select>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import axios from 'axios'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  label: {
    type: String,
    required: true,
  },

  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },

  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  warningMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  modelValue: {
    type: String,
    required: false,
    default: '0',
  },
  options: {
    type: Object,
    required: false,
    default: () => {},
  },
})

const emit = defineEmits(['update:modelValue'])

const options = ref([])
const loadingItems = ref(false)
const infoMessages = ref(null)
const warningMessages = ref(null)
const displayValue = ref(null)
const data = ref(null)

onMounted(() => {
  if (props.modelValue.Value !== null && props.modelValue.Value !== '') {
    displayValue.value = props.modelValue.Value
  }
})

async function getAvailableFinancialInstitutions(value) {
  loadingItems.value = true

  let response = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/AvailableFinancialInstitutions/GetAvailableFinancialInstitutions',
    {
      params: { search: value },
    }
  )

  loadingItems.value = false
  options.value.splice(0)
  response.data.availableFinancialInstitutions.forEach((e) => options.value.push(e.value))
  data.value = response.data.availableFinancialInstitutions
}

function filterFn(value, update, abort) {
    if (value.length < 3) {
    abort()
    return
  }


  update(async () => {
    await getAvailableFinancialInstitutions(value)
  })
}

async function validateQForm() {
  // run vee-validate validations

  if(value.value === undefined && displayValue.value !== null) {
    value.value = displayValue.value
  }

  await veeValidate()

  if (veeErrors.value.length > 0) {
    return false
  } else {
    return true
  }
}

function inputChanged() {
  let availableFinancialInstitutionData = {}
  if (value.value !== null) {
    let indexOfValue = data.value.findIndex((item) =>
      value.value == item.value
    )
    displayValue.value = value.value === null ? null : data.value[indexOfValue].value

    availableFinancialInstitutionData = {
    Code: data.value[indexOfValue].code,
    IsDisabled: false,
    Value: data.value[indexOfValue].value
      
    }
  } else {
    displayValue.value = value.value
    availableFinancialInstitutionData = {
        code: null,
        value: null,
    }
  }

  emit('update:modelValue', availableFinancialInstitutionData)
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules
  )
}

const {
  handleChange,
  errors: veeErrors,
  value,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
</script>

<script>
export default {
  name: 'FinancialInstitutionsInput',
}
</script>
<style lang="scss" scoped>
:deep(.q-field--standout) {
    &.q-field * {
      border-width: 0 !important;
    }
    .q-field__label {
      color: #4a567c !important;
    }
    .q-field__control:before {
      background-color: transparent;
    }
    &.q-field--highlighted .q-field__control {
      box-shadow: none;
    }
  
    .q-field__control {
      border-radius: $half !important;
      padding: 0 1rem !important;
      box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
      border: 1px solid #f5f4f8 !important;
    }
  
    .q-field__label {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
    }
  
    .q-field--float {
      .q-field__label {
        transform: translateY(-40%) scale(10 / 12) !important;
      }
    }
  
    .q-field__native,
    .q-field__prefix,
    .q-field__suffix,
    .q-field__input {
      font-weight: $font-weight-bold !important;
    }
  
    .q-field__append {
      background: transparent !important;
      width: auto !important;
    }
  }
  
  :deep(.q-field--standout:hover) {
    .q-field__control {
      border: 1px solid $border-hover !important;
    }
  }
  
  :deep(.q-field--standout.q-field--focused) {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
  
  :deep(.q-field--focused:hover) {
    .q-field__control {
      background-color: white !important;
    }
  }
  
  :deep(.q-field--float:hover) {
    .q-field__control {
      border: 0.125rem solid $border-data-hover !important;
      background-color: $input-data-bg-hover !important;
    }
  }
  
  :deep(.q-field--error) {
    & * {
      color: $input-text-validation !important;
    }
  
    .q-field__control {
      border: 1px solid $border-validation !important;
      background-color: white !important;
    }
  }
  
  :deep(.q-field--standout) {
    &.q-field.q-field--error * {
      color: $input-text-validation !important;
    }
  }
  
  :deep(.q-field--standout.q-field .q-field__append) {
    background: transparent !important;
    width: auto !important;
  
    svg,
    svg * {
      color: $N-600 !important;
    }
  }
  
  :deep(.q-field__append.q-field__marginal.row.no-wrap.items-center) {
    width: auto;
  }
  
  :deep(.q-field__control-container.col.relative-position.row.no-wrap.q-anchor--skip) {
    font-size: 0.875rem;
    span {
      line-height: 1.25rem;
      color: #0c1224;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  :deep(.q-field__label.no-pointer-events.absolute.ellipsis) {
    color: #4a567c !important;
    order: 1;
    position: absolute;
  }
  :deep(.q-field__native.row.items-center) {
    order: 2;
  }
</style>