<template>
  <span
    v-if="tooltipContent"
    class="cursor-pointer all-pointer-events"
  >
    <div
      ref="tooltip"
      data-bs-toggle="tooltip"
      :data-bs-title="tooltipContent"
      data-bs-container="body"
      :data-bs-placement="tooltipPlacement"
      :data-bs-custom-class="customClass"
      data-bs-html="true"
    >
      <div
        class="tag-long w-auto bg-purple"
      >
        <div class="icon-holder bg-white">
          <PhIconManager
            :ph-icon="`ph-${discountIcon}`"
            :size="iconSize"
            color="purple"
          />
        </div>
        <div class="tag-text text-white text-bold t-normal">
          {{ title }}
        </div>
        <ph-arrow-right
          :size="16"
          color="white"
        />
      </div>
    </div>
  </span>
  <div
    v-else
    class="tag-long w-auto bg-purple"
  >
    <div class="icon-holder bg-white">
      <PhIconManager
        :ph-icon="`ph-${discountIcon}`"
        :size="iconSize"
        color="purple"
      />
    </div>
    <div class="tag-text text-white text-bold t-normal">
      {{ title }}
    </div>
    <ph-arrow-right
      :size="16"
      color="white"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Tooltip } from 'bootstrap'

const props = defineProps({
  tooltipContent: {
    type: String,
    required: true,
    default: '',
  },

  title: {
    type: String,
    required: true,
    default: '',
  },

  discountIcon: {
    type: String,
    required: true,
    default: 'info',
  },

  iconSize: {
    type: Number,
    required: false,
    default: 20,
  },

  tooltipPlacement: {
    type: String,
    required: false,
    default: 'bottom',
  },

  customClass: {
    type: String,
    required: false,
    default: 'tooltip',
  }
})

const tooltip = ref(null)

onMounted(() => {
  if (tooltip.value) {
    new Tooltip(tooltip.value, {
      trigger: 'click',
    })
  }
})

onBeforeUnmount(() => {
  if(tooltip.value.getAttribute('aria-describedby')){
    tooltip.value.click();
  }
})
</script>

<script>
export default {
  name: 'DiscountBarTooltip',
}
</script>
