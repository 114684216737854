<template>
  <div
    v-if="tooltipContent.length"
    class="recommendation"
  >
    <div
      ref="tooltip"
      data-bs-toggle="tooltip"
      :data-bs-title="tooltipContent"
      data-bs-container="body"
      :data-bs-placement="tooltipPlacement"
      :data-bs-custom-class="customClass"
      data-bs-html="true"
    >
      {{ title }}
      <PhIconManager
        ph-icon="ph-info"
        :size="iconSize"
        :color="'#1d2c5b'"
        :background="'white'"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Tooltip } from 'bootstrap'

const props = defineProps({
  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },

  iconSize: {
    type: Number,
    required: false,
    default: 20,
  },

  tooltipPlacement: {
    type: String,
    required: false,
    default: 'right',
  },

  customClass: {
    type: String,
    required: false,
    default: 'tooltip',
  },

  alternateContent: {
    type: String,
    required: false,
    default: '',
  },

  alternateContentClass: {
    type: String,
    required: false,
    default: '',
  },
  title: {
    type: String,
    required: true,
    default: '',
  },
})

const tooltip = ref(null)

onMounted(() => {
  if (tooltip.value) {
    new Tooltip(tooltip.value)
  }
})
</script>

<script>
export default {
  name: 'TooltipElement',
}
</script>
<style lang="scss" scoped>
.recommendation {
  background: $dark;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: .125rem .5rem;
  gap: .25rem;
  border-radius: 6.25rem;
  font-weight: 700;
  font-size: .625rem;
  line-height: 1rem;
  color: #ffffff;
  align-items: center;
  cursor: pointer !important;
  pointer-events: all !important;
  align-self: center;
  max-height: 1.5rem;
  min-width: fit-content;
}
</style>
