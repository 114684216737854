let optionValues = {
  getKgfbValues() {
    return {
      szerzodeskotesOkaOpciok: [
        {
          label: 'Évfordulós szerződéskötés',
          value: '1',
        },
        {
          label: 'Tulajdonos vagy üzembentartó váltás',
          value: '0',
        },
        {
          label: 'Díjnemfizetés miatt a szerződés megszűnt',
          value: '2',
        },
        {
          label: 'Szerződés megszűnése közös megegyezéssel',
          value: '5',
        },
        {
          label: 'Gépjármű forgalomba történő visszahelyezése',
          value: '3',
        },
      ],
      szerzodeskotesOka: '-1',
      rendszam: '',
      frszNincs: false,
    }
  },

  getAccidentValues() {
    return {
      szerzodoNemeOpciok: [
        {
          label: 'férfi',
          value: '0',
        },
        {
          label: 'nő',
          value: '1',
        },
        {
          label: 'nem természetes személy',
          value: '2',
        },
      ],
      szerzodoNeme: '-1',
      felnottDarab: null,
      gyermekDarab: null,
    }
  },

  getHomeValues() {
    return {
      biztositastargyaOpciok: [
        {
          label: 'Ingatlan és ingóság',
          value: '0',
        },
        {
          label: 'Csak ingatlan',
          value: '1',
        },
        {
          label: 'Csak ingóság',
          value: '2',
        },
      ],
      ingatlantipusaOpciok: [
        {
          label: 'Családi ház, sorház, ikerház',
          value: '0',
        },
        {
          label: 'Társasházi lakás',
          value: '1',
        },
      ],
      biztositasTargya: '-1',
      ingatlanTipusa: '-1',
      iranyitoSzam: null,
    }
  },

  getHomeValuesMobileTablet(){
    return {
      teljesNev: '',
      korzetszam: '',
      telefonszam: null,
      megjegyzes: '',
      nyilatkozat: null,
    }
  },

  getTravelValues() {
    return {
      utazasOkaOpciok: [
        {
          label: 'Városnéző',
          value: '0',
        },
        {
          label: 'Kirándulás',
          value: '2',
        },
        {
          label: 'Tengerpart',
          value: '1',
        },
        {
          label: 'Síelés',
          value: '3',
        },
      ],
      utazasiJarmuOpciok: [
        {
          label: 'Autó',
          value: '0',
        },
        {
          label: 'Repülő',
          value: '3',
        },
        {
          label: 'Busz',
          value: '2',
        },
        {
          label: 'Vonat',
          value: '1',
        },
      ],
      uticelOpciok: [
        {
          label: 'Magyarország',
          value: '0',
        },
      ],
      utazasOka: '-1',
      utazasiJarmu: '-1',
      uticel: '0',
    }
  },

  getCascoValues() {
    return {
      szerzodeskotesOkaOpciok: [
        {
          label: 'Most vásárolt új vagy használt gépjárműre kötök',
          value: '1',
        },
        {
          label: 'Casco nélküli, használt gépjárműre kötök',
          value: '2',
        },
        {
          label: 'Szerződés évfordulóra történő átkötése',
          value: '3',
        },
        {
          label:
            'Előzmény szerződésem nem a Genertelnél volt és díj nemfizetés vagy közös megegyezés miatt szűnt meg',
          value: '4',
        },
        {
          label:
            'Generteles előzmény szerződésem díj nemfizetés vagy közös megegyezés miatt szűnt meg',
          value: '5',
        },
      ],
      felhasznalasmodjaOpciok: [
        {
          label: 'általános',
          value: '0',
        },
        {
          label: 'taxi vagy személyszállító',
          value: '1',
        },
        {
          label: 'bérgépjármű',
          value: '2',
        },
        {
          label: 'versenyautó',
          value: '3',
        },
        {
          label: 'oktató gépjármű',
          value: '4',
        },
        {
          label: 'hadsereg',
          value: '7',
        },
        {
          label: 'mentő',
          value: '8',
        },
        {
          label: 'építkezésen',
          value: '9',
        },
        {
          label: 'rendőrségi',
          value: '10',
        },
        {
          label: 'repülőtéri',
          value: '11',
        },
        {
          label: 'tűzoltó',
          value: '12',
        },
        {
          label: 'diplomáciai gépjármű',
          value: '14',
        },
      ],
      szerzodeskotesOka: '0',
      felhasznalasmodja: '-1',
      rendszam: '',
      frszNincs: false,
    }
  },

  getMinicascoValues() {
    return {
      szerzodoErdekeltsegOpciok: [
        {
          label: 'Tulajdonos',
          value: '16',
        },
        {
          label: 'Üzembentartó',
          value: '32',
        },
      ],
      elozoKgfbOpciok: [
        {
          label: 'Generali Biztosító Zrt.',
          value: '2',
        },
        {
          label: 'Allianz Hungária Biztosító Zrt.',
          value: '3',
        },
        {
          label: 'Alfa Vienna Insurance Group Biztosító Zrt. (volt Aegon Biztosító)',
          value: '4',
        },
        {
          label: 'Uniqa Biztosító Zrt.',
          value: '7',
        },
        {
          label: 'K&H Biztosító Zrt.',
          value: '9',
        },
        {
          label: 'KÖBE Közép-Európai Kölcsönös Biztosító Egyesület',
          value: '10',
        },
        {
          label: 'Magyar Posta Biztosító Zrt.',
          value: '11',
        },
        {
          label: 'Union Vienna Insurance Group Biztosító Zrt.',
          value: '15',
        },
        {
          label: 'Signal Iduna Biztosító Zrt.',
          value: '17',
        },
        {
          label: 'Genertel Biztosító Zrt.',
          value: '18',
        },
        {
          label: 'Gránit Biztosító Zrt. (volt Wáberer Biztosító)',
          value: '20',
        },
        {
          label: 'Groupama Biztosító Zrt.',
          value: '22',
        },
        {
          label: 'CIG Pannónia Első Magyar Általános Biztosító Zrt.',
          value: '24',
        },
        {
          label: 'Pannónia (MKB)',
          value: '25',
        },
      ],
      forgalmiNevOpciok: [
        {
          label: 'Igen',
          value: '1',
        },
        {
          label: 'Nem',
          value: '0',
        },
      ],
      szerzodoErdekeltseg: '0',
      forgalmiNev: '0',
      elozoKgfb: '-1',
    }
  },

  getTheftCasco() {
    return {
      szerzodoErdekeltsegOpciok: [
        {
          label: 'Tulajdonos',
          value: '16',
        },
        {
          label: 'Üzembentartó',
          value: '32',
        },
      ],
      gepjarmuFajtajaOpciok: [
        {
          label: 'Személygépjármű',
          value: '1',
        },
        {
          label: 'Kis haszongépjármű',
          value: '3',
        },
      ],
      forgalmiNevOpciok: [
        {
          label: 'Igen',
          value: '1',
        },
        {
          label: 'Nem',
          value: '0',
        },
      ],
      szerzodoErdekeltseg: '0',
      gepjarmuFajtaja: '0',
      forgalmiNev: '-1',
    }
  },
}

export default optionValues
