<template>
  <div
    v-if="hasErrorMessages"
    class="error-message"
  >
    <div
      v-for="(message, index) in messages"
      :key="index"
    >
      <div class="error">
        {{ message }}
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <div
        v-for="(message, index) in infoMessages"
        :key="index"
      >
        <div class="info fa fa-info-circle">
          {{ message }}
        </div>
      </div>
    </div>
    <div>
      <div
        v-for="(message, index) in warningMessages"
        :key="index"
      >
        <div class="warning">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  warningMessages: {
    type: Array,
    required: false,
  },

  infoMessages: {
    type: Array,
    required: false,
  },

  messages: {
    type: Array,
    required: false,
  },
})

const hasErrorMessages = computed(() => {
  return props.messages.length != 0
})
</script>

<script>
export default {
  name: 'ValidationMessage',
}
</script>
<style lang="scss" scoped>
.info {
  color: #828aab;
}

.info {
  color: $danger;
}

.error {
  color: $danger;
}
</style>
