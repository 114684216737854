
let imageUrlHelper = {
/**
 * @description
 * - return full url for image based on source (cms or static assets)
 * - metaUrl and urlPrefix optional params are deprecated! TODO: remove
 * @param {string} url - image url
 * @returns {string} full url for image
 */ 
  getImageUrl(url, metaUrl = null, urlPrefix = null) {
       
    if(url == null || url == '' ){
      return ''
    }

    // image from umbraco media
    if (url && url.includes('media'))
    {
      return import.meta.env.VITE_RESP_BASEURL + url
    }

    // image from static assers folder
    return import.meta.env.VITE_IMAGE_BASE_PATH + url
  }
}

export default imageUrlHelper
