<template>
  <div class="sticky-cart">
    <div class="cart-content-holder">
      <div
        v-if="householdWizardStore.getCurrentPage != '/vagyontargyak'"
        class="package-button"
      >
        <div
          v-if="householdWizardStore.getCurrentPage != '/vagyontargyak'"
          class="package-name"
        >
          {{ selectedProductName }}
        </div>
        <div
          v-if="householdWizardStore.getCurrentPage != '/vagyontargyak'"
          class="more-button"
        >
          <q-btn
            v-if="$q.screen.width > 767"
            
            :ripple="false"
            :unelevated="true"
            @click="open = !open"
          >
            <div v-if="open">
              Részletek bezárása

              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-caret-down"
              />
            </div>
            <div v-else>
              Részletek megtekintése
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-caret-up"
              />
            </div>
          </q-btn>
          <q-btn
            v-if="$q.screen.width <= 767"
            :ripple="false"
            :unelevated="true"
            @click="open = !open"
          >
            Részletek
            <PhIconManager
              v-if="open"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-caret-down"
            />
            <PhIconManager
              v-else
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-caret-up"
            />
          </q-btn>
        </div>
      </div>
      <div
        class="price-section"
        :class="
          householdWizardStore.getpagePathsCounter == 1
            ? 'sticky-cart-second-page-price'
            : ''
        "
      >
        <div
          v-if="householdWizardStore.getCurrentPage == '/vagyontargyak'"
          class="price-title"
        >
          <TitleTooltip
            htag="p"
            class="price-title"
            :tooltip-content="texts.vtxt_stickyFooter_tooltipContent"
          >
            Biztosítási díj
          </TitleTooltip>
        </div>
        <div class="prices-holder">
          <div class="price">
            {{
              getFormattedNumber(
                householdWizardStore.getpagePathsCounter + 1 < 3
                  ? selectedProduct.FeePart
                  : viewModel.Cart.FeePart
              ) +
                ' Ft' +
                getPeriodText()
            }}
          </div>
          <div
            v-if="showAnnualFee"
            class="price-year"
          >
            {{
              getFormattedNumber(
                householdWizardStore.getpagePathsCounter + 1 < 3
                  ? selectedProduct.AnnualFee
                  : viewModel.Cart.AnnualFee
              ) + ' Ft/év'
            }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="open"
      class="additionals-holder"
    >
      <div class="additional">
        <div class="additional-name">
          {{ '20 alap kockázat' }}
        </div>
        <div class="additional-price">
          <PhIconManager
            class="additional-icon"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </div>
      </div>
      <div
        v-if="
          (viewModel.InsuranceType.Value.value == 0 ||
            viewModel.InsuranceType.Value.value == 1) && 
            selectedProduct.EstateBO.Value != 0
        "
        class="additional"
      >
        <div class="additional-name">
          {{ 'Ingatlan alapfedezet' }}
        </div>
        <div class="additional-price">
          {{ getFormattedNumber(selectedProduct.EstateBO.Value) + ' Ft' }}
        </div>
      </div>
      <div
        v-if="
          (viewModel.InsuranceType.Value.value == 0 ||
            viewModel.InsuranceType.Value.value == 2) &&
            selectedProduct.EstatePersonalitySumValueBO.Value != 0
        "
        class="additional"
      >
        <div class="additional-name">
          {{ 'Háztartási ingóság' }}
        </div>
        <div class="additional-price">
          {{ getFormattedNumber(
            selectedProduct.EstatePersonalitySumValueBO.Value
          ) + ' Ft' }}
        </div>
      </div>
      <div
        v-if="
          (viewModel.InsuranceType.Value.value == 0 ||
            viewModel.InsuranceType.Value.value == 2) && 
            selectedProduct.ValuablesSumBO.Value != 0
        "
        class="additional"
      >
        <div class="additional-name">
          {{ 'Értéktárgyak' }}
        </div>
        <div class="additional-price">
          {{ getFormattedNumber(selectedProduct.ValuablesSumBO.Value, true) +
            ' Ft' }}
        </div>
      </div>
      <div
        v-for="(steading, steadingIndex) in viewModel.SteadingList"
        :key="'steading_' + steadingIndex"
        class="additional"
      >
        <template 
          v-if="steading.SteadingValue.Value != 0"
        >
          <div class="additional-name">
            {{ `Melléképület alapfedezet ${steadingIndex + 1}` }}
          </div>
          <div class="additional-price">
            {{ getFormattedNumber(steading.SteadingValue.Value) + ' Ft' }}
          </div>
        </template>
      </div>
      <div
        v-if="
          ((viewModel.InsuranceType.Value.value == 0 &&
            viewModel.SteadingNumber.Value > 0) ||
            viewModel.InsuranceType.Value.value == 2) && 
            (selectedProduct.SteadingPersonalEstateSumBO.Value != 0 &&
              selectedProduct.SteadingPersonalEstateSumBO.Value != null)
        "
        class="additional"
      >
        <div class="additional-name">
          {{ 'Összes melléképület ingóságának becsült értéke' }}
        </div>
        <div class="additional-price">
          {{
            getFormattedNumber(
              selectedProduct.SteadingPersonalEstateSumBO.Value
            ) + ' Ft'
          }}
        </div>
      </div>
      <div class="additional">
        <div class="additional-name">
          {{ 'Felelősségbiztosítás ' }}
        </div>
        <div class="additional-price">
          {{
            getFormattedNumber(selectedProduct.LiabilityInsurance.Value.label) +
              ' Ft'
          }}
        </div>
      </div>
      <div
        v-for="(additional, index) in additionals"
        :key="index"
        class="additional"
      >
        <template
          v-if="additional.Options.Value.label != 0"
        >
          <div class="additional-name">
            {{ additional.Name }}
          </div>
          <div
            v-if="
              additional.Id != 'CsaladiHazCsomag' &&
                additional.Id != 'RelaxCsomag'
            "
            class="additional-price"
          >
            {{
              getFormattedNumber(additional.Options.Value.label) +
                getSubmodusPostfix(additional)
            }}
          </div>
          <div
            v-else
            class="additional-price"
          >
            <PhIconManager
              class="additional-icon"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-check"
            />
          </div>
        </template>
        <template 
          v-if="additional.Id == 'IngatlanKiegeszitoKockazatokEpitesSzereles'"
        >
          <div class="additional-name">
            {{ additional.Name }}
          </div>
          <div
            class="additional-price"
          >
            <PhIconManager
              class="additional-icon"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-check"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, inject } from 'vue'
import { useHouseholdWizardStore } from '../../stores/householdWizardStore'
import { useCalculatorStore } from '../../stores/calculatorStore'

const calculatorStore = useCalculatorStore()
const { getFormattedNumber } = inject('util')
const householdWizardStore = useHouseholdWizardStore()
const viewModel = computed(() => {
  return householdWizardStore.getViewModel
})

const texts = householdWizardStore.getTexts
const submodusPostfixes = new Map([
  ['PenzugyiVesztesegKockazatokDijAtvallalas', ' hónap'],
  ['BalesetBiztositasCsomag', ''],
  ['HobbiKisallatBiztositasCsomag', ''],
])
const props = defineProps({
  isAnnualFeeVisible: {
    type: Boolean,
    default: true,
  },
})
function getSubmodusPostfix(item) {
  return submodusPostfixes.has(item.Id) ? submodusPostfixes.get(item.Id) : ' Ft'
}
const selectedProduct = computed(() => {
  let selectedProduct
  if (householdWizardStore.getIsGTDisabled) {
    selectedProduct = viewModel.value.Packages.Start
  } else if (viewModel.value.Packages) {
    if (viewModel.value.Packages.Start.Selected.Value) {
      selectedProduct = viewModel.value.Packages.Start
    } else if (viewModel.value.Packages.StartPlus.Selected.Value) {
      selectedProduct = viewModel.value.Packages.StartPlus
    } else if (viewModel.value.Packages.Komfort.Selected.Value) {
      selectedProduct = viewModel.value.Packages.Komfort
    } else if (viewModel.value.Packages.Extra.Selected.Value) {
      selectedProduct = viewModel.value.Packages.Extra
    } else {
      selectedProduct = viewModel.value.Packages.StartPlus
    }
  }

  return selectedProduct
})
const selectedProductName = computed(() => {
  let selectedProductName
  if (householdWizardStore.getIsGTDisabled) {
    selectedProductName = 'Start(MFO) Csomag'
  } else if (viewModel.value.Packages) {
    if (viewModel.value.Packages.Start.Selected.Value) {
      selectedProductName = 'Start(MFO) Csomag'
    } else if (viewModel.value.Packages.StartPlus.Selected.Value) {
      selectedProductName = 'Start+ Csomag'
    } else if (viewModel.value.Packages.Komfort.Selected.Value) {
      selectedProductName = 'Komfort Csomag'
    } else if (viewModel.value.Packages.Extra.Selected.Value) {
      selectedProductName = 'Extra Csomag'
    } else {
      selectedProductName = 'Start+ Csomag'
    }
  }

  return selectedProductName
})

const showAnnualFee = computed(() => {
  if (viewModel.value.Cart.Payment.PaymentPeriod.Value) {
    if (viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 1) {
      calculatorStore.setShowAnnualFee(false)

      return false
    } else {
      calculatorStore.setShowAnnualFee(true)

      return true
    }
  }
  calculatorStore.setShowAnnualFee(false)

  return false
})
const open = ref(false)

function getPeriodText() {
  if (viewModel.value.Cart.Payment.PaymentPeriod.Value) {
    if (viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 1) {
      return '/év'
    } else if (viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 2) {
      return '/félév'
    } else if (viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 4) {
      return '/negyedév'
    } else return '/hónap'
  }
}
const additionals = computed(() => {
  let additionals = []
  viewModel.value.Additionals.forEach((category) =>
    category.Items.forEach((additional) => {
      if (additional.IsVirtualPackage && additional.Selected.Value == false) {
        additional.Items.forEach((virtualPackageAdditional) => {
          if (virtualPackageAdditional.Selected.Value) {
            additionals.push(virtualPackageAdditional)
          }
        })
      } else {
        if (additional.Selected.Value) {
          if (additional.IsVirtualPackage) {
            let hasVirtualPackage = false
            additional.Items.forEach((virtualPackageAdditional) => {
              if (virtualPackageAdditional.Selected.Value == false) {
                hasVirtualPackage = true
              }
            })
            if (hasVirtualPackage) {
              additional.Items.forEach((virtualPackageAdditional) => {
                if (virtualPackageAdditional.Selected.Value) {
                  additionals.push(virtualPackageAdditional)
                }
              })
            } else {
              additionals.push(additional)
            }
          } else {
            additionals.push(additional)
          }
        }
      }
    })
  )
  return additionals
})
function getAdditionals() {
  viewModel.value.Additionals.forEach((category) =>
    category.Items.forEach((additional) => {
      if (additional.IsVirtualPackage) {
        additional.Items.forEach((virtualPackageAdditional) => {
          if (virtualPackageAdditional.Selected.Value) {
            selectedAdditionals.value.push(virtualPackageAdditional)
          } else if (virtualPackageAdditional.IsGift) {
            giftAdditionals.value.push(virtualPackageAdditional)
          }
        })
      } else {
        if (additional.Selected.Value) {
          selectedAdditionals.value.push(additional)
        } else if (additional.IsGift) {
          giftAdditionals.value.push(additional)
        }
      }
    })
  )
}
</script>
<style lang="scss" scoped>
.sticky-cart {
  z-index: 999;
  width: 100%;
  background-color: #edf4ff;
  position: fixed;
  bottom: 0;
  padding: 1.25rem 0;
  box-shadow: 0rem -0.625rem 0.9375rem rgba(0, 0, 0, 0.1),
    0rem -0.25rem 0.375rem rgba(0, 0, 0, 0.05);
  .cart-content-holder {
    width: 71.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .package-button {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.5rem;
      .package-name {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: $dark;
      }
    }
    .more-button {
      :deep(.q-btn__content) {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: $secondary;
        text-transform: none;
        svg {
          width: auto;
          margin-left: 0.5rem;
        }
      }
      .q-btn {
        min-height: unset;
      }
    }
  }
  .price-section {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    width: 100%;
    justify-content: right;
    .price-title {
      margin: 0;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.75rem;
    }
  }
  .prices-holder {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    .price {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2.25rem;
      color: $dark;
    }
    .price-year {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: $dark;
    }
  }
  .additionals-holder {
    border-top: 0.0625rem solid $N-600;
    padding-top: 1rem;
    width: 71.5rem;
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    .additional {
      background-color: white;
      border: $N-300 0.0625rem solid;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;
      .additional-name {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $dark;
        hyphens: auto;
      }
      .additional-price {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $dark;
        .additional-icon {
          color: $G-500;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg-max) {
  .sticky-cart {
    padding: 1.25rem 2rem;
    .cart-content-holder {
      width: 100%;
      padding: 0;
      .package-button {
        .package-name {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
    .additionals-holder {
      width: 100%;
      margin-top: 1rem;
      padding-top: 0.5rem;
    }
    .prices-holder {
      .price {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
      .price-year {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .sticky-cart {
    padding: 1rem;
    .cart-content-holder {
      width: 100%;
      padding: 0;
      .package-button {
        flex-direction: column;
        gap: 0.25rem;
        align-items: flex-start;
        width: 60%;
        .package-name {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
        .more-button {
          .q-btn {
            padding: 0;
          }
        }
      }
    }

    .additionals-holder {
      width: 100%;
      padding: 0;
      padding-top: 0.5rem;
      margin-top: 0.5rem;
      overflow-y: auto;
      max-height: calc(100vh - 10.625rem);
      max-width: 100%;
      .additional {
        width: fit-content;
      }
    }
    .sticky-cart-second-page-price {
      justify-content: space-between;
    }
    .prices-holder {
      .price {
        text-align: right;
      }
      .price-year {
        text-align: right;
      }
    }
  }
}
</style>
