<template>
  <div class="list">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="list-item d-flex justify-content-sm-between align-items-sm-center flex-column flex-sm-row py-12px px-16px"
    >
      <span class="d-block mb-8px mb-sm-0">{{ item.label }}</span>
      <span>{{ item.value }}</span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
})
</script>

<script>
export default {
  name: 'ListComponent',
}
</script>

<style lang="scss" scoped>
.list-item {
  color: $D-500;
  background-color: $white;
  border-radius: 0.25rem;
  border: 1px solid #e6e8f3;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    font-size: 0.75rem;
    color: $D-500;

    @media (min-width: $breakpoint-xs) {
      font-size: 1rem;
    }
  }
}
</style>
