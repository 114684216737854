<template>
  <!-- Validációnek kell vezérelnie a bindot -->
  <div :class="false ? 'fix-height-validation' : 'fix-height'">
    <div v-sticky-parent>
      <div v-calculatorsticky>
        <div class="row head-row">
          <div class="col-2" />
          <div
            id="extraPackage"
            class="col field-content first"
            :class="false ? 'package-selected' : ''"
          >
            <div
              class="package-head first"
              :class="false ? 'disabled-package' : ''"
            >
              <div class="package-name">
                {{ 'Extra LAKÁSBIZTOSÍTÁS' }}
              </div>
              <div class="package-price-holder first">
                <div class="package-period-price">
                  {{ '34 717 Ft /félév' }}
                </div>
                <div class="package-annual-price">
                  {{ '69 434 Ft /év' }}
                </div>
              </div>
              <div class="package-radio-input-holder">
                <div class="radio-input">
                  <q-radio
                    v-model="shape"
                    val="line"
                    label="Ezt választom"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="komfortPackage"
            class="col field-content highlighted"
            :class="true ? 'package-selected' : ''"
          >
            <div
              class="package-head"
              :class="false ? 'disabled-package' : ''"
            >
              <div class="recommended-package">
                AJÁNLOTT
              </div>
              <div class="package-name">
                {{ 'Komfort LAKÁSBIZTOSÍTÁS' }}
              </div>
              <div class="package-price-holder">
                <div class="package-period-price">
                  {{ '34 717 Ft /félév' }}
                </div>
                <div class="package-annual-price">
                  {{ '69 434 Ft /év' }}
                </div>
              </div>
              <div class="package-radio-input-holder">
                <div class="radio-input">
                  <q-radio
                    v-model="shape"
                    val="polygon"
                    label="Ezt választom"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="startPlusPackage"
            class="col field-content"
            :class="false ? 'package-selected' : ''"
          >
            <div
              class="package-head"
              :class="false ? 'disabled-package' : ''"
            >
              <div class="package-name">
                {{ 'Start + LAKÁSBIZTOSÍTÁS' }}
              </div>
              <div class="package-price-holder">
                <div class="package-period-price">
                  {{ '34 717 Ft /félév' }}
                </div>
                <div class="package-annual-price">
                  {{ '69 434 Ft /év' }}
                </div>
              </div>
              <div class="package-radio-input-holder">
                <div class="radio-input">
                  <q-radio
                    v-model="shape"
                    val="rectangle"
                    label="Ezt választom"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="startPackage"
            class="col field-content last"
            :class="false ? 'package-selected' : ''"
          >
            <div
              class="package-head"
              :class="true ? 'disabled-package' : ''"
            >
              <div class="package-name">
                {{ 'Start minősített fogyasztóbarát otthonbiztosítás' }}
              </div>
              <div class="package-price-holder last">
                <div class="package-period-price">
                  {{ '34 717 Ft /félév' }}
                </div>
                <div class="package-annual-price">
                  {{ '69 434 Ft /év' }}
                </div>
              </div>
              <div class="package-radio-input-holder last">
                <div class="radio-input">
                  <q-radio
                    v-model="shape"
                    val="ellipse"
                    label="Ezt választom"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row validation-row">
          <div class="col-2" />

          <div
            v-if="true"
            class="col validation-col"
          >
            <ValidationMessage
              :messages="['Kérem, válasszon csomagot a továbblépéshez!']"
              :info-messages="infoMessages"
              :warning-messages="warningMessages"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  warningMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  modelValue: {
    type: Boolean,
    required: false,
    default: null,
  },
})

const {
  handleChange,
  errors: veeErrors,
  value,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()

  if (veeErrors.value.length > 0) {
    return false
  } else {
    return true
  }
}

function inputChanged() {
  emit('update:modelValue', value.value)
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules
  )
}
const shape = ref('line')
</script>

<style lang="scss" scoped>
.sticky-calculator {
  max-width: 71.5rem;

  .col-2 {
    background: white;
  }
  .recommended-package {
    display: none;
  }
}
:deep(.q-radio__inner.relative-position.q-radio__inner--truthy) {
  color: #4e95fb;
}
:deep(.q-radio__inner.relative-position) {
  width: fit-content;
}
:deep(.q-radio__label) {
  flex: 1;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #111a37;
}

:deep(.q-option-group.q-gutter-x-sm) {
  margin-left: 0;
}

:deep(.q-option-group) {
  display: flex;
  flex-direction: row;
}
:deep(.q-gutter-x-sm > *, .q-gutter-sm > *) {
  margin-left: 0;
}

.radio-input {
  .q-field__messages.col {
    line-height: 1.25rem;
  }
  .q-field--with-bottom {
    padding-bottom: 1.5rem;
  }
}
.validation-row {
  .validation-col {
    padding: 0.5rem;
    text-align: center;
    border-left: 0.0625rem solid #dde1ef;
    border-right: 0.0625rem solid #dde1ef;
    border-bottom: 0.0625rem solid #dde1ef;
    background: white;
    :deep(.error) {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
.head-row {
  .first {
    border-radius: 0.25rem 0rem 0rem 0rem;
  }
  .last {
    border-radius: 0rem 0.25rem 0rem 0rem;
  }
}

.col.field-content {
  background: #dde1ef;
}

.package-head {
  .package-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    height: 5.75rem;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1d2c5b;
    border-left: 0.0625rem solid white;
  }
  &.first {
    .package-name {
      border-left: 0.0625rem solid #dde1ef;
      border-radius: 0.25rem 0rem 0rem 0rem;
    }
  }
  .package-price-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #ffffff;
    border-left: 0.0625rem solid #dde1ef;
    &.last {
      border-right: 0.0625rem solid #dde1ef;
    }
    .package-period-price {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
    }
    .package-annual-price {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .package-radio-input-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #ffffff;
    border-left: 0.0625rem solid #dde1ef;
    border-top: 0.0625rem solid #dde1ef;
    border-bottom: 0.0625rem solid #dde1ef;

    &.last {
      border-right: 0.0625rem solid #dde1ef;
    }
  }
}

.col.field-content.package-selected {
  background: #2f5997;
  .package-name {
    color: #ffffff;
  }
}
.recommended-package {
  text-align: center;
  border-left: 0.0625rem solid #fff;
  padding: 0.25rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 0.75rem;
  background: #1d2c5b;
  margin-top: -1.25rem;
}
.fix-height-validation.sticky {
  height: 15rem;
}
.fix-height.sticky {
  height: 12.5rem;
}
</style>
