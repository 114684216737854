<template>
  <component :is="markup">
    <slot />
    <span
      v-if="tooltipContent.length"
      class="cursor-pointer all-pointer-events title-tooltip"
      :class="alternateContentClass !== '' ? alternateContentClass : ''"
    >
      <div
        ref="tooltip"
        data-bs-toggle="tooltip"
        :data-bs-title="tooltipContent"
        data-bs-container="body"
        :data-bs-placement="tooltipPlacement"
        :data-bs-custom-class="customClass"
        data-bs-html="true"
      >
        <PhIconManager
          v-if="alternateContent === ''"
          ph-icon="ph-info"
          :size="iconSize"
        />
        <div v-else>
          {{ alternateContent }}
        </div>
      </div>
    </span>
  </component>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Tooltip } from 'bootstrap'

const props = defineProps({
  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },

  iconSize: {
    type: Number,
    required: false,
    default: 20,
  },

  tooltipPlacement: {
    type: String,
    required: false,
    default: 'right',
  },

  customClass: {
    type: String,
    required: false,
    default: 'tooltip',
  },

  alternateContent: {
    type: String,
    required: false,
    default: '',
  },

  alternateContentClass: {
    type: String,
    required: false,
    default: '',
  },
  htag: {
    type: String,
    required: true,
  },
})

const markup = ref(props.htag)

onMounted(() => {
  if (tooltip.value) {
    new Tooltip(tooltip.value)
  }
})
const tooltip = ref(null)
</script>

<script>
export default {
  name: 'TitleElement',
  name: 'TooltipElement',
}
</script>
<style lang="scss" scoped>
.cursor-pointer.all-pointer-events {
  margin: 0;
}
.cursor-pointer.all-pointer-events.title-tooltip{
  margin-left: .25rem;
}
</style>
