<template>
  <div class="modal-btn-holder">
    <q-btn
      id="KALK_102_MODAL"
      class="btn btn-secondary btn-outline btn-back"
      @click="dialogOpen = true"
    >
      Kiszámolom részletesen
    </q-btn>
  </div>

  <Dialog
    :key="dialogOpen"
    class="calculator-modal"
    :open="dialogOpen"
  >
    <q-card-section>
      <div class="dialogtitle-holder">
        <div class="dialog-title">
          Hasznos alapterület
        </div>
        <div class="dialog-close-button">
          <q-btn
            v-close-popup
            @click="dialogOpen = false"
          >
            <PhIconManager
              ph-icon="ph-x"
              color="#828BA8"
            />
          </q-btn>
        </div>
      </div>
      <div
        v-if="false"
        class="dialog-text"
      />
      <q-form
        ref="estateAreaSizeDialog"
        :key="sumArea > 350"
      >
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Lakáscélú helyiség
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_103"
                v-model:model-value="localResidentalAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                1,9 m-t elérő lakáscélú tetőtér
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_104"
                v-model:model-value="localUsefulAtticAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Talajszint alatti padozatú, lakás céljára használt helyiség
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_105"
                v-model:model-value="localBasementAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Üvegveranda, télikert
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_106"
                v-model:model-value="localPorchAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Nem lakás céljára szolgáló helyiség
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_107"
                v-model:model-value="localNonResidentalAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Lakáson belül kialakított galéria
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_108"
                v-model:model-value="localLoftAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                1,9 m belső magasság alatti területek
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_109"
                v-model:model-value="localNonUsefulAtticAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Terasz, erkély, loggia, tornác
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_110"
                v-model:model-value="localPatioAreaSize"
                label="Kérjük, adja meg a terület méretét!"
                :maxlength="20"
                postfix="m<sup>2</sup>"
                validation-rules="VAL_356_SUM"
                mask="###"
              />
            </div>
          </template>
        </Row66Sm>
      </q-form>
      <ModalButtons
        class="modal-btns"
        @close-modal="closeModal"
        @apply-changes="applyChanges"
      />
    </q-card-section>
  </Dialog>
</template>
<script setup>
import { ref, inject, computed, watch } from 'vue'
import { focusFirstValidation } from '../../utils/validations/validationFocus'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { defineRule } from 'vee-validate'
import { customDefineRule  ,getSkipEmptyFields } from '@/utils/validations/customDefineRule'

const dialogOpen = ref(false)
const viewModel = inject('viewModel')
const localResidentalAreaSize = ref(0)
const localUsefulAtticAreaSize = ref(0)
const localBasementAreaSize = ref(0)
const localPorchAreaSize = ref(0)
const localNonResidentalAreaSize = ref(0)
const localLoftAreaSize = ref(0)
const localNonUsefulAtticAreaSize = ref(0)
const localPatioAreaSize = ref(0)

const estateAreaSizeDialog = ref(null)

const emits = defineEmits(['updateEstateAreaSizeValue'])

function closeModal() {
  dialogOpen.value = false
}

async function applyChanges() {
  const isValid = await estateAreaSizeDialog.value.validate()
  if (isValid) {
    updateEstateAreaSizeValue()
    closeModal()
  } else {
    focusFirstValidation()
  }
}

function updateEstateAreaSizeValue(value) {
  viewModel.value.ResidentalAreaSize.Value = localResidentalAreaSize.value
  viewModel.value.UsefulAtticAreaSize.Value = localUsefulAtticAreaSize.value
  viewModel.value.BasementAreaSize.Value = localBasementAreaSize.value
  viewModel.value.PorchAreaSize.Value = localPorchAreaSize.value
  viewModel.value.NonResidentalAreaSize.Value = localNonResidentalAreaSize.value
  viewModel.value.LoftAreaSize.Value = localLoftAreaSize.value
  viewModel.value.NonUsefulAtticAreaSize.Value =
    localNonUsefulAtticAreaSize.value
  viewModel.value.PatioAreaSize.Value = localPatioAreaSize.value
  emits('updateEstateAreaSizeValue')
}

const sumArea = computed(() => {
  let sum = Math.floor(
    (Number(localResidentalAreaSize.value) || 0) +
      (Number(localUsefulAtticAreaSize.value) || 0) +
      (Number(localBasementAreaSize.value) || 0) +
      (Number(localPorchAreaSize.value) || 0) +
      (0.5 * Number(localNonResidentalAreaSize.value) || 0) +
      (0.5 * Number(localLoftAreaSize.value) || 0) +
      (0.5 * Number(localNonUsefulAtticAreaSize.value) || 0) +
      (0.5 * Number(localPatioAreaSize.value) || 0)
  )
  return sum
})

customDefineRule('VAL_356_SUM', (value) => {
  if (value != 0 && sumArea.value > 350) {
    return ValidationMessages.VAL_356
  }
  return true
})
watch(
  () => dialogOpen.value,
  (value) => {
    if (value) {
      localResidentalAreaSize.value =
        viewModel.value.ResidentalAreaSize.Value ?? 0
      localUsefulAtticAreaSize.value =
        viewModel.value.UsefulAtticAreaSize.Value ?? 0
      localBasementAreaSize.value = viewModel.value.BasementAreaSize.Value ?? 0
      localPorchAreaSize.value = viewModel.value.PorchAreaSize.Value ?? 0
      localNonResidentalAreaSize.value =
        viewModel.value.NonResidentalAreaSize.Value ?? 0
      localLoftAreaSize.value = viewModel.value.LoftAreaSize.Value ?? 0
      localNonUsefulAtticAreaSize.value =
        viewModel.value.NonUsefulAtticAreaSize.Value ?? 0
      localPatioAreaSize.value = viewModel.value.PatioAreaSize.Value ?? 0
    }
  }
)
watch(
  () => sumArea.value,
  async (value)  => {
    await estateAreaSizeDialog.value.validate()
  }
)
</script>
<style lang="scss" scoped>
.dialog-close-button{
  width: 1.5rem;
}
</style>
