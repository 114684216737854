<template>
  <MFODesktopPackageRowLayout>
    <template #first>
      <SubmodusTitle
        :icon-size="16"
        :tooltip-content="texts.vtxt_kalk219_tooltipContent"
        :tooltip-title="'Háztartási ingóság'"
      >
        Háztartási ingóság
      </SubmodusTitle>
    </template>
    <template #second>
      <TextInputPackage
        id="KALK_219_Extra"
        v-model="viewModel.Packages.Extra.EstatePersonalitySumValueBO.Value"
        :is-disabled="isGTDisabled"
        :maxlength="21"
        validation-rules="VAL_363_MAIN|VAL_445_MAIN"
        warning-message-rules="VAL_447"
        :warning-messages-target="[
          estateChangedFromModalWarning && estateValuechanged,
        ]"
        :init-input-value="
          viewModel.Packages.Extra.EstatePersonalitySumValueBO.Value
        "
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :revalidate="revalidate"
        @value-changed="
          extraChanged(
            viewModel.Packages.Extra.EstatePersonalitySumValueBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #third>
      <TextInputPackage
        id="KALK_219_Komfort"
        v-model="viewModel.Packages.Komfort.EstatePersonalitySumValueBO.Value"
        :is-disabled="isGTDisabled"
        :maxlength="21"
        validation-rules="VAL_363_MAIN|VAL_445_MAIN"
        warning-message-rules="VAL_447"
        :warning-messages-target="[
          estateChangedFromModalWarning && estateValuechanged,
        ]"
        :init-input-value="
          viewModel.Packages.Komfort.EstatePersonalitySumValueBO.Value
        "
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :revalidate="revalidate"
        @value-changed="
          komfortChanged(
            viewModel.Packages.Komfort.EstatePersonalitySumValueBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #fourth>
      <TextInputPackage
        id="KALK_219_StartPlus"
        v-model="viewModel.Packages.StartPlus.EstatePersonalitySumValueBO.Value"
        :is-disabled="isGTDisabled"
        :maxlength="21"
        validation-rules="VAL_363_MAIN|VAL_445_MAIN"
        warning-message-rules="VAL_447"
        :warning-messages-target="[
          estateChangedFromModalWarning && estateValuechanged,
        ]"
        :init-input-value="
          viewModel.Packages.StartPlus.EstatePersonalitySumValueBO.Value
        "
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :revalidate="revalidate"
        @value-changed="
          startPlusChanged(
            viewModel.Packages.StartPlus.EstatePersonalitySumValueBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #fifth>
      <TextInputPackage
        id="KALK_219_Start"
        v-model="viewModel.Packages.Start.EstatePersonalitySumValueBO.Value"
        :maxlength="21"
        validation-rules="VAL_363_MAIN|VAL_445_MAIN"
        warning-message-rules="VAL_447"
        :warning-messages-target="[
          estateChangedFromModalWarning && estateValuechanged,
        ]"
        :init-input-value="
          viewModel.Packages.Start.EstatePersonalitySumValueBO.Value
        "
        mask="### ### ### ### ### ### ###"
        :reverse-fill-mask="true"
        :is-disabled="isMFODisabled"
        postfix="Ft"
        :revalidate="revalidate"
        @value-changed="
          startChanged(
            viewModel.Packages.Start.EstatePersonalitySumValueBO.Value
          ),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
  </MFODesktopPackageRowLayout>
</template>
<script setup>
import { inject, onMounted, ref } from 'vue'
import { customDefineRule } from '@/utils/validations/customDefineRule'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'

const { getFormattedNumber } = inject('util')
const texts = inject('texts')
let viewModel = inject('viewModel')
const emit = defineEmits(['calculate'])
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')
const estateValuechanged = ref(false)
const estateChangedFromModalWarning = ref(false)
onMounted(() => {
  estateChangedFromModalWarning.value = estatechangedfrommodal()
})

const revalidate  = ref(false)

function revalidateinput(){
  revalidate.value = true
}

function estatechangedfrommodal() {
  if (
    viewModel.value.HouseholdAppliancesValue.Value > 0 ||
    viewModel.value.ToolsAndNonIntegratedLampsValue.Value > 0 ||
    viewModel.value.TelecommunicationDevicesValue.Value > 0 ||
    viewModel.value.FurnitureValue.Value > 0 ||
    viewModel.value.ClothesValue.Value > 0 ||
    viewModel.value.KitchenAndBathroomEquipment.Value > 0 ||
    viewModel.value.TextileValue.Value > 0 ||
    viewModel.value.MiscellaneousItemsValue.Value > 0
  ) {
    return true
  }
  return false
}
function startChanged(value) {
  estateValuechanged.value = true
  viewModel.value.EstatePersonalytySumValue.Value = value

  if (viewModel.value.MNBMode == undefined) {
    viewModel.value.Packages.CheckBOValueIII = true
    viewModel.value.Packages.StartPlus.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.Komfort.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.Extra.EstatePersonalitySumValueBO.Value = value
  }
  if (estatechangedfrommodal()) {
    viewModel.value.HouseholdAppliancesValue.Value = 0
    viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
    viewModel.value.TelecommunicationDevicesValue.Value = 0
    viewModel.value.FurnitureValue.Value = 0
    viewModel.value.ClothesValue.Value = 0
    viewModel.value.KitchenAndBathroomEquipment.Value = 0
    viewModel.value.TextileValue.Value = 0
    viewModel.value.MiscellaneousItemsValue.Value = 0
  }
  emit('calculate')
}

function startPlusChanged(value) {
  estateValuechanged.value = true
  viewModel.value.EstatePersonalytySumValue.Value = value
  viewModel.value.Packages.Start.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.Komfort.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.Extra.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.CheckBOValueIII = true

  if (estatechangedfrommodal()) {
    viewModel.value.HouseholdAppliancesValue.Value = 0
    viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
    viewModel.value.TelecommunicationDevicesValue.Value = 0
    viewModel.value.FurnitureValue.Value = 0
    viewModel.value.ClothesValue.Value = 0
    viewModel.value.KitchenAndBathroomEquipment.Value = 0
    viewModel.value.TextileValue.Value = 0
    viewModel.value.MiscellaneousItemsValue.Value = 0
  }
  emit('calculate')
}

function komfortChanged(value) {
  estateValuechanged.value = true
  viewModel.value.EstatePersonalytySumValue.Value = value
  viewModel.value.Packages.Start.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.StartPlus.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.Extra.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.CheckBOValueIII = true

  if (estatechangedfrommodal()) {
    viewModel.value.HouseholdAppliancesValue.Value = 0
    viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
    viewModel.value.TelecommunicationDevicesValue.Value = 0
    viewModel.value.FurnitureValue.Value = 0
    viewModel.value.ClothesValue.Value = 0
    viewModel.value.KitchenAndBathroomEquipment.Value = 0
    viewModel.value.TextileValue.Value = 0
    viewModel.value.MiscellaneousItemsValue.Value = 0
  }
  emit('calculate')
}
function extraChanged(value) {
  estateValuechanged.value = true
  viewModel.value.EstatePersonalytySumValue.Value = value
  viewModel.value.Packages.Start.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.StartPlus.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.Komfort.EstatePersonalitySumValueBO.Value = value
  viewModel.value.Packages.CheckBOValueIII = true

  if (estatechangedfrommodal()) {
    viewModel.value.HouseholdAppliancesValue.Value = 0
    viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
    viewModel.value.TelecommunicationDevicesValue.Value = 0
    viewModel.value.FurnitureValue.Value = 0
    viewModel.value.ClothesValue.Value = 0
    viewModel.value.KitchenAndBathroomEquipment.Value = 0
    viewModel.value.TextileValue.Value = 0
    viewModel.value.MiscellaneousItemsValue.Value = 0
  }
  emit('calculate')
}


customDefineRule('VAL_445_MAIN', (value) => {
  const targetNumber = parseInt(
    viewModel.value.Packages.Start.EstatePersonalitySumMinimumBO.Value,
    10
  )
  if (Number(value) < targetNumber) {
    return ValidationMessages.VAL_445.replace(
      '{0}',
      getFormattedNumber(targetNumber)
    )
  }
  return true
})
</script>
<style lang="scss" scoped></style>
