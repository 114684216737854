<template>
  <div class="marketistatement-input-holder">
    <p class="marketingstatement-paragraph">
      Hozzájárulok, hogy a megadott személyes adataimat (név, telefonszám,
      e-mail cím) és a kalkuláció során megadott adataimat a Genertel Biztosító
      Zrt. direkt marketing célból a jelen nyilatkozat visszavonásáig kezelje.
    </p>
    <div class="marketingstatement-checkbox">
      <CheckboxInput>Megadom a hozzájárulásomat</CheckboxInput>
    </div>
  </div>
</template>
<script>
import CheckboxInput from './CheckboxInput.vue'
</script>
<style lang="scss" scoped>
.marketistatement-input-holder {
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .marketingstatement-paragraph {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4a567c;
  }
  .marketingstatement-checkbox {
    :deep(.q-checkbox) {
      margin: 0 !important;
      .q-checkbox__label {
        height: 1.25rem;
        display: flex;
        align-items: center;
      }
      .q-item__label {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem !important;
        color: $D-700;
      }
    }
  }
}
</style>
