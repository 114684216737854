<template>
  <q-input
    class="numeric-up-down"
    standout
    bottom-slots
    :model-value="value"
    :item-aligned="true"
    :borderless="true"
  >
    <template #prepend>
      <div
        :class="value == 0 ? ' disable' : ''"
        class="cursor-pointer minus-icon-holder"
        @click="minus()"
      >
        <PhIconManager
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-minus-circle"
        />
      </div>
    </template>
    <template #append>
      <div
        class="cursor-pointer plus-icon-holder"
        :class="value == 10 ? ' disable' : ''"
        @click="plus()"
      >
        <PhIconManager
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-plus-circle"
        />
      </div>
    </template>
  </q-input>
</template>
<script setup>
import { ref } from 'vue';


const value = ref(0)
const props = defineProps({
  modelvalue: {
    type: Number,
    default: 0,
  },
})
function plus() {
  value.value++
}
function minus() {
  value.value--
}
</script>
<style lang="scss" scoped>
.numeric-up-down {
  width: 8rem;
  border: .0625rem solid #f5f4f8;
  box-shadow: 0rem .0625rem .125rem rgba(0, 0, 0, 0.06), 0rem .0625rem .1875rem rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
  background-color: #ffffff;
  padding: 1.125rem 1rem;

  :deep(.q-field__inner) {
    .q-field__control {
      align-items: center;
    }
    .q-field__control {
      height: auto;
    }
    .q-field__prepend {
      pointer-events: all;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0rem;
    }
    .q-field__append {
      pointer-events: all;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0rem;
    }

    .plus-icon-holder,
    .minus-icon-holder {
      user-select: none;
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0rem;
      svg {
        font-size: 0rem;
        color: $secondary;
      }
    }
    .disable {
      svg {
        color: #b4b9cb;
      }
    }
    .q-field__native {
      padding: 0;
      text-align: center;
    }
  }
  @media screen and (max-width: $breakpoint-lg-max) {
    padding: .5rem;
    width: 17.9375rem;
    margin-right: 8.8125rem;
    :deep(.q-field__control) {
      height: 2.5rem !important;
    }
    :deep(.q-field__native) {
      min-width: 10.9375rem;
      width: 100%;
    }
    :deep(.q-field__inner) {
      .q-field__prepend,.q-field__append{
        height: 1.5rem;
        width: 1.5rem;
      }
      .minus-icon-holder,
      .plus-icon-holder {
        height: 1.5rem;
        width: 1.5rem;

        svg{
          height: 1.5rem;
        width: 1.5rem;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-md-max) {
    width: 100%;
  }
}
</style>
