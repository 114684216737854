<template>
  <div
    v-if="loadingWithTimer"
    class="loading-overlay"
  >
    <div class="loading-spinner" />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
})

const triggeredTimestamp = ref(null)
const loadingWithTimer = ref(false)

watch(
  () => props.isLoading,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      if(newValue === true){
        triggeredTimestamp.value = Date.now()
        loadingWithTimer.value = true
      }
      else{
        const currentTimestamp = Date.now()
        if(currentTimestamp - triggeredTimestamp.value < 1000){
          setTimeout(() => {
            loadingWithTimer.value = false
          }, 1000 - (currentTimestamp - triggeredTimestamp.value))
        }
        else{
          loadingWithTimer.value = false
        }
      }

    }
  }
)

</script>

<style lang="scss" scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 5rem;
  height: 5rem;
  border: .25rem solid $B-500;
  border-top: .25rem solid rgba(255, 255, 255, 0.92) ;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .loading-spinner {
    width: 3rem;
  height: 3rem;
  border: .1563rem solid $B-500;
  border-top: .1563rem solid rgba(255, 255, 255, 0.92) ;
  }
}
</style>
