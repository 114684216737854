<template>
  <div class="container footer-left-bar">
    <div class="logo-holder d-none d-lg-flex d-xxl-flex">
      <div
        class="navbar-brand justify-content-start"
      >
        <img
          :src="`${imageUrlHelper.getImageUrl(
            '/genertel-logo.svg',
            componentUrl
          )}`"
          alt="Genertel logo"
          width="112"
          height="32"
        >
      </div>
      <img
        :src="`${imageUrlHelper.getImageUrl(
          '/Generali_short_logo_white.svg',
          componentUrl
        )}`"
        color="white" 
        alt="Genertel logo"
        width="104"
        height="84"
      >
    </div>
    <div
      v-if="$q.screen.width < 992"
      id="footer-menu-accordion"
      class="d-lg-none d-xxl-none"
    >
      <MobileFooterItem
        v-for="(columnItem, index) in footerItems.columns"
        :key="columnItem.title"
        :column-item="columnItem"
        :index="index"
        :clicked-footer-item="clickedFooterItem"
        @toggle-footer="footerItemToggler"
      />
    </div>
    <div class="social-bar">
      <a :href="footerItems.facebook"><PhIconManager ph-icon="ph-facebook-logo" /></a>
      <a :href="footerItems.youtube"><PhIconManager ph-icon="ph-youtube-logo" /></a>
      <a
        :href="footerItems.blog"
        target="_blank"
      >
        <img
          :src="`${imageUrlHelper.getImageUrl('/blog-icon.svg', componentUrl)}`"
          width="24"
          height="24"
          alt="alt"
        >
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useQuasar } from 'quasar'
import imageUrlHelper from '../utils/infrastructure/imageUrlHelper.js'

const props = defineProps({
  footerItems: {
    type: Object,
    required: true,
    default: () => {},
  },
})

const $q = useQuasar()
const componentUrl = import.meta.url
const clickedFooterItem = ref(-1)
const baseUrl = ref('')

onMounted(() => {
  baseUrl.value = window.location.hostname == 'localhost' ? '/src' : ''
})

function footerItemToggler(index) {
  clickedFooterItem.value = index
}
</script>

<script>
export default {
  name: 'MobileFooter',
}
</script>
<style lang="scss" scoped>
  .logo-holder{
    flex-direction: column;
    gap: 2.5rem;
  }
</style>