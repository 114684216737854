<template>
  <MFODesktopPackageRowLayout>
    <template #first>
      <SubmodusTitle
        :icon-size="16"
        :tooltip-content="texts.vtxt_kalk220_tooltipContent"
        :tooltip-title="'Értéktárgyak'"
      >
        Értéktárgyak
      </SubmodusTitle>
    </template>
    <template #second>
      <NumberInputPackage
        id="KALK_220_Extra"
        v-model="viewModel.Packages.Extra.ValuablesSumBO.Value"
        :maxlength="20"
        validation-rules="VAL_364_MAIN"
        warning-message-rules="VAL_420|VAL_447"
        :warning-messages-target="[
          valuablesChangedFromModalWarning && valuablesValuechanged,
        ]"
        :init-input-value="viewModel.Packages.Extra.ValuablesSumBO.Value"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="extraChanged(viewModel.Packages.Extra.ValuablesSumBO.Value),revalidateinput()"
        @revalidated="revalidate = false"
      />
    </template>
    <template #third>
      <NumberInputPackage
        id="KALK_220_Komfort"
        v-model="viewModel.Packages.Komfort.ValuablesSumBO.Value"
        :maxlength="20"
        validation-rules="VAL_364_MAIN"
        warning-message-rules="VAL_420|VAL_447"
        :warning-messages-target="[
          valuablesChangedFromModalWarning && valuablesValuechanged,
        ]"
        :init-input-value="viewModel.Packages.Komfort.ValuablesSumBO.Value"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="
          komfortChanged(viewModel.Packages.Komfort.ValuablesSumBO.Value),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #fourth>
      <NumberInputPackage
        id="KALK_220_StartPlus"
        v-model="viewModel.Packages.StartPlus.ValuablesSumBO.Value"
        :maxlength="20"
        validation-rules="VAL_364_MAIN"
        warning-message-rules="VAL_420|VAL_447"
        :init-input-value="viewModel.Packages.StartPlus.ValuablesSumBO.Value"
        :warning-messages-target="[
          valuablesChangedFromModalWarning && valuablesValuechanged,
        ]"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="
          startPlusChanged(viewModel.Packages.StartPlus.ValuablesSumBO.Value),revalidateinput()
        "
        @revalidated="revalidate = false"
      />
    </template>
    <template #fifth>
      <NumberInputPackage
        id="KALK_220_Start"
        v-model="viewModel.Packages.Start.ValuablesSumBO.Value"
        :maxlength="20"
        validation-rules="VAL_364_MAIN"
        warning-message-rules="VAL_420|VAL_447"
        :warning-messages-target="[
          valuablesChangedFromModalWarning && valuablesValuechanged,
        ]"
        :init-input-value="viewModel.Packages.Start.ValuablesSumBO.Value"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        :is-disabled="isMFODisabled"
        postfix="Ft"
        :revalidate="revalidate"
        @value-changed="startChanged(viewModel.Packages.Start.ValuablesSumBO.Value),revalidateinput()"
        @revalidated="revalidate = false"
      />
    </template>
  </MFODesktopPackageRowLayout>
</template>
<script setup>
import { inject, onMounted, ref } from 'vue'

const texts = inject('texts')
let viewModel = inject('viewModel')
const emit = defineEmits(['calculate'])
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')

const valuablesValuechanged = ref(false)
const valuablesChangedFromModalWarning = ref(false)


function valuableschangedfrommodal() {
  if (
    viewModel.value.JewelryValue.Value > 0 ||
    viewModel.value.AntiquesValue.Value > 0 ||
    viewModel.value.FursAndCarpetsValue.Value > 0 ||
    viewModel.value.CollectionsValue.Value > 0
  ) {
    return true
  }
  return false
}
onMounted(() => {
  setInitinputValues()
  valuablesChangedFromModalWarning.value = valuableschangedfrommodal()
})

const revalidate  = ref(false)

function revalidateinput(){
  revalidate.value = true
}

function startChanged(value) {
  valuablesValuechanged.value = true
  if (viewModel.value.MNBMode == undefined) {
    viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = value
    viewModel.value.Packages.Komfort.ValuablesSumBO.Value = value
    viewModel.value.Packages.Extra.ValuablesSumBO.Value = value
    viewModel.value.ValuablesSumValue.Value = value
  }
  if (valuableschangedfrommodal()) {
    viewModel.value.JewelryValue.Value = 0
    viewModel.value.AntiquesValue.Value = 0
    viewModel.value.FursAndCarpetsValue.Value = 0
    viewModel.value.CollectionsValue.Value = 0
  }

  emit('calculate')
}

function startPlusChanged(value) {
  valuablesValuechanged.value = true

  viewModel.value.Packages.Start.ValuablesSumBO.Value = value
  viewModel.value.Packages.Komfort.ValuablesSumBO.Value = value
  viewModel.value.Packages.Extra.ValuablesSumBO.Value = value
  viewModel.value.ValuablesSumValue.Value = value
  if (valuableschangedfrommodal()) {
    viewModel.value.JewelryValue.Value = 0
    viewModel.value.AntiquesValue.Value = 0
    viewModel.value.FursAndCarpetsValue.Value = 0
    viewModel.value.CollectionsValue.Value = 0
  }
  emit('calculate')
}

function komfortChanged(value) {
  valuablesValuechanged.value = true

  viewModel.value.Packages.Start.ValuablesSumBO.Value = value
  viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = value
  viewModel.value.Packages.Extra.ValuablesSumBO.Value = value
  viewModel.value.ValuablesSumValue.Value = value
  if (valuableschangedfrommodal()) {
    viewModel.value.JewelryValue.Value = 0
    viewModel.value.AntiquesValue.Value = 0
    viewModel.value.FursAndCarpetsValue.Value = 0
    viewModel.value.CollectionsValue.Value = 0
  }
  emit('calculate')
}

function extraChanged(value) {
  valuablesValuechanged.value = true

  viewModel.value.Packages.Start.ValuablesSumBO.Value = value
  viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = value
  viewModel.value.Packages.Komfort.ValuablesSumBO.Value = value
  viewModel.value.ValuablesSumValue.Value = value
  if (valuableschangedfrommodal()) {
    viewModel.value.JewelryValue.Value = 0
    viewModel.value.AntiquesValue.Value = 0
    viewModel.value.FursAndCarpetsValue.Value = 0
    viewModel.value.CollectionsValue.Value = 0
  }
  emit('calculate')
}
function setInitinputValues() {
  if (viewModel.value.ValuablesSumValue.Value) {
    viewModel.value.Packages.Extra.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
    viewModel.value.Packages.Komfort.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
    viewModel.value.Packages.StartPlus.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
    viewModel.value.Packages.Start.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
  } else {
    viewModel.value.Packages.Extra.ValuablesSumBO.Value = 0
    viewModel.value.Packages.Komfort.ValuablesSumBO.Value = 0
    viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = 0
    viewModel.value.Packages.Start.ValuablesSumBO.Value = 0
  }
}
</script>
<style lang="scss" scoped></style>
