<template>
  <Row66Xl48Lg class="input-container">
    <template #first>
      <div class="calculator-coupon-description-holder">
        <div class="btn-tag coupon-icon">
          <PhIconManager
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-tag"
            color="white"
          />
        </div>
        <div class="text-holders">
          <TitleTooltipModal
            htag="h3"
            class="input-title"
            tooltip-content=""
          >
            {{ 'Kuponkód megadása' }}
          </TitleTooltipModal>
          <p class="normal">
            {{ informationContent }}
          </p>
        </div>
      </div>
    </template>
    <template #second>
      <div class="coupon-input-holder">
        <div class="coupon-input">
          <TextInput
            :id="id"
            v-model:model-value="viewModel.CouponCode.Value"
            :init-input-value="viewModel.CouponCode.Value"
            :label="'Kuponkód'"
            :max-length="20"
          />
          <q-btn
            class="btn btn-secondary btn-outline btn-back coupon-btn"
            @click="couponDetails"
          >
            {{ 'Kupon beváltása' }}
          </q-btn>
        </div>
        <div
          v-if="responseData != null"
          class="coupon-alert"
        >
          <Alert
            :color="responseData.HasError ? 'error' : 'success'"
            :title="alertTitle()"
            :content="alertContent()"
          />

          <div
            v-if="!responseData.HasError"
            class="coupon-success"
          >
            <p class="success-title">
              {{ texts.vtxt_couponDetailsTitle_label }}
            </p>
            <div class="coupon-summary-row">
              <div class="coupon-summary-item">
                <p class="summary-title">
                  {{ texts.vtxt_paymentPeriod_label }}
                </p>
                <p class="summary-price">
                  {{ periodicalFee() }}
                </p>
              </div>
              <div class="coupon-summary-item">
                <p class="summary-title">
                  {{ texts.vtxt_annualFee_label }}
                </p>
                <p class="summary-price">
                  {{ annualFee() }}
                </p>
              </div>
            </div>
            <div class="coupon-summary-row">
              <div class="coupon-summary-item">
                <p class="summary-title">
                  {{ texts.vtxt_couponDiscount_label }}
                </p>
                <p class="summary-price">
                  {{ discountFee() }}
                </p>
              </div>
              <div class="coupon-summary-item">
                <p class="summary-title">
                  {{ texts.vtxt_payableFee_label }}
                </p>
                <p
                  v-if="periodicalFeeWithCoupon() != null"
                  class="summary-price"
                >
                  {{ periodicalFeeWithCoupon() }}
                </p>
                <p
                  v-else
                  class="summary-price"
                >
                  {{ payableFee() }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Row66Xl48Lg>
</template>
<script setup>
import { inject, onMounted, ref } from 'vue'
import axios from 'axios'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { useCalculatorStore } from '@/stores/calculatorStore'

const { callAdditionals } = inject('wizard')

const calculatorStore = useCalculatorStore()

const { getFormattedNumber } = inject('util')
const viewModel = inject('viewModel')

const pushDataLayerCoupon = inject('pushDataLayerCoupon')

const modelValue = ref(null)
const responseData = ref()
const couponValidationId = ref(null)

const props = defineProps({
  type: {
    type: String,
    default: 'success',
  },
  alertTitle: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  informationContent: {
    type: String,
    default: '',
  },
  texts: {
    type: Object,
    required: true,
  },
  productId: {
    type: Number,
    required: true,
  },
  viewModel: {
    type: Object,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
})
// ÁT KELL ÍRNI
function annualFee() {
  if (
    responseData.value.ResponseData != null &&
    responseData.value.ResponseData.AnnualFee !== undefined
  ) {
    return (
      getFormattedNumber(responseData.value.ResponseData.AnnualFee) +
      ' ' +
      currency()
    )
  } else {
    return null
  }
}
function payableFee() {
  if (
    responseData.value != null &&
    responseData.value.ResponseData.PayableFee !== undefined
  ) {
    return (
      getFormattedNumber(responseData.value.ResponseData.PayableFee) +
      ' ' +
      currency()
    )
  } else {
    return null
  }
}
function discountFee() {
  return responseData.value != null &&
    responseData.value.ResponseData.DiscountFee !== undefined
    ? getFormattedNumber(responseData.value.ResponseData.DiscountFee) +
        ' ' +
        currency()
    : null
}
function periodicalFee() {
  if (
    responseData.value != null &&
    responseData.value.ResponseData.PeriodicalFee !== undefined
  ) {
    return (
      getFormattedNumber(responseData.value.ResponseData.PeriodicalFee) +
      ' ' +
      currency()
    )
  } else {
    return null
  }
}
function periodicalFeeWithCoupon() {
  return responseData.value != null &&
    responseData.value.ResponseData.PeriodicalFeeWithCoupon !== undefined
    ? getFormattedNumber(
        responseData.value.ResponseData.PeriodicalFeeWithCoupon
      ) +
        ' ' +
        currency()
    : null
}
function currency() {
  let currency = 'Ft'
  return currency
}
function alertContent() {
  if (!responseData.value.HasError) {
    return (
      'Első fizetendő biztosítási díjából ' +
      getFormattedNumber(responseData.value.ResponseData.DiscountFee) +
      ' Ft kedvezmény kap.'
    )
  } else {
    return ValidationMessages[responseData.value.ResponseData.ValidationMessage]
  }
}
function alertTitle() {
  if (!responseData.value.HasError) {
    return 'Siker!'
  } else {
    return 'Sajnos a kuponkód nem megfelelő'
  }
}

async function couponDetails() {
  await axios
    .post(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Wizard/CouponDetails',
      viewModel.value
    )
    .then((response) => {
      responseData.value = response.data
      couponValidationId.value = response.data.ResponseData.ValidationMessage

      couponDataLayer(response.data.ResponseData)
    })
    calculatorStore.setValidCoupon(!responseData.value.HasError)
    if(!responseData.value.HasError){
      viewModel.value.Cart.HasCouponAccepted = true
      await callAdditionals()
    }
}

function couponDataLayer(response) {
  const eventName =
    response.AnnualFee !== undefined
      ? 'coupon_redemption_success'
      : 'coupon_redemption_error'
  const errorMessage = response.Valid ? '' : ValidationMessages[response.ValidationMessage]
  const userType =
    calculatorStore.getSavedMode == true ? 'Mentett kötés' : 'Új kötés'
  pushDataLayerCoupon(
    window.innerWidth,
    eventName,
    'lakasbiztositas',
    viewModel.value.CouponCode.Value,
    userType,
    errorMessage
  )
}
</script>
<style lang="scss" scoped>
.calculator-coupon-description-holder {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding-right: 3rem;
}
.input-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: $dark;
  margin: 0;
  word-break: break-word;
  padding-bottom: 0.5rem;
}
.coupon-icon {
  background: #a124ad;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
}
p.normal {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}
.coupon-input-holder {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.coupon-input {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.coupon-btn {
  padding: 0.75rem 1.25rem;
  border: 0;
  outline: 0.125rem solid #4e95fb !important;
  width: fit-content;
  display: flex;
  align-self: flex-end;
}
.coupon-alert {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.coupon-success {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #ffffff;
  box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
    0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  .success-title {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
    margin: 0;
  }
}
.coupon-summary-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .coupon-summary-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
  }
  .summary-title {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #828ba8;
    margin: 0;
  }
  .summary-price {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
    margin: 0;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .coupon-input-holder {
    gap: 2rem;
  }
  .coupon-input {
    gap: 2rem;
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .calculator-coupon-description-holder {
    padding-right: 0;
    padding-bottom: 2rem;
  }

  .coupon-input-holder {
    gap: 2rem;
  }
  .coupon-input {
    gap: 2rem;
    .q-btn {
      width: 100%;
      padding: 0.75rem 1.25rem;
    }
  }
  .coupon-summary-row {
    flex-direction: column;
  }
}
</style>
