<template>
  <div class="additional-price-row">
    <div class="price-holder">
      <div class="fee">
        Díj
      </div>
      <div class="fees">
        <div class="period-fee">
          {{ periodicalFee(periodFee, period) }}
        </div>
        <div
          v-if="!isAnnual && periodFee"
          class="annual-fee"
        >
          {{ annualFee + ' Ft/év' }}
        </div>
      </div>
    </div>
    <div
      v-if="isVisible()"
      class="input-holder"
    >
      <SelectInput
        :id="id"  
        :key="options"      
        :is-disabled="isDisabled"
        :label="getLabel()"
        :options="options"
        :v-model="value"
        :init-option-value="initOptionValue"
        :format-number="true"
        :suffix="suffix"
        @update:model-value="updateAdditional"
      />
    </div>
  </div>
</template>
<script setup>

const props = defineProps({
  annualFee: {
    type: String,
  },
  periodFee: {
    type: String,
  },
  period: {
    type: String,
  },
  isAnnual: {
    type: Boolean,
  },
  id: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
  },
  label: {
    type: String,
  },
  options: {
    type: Array,
  },
  initOptionValue: {
    type: Number,
  },
  suffix: {
    type: String,
  },
  isAdditionalDisabled: {
    type: Boolean,
  },
})

const emit = defineEmits(['update:modelValue'])
function updateAdditional(v){
    emit('update:modelValue', v)
}
function periodicalFee(periodFee, period){
  if(periodFee){
    return periodFee + ' Ft' + period
  }else{
    return '0 Ft'
  }
}

function getLabel(){
    switch (props.id) {
      case 'PenzugyiVesztesegKockazatokDijAtvallalas_select':
        return 'Díjátvállalás időtartama'
      case 'BalesetBiztositasCsomag_select':
        return ''
      default:
        return 'Fedezeti összeghatár'
    }
}

function isVisible(){
  switch (props.id) {
    case 'HobbiKisallatBiztositasCsomag_select':
      return false
    case 'IngatlanKiegeszitoKockazatokEpitesSzereles_select':
      return false
    default:
      return true
  }
}
</script>
<style lang="scss" scoped>
:deep(.q-field--disabled) {
  .q-field__append {
    display: none;
  }
}
.price-holder {
  width: 9rem;
  margin-left: 2.75rem;
  .fee {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #828ba8;
  }
  .period-fee {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
  }
  .annual-fee {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #111a37;
  }
}
.additional-price-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-holder{
  width: 50%;
}

@media screen and (max-width: $breakpoint-lg-max) {
  .price-holder {
    margin-left: 2.25rem;
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .input-holder{
    width: calc(100% - 2.25rem);
}
  .additional-price-row {
    gap: 1rem;
    flex-direction: column;
  }
  .price-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 2.25rem);
  }
  .input-holder {
    margin-left: 2.25rem;
  }
}
</style>
