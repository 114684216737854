<template>
  <MFODesktopPackageRowLayout>
    <template #first>
      <SubmodusTitle
        :icon-size="16"
        :tooltip-content="texts.vtxt_kalk158_tooltipContent"
        :tooltip-title="'Ingatlan alapfedezet'"
      >
        Ingatlan alapfedezet
      </SubmodusTitle>
    </template>
    <template #second>
      <TextInputPackage
        id="KALK_158_Extra"
        v-model="viewModel.Packages.Extra.EstateBO.Value"
        :maxlength="20"
        :init-input-value="viewModel.Packages.Extra.EstateBO.Value"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        validation-rules="VAL_379|VAL_444"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="extraChanged(viewModel.Packages.Extra.EstateBO.Value), revalidateinput()"
        @revalidated="revalidate = false"
      />
    </template>
    <template #third>
      <TextInputPackage
        id="KALK_158_Komfort"
        v-model="viewModel.Packages.Komfort.EstateBO.Value"
        :maxlength="20"
        :init-input-value="viewModel.Packages.Komfort.EstateBO.Value"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        validation-rules="VAL_379|VAL_444"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="komfortChanged(viewModel.Packages.Komfort.EstateBO.Value), revalidateinput()"
        @revalidated="revalidate = false"
      />
    </template>
    <template #fourth>
      <TextInputPackage
        id="KALK_158_StartPlus"
        v-model="viewModel.Packages.StartPlus.EstateBO.Value"
        :maxlength="20"
        :init-input-value="viewModel.Packages.StartPlus.EstateBO.Value"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        validation-rules="VAL_379|VAL_444"
        :is-disabled="isGTDisabled"
        :revalidate="revalidate"
        @value-changed="startPlusChanged(viewModel.Packages.StartPlus.EstateBO.Value), revalidateinput()"
        @revalidated="revalidate = false"
      />
    </template>
    <template #fifth>
      <TextInputPackage
        id="KALK_158_Start"
        v-model="viewModel.Packages.Start.EstateBO.Value"
        :is-disabled="isMFODisabled"
        :maxlength="20"
        :init-input-value="viewModel.Packages.Start.EstateBO.Value"
        mask="### ### ### ### ###"
        :reverse-fill-mask="true"
        postfix="Ft"
        validation-rules="VAL_379|VAL_444"
        :revalidate="revalidate"
        @value-changed="startChanged(viewModel.Packages.Start.EstateBO.Value), revalidateinput()"
        @revalidated="revalidate = false"
      />
    </template>
  </MFODesktopPackageRowLayout>
</template>
<script setup>
import { inject,ref } from 'vue'
import { customDefineRule } from '@/utils/validations/customDefineRule'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'

const { getFormattedNumber } = inject('util')
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')
const texts = inject('texts')
let viewModel = inject('viewModel')
const emit = defineEmits(['calculate'])
const revalidate  = ref(false)

function revalidateinput(){
  revalidate.value = true
}

function startChanged(value) {
  if (viewModel.value.MNBMode == undefined) {
    viewModel.value.Packages.StartPlus.EstateBO.Value = value
    viewModel.value.Packages.Komfort.EstateBO.Value = value
    viewModel.value.Packages.Extra.EstateBO.Value = value
  }
  emit('calculate')
}

function startPlusChanged(value) {
  viewModel.value.Packages.Start.EstateBO.Value = value
  viewModel.value.Packages.Komfort.EstateBO.Value = value
  viewModel.value.Packages.Extra.EstateBO.Value = value
  emit('calculate')
}

function komfortChanged(value) {
  viewModel.value.Packages.Start.EstateBO.Value = value
  viewModel.value.Packages.StartPlus.EstateBO.Value = value
  viewModel.value.Packages.Extra.EstateBO.Value = value
  emit('calculate')
}
function extraChanged(value) {
  viewModel.value.Packages.Start.EstateBO.Value = value
  viewModel.value.Packages.StartPlus.EstateBO.Value = value
  viewModel.value.Packages.Komfort.EstateBO.Value = value
  emit('calculate')
}
customDefineRule('VAL_444', (value) => {
  if (
    value < viewModel.value.Packages.Start.EstateMinimumBo.Value
  ) {
    return ValidationMessages.VAL_444.replace(
      '{0}',
      getFormattedNumber(viewModel.value.Packages.Start.EstateMinimumBo.Value)
      
    )
  } else {
    return true
  }
})
</script>
<style lang="scss" scoped></style>
