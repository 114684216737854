<template>
  <div
    id="mfo_mobilePackage_select"
    :class="veeErrors[0] ? 'fix-height-validation' : 'fix-height'"
    class="insurance-package-tablet-mobile"
  >
    <div v-sticky-parent>
      <div v-calculatorsticky>
        <div class="row head-row">
          <q-tabs
            v-model="selectedPackageTabs"
            @update:model-value="
              packageSelected(selectedPackageTabs),
              updateTabs(selectedPackageTabs)
            "
          >
            <q-tab
              v-if="viewModel.MNBMode == undefined"
              id="extraPackage"
              name="3"
              content-class="package-tab"
              class="col field-content first"
              :class="selectedPackageTabs == 3 ? 'package-selected' : ''"
            >
              <div
                class="package-head first"
                :class="isGTDisabled ? 'disabled-package' : ''"
              >
                <div class="package-name d-none d-lg-flex">
                  Extra Lakásbiztosítás
                </div>
                <div class="package-name d-flex d-lg-none">
                  {{ 'Extra' }}
                </div>
                <div class="package-price-holder first">
                  <div
                    class="package-period-price"
                    :class="
                      viewModel.Packages.Extra.AnnualFee ===
                        viewModel.Packages.Extra.FeePart
                        ? 'annual'
                        : ''
                    "
                  >
                    <div>
                      {{
                        getFormattedNumber(viewModel.Packages.Extra.FeePart) +
                          ' Ft /'
                      }}
                    </div>
                    <div>
                      {{ getPeriodText() }}
                    </div>
                  </div>
                  <div
                    v-if="
                      viewModel.Packages.Extra.AnnualFee !==
                        viewModel.Packages.Extra.FeePart
                    "
                    class="package-annual-price"
                  >
                    {{
                      getFormattedNumber(viewModel.Packages.Extra.AnnualFee) +
                        ' Ft/év '
                    }}
                  </div>
                </div>
                <div class="package-radio-input-holder">
                  <div class="radio-input">
                    <q-radio
                      v-model="selectedPackageRadio"
                      :disable="isGTDisabled"
                      val="3"
                      :label="
                        isGTDisabled
                          ? 'Nem választható'
                          : selectedPackageRadio != 3
                            ? 'Ezt választom'
                            : 'Kiválasztva'
                      "
                      @update:model-value="
                        packageSelected(selectedPackageRadio),
                        updateRadio(selectedPackageRadio)
                      "
                    />
                  </div>
                </div>
              </div>
            </q-tab>
            <q-tab
              v-if="viewModel.MNBMode == undefined"
              id="komfortPackage"
              name="2"
              content-class="package-tab"
              class="col field-content"
              :class="selectedPackageTabs == 2 ? 'package-selected' : ''"
            >
              <div
                class="package-head"
                :class="isGTDisabled ? 'disabled-package' : ''"
              >
                <div
                  v-if="!isGTDisabled"
                  class="recommended-package"
                >
                  AJÁNLOTT
                </div>
                <div class="package-name d-none d-lg-flex">
                  Komfort Lakásbiztosítás
                </div>
                <div class="package-name d-flex d-lg-none">
                  {{ 'Komfort' }}
                </div>
                <div class="package-price-holder">
                  <div
                    class="package-period-price"
                    :class="
                      viewModel.Packages.Komfort.AnnualFee ===
                        viewModel.Packages.Komfort.FeePart
                        ? 'annual'
                        : ''
                    "
                  >
                    <div>
                      {{
                        getFormattedNumber(viewModel.Packages.Komfort.FeePart) +
                          ' Ft /'
                      }}
                    </div>
                    <div>
                      {{ getPeriodText() }}
                    </div>
                  </div>
                  <div
                    v-if="
                      viewModel.Packages.Komfort.AnnualFee !==
                        viewModel.Packages.Komfort.FeePart
                    "
                    class="package-annual-price"
                  >
                    {{
                      getFormattedNumber(viewModel.Packages.Komfort.AnnualFee) +
                        ' Ft/év '
                    }}
                  </div>
                </div>
                <div class="package-radio-input-holder">
                  <div class="radio-input">
                    <q-radio
                      v-model="selectedPackageRadio"
                      :disable="isGTDisabled"
                      val="2"
                      :label="
                        isGTDisabled
                          ? 'Nem választható'
                          : selectedPackageRadio != 2
                            ? 'Ezt választom'
                            : 'Kiválasztva'
                      "
                      @update:model-value="
                        packageSelected(selectedPackageRadio),
                        updateRadio(selectedPackageRadio)
                      "
                    />
                  </div>
                </div>
              </div>
            </q-tab>
            <q-tab
              v-if="viewModel.MNBMode == undefined"
              id="startPlusPackage"
              name="1"
              content-class="package-tab"
              class="col field-content"
              :class="selectedPackageTabs == 1 ? 'package-selected' : ''"
            >
              <div
                class="package-head"
                :class="isGTDisabled ? 'disabled-package' : ''"
              >
                <div class="package-name d-none d-lg-flex">
                  Start+ Lakásbiztosítás
                </div>
                <div class="package-name d-flex d-lg-none">
                  {{ 'Start +' }}
                </div>
                <div class="package-price-holder">
                  <div
                    class="package-period-price"
                    :class="
                      viewModel.Packages.StartPlus.AnnualFee ===
                        viewModel.Packages.StartPlus.FeePart
                        ? 'annual'
                        : ''
                    "
                  >
                    <div>
                      {{
                        getFormattedNumber(
                          viewModel.Packages.StartPlus.FeePart
                        ) + ' Ft /'
                      }}
                    </div>
                    <div>
                      {{ getPeriodText() }}
                    </div>
                  </div>
                  <div
                    v-if="
                      viewModel.Packages.StartPlus.AnnualFee !==
                        viewModel.Packages.StartPlus.FeePart
                    "
                    class="package-annual-price"
                  >
                    {{
                      getFormattedNumber(
                        viewModel.Packages.StartPlus.AnnualFee
                      ) + ' Ft/év '
                    }}
                  </div>
                </div>
                <div class="package-radio-input-holder">
                  <div class="radio-input">
                    <q-radio
                      v-model="selectedPackageRadio"
                      :disable="isGTDisabled"
                      val="1"
                      :label="
                        isGTDisabled
                          ? 'Nem választható'
                          : selectedPackageRadio != 1
                            ? 'Ezt választom'
                            : 'Kiválasztva'
                      "
                      @update:model-value="
                        packageSelected(selectedPackageRadio),
                        updateRadio(selectedPackageRadio)
                      "
                    />
                  </div>
                </div>
              </div>
            </q-tab>
            <q-tab
              id="startPackage"
              name="0"
              content-class="package-tab"
              class="col field-content last"
              :class="
                selectedPackageTabs == 0 && viewModel.MNBMode == undefined
                  ? 'package-selected'
                  : ''
              "
            >
              <div
                class="package-head"
                :class="[
                  isMFODisabled ? 'disabled-package' : '',
                  viewModel.MNBMode != null &&
                    viewModel.MNBMode != undefined &&
                    viewModel.MNBMode == true
                    ? 'mnb-package-head'
                    : '',
                ]"
              >
                <div class="package-name d-none d-lg-flex">
                  Start Minősített Fogyasztóbarát Otthonbiztosítás
                </div>
                <div class="package-name package-name-mfo d-flex d-lg-none">
                  {{ 'Start' }}
                  <Tooltip
                    :tooltip-content="'Start minősített fogyasztóbarát otthonbiztosítás'"
                    alternate-content="(MFO)"
                  />
                </div>
                <div class="package-price-holder last">
                  <div
                    class="package-period-price"
                    :class="
                      viewModel.Packages.Start.AnnualFee ===
                        viewModel.Packages.Start.FeePart
                        ? 'annual'
                        : ''
                    "
                  >
                    <div>
                      {{
                        getFormattedNumber(viewModel.Packages.Start.FeePart) +
                          ' Ft /'
                      }}
                    </div>
                    <div>
                      {{ getPeriodText() }}
                    </div>
                  </div>
                  <div
                    v-if="
                      viewModel.Packages.Start.AnnualFee !==
                        viewModel.Packages.Start.FeePart
                    "
                    class="package-annual-price"
                  >
                    {{
                      getFormattedNumber(viewModel.Packages.Start.AnnualFee) +
                        ' Ft/év '
                    }}
                  </div>
                </div>
                <div
                  v-if="viewModel.MNBMode == undefined"
                  class="package-radio-input-holder last"
                >
                  <div class="radio-input">
                    <q-radio
                      v-model="selectedPackageRadio"
                      :disable="isMFODisabled"
                      val="0"
                      :label="
                        isMFODisabled
                          ? 'Nem választható'
                          : selectedPackageRadio != 0
                            ? 'Ezt választom'
                            : 'Kiválasztva'
                      "
                      @update:model-value="
                        packageSelected(selectedPackageRadio),
                        updateRadio(selectedPackageRadio)
                      "
                    />
                  </div>
                </div>
              </div>
            </q-tab>
          </q-tabs>
        </div>
        <div class="row validation-row">
          <div
            v-if="veeErrors[0]"
            class="col validation-col"
          >
            <ValidationMessage
              :messages="['Kérem, válasszon csomagot a továbblépéshez!']"
              :info-messages="infoMessages"
              :warning-messages="warningMessages"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- //ANCHOR - BasicRisc -->

    <div class="row submodus-row">
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="texts.vtxt_kalkThirdPageBasicRisk_tooltipContent"
            :tooltip-title="texts.vtxt_kalkThirdPageBasicRisk_label"
          >
            {{ texts.vtxt_kalkThirdPageBasicRisk_label }}
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="selectedPackageTabs"
        class="col"
        animated
      >
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="3"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="2"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="1"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
        <q-tab-panel
          name="0"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Start.Selected.Value &&
              viewModel.MNBMode == undefined
              ? 'highlighted'
              : '',
            isMFODisabled ? 'disabled-package' : '',
          ]"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <!-- //ANCHOR - EstateBO -->

    <div
      v-if="
        viewModel.InsuranceType.Value.value == 0 ||
          viewModel.InsuranceType.Value.value == 1
      "
      class="row submodus-row"
    >
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="texts.vtxt_kalk158_tooltipContent"
            :tooltip-title="'Ingatlan alapfedezet'"
          >
            Ingatlan alapfedezet
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="selectedPackageTabs"
        class="col"
        animated
      >
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="3"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <TextInputPackage
            id="KALK_158_Extra"
            v-model="viewModel.Packages.Extra.EstateBO.Value"
            :maxlength="20"
            :init-input-value="viewModel.Packages.Extra.EstateBO.Value"
            mask="### ### ### ### ###"
            :reverse-fill-mask="true"
            postfix="Ft"
            validation-rules="VAL_379|VAL_444"
            :is-disabled="isGTDisabled"
            @value-changed="extraChanged(
              'EstateBO',
              viewModel.Packages.Extra.EstateBO.Value
            )"
          />
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="2"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <TextInputPackage
            id="KALK_158_Komfort"
            v-model="viewModel.Packages.Komfort.EstateBO.Value"
            :maxlength="20"
            :init-input-value="viewModel.Packages.Komfort.EstateBO.Value"
            mask="### ### ### ### ###"
            :reverse-fill-mask="true"
            postfix="Ft"
            validation-rules="VAL_379|VAL_444"
            :is-disabled="isGTDisabled"
            @value-changed="komfortChanged(
              'EstateBO',
              viewModel.Packages.Komfort.EstateBO.Value
            )"
          />
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="1"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <TextInputPackage
            id="KALK_158_StartPlus"
            v-model="viewModel.Packages.StartPlus.EstateBO.Value"
            :maxlength="20"
            :init-input-value="viewModel.Packages.StartPlus.EstateBO.Value"
            mask="### ### ### ### ###"
            :reverse-fill-mask="true"
            postfix="Ft"
            validation-rules="VAL_379|VAL_444"
            :is-disabled="isGTDisabled"
            @value-changed="startPlusChanged(
              'EstateBO',
              viewModel.Packages.StartPlus.EstateBO.Value
            )"
          />
        </q-tab-panel>
        <q-tab-panel
          name="0"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
            isMFODisabled ? 'disabled-package' : '',
          ]"
        >
          <TextInputPackage
            id="KALK_158_Start"
            v-model="viewModel.Packages.Start.EstateBO.Value"
            :is-disabled="isMFODisabled"
            :maxlength="20"
            :init-input-value="viewModel.Packages.Start.EstateBO.Value"
            mask="### ### ### ### ###"
            :reverse-fill-mask="true"
            postfix="Ft"
            validation-rules="VAL_379|VAL_444"
            @value-changed="startChanged(
              'EstateBO',
              viewModel.Packages.Start.EstateBO.Value
            )"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <!-- //ANCHOR - EstatePersonalitySumValueBO -->

    <div
      v-if="
        viewModel.InsuranceType.Value.value == 0 ||
          viewModel.InsuranceType.Value.value == 2
      "
      class="row submodus-row"
    >
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="texts.vtxt_kalk219_tooltipContent"
            :tooltip-title="'Háztartási ingóság'"
          >
            Háztartási ingóság
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="selectedPackageTabs"
        class="col"
        animated
      >
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="3"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              id="KALK_219_Extra"
              v-model="
                viewModel.Packages.Extra.EstatePersonalitySumValueBO.Value
              "
              :is-disabled="isGTDisabled"
              :maxlength="20"
              validation-rules="VAL_363_MAIN|VAL_445_MAIN"
              warning-message-rules="VAL_447"
              :warning-messages-target="[
                estateChangedFromModalWarning && estateValuechanged,
              ]"
              :init-input-value="
                viewModel.Packages.Extra.EstatePersonalitySumValueBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              @value-changed="extraChanged(
                'EstatePersonalitySumValueBO',
                viewModel.Packages.Extra.EstatePersonalitySumValueBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="2"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              id="KALK_219_Komfort"
              v-model="
                viewModel.Packages.Komfort.EstatePersonalitySumValueBO.Value
              "
              :is-disabled="isGTDisabled"
              :maxlength="20"
              validation-rules="VAL_363_MAIN|VAL_445_MAIN"
              warning-message-rules="VAL_447"
              :warning-messages-target="[
                estateChangedFromModalWarning && estateValuechanged,
              ]"
              :init-input-value="
                viewModel.Packages.Komfort.EstatePersonalitySumValueBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              @value-changed="komfortChanged(
                'EstatePersonalitySumValueBO',
                viewModel.Packages.Komfort.EstatePersonalitySumValueBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="1"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              id="KALK_219_StartPlus"
              v-model="
                viewModel.Packages.StartPlus.EstatePersonalitySumValueBO.Value
              "
              :is-disabled="isGTDisabled"
              :maxlength="20"
              validation-rules="VAL_363_MAIN|VAL_445_MAIN"
              warning-message-rules="VAL_447"
              :warning-messages-target="[
                estateChangedFromModalWarning && estateValuechanged,
              ]"
              :init-input-value="
                viewModel.Packages.StartPlus.EstatePersonalitySumValueBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              @value-changed="startPlusChanged(
                'EstatePersonalitySumValueBO',
                viewModel.Packages.StartPlus.EstatePersonalitySumValueBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="0"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
            isMFODisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              id="KALK_219_Start"
              v-model="
                viewModel.Packages.Start.EstatePersonalitySumValueBO.Value
              "
              :maxlength="20"
              validation-rules="VAL_363_MAIN|VAL_445_MAIN"
              warning-message-rules="VAL_447"
              :warning-messages-target="[
                estateChangedFromModalWarning && estateValuechanged,
              ]"
              :init-input-value="
                viewModel.Packages.Start.EstatePersonalitySumValueBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              :is-disabled="isMFODisabled"
              postfix="Ft"
              @value-changed="startChanged(
                'EstatePersonalitySumValueBO',
                viewModel.Packages.Start.EstatePersonalitySumValueBO.Value
              )"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <!-- //ANCHOR - ValuablesSumBO -->

    <div
      v-if="
        viewModel.InsuranceType.Value.value == 0 ||
          viewModel.InsuranceType.Value.value == 2
      "
      class="row submodus-row"
    >
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="texts.vtxt_kalk220_tooltipContent"
            :tooltip-title="'Értéktárgyak'"
          >
            Értéktárgyak
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="selectedPackageTabs"
        class="col"
        animated
      >
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="3"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_220_Extra"
              v-model="viewModel.Packages.Extra.ValuablesSumBO.Value"
              :maxlength="20"
              validation-rules="VAL_364_MAIN"
              warning-message-rules="VAL_420|VAL_447"
              :warning-messages-target="[
                valuablesChangedFromModalWarning && valuablesValuechanged,
              ]"
              :init-input-value="viewModel.Packages.Extra.ValuablesSumBO.Value"
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              @value-changed="extraChanged(
                'ValuablesSumBO',
                viewModel.Packages.Extra.ValuablesSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="2"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_220_Komfort"
              v-model="viewModel.Packages.Komfort.ValuablesSumBO.Value"
              :maxlength="20"
              validation-rules="VAL_364_MAIN"
              warning-message-rules="VAL_420|VAL_447"
              :warning-messages-target="[
                valuablesChangedFromModalWarning && valuablesValuechanged,
              ]"
              :init-input-value="
                viewModel.Packages.Komfort.ValuablesSumBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              @value-changed="komfortChanged(
                'ValuablesSumBO',
                viewModel.Packages.Komfort.ValuablesSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="1"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_220_StartPlus"
              v-model="viewModel.Packages.StartPlus.ValuablesSumBO.Value"
              :maxlength="20"
              validation-rules="VAL_364_MAIN"
              warning-message-rules="VAL_420|VAL_447"
              :warning-messages-target="[
                valuablesChangedFromModalWarning && valuablesValuechanged,
              ]"
              :init-input-value="
                viewModel.Packages.StartPlus.ValuablesSumBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              @value-changed="startPlusChanged(
                'ValuablesSumBO',
                viewModel.Packages.StartPlus.ValuablesSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="0"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
            isMFODisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_220_Start"
              v-model="viewModel.Packages.Start.ValuablesSumBO.Value"
              :maxlength="20"
              validation-rules="VAL_364_MAIN"
              warning-message-rules="VAL_420|VAL_447"
              :warning-messages-target="[
                valuablesChangedFromModalWarning && valuablesValuechanged,
              ]"
              :init-input-value="viewModel.Packages.Start.ValuablesSumBO.Value"
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              :is-disabled="isMFODisabled"
              postfix="Ft"
              @value-changed="startChanged(
                'ValuablesSumBO',
                viewModel.Packages.Start.ValuablesSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <!-- //ANCHOR - SteadingValue -->

    <div
      v-for="(steading, steadingIndex) in viewModel.SteadingList"
      :key="'steading_' + steadingIndex"
      class="row submodus-row"
    >
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="texts.vtxt_kalk159_tooltipContent"
            :tooltip-title="steadingLabel(steadingIndex)"
          >
            {{ steadingLabel(steadingIndex) }}
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="selectedPackageTabs"
        class="col"
        animated
      >
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="3"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              :id="'KALK_159_Extra_' + steadingIndex"
              v-model="steading.SteadingValue.Value"
              :maxlength="20"
              validation-rules="VAL_380|VAL_446"
              :init-input-value="steading.SteadingValue.Value"
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              :index="steadingIndex"
              @update:model-value="callCalculate(true,2000)"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="2"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              :id="'KALK_159_Extra_' + steadingIndex"
              v-model="steading.SteadingValue.Value"
              :maxlength="20"
              validation-rules="VAL_380|VAL_446"
              :init-input-value="steading.SteadingValue.Value"
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              :index="steadingIndex"
              @update:model-value="callCalculate(true,2000)"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="1"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              :id="'KALK_159_Extra_' + steadingIndex"
              v-model="steading.SteadingValue.Value"
              :maxlength="20"
              validation-rules="VAL_380|VAL_446"
              :init-input-value="steading.SteadingValue.Value"
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              :index="steadingIndex"
              @update:model-value="callCalculate(true,2000)"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="0"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
            isMFODisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <TextInputPackage
              :id="'KALK_159_Start_' + steadingIndex"
              v-model="steading.SteadingValue.Value"
              :maxlength="20"
              validation-rules="VAL_380|VAL_446"
              :init-input-value="steading.SteadingValue.Value"
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              :is-disabled="isMFODisabled"
              postfix="Ft"
              :index="steadingIndex"
              @update:model-value="callCalculate(true,2000)"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <!-- //ANCHOR - SteadingPersonalEstateSumBO -->

    <div
      v-if="
        (viewModel.InsuranceType.Value.value == 0 &&
          viewModel.SteadingNumber.Value > 0) ||
          viewModel.InsuranceType.Value.value == 2
      "
      class="row submodus-row"
    >
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="texts.vtxt_kalk221_tooltipContent"
            :tooltip-title="' Összes melléképület ingóságának becsült értéke'"
          >
            Összes melléképület ingóságának becsült értéke
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="selectedPackageTabs"
        class="col"
        animated
      >
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="3"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_221_Extra"
              v-model="
                viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value
              "
              :maxlength="20"
              validation-rules="VAL_361"
              :init-input-value="
                viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value ==
                  undefined
                  ? viewModel.SteadingPersonalytySumValue.Value
                  : viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              @value-changed="extraChanged(
                'SteadingPersonalEstateSumBO',
                viewModel.Packages.Extra.SteadingPersonalEstateSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="2"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_221_Komfort"
              v-model="
                viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value
              "
              :maxlength="20"
              validation-rules="VAL_361"
              :init-input-value="
                viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value ==
                  undefined
                  ? viewModel.SteadingPersonalytySumValue.Value
                  : viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              @value-changed="komfortChanged(
                'SteadingPersonalEstateSumBO',
                viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="1"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_221_StartPlus"
              v-model="
                viewModel.Packages.StartPlus.SteadingPersonalEstateSumBO.Value
              "
              :maxlength="20"
              validation-rules="VAL_361"
              :init-input-value="
                viewModel.Packages.Komfort.SteadingPersonalEstateSumBO.Value ==
                  undefined
                  ? viewModel.SteadingPersonalytySumValue.Value
                  : viewModel.Packages.StartPlus.SteadingPersonalEstateSumBO
                    .Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              postfix="Ft"
              :is-disabled="isGTDisabled"
              @value-changed="startPlusChanged(
                'SteadingPersonalEstateSumBO',
                viewModel.Packages.StartPlus.SteadingPersonalEstateSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="0"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
            isMFODisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <NumberInputPackage
              id="KALK_221_Start"
              v-model="
                viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value
              "
              :maxlength="20"
              validation-rules="VAL_361"
              :init-input-value="
                viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value ==
                  undefined
                  ? viewModel.SteadingPersonalytySumValue.Value
                  : viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value
              "
              mask="### ### ### ### ###"
              :reverse-fill-mask="true"
              :is-disabled="isMFODisabled"
              postfix="Ft"
              @value-changed="startChanged(
                'SteadingPersonalEstateSumBO',
                viewModel.Packages.Start.SteadingPersonalEstateSumBO.Value
              )"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <!-- //ANCHOR - LiabilityInsurance -->

    <div class="row submodus-row">
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="texts.vtxt_kalk241_tooltipContent"
            :tooltip-title="'Felelősségbiztosítás'"
          >
            Felelősség&shy;biztosítás
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="selectedPackageTabs"
        class="col"
        animated
      >
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="3"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="KALK_241_Extra"
              :key="viewModel.Packages.Extra.LiabilityInsurance.Value"
              v-model="viewModel.Packages.Extra.LiabilityInsurance.Value"
              :options="viewModel.Packages.Extra.LiabilityInsurance.Values"
              :init-option-value="
                viewModel.Packages.Extra.LiabilityInsurance.Value.value
              "
              validation-rules=""
              :texts="texts"
              :format-number="true"
              suffix="Ft"
              :is-disabled="isGTDisabled"
              @update:model-value="setLiabilitySubmodusValue"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="2"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="KALK_241_Komfort"
              :key="viewModel.Packages.Komfort.LiabilityInsurance.Value"
              v-model="viewModel.Packages.Komfort.LiabilityInsurance.Value"
              :options="viewModel.Packages.Komfort.LiabilityInsurance.Values"
              :init-option-value="
                viewModel.Packages.Komfort.LiabilityInsurance.Value.value
              "
              validation-rules=""
              :texts="texts"
              :format-number="true"
              suffix="Ft"
              :is-disabled="isGTDisabled"
              @update:model-value="setLiabilitySubmodusValue"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          v-if="viewModel.MNBMode == undefined"
          name="1"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
            isGTDisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="KALK_241_StartPlus"
              :key="viewModel.Packages.StartPlus.LiabilityInsurance.Value"
              v-model="viewModel.Packages.StartPlus.LiabilityInsurance.Value"
              :options="viewModel.Packages.StartPlus.LiabilityInsurance.Values"
              :init-option-value="
                viewModel.Packages.StartPlus.LiabilityInsurance.Value.value
              "
              validation-rules=""
              :texts="texts"
              :format-number="true"
              suffix="Ft"
              :is-disabled="isGTDisabled"
              @update:model-value="setLiabilitySubmodusValue"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="0"
          class="submodus-control-holder"
          :class="[
            viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
            isMFODisabled ? 'disabled-package' : '',
          ]"
        >
          <div class="submodus-input-holder">
            <div class="text-only">
              {{ getFormattedNumber(liabilityInsuranceText()) + ' Ft' }}
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <!-- //ANCHOR - Additionals -->

    <template
      v-for="(item, index) in visibleAdditionalCategories"
      :key="index"
    >
      <div
        v-if="
          !item.IsVirtualPackage &&
            item.Id != 'IngatlanKiegeszitoKockazatokEpitesSzereles'
        "
        class="row submodus-row"
      >
        <div class="col submodus-name-holder">
          <div
            v-if="
              isSubmodusGift(item, viewModel.Packages.Extra) ||
                isSubmodusGift(item, viewModel.Packages.Komfort) ||
                isSubmodusGift(item, viewModel.Packages.StartPlus) ||
                isSubmodusGift(item, viewModel.Packages.Start)
            "
            class="submodus-gift-holder"
          >
            <div class="submodus-gift">
              <PhIconManager
                height=".9375rem"
                color="white"
                width=".9375rem"
                ph-icon="ph-gift"
              />
            </div>
          </div>
          <div class="submodus-title-holder">
            <SubmodusTitle
              v-sanitize="item.Name" 
              :icon-size="16"
              :tooltip-content="item.InformationContent"
              :tooltip-title="item.Name"
            />
          </div>
        </div>
        <q-tab-panels
          v-model="selectedPackageTabs"
          class="col"
          animated
        >
          <q-tab-panel
            v-if="viewModel.MNBMode == undefined"
            name="3"
            class="submodus-control-holder"
            :class="[
              viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
              isGTDisabled ? 'disabled-package' : '',
              !isSubmodusGift(item, viewModel.Packages.Extra)
                ? 'text-only'
                : 'submodus-gift',
            ]"
          >
            <PhIconManager
              v-if="
                showSubmodusInput(item, viewModel.Packages.Extra) == false ||
                  showSubmodusInput(item, viewModel.Packages.Extra) == undefined
              "
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-plus"
              @click="addSubmodus(item)"
            />
            <div
              v-if="
                (showSubmodusInput(item, viewModel.Packages.Extra) &&
                  getSubmodusValues(item, viewModel.Packages.Extra).length >
                  1 &&
                  !getSubmodusReadOnly(item, viewModel.Packages.Extra) &&
                  !isSubmodusGift(item, viewModel.Packages.Extra)) ||
                  (((showSubmodusInput(item, viewModel.Packages.Extra) &&
                    getSubmodusValues(item, viewModel.Packages.Extra).length ==
                    1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.Extra)) &&
                    !isSubmodusGift(item, viewModel.Packages.Extra))
              "
              class="submodus-input-holder"
            >
              <SelectInput
                v-if="
                  showSubmodusInput(item, viewModel.Packages.Extra) &&
                    getSubmodusValues(item, viewModel.Packages.Extra).length >
                    1 &&
                    !getSubmodusReadOnly(item, viewModel.Packages.Extra) &&
                    !isSubmodusGift(item, viewModel.Packages.Extra)
                "
                :id="'extra_' + item.Id"
                :key="getSubmodusValue(item, viewModel.Packages.Extra).Value"
                v-model="getSubmodusValue(item, viewModel.Packages.Extra).Value"
                :is-disabled="isGTDisabled"
                :texts="texts"
                :options="getSubmodusValues(item, viewModel.Packages.Extra)"
                :init-option-value="
                  getSubmodusValue(item, viewModel.Packages.Extra).Value.value
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(item)"
                @update:model-value="
                  setSubmodusValue(
                    item,
                    getSubmodusValue(item, viewModel.Packages.Extra).Value,
                    getSubmodusValues(item, viewModel.Packages.Extra)
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(item, viewModel.Packages.Extra) &&
                    getSubmodusValues(item, viewModel.Packages.Extra).length ==
                    1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.Extra)) &&
                    !isSubmodusGift(item, viewModel.Packages.Extra)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(item, viewModel.Packages.Extra)[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(item)
                }}
              </div>
            </div>
            <div
              v-if="isSubmodusGift(item, viewModel.Packages.Extra)"
              class="text-only"
            >
              {{
                getSubmodusGiftValue(getSubmodusValues(item, viewModel.Packages.Extra)) +
                  ' ' +
                  getSubmodusPostfix(item)
              }}
            </div>
            <div
              v-if="
                showSubmodusInput(item, viewModel.Packages.Extra) &&
                  !isSubmodusGift(item, viewModel.Packages.Extra)
              "
              class="submodus-modification-button-holder"
            >
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-trash"
                @click="removeSubmodus(item)"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel
            v-if="viewModel.MNBMode == undefined"
            name="2"
            class="submodus-control-holder"
            :class="[
              viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
              isGTDisabled ? 'disabled-package' : '',
              !isSubmodusGift(item, viewModel.Packages.Komfort)
                ? 'text-only'
                : 'submodus-gift',
            ]"
          >
            <PhIconManager
              v-if="
                (showSubmodusInput(item, viewModel.Packages.Komfort) == false ||
                  showSubmodusInput(item, viewModel.Packages.Komfort) ==
                  undefined) &&
                  !isSubmodusGift(item, viewModel.Packages.Komfort)
              "
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-plus"
              @click="addSubmodus(item)"
            />
            <div
              v-if="
                (showSubmodusInput(item, viewModel.Packages.Komfort) &&
                  getSubmodusValues(item, viewModel.Packages.Komfort).length >
                  1 &&
                  !getSubmodusReadOnly(item, viewModel.Packages.Komfort) &&
                  !isSubmodusGift(item, viewModel.Packages.Komfort)) ||
                  (((showSubmodusInput(item, viewModel.Packages.Komfort) &&
                    getSubmodusValues(item, viewModel.Packages.Komfort).length ==
                    1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.Komfort)) &&
                    !isSubmodusGift(item, viewModel.Packages.Komfort))
              "
              class="submodus-input-holder"
            >
              <SelectInput
                v-if="
                  showSubmodusInput(item, viewModel.Packages.Komfort) &&
                    getSubmodusValues(item, viewModel.Packages.Komfort).length >
                    1 &&
                    !getSubmodusReadOnly(item, viewModel.Packages.Komfort) &&
                    !isSubmodusGift(item, viewModel.Packages.Komfort)
                "
                :id="'komfort_' + item.Id"
                :key="getSubmodusValue(item, viewModel.Packages.Komfort).Value"
                v-model="
                  getSubmodusValue(item, viewModel.Packages.Komfort).Value
                "
                :is-disabled="isGTDisabled"
                :texts="texts"
                :options="getSubmodusValues(item, viewModel.Packages.Komfort)"
                :init-option-value="
                  getSubmodusValue(item, viewModel.Packages.Komfort).Value.value
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(item)"
                @update:model-value="
                  setSubmodusValue(
                    item,
                    getSubmodusValue(item, viewModel.Packages.Komfort).Value,
                    getSubmodusValues(item, viewModel.Packages.Komfort)
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(item, viewModel.Packages.Komfort) &&
                    getSubmodusValues(item, viewModel.Packages.Komfort)
                      .length == 1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.Komfort)) &&
                    !isSubmodusGift(item, viewModel.Packages.Komfort)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(item, viewModel.Packages.Komfort)[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(item)
                }}
              </div>
            </div>
            <div
              v-if="isSubmodusGift(item, viewModel.Packages.Komfort)"
              class="text-only"
            >
              {{
                getSubmodusGiftValue(getSubmodusValues(item, viewModel.Packages.Komfort)) +
                  ' ' +
                  getSubmodusPostfix(item)
              }}
            </div>
            <div
              v-if="
                showSubmodusInput(item, viewModel.Packages.Komfort) &&
                  !isSubmodusGift(item, viewModel.Packages.Komfort)
              "
              class="submodus-modification-button-holder"
            >
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-trash"
                @click="removeSubmodus(item)"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel
            v-if="viewModel.MNBMode == undefined"
            name="1"
            class="submodus-control-holder"
            :class="[
              viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
              isGTDisabled ? 'disabled-package' : '',
              !isSubmodusGift(item, viewModel.Packages.StartPlus)
                ? 'text-only'
                : 'submodus-gift',
            ]"
          >
            <PhIconManager
              v-if="
                (showSubmodusInput(item, viewModel.Packages.StartPlus) ==
                  false ||
                  showSubmodusInput(item, viewModel.Packages.StartPlus) ==
                  undefined) &&
                  !isSubmodusGift(item, viewModel.Packages.StartPlus)
              "
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-plus"
              @click="addSubmodus(item)"
            />
            <div
              v-if="
                (showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                  getSubmodusValues(item, viewModel.Packages.StartPlus).length >
                  1 &&
                  !getSubmodusReadOnly(item, viewModel.Packages.StartPlus) &&
                  !isSubmodusGift(item, viewModel.Packages.StartPlus)) ||
                  (((showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                    getSubmodusValues(item, viewModel.Packages.StartPlus)
                      .length == 1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.StartPlus)) &&
                    !isSubmodusGift(item, viewModel.Packages.StartPlus))
              "
              class="submodus-input-holder"
            >
              <SelectInput
                v-if="
                  showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                    getSubmodusValues(item, viewModel.Packages.StartPlus).length >
                    1 &&
                    !getSubmodusReadOnly(item, viewModel.Packages.StartPlus) &&
                    !isSubmodusGift(item, viewModel.Packages.StartPlus)
                "
                :id="'startPlus_' + item.Id"
                :key="
                  getSubmodusValue(item, viewModel.Packages.StartPlus).Value
                "
                v-model="
                  getSubmodusValue(item, viewModel.Packages.StartPlus).Value
                "
                :is-disabled="isGTDisabled"
                :texts="texts"
                :options="getSubmodusValues(item, viewModel.Packages.StartPlus)"
                :init-option-value="
                  getSubmodusValue(item, viewModel.Packages.StartPlus).Value
                    .value
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(item)"
                @update:model-value="
                  setSubmodusValue(
                    item,
                    getSubmodusValue(item, viewModel.Packages.StartPlus).Value,
                    getSubmodusValues(item, viewModel.Packages.StartPlus)
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(item, viewModel.Packages.StartPlus) &&
                    getSubmodusValues(item, viewModel.Packages.StartPlus)
                      .length == 1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.StartPlus)) &&
                    !isSubmodusGift(item, viewModel.Packages.StartPlus)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(item, viewModel.Packages.StartPlus)[0]
                      .label
                  ) +
                    ' ' +
                    getSubmodusPostfix(item)
                }}
              </div>
            </div>
            <div
              v-if="isSubmodusGift(item, viewModel.Packages.StartPlus)"
              class="text-only"
            >
              {{
                getSubmodusGiftValue(getSubmodusValues(item, viewModel.Packages.StartPlus)) +
                  ' ' +
                  getSubmodusPostfix(item)
              }}
            </div>
            <div
              v-if="showSubmodusInput(item, viewModel.Packages.StartPlus)"
              class="submodus-modification-button-holder"
            >
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-trash"
                @click="removeSubmodus(item)"
              />
            </div>
          </q-tab-panel>
          <q-tab-panel
            name="0"
            class="submodus-control-holder"
            :class="[
              viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
              isMFODisabled ? 'disabled-package' : '',
              !isSubmodusGift(item, viewModel.Packages.Start)
                ? 'text-only'
                : 'submodus-gift',
            ]"
          >
            <PhIconManager
              v-if="
                (showSubmodusInput(item, viewModel.Packages.Start) == false ||
                  showSubmodusInput(item, viewModel.Packages.Start) ==
                  undefined) &&
                  !isSubmodusGift(item, viewModel.Packages.Start)
              "
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-plus"
              @click="addSubmodus(item)"
            />
            <div
              v-if="
                (showSubmodusInput(item, viewModel.Packages.Start) &&
                  getSubmodusValues(item, viewModel.Packages.Start).length >
                  1 &&
                  !getSubmodusReadOnly(item, viewModel.Packages.Start) &&
                  !isSubmodusGift(item, viewModel.Packages.Start)) ||
                  (((showSubmodusInput(item, viewModel.Packages.Start) &&
                    getSubmodusValues(item, viewModel.Packages.Start).length ==
                    1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.Start)) &&
                    !isSubmodusGift(item, viewModel.Packages.Start))
              "
              class="submodus-input-holder"
            >
              <SelectInput
                v-if="
                  showSubmodusInput(item, viewModel.Packages.Start) &&
                    getSubmodusValues(item, viewModel.Packages.Start).length >
                    1 &&
                    !getSubmodusReadOnly(item, viewModel.Packages.Start) &&
                    !isSubmodusGift(item, viewModel.Packages.Start)
                "
                :id="'start_' + item.Id"
                :key="getSubmodusValue(item, viewModel.Packages.Start).Value"
                v-model="getSubmodusValue(item, viewModel.Packages.Start).Value"
                :is-disabled="isMFODisabled"
                :texts="texts"
                :options="getSubmodusValues(item, viewModel.Packages.Start)"
                :init-option-value="
                  getSubmodusValue(item, viewModel.Packages.Start).Value.value
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(item)"
                @update:model-value="
                  setSubmodusValue(
                    item,
                    getSubmodusValue(item, viewModel.Packages.Start).Value,
                    getSubmodusValues(item, viewModel.Packages.Start)
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(item, viewModel.Packages.Start) &&
                    getSubmodusValues(item, viewModel.Packages.Start).length ==
                    1) ||
                    getSubmodusReadOnly(item, viewModel.Packages.Start)) &&
                    !isSubmodusGift(item, viewModel.Packages.Start)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(item, viewModel.Packages.Start)[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(item)
                }}
              </div>
            </div>
            <div
              v-if="isSubmodusGift(item, viewModel.Packages.Start)"
              class="text-only"
            >
              {{
                getFormattedNumber(
                  getSubmodusValues(item, viewModel.Packages.Start)[0].label
                ) +
                  ' ' +
                  getSubmodusPostfix(item)
              }}
            </div>
            <div
              v-if="showSubmodusInput(item, viewModel.Packages.Start)"
              class="submodus-modification-button-holder"
            >
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-trash"
                @click="removeSubmodus(item)"
              />
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>

      <!-- //ANCHOR - VirtualAdditionals -->

      <q-expansion-item
        v-if="item.IsVirtualPackage"
        v-model="accordion[index]"
        header-class="package-accordion-header"
        class="package-accordion"
        expand-icon-class="package-accordion-icon"
        expand-icon-toggle
      >
        <template #header>
          <div class="row submodus-row">
            <div class="col submodus-name-holder">
              <div
                v-if="false"
                class="submodus-gift-holder"
              >
                <div class="submodus-gift">
                  <PhIconManager
                    height=".9375rem"
                    color="white"
                    width=".9375rem"
                    ph-icon="ph-gift"
                  />
                </div>
              </div>
              <div class="package-accordion-name">
                <div class="left-holder submodus-title-holder">
                  <SubmodusTitle
                    :icon-size="16"
                    :tooltip-content="item.InformationContent"
                    :tooltip-title="item.Name"
                  >
                    {{ item.Name }}
                  </SubmodusTitle>
                </div>
                <div class="right-holder">
                  <q-btn
                    class="package-accordion-button"
                    :ripple="false"
                    flat
                    @click.stop="accordion[index] = !accordion[index]"
                  >
                    <PhIconManager
                      v-if="!accordion[index]"
                      height="1rem"
                      width="1rem"
                      ph-icon="ph-caret-down"
                    />
                    <PhIconManager
                      v-if="accordion[index]"
                      height="1rem"
                      width="1rem"
                      ph-icon="ph-caret-up"
                    />
                  </q-btn>
                </div>
              </div>
            </div>

            <q-tab-panels
              v-model="selectedPackageTabs"
              class="col"
              animated
            >
              <q-tab-panel
                v-if="viewModel.MNBMode == undefined"
                name="3"
                class="submodus-control-holder"
                :class="[
                  viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
                  isGTDisabled ? 'disabled-package' : '',
                  item.IsVirtualPackage ? 'virtual-package-tab' : '',
                ]"
              >
                <div class="virtual-package-holder">
                  <div
                    class="virtual-package-icon"
                    :class="{
                      virtualPackageIconPadding:
                        isTrashVisibleInVirtualPackage(item,viewModel.Packages.Extra),
                    }"
                  >
                    <PhIconManager
                      v-if="
                        !virtualPackageHasSelectedValue(item,viewModel.Packages.Extra) && !hasVirtualGift(item, viewModel.Packages.Extra)
                      "
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-plus"
                      @click="addVirtualPackage(item, viewModel.Packages.Extra)"
                    />
                    <PhIconManager
                      v-else
                      class="submodus-check"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-check"
                    />
                  </div>
                  <div class="virtual-package-input">
                    <PhIconManager
                      v-if="isTrashVisibleInVirtualPackage(item, viewModel.Packages.Extra)"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-trash"
                      @click="removeVirtualPackage(item)"
                    />
                  </div>
                </div>
              </q-tab-panel>
              <q-tab-panel
                v-if="viewModel.MNBMode == undefined"
                name="2"
                class="submodus-control-holder"
                :class="[
                  viewModel.Packages.Komfort.Selected.Value
                    ? 'highlighted'
                    : '',
                  isGTDisabled ? 'disabled-package' : '',
                  item.IsVirtualPackage ? 'virtual-package-tab' : '',
                ]"
              >
                <div class="virtual-package-holder">
                  <div
                    class="virtual-package-icon"
                    :class="{
                      virtualPackageIconPadding:
                        isTrashVisibleInVirtualPackage(item, viewModel.Packages.Komfort),
                    }"
                  >
                    <PhIconManager
                      v-if="
                        !virtualPackageHasSelectedValue(item,viewModel.Packages.Komfort) && !hasVirtualGift(item, viewModel.Packages.Komfort)
                      "
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-plus"
                      @click="
                        addVirtualPackage(item, viewModel.Packages.Komfort)
                      "
                    />
                    <PhIconManager
                      v-else
                      class="submodus-check"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-check"
                    />
                  </div>
                  <div class="virtual-package-input">
                    <PhIconManager
                      v-if="isTrashVisibleInVirtualPackage(item, viewModel.Packages.Komfort)"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-trash"
                      @click="removeVirtualPackage(item)"
                    />
                  </div>
                </div>
              </q-tab-panel>
              <q-tab-panel
                v-if="viewModel.MNBMode == undefined"
                name="1"
                class="submodus-control-holder"
                :class="[
                  viewModel.Packages.StartPlus.Selected.Value
                    ? 'highlighted'
                    : '',
                  isGTDisabled ? 'disabled-package' : '',
                  !isSubmodusGift(item, viewModel.Packages.StartPlus)
                    ? 'text-only'
                    : 'submodus-gift',
                  item.IsVirtualPackage ? 'virtual-package-tab' : '',
                ]"
              >
                <div class="virtual-package-holder">
                  <div
                    class="virtual-package-icon"
                    :class="{
                      virtualPackageIconPadding:
                        isTrashVisibleInVirtualPackage(item,viewModel.Packages.StartPlus),
                    }"
                  >
                    <PhIconManager
                      v-if="
                        !virtualPackageHasSelectedValue(item,viewModel.Packages.StartPlus) && !hasVirtualGift(item, viewModel.Packages.StartPlus)
                      "
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-plus"
                      @click="
                        addVirtualPackage(item, viewModel.Packages.StartPlus)
                      "
                    />
                    <PhIconManager
                      v-else
                      class="submodus-check"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-check"
                    />
                  </div>
                  <div class="virtual-package-input">
                    <PhIconManager
                      v-if="isTrashVisibleInVirtualPackage(item,viewModel.Packages.StartPlus)"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-trash"
                      @click="removeVirtualPackage(item)"
                    />
                  </div>
                </div>
              </q-tab-panel>
              <q-tab-panel
                name="0"
                class="submodus-control-holder"
                :class="[
                  viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
                  isMFODisabled ? 'disabled-package' : '',
                  !isSubmodusGift(item, viewModel.Packages.Start)
                    ? 'text-only'
                    : 'submodus-gift',
                  item.IsVirtualPackage ? 'virtual-package-tab' : '',
                ]"
              >
                <div class="virtual-package-holder">
                  <div
                    class="virtual-package-icon"
                    :class="{
                      virtualPackageIconPadding:
                        isTrashVisibleInVirtualPackage(item,viewModel.Packages.Start),
                    }"
                  >
                    <PhIconManager
                      v-if="
                        !virtualPackageHasSelectedValue(item,viewModel.Packages.Start) && !hasVirtualGift(item, viewModel.Packages.Start)
                      "
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-plus"
                      @click="addVirtualPackage(item, viewModel.Packages.Start)"
                    />
                    <PhIconManager
                      v-else
                      class="submodus-check"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-check"
                    />
                  </div>
                  <div class="virtual-package-input">
                    <PhIconManager
                      v-if="isTrashVisibleInVirtualPackage(item,viewModel.Packages.Start)"
                      height="1.25rem"
                      width="1.25rem"
                      ph-icon="ph-trash"
                      @click="removeVirtualPackage(item)"
                    />
                  </div>
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </template>
        <div
          v-for="(virtualItem, virtualIndex) in item.Items"
          :key="virtualIndex"
          class="row submodus-row"
        >
          <div class="col submodus-name-holder">
            <div
              v-if="
                isSubmodusGift(virtualItem, viewModel.Packages.Extra, item) ||
                  isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item) ||
                  isSubmodusGift(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ) ||
                  isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
              "
              class="submodus-gift-holder"
            >
              <div class="submodus-gift">
                <PhIconManager
                  height=".9375rem"
                  color="white"
                  width=".9375rem"
                  ph-icon="ph-gift"
                />
              </div>
            </div>
            <div class="submodus-title-holder">
              <SubmodusTitle
                v-sanitize="virtualItem.Name" 
                :icon-size="16"
                :tooltip-content="virtualItem.InformationContent"
                :tooltip-title="virtualItem.Name"
              /> 
            </div>
          </div>
          <q-tab-panels
            v-model="selectedPackageTabs"
            class="col"
            animated
          >
            <q-tab-panel
              v-if="viewModel.MNBMode == undefined"
              name="3"
              class="submodus-control-holder"
              :class="[
                viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
                isGTDisabled ? 'disabled-package' : '',
                ((showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Extra,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)
                    .length == 1) ||
                  getSubmodusReadOnly(
                    virtualItem,
                    viewModel.Packages.Extra,
                    item
                  )) &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
                  ? 'text-only'
                  : '',
                !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
                  ? 'text-only'
                  : 'submodus-gift',
              ]"
            >
              <PhIconManager
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Extra,
                    item
                  ) == false ||
                    showSubmodusInput(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    ) == undefined
                "
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-plus"
                @click="addSubmodus(virtualItem, item)"
              />
              <SelectInput
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Extra,
                    item
                  ) &&
                    getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)
                      .length > 1 &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
                "
                :id="'extra_' + virtualItem.Id"
                :key="
                  getSubmodusValue(virtualItem, viewModel.Packages.Extra, item)
                    .Value
                "
                v-model="
                  getSubmodusValue(virtualItem, viewModel.Packages.Extra, item)
                    .Value
                "
                :is-disabled="isGTDisabled"
                :texts="texts"
                :options="
                  getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)
                "
                :init-option-value="
                  getSubmodusValue(virtualItem, viewModel.Packages.Extra, item)
                    .Value.value
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(virtualItem)"
                @update:model-value="
                  setSubmodusValue(
                    virtualItem,
                    getSubmodusValue(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    ).Value,
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    ),
                    item
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Extra,
                    item
                  ) &&
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    ).length == 1) ||
                    getSubmodusReadOnly(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    )) &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Extra,
                      item
                    )[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(virtualItem)
                }}
              </div>
              <div
                v-if="
                  isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
                "
                class="text-only"
              >
                {{
                  getSubmodusGiftValue(getSubmodusValues(virtualItem, viewModel.Packages.Extra, item)) +
                    ' ' +
                    getSubmodusPostfix(virtualItem)
                }}
              </div>
              <div
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Extra,
                    item
                  ) &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Extra, item)
                "
                class="submodus-modification-button-holder"
              >
                <PhIconManager
                  height="1.25rem"
                  width="1.25rem"
                  ph-icon="ph-trash"
                  @click="removeSubmodus(virtualItem, item)"
                />
              </div>
            </q-tab-panel>
            <q-tab-panel
              v-if="viewModel.MNBMode == undefined"
              name="2"
              class="submodus-control-holder"
              :class="[
                viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
                isGTDisabled ? 'disabled-package' : '',
                ((showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Komfort,
                  item
                ) &&
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ).length == 1) ||
                  getSubmodusReadOnly(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  )) &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
                  ? 'text-only'
                  : '',
                !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
                  ? 'text-only'
                  : 'submodus-gift',
              ]"
            >
              <PhIconManager
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ) == false ||
                    showSubmodusInput(
                      virtualItem,
                      viewModel.Packages.Komfort,
                      item
                    ) == undefined
                "
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-plus"
                @click="addSubmodus(virtualItem, item)"
              />
              <SelectInput
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ) &&
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Komfort,
                      item
                    ).length > 1 &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
                "
                :id="'komfort_' + virtualItem.Id"
                :key="
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ).Value
                "
                v-model="
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ).Value
                "
                :is-disabled="isGTDisabled"
                :texts="texts"
                :options="
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  )
                "
                :init-option-value="
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ).Value.value
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(virtualItem)"
                @update:model-value="
                  setSubmodusValue(
                    virtualItem,
                    getSubmodusValue(
                      virtualItem,
                      viewModel.Packages.Komfort,
                      item
                    ).Value,
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Komfort,
                      item
                    ),
                    item
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ) &&
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Komfort,
                      item
                    ).length == 1) ||
                    getSubmodusReadOnly(
                      virtualItem,
                      viewModel.Packages.Komfort,
                      item
                    )) &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Komfort,
                      item
                    )[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(virtualItem)
                }}
              </div>
              <div
                v-if="
                  isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
                "
                class="text-only"
              >
                {{
                  getSubmodusGiftValue(getSubmodusValues(virtualItem, viewModel.Packages.Komfort, item)) +
                    ' ' +
                    getSubmodusPostfix(virtualItem)
                }}
              </div>
              <div
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Komfort,
                    item
                  ) &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Komfort, item)
                "
                class="submodus-modification-button-holder"
              >
                <PhIconManager
                  height="1.25rem"
                  width="1.25rem"
                  ph-icon="ph-trash"
                  @click="removeSubmodus(virtualItem, item)"
                />
              </div>
            </q-tab-panel>
            <q-tab-panel
              v-if="viewModel.MNBMode == undefined"
              name="1"
              class="submodus-control-holder"
              :class="[
                viewModel.Packages.StartPlus.Selected.Value
                  ? 'highlighted'
                  : '',
                isGTDisabled ? 'disabled-package' : '',
                ((showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.StartPlus,
                  item
                ) &&
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ).length == 1) ||
                  getSubmodusReadOnly(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  )) &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item)
                  ? 'text-only'
                  : '',
                !isSubmodusGift(virtualItem, viewModel.Packages.StartPlus, item)
                  ? 'text-only'
                  : 'submodus-gift',
              ]"
            >
              <PhIconManager
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ) == false ||
                    showSubmodusInput(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    ) == undefined
                "
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-plus"
                @click="addSubmodus(virtualItem, item)"
              />
              <SelectInput
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ) &&
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    ).length > 1 &&
                    !isSubmodusGift(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    )
                "
                :id="'startPlus_' + virtualItem.Id"
                :key="
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ).Value
                "
                v-model="
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ).Value
                "
                :is-disabled="isGTDisabled"
                :init-option-value="
                  getSubmodusValue(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ).Value.value
                "
                :texts="texts"
                :options="
                  getSubmodusValues(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  )
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(virtualItem)"
                @update:model-value="
                  setSubmodusValue(
                    virtualItem,
                    getSubmodusValue(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    ).Value,
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    ),
                    item
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ) &&
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    ).length == 1) ||
                    getSubmodusReadOnly(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    )) &&
                    !isSubmodusGift(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    )
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    )[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(virtualItem)
                }}
              </div>
              <div
                v-if="
                  isSubmodusGift(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  )
                "
                class="text-only"
              >
                {{
                  getSubmodusGiftValue(getSubmodusValues(virtualItem, viewModel.Packages.StartPlus, item)) +
                    ' ' +
                    getSubmodusPostfix(virtualItem)
                }}
              </div>
              <div
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.StartPlus,
                    item
                  ) &&
                    !isSubmodusGift(
                      virtualItem,
                      viewModel.Packages.StartPlus,
                      item
                    )
                "
                class="submodus-modification-button-holder"
              >
                <PhIconManager
                  height="1.25rem"
                  width="1.25rem"
                  ph-icon="ph-trash"
                  @click="removeSubmodus(virtualItem, item)"
                />
              </div>
            </q-tab-panel>
            <q-tab-panel
              name="0"
              class="submodus-control-holder"
              :class="[
                viewModel.Packages.Start.Selected.Value ? 'highlighted' : '',
                isMFODisabled ? 'disabled-package' : '',
                ((showSubmodusInput(
                  virtualItem,
                  viewModel.Packages.Start,
                  item
                ) &&
                  getSubmodusValues(virtualItem, viewModel.Packages.Start, item)
                    .length == 1) ||
                  getSubmodusReadOnly(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  )) &&
                  !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
                  ? 'text-only'
                  : '',
                !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
                  ? 'text-only'
                  : 'submodus-gift',
              ]"
            >
              <PhIconManager
                v-if="
                  (showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  ) == false ||
                    showSubmodusInput(
                      virtualItem,
                      viewModel.Packages.Start,
                      item
                    ) == undefined) &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
                "
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-plus"
                @click="addSubmodus(virtualItem, item)"
              />
              <SelectInput
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  ) &&
                    getSubmodusValues(virtualItem, viewModel.Packages.Start, item)
                      .length > 1 &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
                "
                :id="'start_' + virtualItem.Id"
                :key="
                  getSubmodusValue(virtualItem, viewModel.Packages.Start, item)
                    .Value
                "
                v-model="
                  getSubmodusValue(virtualItem, viewModel.Packages.Start, item)
                    .Value
                "
                :is-disabled="isMFODisabled"
                :init-option-value="
                  getSubmodusValue(virtualItem, viewModel.Packages.Start, item)
                    .Value.value
                "
                :texts="texts"
                :options="
                  getSubmodusValues(virtualItem, viewModel.Packages.Start, item)
                "
                :format-number="true"
                :suffix="getSubmodusPostfix(virtualItem)"
                @update:model-value="
                  setSubmodusValue(
                    virtualItem,
                    getSubmodusValue(
                      virtualItem,
                      viewModel.Packages.Start,
                      item
                    ).Value,
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Start,
                      item
                    ),
                    item
                  )
                "
              />
              <div
                v-if="
                  ((showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  ) &&
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Start,
                      item
                    ).length == 1) ||
                    getSubmodusReadOnly(
                      virtualItem,
                      viewModel.Packages.Start,
                      item
                    )) &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(
                      virtualItem,
                      viewModel.Packages.Start,
                      item
                    )[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(virtualItem)
                }}
              </div>
              <div
                v-if="
                  isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
                "
                class="text-only"
              >
                {{
                  getFormattedNumber(
                    getSubmodusValues(virtualItem, viewModel.Packages.Start, item)[0].label
                  ) +
                    ' ' +
                    getSubmodusPostfix(item)
                }}
              </div>
              <div
                v-if="
                  showSubmodusInput(
                    virtualItem,
                    viewModel.Packages.Start,
                    item
                  ) &&
                    !isSubmodusGift(virtualItem, viewModel.Packages.Start, item)
                "
                class="submodus-modification-button-holder"
              >
                <PhIconManager
                  height="1.25rem"
                  width="1.25rem"
                  ph-icon="ph-trash"
                  @click="removeSubmodus(virtualItem, item)"
                />
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </q-expansion-item>
    </template>
  </div>
</template>

<!-- //ANCHOR - Script -->

<script setup>
let viewModel = inject('viewModel')
const texts = inject('texts')
const selectedPackageTabs = ref(null)
const selectedPackageRadio = ref(null)
const submodusPostfixes = ref(new Map())
const estateValuechanged = ref(false)
const estateChangedFromModalWarning = ref(false)
const valuablesValuechanged = ref(false)
const valuablesChangedFromModalWarning = ref(false)
const { GtHouseExclusion, MFOExclusion } = inject('exclusion')
import { ref, onMounted, computed, watch, inject } from 'vue'
const emit = defineEmits(['packageSelected'])
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')
import { useField } from 'vee-validate'
import { useFormChild } from 'quasar'
import {
  customDefineRule,
  setSkipEmptyFields,
} from '@/utils/validations/customDefineRule'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'

const { getFormattedNumber } = inject('util')
const { callCalculate, callValidate } = inject('wizard')

const visibleAdditionalCategories = computed(() => {
  if (viewModel.value.MNBMode == undefined) {
    return viewModel.value.Packages.Union.Additionals.filter(
      (additional) => !additional.IsHidden
    )
  }

  let result = []

  viewModel.value.Packages.Start.Additionals.forEach((element) => {
    if (element.Selected.Value) result.push(element)
    if (element.IsVirtualPackage) {
      element.Items.forEach((innerElement) => {
        if (innerElement.Selected.Value) result.push(innerElement)
      })
    }
  })

  return result
})

customDefineRule('VAL_380', (value) => {
  let sum = 0
  viewModel.value.SteadingList.forEach((element) => {
    sum += Number(element.SteadingValue.Value)
  })
  if (sum > 30000000) {
    return ValidationMessages.VAL_380
  } else {
    return true
  }
})
customDefineRule('VAL_446', (value) => {
  let index = 0
  for (index; index < viewModel.value.SteadingList.length - 1; index++) {
    if (
      Number(viewModel.value.SteadingList[index].SteadingValue.Value) ==
      Number(value)
    ) {
      break
    }
  }
  let min =
    viewModel.value.SteadingList[index].SteadingRecommendedValue.Value * 0.7
  if (value < min) {
    return ValidationMessages.VAL_446.replace(
      '{0}',
      min.toLocaleString().replaceAll(',', ' ')
    )
  } else {
    return true
  }
})
function setSelectedPackageInitInputValue() {
  if (viewModel.value.Packages.Extra.Selected.Value == true) {
    selectedPackageTabs.value = '3'
    selectedPackageRadio.value = '3'
    packageSelected(3)
  }
  if (viewModel.value.Packages.Komfort.Selected.Value == true) {
    selectedPackageTabs.value = '2'
    selectedPackageRadio.value = '2'
    packageSelected(2)
  }
  if (viewModel.value.Packages.StartPlus.Selected.Value == true) {
    selectedPackageTabs.value = '1'
    selectedPackageRadio.value = '1'
    packageSelected(1)
  }
  if (viewModel.value.Packages.Start.Selected.Value == true) {
    selectedPackageTabs.value = '0'
    selectedPackageRadio.value = '0'
    packageSelected(0)
  }
}

watch(
  () => selectedPackageTabs.value,
  (value, oldValue) => {
    if (
      isMFODisabled.value &&
      selectedPackageTabs.value == 0 &&
      selectedPackageRadio.value == 0
    ) {
      selectedPackageRadio.value = null
      packageSelected(-1)
    }
    if (
      isGTDisabled.value &&
      (selectedPackageTabs.value == 1 ||
        selectedPackageTabs.value == 2 ||
        selectedPackageTabs.value == 3) &&
      selectedPackageRadio.value != 0
    ) {
      selectedPackageRadio.value = null
      packageSelected(-1)
    }
  }
)
watch(
  () => viewModel.value.ContentDiscount.Value,
  (value, oldValue) => {
    if (isMFODisabled.value) {
      if (selectedPackageRadio.value == '0') {
        selectedPackageRadio.value = null
        packageSelected(-1)
      }
    }
  }
)
watch(
  () => viewModel.value.Cart.Payment.PaymentMode.Value,
  (value, oldValue) => {
    if (isGTDisabled.value) {
      if (
        (viewModel.value.Cart.Payment.PaymentMode.Value.value === 8 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 7 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 1) &&
        viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 5 &&
        !isMFODisabled.value
      ) {
        if (selectedPackageRadio.value != '0') {
          selectedPackageRadio.value = null
          selectedPackageTabs.value = '0'
          packageSelected(-1)
        }
      }
    }
  }
)
watch(
  () => viewModel.value.Cart.Payment.PaymentPeriod.Value,
  (value, oldValue) => {
    if (isGTDisabled.value) {
      if (
        (viewModel.value.Cart.Payment.PaymentMode.Value.value === 8 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 7 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 1) &&
        viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 5 &&
        !isMFODisabled.value
      ) {
        if (selectedPackageRadio.value != '0') {
          selectedPackageRadio.value = null
          selectedPackageTabs.value = '0'
          packageSelected(-1)
        }
      }
    }
  }
)
customDefineRule('VAL_444', (value) => {
  if (value < viewModel.value.Packages.Start.EstateMinimumBo.Value) {
    return ValidationMessages.VAL_444.replace(
      '{0}',
      getFormattedNumber(viewModel.value.Packages.Start.EstateMinimumBo.Value)
    )
  } else {
    return true
  }
})
onMounted(() => {
  estateChangedFromModalWarning.value = estatechangedfrommodal()
  valuablesChangedFromModalWarning.value = valuableschangedfrommodal()
  if (isGTDisabled.value) {
    selectedPackageTabs.value = '0'
  } else {
    selectedPackageTabs.value = '2'
  }
  setSelectedPackageInitInputValue()
  setSubmodusPostfixes()
  setValuablesInitinputValues()
  accordionDefaultValues()
})
function estatechangedfrommodal() {
  if (
    viewModel.value.HouseholdAppliancesValue.Value > 0 ||
    viewModel.value.ToolsAndNonIntegratedLampsValue.Value > 0 ||
    viewModel.value.TelecommunicationDevicesValue.Value > 0 ||
    viewModel.value.FurnitureValue.Value > 0 ||
    viewModel.value.ClothesValue.Value > 0 ||
    viewModel.value.KitchenAndBathroomEquipment.Value > 0 ||
    viewModel.value.TextileValue.Value > 0 ||
    viewModel.value.MiscellaneousItemsValue.Value > 0
  ) {
    return true
  }
  return false
}

function valuableschangedfrommodal() {
  if (
    viewModel.value.JewelryValue.Value > 0 ||
    viewModel.value.AntiquesValue.Value > 0 ||
    viewModel.value.FursAndCarpetsValue.Value > 0 ||
    viewModel.value.CollectionsValue.Value > 0
  ) {
    return true
  }
  return false
}

function setSubmodusPostfixes() {
  submodusPostfixes.value.set(
    'PenzugyiVesztesegKockazatokDijAtvallalas',
    ' hónap'
  )
  submodusPostfixes.value.set('BalesetBiztositasCsomag', '')
  submodusPostfixes.value.set('HobbiKisallatBiztositasCsomag', '')
}
function getPeriodText() {
  if (viewModel.value.Cart.Payment.PaymentPeriod.Value) {
    if (
      viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 1 ||
      viewModel.value.Cart.Payment.PaymentPeriod.Value === 1
    ) {
      return 'év'
    } else if (
      viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 2 ||
      viewModel.value.Cart.Payment.PaymentPeriod.Value === 2
    ) {
      return 'félév'
    } else if (
      viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 4 ||
      viewModel.value.Cart.Payment.PaymentPeriod.Value === 4
    ) {
      return 'negyedév'
    } else return 'hónap'
  }
}
function setValuablesInitinputValues() {
  if (viewModel.value.ValuablesSumValue.Value) {
    viewModel.value.Packages.Extra.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
    viewModel.value.Packages.Komfort.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
    viewModel.value.Packages.StartPlus.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
    viewModel.value.Packages.Start.ValuablesSumBO.Value =
      viewModel.value.ValuablesSumValue.Value
  } else {
    viewModel.value.Packages.Extra.ValuablesSumBO.Value = 0
    viewModel.value.Packages.Komfort.ValuablesSumBO.Value = 0
    viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = 0
    viewModel.value.Packages.Start.ValuablesSumBO.Value = 0
  }
}
function packageSelected(value) {
  emit('packageSelected', value)
}

customDefineRule('VAL_MFOPACKAGE', (value) => {
  if (
    viewModel.value.Packages.Start.Selected.Value ||
    viewModel.value.Packages.StartPlus.Selected.Value ||
    viewModel.value.Packages.Komfort.Selected.Value ||
    viewModel.value.Packages.Extra.Selected.Value
  ) {
    return true
  }
  return 'Kérem, válasszon csomagot a továbblépéshez!'
})
const {
  handleChange,
  errors: veeErrors,
  value,
  validate: veeValidate,
} = useField('mfo_mobilePackage_select', 'VAL_MFOPACKAGE', {
  validateOnValueUpdate: false,
  bails: true,
})
function resetValidationQForm() {}

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
async function validateQForm() {
  // run vee-validate validations
  await veeValidate()
  if (veeErrors.value.length > 0) {
    return false
  } else {
    return true
  }
}
function updateTabs(value) {
  if (Number(value) == 0) {
    if (!isMFODisabled.value) {
      selectedPackageRadio.value = value
    } else {
    }
  } else {
    if (!isGTDisabled.value) {
      selectedPackageRadio.value = value
    } else {
    }
  }
}
function updateRadio(value) {
  selectedPackageTabs.value = value
}
function steadingLabel(index) {
  return texts.vtxt_kalk159_label.replace('[]', index + 1)
}

function liabilityInsuranceText() {
  const liabilityValue =
    viewModel.value.Packages.Start.LiabilityInsurance.Values[0]
  if (liabilityValue) {
    return `${liabilityValue.label}`
  }
}

async function setLiabilitySubmodusValue(selectedValue) {
  if (viewModel.value.MNBMode == undefined) {
    let valueToSet =
      viewModel.value.Packages.Extra.LiabilityInsurance.Values.find(
        (value) => value.value === selectedValue
      )

    let valuetoSetObject = {
      Id: valueToSet.value,
      Text: valueToSet.label,
      IsDisabled: false,
    }
    viewModel.value.Packages.Extra.LiabilityInsurance.Value = valuetoSetObject
    viewModel.value.Packages.Komfort.LiabilityInsurance.Value = valuetoSetObject
    viewModel.value.Packages.StartPlus.LiabilityInsurance.Value =
      valuetoSetObject
  }
  await callCalculate(true,2000)
}

function getSubmodus(item, householdPackage, isInVirtualPackage = null) {
  let result
  if (!isInVirtualPackage) {
    result = householdPackage.Additionals.find(
      (additional) => additional.Id === item.Id
    )
  } else {
    const virtualPackage = householdPackage.Additionals.find(
      (additional) => additional.Id === isInVirtualPackage.Id
    )
    result = virtualPackage.Items.find(
      (virtualPackageAdditional) => virtualPackageAdditional.Id === item.Id
    )
  }

  if (viewModel.value.MNBMode && result === undefined) {
    householdPackage.Additionals.forEach((element) => {
      if (result === undefined && element.IsVirtualPackage) {
        result = element.Items.find(
          (innerElement) => innerElement.Id === item.Id
        )
        if (result) return result
      }
    })
  }

  return result
}
function showSubmodusInput(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.Selected.Value
}
function getSubmodusValues(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.Options.Values
}
function getSubmodusValue(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.Options
}
function getSubmodusReadOnly(
  item,
  householdPackage,
  isInVirtualPackage = null
) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.ReadOnly
}

function isSubmodusGift(item, householdPackage, isInVirtualPackage = null) {
  const subModus = getSubmodus(item, householdPackage, isInVirtualPackage)
  return subModus.IsGift
}

function getSubmodusPostfix(item) {
  return submodusPostfixes.value.has(item.Id)
    ? submodusPostfixes.value.get(item.Id)
    : ' Ft'
}

async function setSubmodusValue(
  item,
  selectedValue,
  selectedValues,
  isInVirtualPackage = null
) {
  let valueToSet = selectedValues.find((value) => value.value === selectedValue)

  let valuetoSetObject = {
    value: valueToSet.value,
    label: valueToSet.label,
    IsDisabled: false,
  }

  if (viewModel.value.MNBMode == undefined) {
    const extra = getSubmodus(
      item,
      viewModel.value.Packages.Extra,
      isInVirtualPackage
    )
    const komfort = getSubmodus(
      item,
      viewModel.value.Packages.Komfort,
      isInVirtualPackage
    )
    const startPlus = getSubmodus(
      item,
      viewModel.value.Packages.StartPlus,
      isInVirtualPackage
    )
    extra.Options.Value = valuetoSetObject
    komfort.Options.Value = valuetoSetObject
    startPlus.Options.Value = valuetoSetObject
  }

  const start = getSubmodus(
    item,
    viewModel.value.Packages.Start,
    isInVirtualPackage
  )
  start.Options.Value = valuetoSetObject
  await callCalculate(true,2000)
}
function getSelectedSubmodusForPackage(submodusList, packageId) {
  if (submodusList.length === 1) {
    return submodusList[0]
  } else {
    return submodusList.find((value) => value.value === packageId)
  }
}
async function addSubmodus(item, isInVirtualPackage = null) {
  let extra = null
  let komfort = null
  let startPlus = null
  if (viewModel.value.MNBMode == undefined) {
    extra = getSubmodus(
      item,
      viewModel.value.Packages.Extra,
      isInVirtualPackage
    )
    komfort = getSubmodus(
      item,
      viewModel.value.Packages.Komfort,
      isInVirtualPackage
    )
    startPlus = getSubmodus(
      item,
      viewModel.value.Packages.StartPlus,
      isInVirtualPackage
    )

    if (
      extra.Options.Value.Id === null ||
      extra.Options.Value.Id === undefined ||
      extra.Options.Value.Id === -1
    ) {
      const setExtra = getSelectedSubmodusForPackage(extra.Options.Values, 3)
      let valuetoSetObject = {
        Id: setExtra.value,
        Text: setExtra.label,
        IsDisabled: false,
      }
      extra.Options.Value = valuetoSetObject
    }
    if (
      komfort.Options.Value.Id === null ||
      komfort.Options.Value.Id === undefined ||
      komfort.Options.Value.Id === -1
    ) {
      const setKomfort = getSelectedSubmodusForPackage(
        komfort.Options.Values,
        2
      )
      let valuetoSetObject = {
        Id: setKomfort.value,
        Text: setKomfort.label,
        IsDisabled: false,
      }
      komfort.Options.Value = valuetoSetObject
    }
    if (
      startPlus.Options.Value.Id === null ||
      startPlus.Options.Value.Id === undefined ||
      startPlus.Options.Value.Id === -1
    ) {
      const setStartP = getSelectedSubmodusForPackage(
        startPlus.Options.Values,
        1
      )
      let valuetoSetObject = {
        Id: setStartP.value,
        Text: setStartP.label,
        IsDisabled: false,
      }
      startPlus.Options.Value = valuetoSetObject
    }

    if (!extra.IsGift) extra.Selected.Value = true
    if (!komfort.IsGift) komfort.Selected.Value = true
    if (!startPlus.IsGift) startPlus.Selected.Value = true
  }

  const start = getSubmodus(
    item,
    viewModel.value.Packages.Start,
    isInVirtualPackage
  )

  if (
    start.Options.Value.Id === null ||
    start.Options.Value.Id === undefined ||
    start.Options.Value.Id === -1
  ) {
    const setStart = getSelectedSubmodusForPackage(start.Options.Values, 0)
    let valuetoSetObject = {
      Id: setStart.value,
      Text: setStart.label,
      IsDisabled: false,
    }
    start.Options.Value = valuetoSetObject
  }

  if (!start.IsGift) start.Selected.Value = true

  if (isInVirtualPackage) {
    if (viewModel.value.MNBMode == undefined) {
      const virtualExtra = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Extra
      )
      const virtualKomfort = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Komfort
      )
      const virtualStartPlus = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.StartPlus
      )

      if (
        virtualExtra.Options.Value.Id === null ||
        virtualExtra.Options.Value.Id === -1
      ) {
        const setVirtualExtra = getSelectedSubmodusForPackage(
          virtualExtra.Options.Values,
          3
        )
        let valuetoSetObject = {
          Id: setVirtualExtra.value,
          Text: setVirtualExtra.label,
          IsDisabled: false,
        }
        virtualExtra.Options.Value = valuetoSetObject
      }
      if (
        virtualKomfort.Options.Value.Id === null ||
        virtualKomfort.Options.Value.Id === -1
      ) {
        const setVirtualKomfort = getSelectedSubmodusForPackage(
          virtualKomfort.Options.Values,
          2
        )
        let valuetoSetObject = {
          Id: setVirtualKomfort.value,
          Text: setVirtualKomfort.label,
          IsDisabled: false,
        }
        virtualKomfort.Options.Value = valuetoSetObject
      }
      if (
        virtualStartPlus.Options.Value.Id === null ||
        virtualStartPlus.Options.Value.Id === -1
      ) {
        const setVirtualStartPlus = getSelectedSubmodusForPackage(
          virtualStartPlus.Options.Values,
          1
        )
        let valuetoSetObject = {
          Id: setVirtualStartPlus.value,
          Text: setVirtualStartPlus.label,
          IsDisabled: false,
        }
        virtualStartPlus.Options.Value = valuetoSetObject
      }

      if (!extra.IsGift) virtualExtra.Selected.Value = true
      if (!komfort.IsGift) virtualKomfort.Selected.Value = true
      if (!startPlus.IsGift) virtualStartPlus.Selected.Value = true
    }

    const virtualStart = getSubmodus(
      isInVirtualPackage,
      viewModel.value.Packages.Start
    )

    if (
      virtualStart.Options.Value.Id === null ||
      virtualStart.Options.Value.Id === -1
    ) {
      const setVirtualStart = getSelectedSubmodusForPackage(
        virtualStart.Options.Values,
        0
      )
      let valuetoSetObject = {
        Id: setVirtualStart.value,
        Text: setVirtualStart.label,
        IsDisabled: false,
      }
      virtualStart.Options.Value = valuetoSetObject
    }

    if (!start.IsGift) virtualStart.Selected.Value = true
  }
  await callCalculate(true,2000)
}
async function removeSubmodus(item, isInVirtualPackage = null) {
  const extra = getSubmodus(
    item,
    viewModel.value.Packages.Extra,
    isInVirtualPackage
  )
  const komfort = getSubmodus(
    item,
    viewModel.value.Packages.Komfort,
    isInVirtualPackage
  )
  const startPlus = getSubmodus(
    item,
    viewModel.value.Packages.StartPlus,
    isInVirtualPackage
  )
  if (viewModel.value.MNBMode == undefined) {
    if (!extra.IsGift) {
      extra.Selected.Value = false
    }
    if (!komfort.IsGift) {
      komfort.Selected.Value = false
    }
    if (!startPlus.IsGift) {
      startPlus.Selected.Value = false
    }
  }
  const start = getSubmodus(
    item,
    viewModel.value.Packages.Start,
    isInVirtualPackage
  )
  if (!start.IsGift) {
    start.Selected.Value = false
  }

  if (isInVirtualPackage) {
    if (viewModel.value.MNBMode == undefined) {
      const virtualExtra = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Extra
      )
      const virtualKomfort = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.Komfort
      )
      const virtualStartPlus = getSubmodus(
        isInVirtualPackage,
        viewModel.value.Packages.StartPlus
      )

      const extraResult = virtualExtra.Items.every((v) => !v.Selected.Value)
      const komfortResult = virtualKomfort.Items.every((v) => !v.Selected.Value)
      const startPlusResult = virtualStartPlus.Items.every(
        (v) => !v.Selected.Value
      )

      virtualExtra.Selected.Value = !extraResult
      virtualKomfort.Selected.Value = !komfortResult
      virtualStartPlus.Selected.Value = !startPlusResult
    }

    const virtualStart = getSubmodus(
      isInVirtualPackage,
      viewModel.value.Packages.Start
    )

    const startResult = virtualStart.Items.every((v) => !v.Selected.Value)
    virtualStart.Selected.Value = !startResult
  }
  if (!extra.IsGift || !komfort.IsGift || !startPlus.IsGift || !start.IsGift) {
    await callCalculate(true,2000)
  }
}
async function addVirtualPackage(item, householdPackage) {
  for (let i = 0; i < item.Items.length; i++) {
    const virtualPackage = item.Items[i]
    const subModusValues = getSubmodusValues(
      virtualPackage,
      householdPackage,
      item
    )

    if (subModusValues.length > 0) {
      if (viewModel.value.MNBMode == undefined) {
        const virtualSubModusValuesExtra = getSubmodusValues(
          virtualPackage,
          viewModel.value.Packages.Extra,
          item
        )
        const virtualSubModusValuesKomfort = getSubmodusValues(
          virtualPackage,
          viewModel.value.Packages.Komfort,
          item
        )
        const virtualSubModusValuesStartPlus = getSubmodusValues(
          virtualPackage,
          viewModel.value.Packages.StartPlus,
          item
        )

        const virtualSubModusExtra = getSubmodus(
          virtualPackage,
          viewModel.value.Packages.Extra,
          item
        )
        const virtualSubModusKomfort = getSubmodus(
          virtualPackage,
          viewModel.value.Packages.Komfort,
          item
        )
        const virtualSubModusStartPlus = getSubmodus(
          virtualPackage,
          viewModel.value.Packages.StartPlus,
          item
        )
        virtualSubModusExtra.Selected.Value = true
        virtualSubModusKomfort.Selected.Value = true
        virtualSubModusStartPlus.Selected.Value = true

        const selectedValueExtra = getSelectedSubmodusForPackage(
          virtualSubModusValuesExtra,
          3
        )
        let selectedValueExtraObject = {
          Id: selectedValueExtra.value,
          Text: selectedValueExtra.label,
          IsDisabled: false,
        }
        virtualSubModusExtra.Options.Value = selectedValueExtraObject

        const selectedValueKomfort = getSelectedSubmodusForPackage(
          virtualSubModusValuesKomfort,
          2
        )

        let selectedValueKomfortObject = {
          Id: selectedValueKomfort.value,
          Text: selectedValueKomfort.label,
          IsDisabled: false,
        }
        virtualSubModusKomfort.Options.Value = selectedValueKomfortObject

        const selectedValueStartPlus = getSelectedSubmodusForPackage(
          virtualSubModusValuesStartPlus,
          1
        )

        let selectedValueStartPlusObject = {
          Id: selectedValueStartPlus.value,
          Text: selectedValueStartPlus.label,
          IsDisabled: false,
        }
        virtualSubModusStartPlus.Options.Value = selectedValueStartPlusObject
      }

      const virtualSubModusValuesStart = getSubmodusValues(
        virtualPackage,
        viewModel.value.Packages.Start,
        item
      )

      const virtualSubModusStart = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.Start,
        item
      )
      virtualSubModusStart.Selected.Value = true

      const selectedValueStart = getSelectedSubmodusForPackage(
        virtualSubModusValuesStart,
        0
      )

      let selectedValueStartObject = {
        Id: selectedValueStart.value,
        Text: selectedValueStart.label,
        IsDisabled: false,
      }
      virtualSubModusStart.Options.Value = selectedValueStartObject
    }
  }

  if (viewModel.value.MNBMode == undefined) {
    const extra = getSubmodus(item, viewModel.value.Packages.Extra)
    const komfort = getSubmodus(item, viewModel.value.Packages.Komfort)
    const startPlus = getSubmodus(item, viewModel.value.Packages.StartPlus)
    extra.Selected.Value = true
    komfort.Selected.Value = true
    startPlus.Selected.Value = true
  }

  const start = getSubmodus(item, viewModel.value.Packages.Start)
  start.Selected.Value = true
  await callCalculate(true,2000)
}
async function removeVirtualPackage(item) {
  for (let i = 0; i < item.Items.length; i++) {
    const virtualPackage = item.Items[i]

    if (viewModel.value.MNBMode == undefined) {
      const virtualSubModusExtra = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.Extra,
        item
      )
      const virtualSubModusKomfort = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.Komfort,
        item
      )
      const virtualSubModusStartPlus = getSubmodus(
        virtualPackage,
        viewModel.value.Packages.StartPlus,
        item
      )
      if (!virtualSubModusExtra.IsGift) {
        virtualSubModusExtra.Selected.Value = false
      }
      if (!virtualSubModusKomfort.IsGift) {
        virtualSubModusKomfort.Selected.Value = false
      }
      if (!virtualSubModusStartPlus.IsGift) {
        virtualSubModusStartPlus.Selected.Value = false
      }
    }

    const virtualSubModusStart = getSubmodus(
      virtualPackage,
      viewModel.value.Packages.Start,
      item
    )
    if (!virtualSubModusStart.IsGift) {
      virtualSubModusStart.Selected.Value = false
    }
  }

  if (viewModel.value.MNBMode == undefined) {
    const extra = getSubmodus(item, viewModel.value.Packages.Extra)
    const komfort = getSubmodus(item, viewModel.value.Packages.Komfort)
    const startPlus = getSubmodus(item, viewModel.value.Packages.StartPlus)
    if (!extra.IsGift) {
      extra.Selected.Value = false
    }

    komfort.Selected.Value = false
    startPlus.Selected.Value = false
  }

  const start = getSubmodus(item, viewModel.value.Packages.Start)
  start.Selected.Value = false

  await callCalculate(true,2000)
}

async function startChanged(model, value) {
  if (model === 'EstateBO') {
    viewModel.value.Packages.StartPlus.EstateBO.Value = value
    viewModel.value.Packages.Komfort.EstateBO.Value = value
    viewModel.value.Packages.Extra.EstateBO.Value = value
  } else if (model === 'EstatePersonalitySumValueBO') {
    estateValuechanged.value = true
    viewModel.value.EstatePersonalytySumValue.Value = value

    if (viewModel.value.MNBMode == undefined) {
      viewModel.value.Packages.CheckBOValueIII = true

      viewModel.value.Packages.StartPlus.EstatePersonalitySumValueBO.Value =
        value
      viewModel.value.Packages.Komfort.EstatePersonalitySumValueBO.Value = value
      viewModel.value.Packages.Extra.EstatePersonalitySumValueBO.Value = value
    }
    if (estatechangedfrommodal()) {
      viewModel.value.HouseholdAppliancesValue.Value = 0
      viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
      viewModel.value.TelecommunicationDevicesValue.Value = 0
      viewModel.value.FurnitureValue.Value = 0
      viewModel.value.ClothesValue.Value = 0
      viewModel.value.KitchenAndBathroomEquipment.Value = 0
      viewModel.value.TextileValue.Value = 0
      viewModel.value.MiscellaneousItemsValue.Value = 0
    }
  } else if (model === 'ValuablesSumBO') {
    valuablesValuechanged.value = true
    if (viewModel.value.MNBMode == undefined) {
      viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = value
      viewModel.value.Packages.Komfort.ValuablesSumBO.Value = value
      viewModel.value.Packages.Extra.ValuablesSumBO.Value = value
      viewModel.value.ValuablesSumValue.Value = value
    }
    if (valuableschangedfrommodal()) {
      viewModel.value.JewelryValue.Value = 0
      viewModel.value.AntiquesValue.Value = 0
      viewModel.value.FursAndCarpetsValue.Value = 0
      viewModel.value.CollectionsValue.Value = 0
    }
  } else if (model === 'SteadingPersonalEstateSumBO') {
    viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.SteadingPersonalytySumValue.Value = value
  }

  await callCalculate(true,2000)
}

async function startPlusChanged(model, value) {
  if (model === 'EstateBO') {
    viewModel.value.Packages.Start.EstateBO.Value = value
    viewModel.value.Packages.Komfort.EstateBO.Value = value
    viewModel.value.Packages.Extra.EstateBO.Value = value
  } else if (model === 'EstatePersonalitySumValueBO') {
    estateValuechanged.value = true
    viewModel.value.EstatePersonalytySumValue.Value = value
    viewModel.value.Packages.CheckBOValueIII = true

    viewModel.value.Packages.Start.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.Komfort.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.Extra.EstatePersonalitySumValueBO.Value = value
    if (estatechangedfrommodal()) {
      viewModel.value.HouseholdAppliancesValue.Value = 0
      viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
      viewModel.value.TelecommunicationDevicesValue.Value = 0
      viewModel.value.FurnitureValue.Value = 0
      viewModel.value.ClothesValue.Value = 0
      viewModel.value.KitchenAndBathroomEquipment.Value = 0
      viewModel.value.TextileValue.Value = 0
      viewModel.value.MiscellaneousItemsValue.Value = 0
    }
  } else if (model === 'ValuablesSumBO') {
    valuablesValuechanged.value = true

    viewModel.value.Packages.Start.ValuablesSumBO.Value = value
    viewModel.value.Packages.Komfort.ValuablesSumBO.Value = value
    viewModel.value.Packages.Extra.ValuablesSumBO.Value = value
    viewModel.value.ValuablesSumValue.Value = value
    if (valuableschangedfrommodal()) {
      viewModel.value.JewelryValue.Value = 0
      viewModel.value.AntiquesValue.Value = 0
      viewModel.value.FursAndCarpetsValue.Value = 0
      viewModel.value.CollectionsValue.Value = 0
    }
  } else if (model === 'SteadingPersonalEstateSumBO') {
    viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.SteadingPersonalytySumValue.Value = value
  }
  await callCalculate(true,2000)
}

async function komfortChanged(model, value) {
  if (model === 'EstateBO') {
    viewModel.value.Packages.Start.EstateBO.Value = value
    viewModel.value.Packages.StartPlus.EstateBO.Value = value
    viewModel.value.Packages.Extra.EstateBO.Value = value
  } else if (model === 'EstatePersonalitySumValueBO') {
    estateValuechanged.value = true
    viewModel.value.EstatePersonalytySumValue.Value = value
    viewModel.value.Packages.CheckBOValueIII = true

    viewModel.value.Packages.Start.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.StartPlus.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.Extra.EstatePersonalitySumValueBO.Value = value
    if (estatechangedfrommodal()) {
      viewModel.value.HouseholdAppliancesValue.Value = 0
      viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
      viewModel.value.TelecommunicationDevicesValue.Value = 0
      viewModel.value.FurnitureValue.Value = 0
      viewModel.value.ClothesValue.Value = 0
      viewModel.value.KitchenAndBathroomEquipment.Value = 0
      viewModel.value.TextileValue.Value = 0
      viewModel.value.MiscellaneousItemsValue.Value = 0
    }
  } else if (model === 'ValuablesSumBO') {
    valuablesValuechanged.value = true

    viewModel.value.Packages.Start.ValuablesSumBO.Value = value
    viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = value
    viewModel.value.Packages.Extra.ValuablesSumBO.Value = value
    viewModel.value.ValuablesSumValue.Value = value
    if (valuableschangedfrommodal()) {
      viewModel.value.JewelryValue.Value = 0
      viewModel.value.AntiquesValue.Value = 0
      viewModel.value.FursAndCarpetsValue.Value = 0
      viewModel.value.CollectionsValue.Value = 0
    }
  } else if (model === 'SteadingPersonalEstateSumBO') {
    viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Extra.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.SteadingPersonalytySumValue.Value = value
  }
  await callCalculate(true,2000)
}
async function extraChanged(model, value) {
  if (model === 'EstateBO') {
    viewModel.value.Packages.Start.EstateBO.Value = value
    viewModel.value.Packages.StartPlus.EstateBO.Value = value
    viewModel.value.Packages.Komfort.EstateBO.Value = value
  } else if (model === 'EstatePersonalitySumValueBO') {
    estateValuechanged.value = true
    viewModel.value.EstatePersonalytySumValue.Value = value
    viewModel.value.Packages.CheckBOValueIII = true

    viewModel.value.Packages.Start.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.StartPlus.EstatePersonalitySumValueBO.Value = value
    viewModel.value.Packages.Komfort.EstatePersonalitySumValueBO.Value = value
    if (estatechangedfrommodal()) {
      viewModel.value.HouseholdAppliancesValue.Value = 0
      viewModel.value.ToolsAndNonIntegratedLampsValue.Value = 0
      viewModel.value.TelecommunicationDevicesValue.Value = 0
      viewModel.value.FurnitureValue.Value = 0
      viewModel.value.ClothesValue.Value = 0
      viewModel.value.KitchenAndBathroomEquipment.Value = 0
      viewModel.value.TextileValue.Value = 0
      viewModel.value.MiscellaneousItemsValue.Value = 0
    }
  } else if (model === 'ValuablesSumBO') {
    valuablesValuechanged.value = true

    viewModel.value.Packages.Start.ValuablesSumBO.Value = value
    viewModel.value.Packages.StartPlus.ValuablesSumBO.Value = value
    viewModel.value.Packages.Komfort.ValuablesSumBO.Value = value
    viewModel.value.ValuablesSumValue.Value = value
    if (valuableschangedfrommodal()) {
      viewModel.value.JewelryValue.Value = 0
      viewModel.value.AntiquesValue.Value = 0
      viewModel.value.FursAndCarpetsValue.Value = 0
      viewModel.value.CollectionsValue.Value = 0
    }
  } else if (model === 'SteadingPersonalEstateSumBO') {
    viewModel.value.Packages.Start.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.StartPlus.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.Packages.Komfort.SteadingPersonalEstateSumBO.Value = value
    viewModel.value.SteadingPersonalytySumValue.Value = value
  }
  await callCalculate(true,2000)
}
function hasVirtualGift(item, householdPackage) {
  let submodus = getSubmodus(item, householdPackage)
  return submodus.Items.some((element) => element.IsGift == true)
}
const accordion = ref([])

function isAccordionHasGiftOpen(index) {
  let visibleExtra = []
  let visibleStart = []
  for (
    let index = 0;
    index < viewModel.value.Packages.Extra.Additionals.length;
    index++
  ) {
    if (!viewModel.value.Packages.Union.Additionals[index].IsHidden) {
      visibleExtra.push(viewModel.value.Packages.Extra.Additionals[index])
    }
  }
  if (visibleExtra[index].IsVirtualPackage) {
    return visibleExtra[index].Items.some((element) => element.IsGift == true)
  }
  for (
    let index = 0;
    index < viewModel.value.Packages.Start.Additionals.length;
    index++
  ) {
    if (!viewModel.value.Packages.Union.Additionals[index].IsHidden) {
      visibleStart.push(viewModel.value.Packages.Start.Additionals[index])
    }
  }
  if (visibleStart[index].IsVirtualPackage) {
    return visibleStart[index].Items.some((element) => element.IsGift == true)
  }

  return false
}
function accordionDefaultValues() {
  let tomb = []
  for (
    let index = 0;
    index < visibleAdditionalCategories.value.length;
    index++
  ) {
    tomb.push(isAccordionHasGiftOpen(index))
  }
  accordion.value = tomb
}

function isTrashVisibleInVirtualPackage(item, viewmodelPackage) {
  let value = false
  viewmodelPackage.Additionals.forEach((element) => {
    if (item.Id == element.Id) {
      element.Items.forEach((items) => {
        if (!items.IsGift) {
          if (items.Selected.Value == true) {
            value = true
          }
        }
      })
    }
  })
  return value
}

function virtualPackageHasSelectedValue(item,viewmodelPackage){
  let returnableValue = false
  viewmodelPackage.Additionals.forEach(additional => {
    if(additional.IsVirtualPackage && (item.Id == additional.Id ) ){
      additional.Items.forEach(element => {
        if(element.Selected.Value){
          returnableValue = true
        }
     });
    }
  });
  
  return returnableValue
}

customDefineRule('VAL_445_MAIN', (value) => {
  const targetNumber = parseInt(
    viewModel.value.Packages.Start.EstatePersonalitySumMinimumBO.Value,
    10
  )
  if (Number(value) < targetNumber) {
    return ValidationMessages.VAL_445.replace(
      '{0}',
      getFormattedNumber(targetNumber)
    )
  }
  return true
})

watch(
  () => [viewModel.value.Cart.Payment.PaymentMode.Value, viewModel.value.Cart.Payment.PaymentPeriod.Value, viewModel.value.ContentDiscount.Value],
  (value, oldValue) => {
    isMFODisabled.value = MFOExclusion()
    isGTDisabled.value = GtHouseExclusion()
  }
)

function getSubmodusGiftValue(values){
  let returnableValue = 1
  values.forEach(value => {
    if(value.value == 1){
      returnableValue = value.label
    }
});
  return getFormattedNumber(returnableValue)
}
</script>
<style lang="scss" scoped>
.virtual-package-tab {
  min-width: 100%;
  display: block !important;
  .virtual-package-holder {
    display: flex;
    height: 100%;
  }
  .virtual-package-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .virtualPackageIconPadding {
    padding-left: 1.125rem;
  }
  .virtual-package-input {
    display: flex;
    align-items: center;
  }
}
.sticky-calculator {
  .recommended-package {
    display: none;
  }
}

.insurance-package-tablet-mobile {
  .head-row {
    .first {
      border-radius: 0.25rem 0rem 0rem 0rem;
    }
    .last {
      border-radius: 0rem 0.25rem 0rem 0rem;
    }
  }

  .col.field-content {
    background: $N-400;
  }

  .package-head {
    width: 100%;
    .package-name {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0.25rem;
      height: 5.75rem;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: #1d2c5b;
      border-left: 0.0625rem solid white;
      white-space: pre-wrap;
    }
    &.first {
      .package-name {
        border-left: 0.0625rem solid $border-hover;
      }
    }
    .package-name-mfo {
      display: flex;
      flex-direction: column;
      .cursor-pointer {
        margin: 0;
        color: $secondary;
      }
    }
    .package-price-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.25rem;
      background: #ffffff;
      border-left: 0.0625rem solid $border-hover;
      &.last {
        border-right: 0.0625rem solid $border-hover;
      }
      .package-period-price {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        display: flex;
        flex-direction: row;
      }
      .package-annual-price {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }

    .package-radio-input-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.25rem;
      background: #ffffff;
      border-left: 0.0625rem solid $border-hover;
      border-top: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;

      &.last {
        border-right: 0.0625rem solid $border-hover;
      }
    }
  }
  .package-head.mnb-package-head {
    .package-price-holder {
      border-bottom: 0.0625rem solid #dde1ef;
    }
  }

  .col.field-content.package-selected {
    background: #2f5997;
    .package-name {
      color: #ffffff;
    }
  }
  .recommended-package {
    text-align: center;
    border-left: 0.0625rem solid #fff;
    padding: 0.25rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 0.75rem;
    background: $dark;
    margin-top: -1.25rem;
  }

  :deep(.package-tab) {
    width: 100%;
  }
  :deep(.q-tab) {
    text-transform: none;
  }
  :deep(.q-tabs__content) {
    overflow: visible;
  }
  :deep(.q-tab__content) {
    padding: 0;
  }
  :deep(.q-tab__indicator) {
    display: none;
  }
  :deep(.q-tab--inactive) {
    opacity: 1;
  }
  :deep(.q-radio__inner.relative-position.q-radio__inner--truthy) {
    color: #4e95fb;
  }
  :deep(.q-radio__inner.relative-position) {
    width: fit-content;
  }
  :deep(.q-radio__label) {
    flex: 1;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
    text-transform: none;
  }

  :deep(.q-option-group.q-gutter-x-sm) {
    margin-left: 0;
  }

  :deep(.q-option-group) {
    display: flex;
    flex-direction: row;
  }
  :deep(.q-gutter-x-sm > *, .q-gutter-sm > *) {
    margin-left: 0;
  }

  .radio-input {
    .q-field__messages.col {
      line-height: 1.25rem;
    }
    .q-field--with-bottom {
      padding-bottom: 1.5rem;
    }
  }
  .validation-row {
    .validation-col {
      padding: 0.5rem;
      text-align: center;
      border-left: 0.0625rem solid $border-hover;
      border-right: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;
      background: white;
      :deep(.error) {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }

  .submodus-name-holder {
    word-break: break-word;
    background: #e6e8f3;
    border-left: 0.0625rem solid $border-hover;
    border-bottom: 0.0625rem solid $border-hover;
    border-right: 0.0625rem solid $border-hover;
    padding: 1rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #1d2c5b;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    max-width: 24.2813rem;
    .submodus-gift-holder {
      position: absolute;
      background: #a124ad;
      transform: rotate(-45deg);
      right: -0.375rem;
      top: -1rem;
      width: 1.6875rem;
      height: 2.9375rem;
      .submodus-gift {
        transform: rotate(45deg);
        top: 0.9375rem;
        position: absolute;
        right: 0.5625rem;
      }
    }
    .submodus-title-holder {
      display: flex;
      align-items: center;
    }
  }

  .submodus-control-holder {
    padding: 1rem 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-right: 0.0625rem solid $border-hover;
    border-bottom: 0.0625rem solid $border-hover;
    height: 100%;
    .submodus-check {
      color: #10b981;
    }
    .submodus-cross {
      color: #ed4353;
    }
    :deep(.text-input) {
      width: 100%;
    }
    :deep(.q-select) {
      width: 100%;
    }
    .submodus-input-holder {
      width: 100%;
      .text-only {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-left: 0.75rem;
      }
    }
  }
  .q-tab-panel.text-only {
    .text-only {
      width: 100%;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-left: 0.75rem;
    }
  }
  .submodus-control-holder {
    .text-only {
      display: flex;
      justify-self: flex-start;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-left: 0.75rem;
    }
  }
  .submodus-gift {
    justify-content: flex-start !important;
  }
  .submodus-modification-button-holder {
    margin-left: 0.375rem;
  }
  .package-accordion {
    :deep(.q-item) {
      padding: 0;
    }
    :deep(.package-accordion-header) {
      .package-accordion-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .package-accordion-icon {
        display: none;
      }
    }
    :deep(.q-expansion-item__content) {
      .submodus-name-holder {
        background: #f7f7fb;
      }
    }
  }

  :deep(.disabled-package) {
    .package-name {
      color: #8a91ab;
    }
    .package-price-holder {
      background: #eef0f7;
      color: #8a91ab;
    }
    .package-radio-input-holder {
      background: #eef0f7;
      .q-radio__label {
        color: #8a91ab;
      }
      span.no-outline {
        display: none;
      }
    }
  }

  .q-tab-panel.submodus-control-holder.disabled-package {
    color: #8a91ab;
    background: #eef0f7;
    .submodus-check,
    .submodus-cross {
      color: #b4b9cb;
    }
  }
}

.sticky-calculator-parent.fix-height-validation {
  margin-top: 7.75rem;
}
.sticky-calculator-parent.fix-height {
  margin-top: 5.25rem;
}
.right-holder {
  min-width: 1rem;
  width: 1rem;
}
.left-holder {
  padding-right: 0.5rem;
}
.package-accordion-button {
  padding: 0;
}

@media screen and (max-width: $breakpoint-md-max) {
  .insurance-package-tablet-mobile {
    .package-head {
      .package-name {
        padding: 0.25rem;
        height: 3rem;
      }
      .package-name-mfo {
        white-space: unset;
      }
      .package-price-holder {
        padding: 0.5rem 0;

        .package-period-price {
          font-weight: 600;
          font-size: 0.625rem;
          line-height: 1.25rem;
          flex-direction: column;
          text-align: center;
          &.annual {
            flex-direction: row;
          }
        }
        .package-annual-price {
          font-weight: 400;
          font-size: 0.625rem;
          line-height: 0.75rem;
        }
      }
    }

    :deep(.q-radio__inner.relative-position.q-radio__inner--truthy) {
      color: #4e95fb;
    }
    :deep(.q-radio__inner.relative-position) {
      width: fit-content;
    }
    :deep(.q-radio__label) {
      font-size: 0.625rem !important;
      line-height: 0.75rem !important;
      min-width: 4.25rem;
      text-align: center;
      min-height: 1.5rem;
      margin: -0.1875rem 0 -0.375rem 0;
      display: flex;
      align-items: center;
    }

    :deep(.q-option-group.q-gutter-x-sm) {
      margin-left: 0;
    }

    :deep(.q-option-group) {
      display: flex;
      flex-direction: row;
    }
    :deep(.q-gutter-x-sm > *, .q-gutter-sm > *) {
      margin-left: 0;
    }

    .radio-input {
      :deep(.q-radio.cursor-pointer.no-outline.row.inline.no-wrap.items-center) {
        margin: 0 !important;
        flex-direction: column;
      }
      :deep(.q-radio__inner.relative-position) {
        margin-right: 0 !important;
      }
      .q-field__messages.col {
        line-height: 1.25rem;
      }
      .q-field--with-bottom {
        padding-bottom: 1.5rem;
      }
    }
    .validation-row {
      .validation-col {
        padding: 0.5rem;
        text-align: center;
        border-left: 0.0625rem solid $border-hover;
        border-right: 0.0625rem solid $border-hover;
        border-bottom: 0.0625rem solid $border-hover;
        background: white;
        :deep(.error) {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }

    .submodus-name-holder {
      background: #e6e8f3;
      border-left: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;
      border-right: 0.0625rem solid $border-hover;
      padding: 1rem 0.5rem 1rem 0.5rem;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #1d2c5b;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      max-width: 9.375rem;
      .submodus-gift-holder {
        position: absolute;
        background: #a124ad;
        transform: rotate(-45deg);
        right: -0.375rem;
        top: -1rem;
        width: 1.6875rem;
        height: 2.9375rem;
        .submodus-gift {
          transform: rotate(45deg);
          top: 0.9375rem;
          position: absolute;
          right: 0.5625rem;
        }
      }
    }

    .submodus-control-holder {
      padding: 1rem 0.75rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border-right: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;
      height: 100%;
      .submodus-check {
        color: #10b981;
      }
      .submodus-cross {
        color: #ed4353;
      }
      :deep(.text-input) {
        width: 100%;
      }
      :deep(.q-select) {
        width: 100%;
      }
      .submodus-input-holder {
        width: 100%;
        :deep(.text-only) {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding-left: 0.75rem;
        }
      }
    }

    .submodus-modification-button-holder {
      margin-left: 0.375rem;
    }
    .package-accordion {
      :deep(.q-item) {
        padding: 0;
      }
      :deep(.package-accordion-header) {
        .package-accordion-name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .package-accordion-icon {
          display: none;
        }
      }
      :deep(.q-expansion-item__content) {
        .submodus-name-holder {
          background: #f7f7fb;
        }
      }
    }

    :deep(.disabled-package) {
      .package-name {
        color: #8a91ab;
      }
      .package-price-holder {
        background: #eef0f7;
        color: #8a91ab;
      }
      .package-radio-input-holder {
        background: #eef0f7;
        .q-radio__label {
          color: #8a91ab;
        }
      }
    }

    .q-tab-panel.submodus-control-holder.disabled-package {
      color: #8a91ab;
      background: #eef0f7;
      .submodus-check,
      .submodus-cross {
        color: #b4b9cb;
      }
    }
  }
  .sticky-calculator-parent.fix-height-validation {
    margin-top: 0;
  }
  .sticky-calculator-parent.fix-height {
    margin-top: 0;
  }
}
@media screen and (max-width: 23.4375rem) {
  .insurance-package-tablet-mobile{
    .submodus-name-holder{
      max-width: 6.875rem !important;
    }
  }
  
  .submodus-control-holder{
    
  :deep(.q-field--standout){
    .q-field__native{
      font-size: .625rem;
    }
    .text-only{
      font-size: .625rem;
    }
}
  }
  :deep(.package-head) {
    .q-radio__label {
      white-space: break-spaces;
      max-width: 3.75rem;
      text-align: center;
      min-height: 3rem;
      min-width: unset;
    }
  }
  :deep(.text-only){
    font-size: .625rem !important;
  }
  .submodus-input-holder{
    :deep(.q-field__suffix){
      font-size: .625rem !important;
    }
    :deep(.input-postfix){
      font-size: .625rem !important;
    }
  }
}
</style>
