//Elements/Phicons
import { defineAsyncComponent } from 'vue'

const components = {
  PhArrowUp: () => import('@/components/elements/phicons/PhArrowUp.vue'),
  PhArrowDown: () => import('@/components/elements/phicons/PhArrowDown.vue'),
  PhMinus: () => import('@/components/elements/phicons/PhMinus.vue'),
  PhMinusCircle: () => import('@/components/elements/phicons/PhMinusCircle.vue'),
  PhPlus: () => import('@/components/elements/phicons/PhPlus.vue'),
  PhPlusCircle: () => import('@/components/elements/phicons/PhPlusCircle.vue'),
  PhUserCircle: () => import('@/components/elements/phicons/PhUserCircle.vue'),
  PhList: () => import('@/components/elements/phicons/PhList.vue'),
  PhX: () => import('@/components/elements/phicons/PhX.vue'),
  PhCaretRight: () => import('@/components/elements/phicons/PhCaretRight.vue'),
  PhMagnifyingGlass: () =>
    import('@/components/elements/phicons/PhMagnifyingGlass.vue'),
  PhCircleHalfTilt: () =>
    import('@/components/elements/phicons/PhCircleHalfTilt.vue'),
  PhFileArrowDown: () =>
    import('@/components/elements/phicons/PhFileArrowDown.vue'),
  PhFacebookLogo: () =>
    import('@/components/elements/phicons/PhFacebookLogo.vue'),
  PhYoutubeLogo: () =>
    import('@/components/elements/phicons/PhYoutubeLogo.vue'),
  PhCaretLeft: () => import('@/components/elements/phicons/PhCaretLeft.vue'),
  PhArrowRight: () => import('@/components/elements/phicons/PhArrowRight.vue'),
  PhCircleWavy: () => import('@/components/elements/phicons/PhCircleWavy.vue'),
  PhCircleWavyCheck: () => import('@/components/elements/phicons/PhCircleWavyCheck.vue'),
  PhWarningCircle: () =>
    import('@/components/elements/phicons/PhWarningCircle.vue'),
  PhStar: () => import('@/components/elements/phicons/PhStar.vue'),
  PhInfo: () => import('@/components/elements/phicons/PhInfo.vue'),
  PhLinkBreak: () => import('@/components/elements/phicons/PhLinkBreak.vue'),
  PhImageSquare: () =>
    import('@/components/elements/phicons/PhImageSquare.vue'),
  PhFile: () => import('@/components/elements/phicons/PhFile.vue'),
  PhTrash: () => import('@/components/elements/phicons/PhTrash.vue'),
  PhCaretDown: () => import('@/components/elements/phicons/PhCaretDown.vue'),
  PhCaretUp: () => import('@/components/elements/phicons/PhCaretUp.vue'),
  PhCalendarBlank: () =>
    import('@/components/elements/phicons/PhCalendarBlank.vue'),
  PhFileArrowUp: () =>
    import('@/components/elements/phicons/PhFileArrowUp.vue'),
  PhMapPin: () => import('@/components/elements/phicons/PhMapPin.vue'),
  PhPhone: () => import('@/components/elements/phicons/PhPhone.vue'),
  PhCheckCircle: () =>
    import('@/components/elements/phicons/PhCheckCircle.vue'),
  PhPaperPlaneTilt: () =>
    import('@/components/elements/phicons/PhPaperPlaneTilt.vue'),
  PhLink: () => import('@/components/elements/phicons/PhLink.vue'),
  PhBrowser: () => import('@/components/elements/phicons/PhBrowser.vue'),
  PhPercent: () => import('@/components/elements/phicons/PhPercent.vue'),
  PhShield: () => import('@/components/elements/phicons/PhShield.vue'),
  PhJeep: () => import('@/components/elements/phicons/PhJeep.vue'),
  PhHeadlights: () => import('@/components/elements/phicons/PhHeadlights.vue'),
  PhCreditCard: () => import('@/components/elements/phicons/PhCreditCard.vue'),
  PhCarSimple: () => import('@/components/elements/phicons/PhCarSimple.vue'),
  PhFileText: () => import('@/components/elements/phicons/PhFileText.vue'),
  PhIdentificationCard: () =>
    import('@/components/elements/phicons/PhIdentificationCard.vue'),
  PhMoney: () => import('@/components/elements/phicons/PhMoney.vue'),
  PhCheckSquareOffset: () =>
    import('@/components/elements/phicons/PhCheckSquareOffset.vue'),
  PhFiles: () => import('@/components/elements/phicons/PhFiles.vue'),
  PhFilePlus: () => import('@/components/elements/phicons/PhFilePlus.vue'),
  PhIdentificationBadge: () =>
    import('@/components/elements/phicons/PhIdentificationBadge.vue'),
  PhBandaids: () => import('@/components/elements/phicons/PhBandaids.vue'),
  PhHouseLine: () => import('@/components/elements/phicons/PhHouseLine.vue'),
  PhAirplaneTilt: () =>
    import('@/components/elements/phicons/PhAirplaneTilt.vue'),
  PhChecks: () => import('@/components/elements/phicons/PhChecks.vue'),
  PhCheckSquare: () =>
    import('@/components/elements/phicons/PhCheckSquare.vue'),
  PhHandshake: () => import('@/components/elements/phicons/PhHandshake.vue'),
  PhTag: () => import('@/components/elements/phicons/PhTag.vue'),
  PhShieldChevron: () =>
    import('@/components/elements/phicons/PhShieldChevron.vue'),
  PhCar: () => import('@/components/elements/phicons/PhCar.vue'),
  PhHeartbeat: () => import('@/components/elements/phicons/PhHeartbeat.vue'),
  PhLaptop: () => import('@/components/elements/phicons/PhLaptop.vue'),
  PhCat: () => import('@/components/elements/phicons/PhCat.vue'),
  PhUmbrella: () => import('@/components/elements/phicons/PhUmbrella.vue'),
  PhCoffee: () => import('@/components/elements/phicons/PhCoffee.vue'),
  PhHouse: () => import('@/components/elements/phicons/PhHouse.vue'),
  PhBatteryLow: () => import('@/components/elements/phicons/PhBatteryLow.vue'),
  PhUsersThree: () => import('@/components/elements/phicons/PhUsersThree.vue'),
  PhGhost: () => import('@/components/elements/phicons/PhGhost.vue'),
  PhBicycle: () => import('@/components/elements/phicons/PhBicycle.vue'),
  PhBuildings: () => import('@/components/elements/phicons/PhBuildings.vue'),
  PhBinoculars: () => import('@/components/elements/phicons/PhBinoculars.vue'),
  PhSunHorizon: () => import('@/components/elements/phicons/PhSunHorizon.vue'),
  PhSnowflake: () => import('@/components/elements/phicons/PhSnowflake.vue'),
  PhWrench: () => import('@/components/elements/phicons/PhWrench.vue'),
  PhNut: () => import('@/components/elements/phicons/PhNut.vue'),
  PhBagSimple: () => import('@/components/elements/phicons/PhBagSimple.vue'),
  PhTruck: () => import('@/components/elements/phicons/PhTruck.vue'),
  PhSwap: () => import('@/components/elements/phicons/PhSwap.vue'),
  PhGasPump: () => import('@/components/elements/phicons/PhGasPump.vue'),
  PhScales: () => import('@/components/elements/phicons/PhScales.vue'),
  PhHourglassHigh: () =>
    import('@/components/elements/phicons/PhHourglassHigh.vue'),
  PhEnvelopeSimple: () =>
    import('@/components/elements/phicons/PhEnvelopeSimple.vue'),
  PhStorefront: () => import('@/components/elements/phicons/PhStorefront.vue'),
  PhLifebuoy: () => import('@/components/elements/phicons/PhLifebuoy.vue'),
  PhMapPinLine: () => import('@/components/elements/phicons/PhMapPinLine.vue'),
  PhFireSimple: () => import('@/components/elements/phicons/PhFireSimple.vue'),
  PhShieldPlus: () => import('@/components/elements/phicons/PhShieldPlus.vue'),
  PhHouseSimple: () =>
    import('@/components/elements/phicons/PhHouseSimple.vue'),
  PhXCircle: () => import('@/components/elements/phicons/PhXCircle.vue'),
  PhIntersect: () => import ('@/components/elements/phicons/PhIntersect.vue'),
  PhLightning: () => import('@/components/elements/phicons/PhLightning.vue'),
  PhLockSimpleOpen: () => import('@/components/elements/phicons/PhLockSimpleOpen.vue'),
  PhCheck: () => import('@/components/elements/phicons/PhCheck.vue'),
  PhNotePencil: () => import('@/components/elements/phicons/PhNotePencil.vue'),
  PhThreeStar: () => import('@/components/elements/phicons/PhThreeStar.vue'), 
  PhCircleWavyCheck: () => import('@/components/elements/phicons/PhCircleWavyCheck.vue'),
  PhRectangleEnvelope: () => import('@/components/elements/phicons/PhRectangleEnvelope.vue'),
  PhGift: () => import('@/components/elements/phicons/PhGift.vue'),
  PhGift: () => import('@/components/elements/phicons/PhGift.vue'),
  PhCarWindow: () => import('@/components/elements/phicons/PhCarWindow.vue'),
  PhCarCrash: () => import('@/components/elements/phicons/PhCarCrash.vue'),
}

export default function (componentName) {
  return defineAsyncComponent(components[componentName])
}
