<template>
  <div class="insurance-package">
    <MfoHouseholdPackageSelectInputPOC />
    <MFODesktopPackageRowLayoutPOC class="first-submodus-row">
      <template #first>
        <SubmodusTitle
          :icon-size="16"
          :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
          :tooltip-title="'Title'"
        >
          20 alapkockázat
        </SubmodusTitle>
      </template>
      <template #secondIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
      <template #thirdIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
      <template #fourthIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
      <template #fifthIcon>
        <PhIconManager
          class="submodus-check"
          height="1.25rem"
          width="1.25rem"
          ph-icon="ph-check"
        />
      </template>
    </MFODesktopPackageRowLayoutPOC>
    <MFODesktopPackageRowLayoutPOC>
      <template #first>
        <SubmodusTitle
          :icon-size="16"
          :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
        >
          Ingatlan alapfedezet
        </SubmodusTitle>
      </template>
      <template #second>
        <TextInput
          id="1"
          :label="'textinput'"
          :maxlength="20"
          validation-rules="VAL_100"
        />
      </template>
      <template #third>
        <TextInput
          id="1"
          :label="'textinput'"
          :maxlength="20"
          validation-rules="VAL_100"
        />
      </template>
      <template #fourth>
        <TextInput
          id="1"
          :label="'textinput'"
          :maxlength="20"
          validation-rules="VAL_100"
        />
      </template>
      <template #fifth>
        <TextInput
          id="1"
          :is-disabled="true"
          :label="'textinput'"
          :maxlength="20"
          validation-rules="VAL_100"
        />
      </template>
    </MFODesktopPackageRowLayoutPOC>
    <MFODesktopPackageRowLayoutPOC>
      <template #first>
        <div>
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
          >
            Felelősségbiztosítás
          </SubmodusTitle>
          <div class="submodus-modification-button-holder">
            <div class="submodus-modification-button active">
              Törlés
            </div>
            <div class="submodus-modification-button">
              HOZZÁADÁS
            </div>
          </div>
        </div>
      </template>
      <template #second>
        <SelectInput
          id="8"
          :label="'selectinput'"
          validation-rules="VAL_100"
        />
      </template>
      <template #third>
        <SelectInput
          id="8"
          :label="'selectinput'"
          validation-rules="VAL_100"
        />
      </template>
      <template #fourth>
        <SelectInput
          id="8"
          :label="'selectinput'"
          validation-rules="VAL_100"
        />
      </template>
      <template #fifth>
        <SelectInput
          id="8"
          :is-disabled="true"
          :label="'selectinput'"
          validation-rules="VAL_100"
        />
      </template>
    </MFODesktopPackageRowLayoutPOC>
    <MFODesktopPackageRowLayoutPOC :is-gift="true">
      <template #first>
        <SubmodusTitle
          :icon-size="16"
          :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
        >
          Balesetbiztosítás
        </SubmodusTitle>
      </template>
      <template #second>
        <SelectInput
          id="8"
          :label="'selectinput'"
          validation-rules="VAL_100"
        />
      </template>
      <template #third>
        <SelectInput
          id="8"
          :label="'selectinput'"
          validation-rules="VAL_100"
        />
      </template>
      <template #fourth>
        <SelectInput
          id="8"
          :label="'selectinput'"
          validation-rules="VAL_100"
        />
      </template>
      <template #fifthIcon>
        <PhIconManager
          ph-icon="ph-x"
          :size="20"
          class="submodus-cross"
        />
      </template>
    </MFODesktopPackageRowLayoutPOC>

    <q-expansion-item
      ref="myItem"
      v-model="accordion"
      header-class="package-accordion-header"
      class="package-accordion"
      expand-icon-class="package-accordion-icon"
      expand-icon-toggle
    >
      <template #header>
        <MFODesktopPackageRowLayoutPOC>
          <template #first>
            <div class="package-accordion-name">
              <div class="left-holder">
                <SubmodusTitle
                  :icon-size="16"
                  :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
                >
                  Relax csomag
                </SubmodusTitle>
                <div class="submodus-modification-button-holder">
                  <div class="submodus-modification-button">
                    Törlés
                  </div>
                  <div class="submodus-modification-button">
                    HOZZÁADÁS
                  </div>
                </div>
              </div>
              <div class="right-holder">
                <q-btn
                  :ripple="false"
                  flat
                  @click.stop="$refs.myItem.toggle()"
                >
                  <PhIconManager
                    v-if="accordion"
                    height="1rem"
                    width="1rem"
                    ph-icon="ph-caret-down"
                  />
                  <PhIconManager
                    v-if="!accordion"
                    height="1rem"
                    width="1rem"
                    ph-icon="ph-caret-up"
                  />
                </q-btn>
              </div>
            </div>
          </template>
          <template #secondIcon>
            <PhIconManager
              class="submodus-check"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-check"
            />
          </template>
          <template #thirdIcon>
            <PhIconManager
              class="submodus-check"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-check"
            />
          </template>
          <template #fourthIcon>
            <PhIconManager
              class="submodus-check"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-check"
            />
          </template>
          <template #fifthIcon>
            <PhIconManager
              class="submodus-cross"
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-x"
            />
          </template>
        </MFODesktopPackageRowLayoutPOC>
      </template>
      <MFODesktopPackageRowLayoutPOC>
        <template #first>
          <div>
            <SubmodusTitle
              :icon-size="16"
              :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
            >
              Balesetbiztosítás
            </SubmodusTitle>
            <div class="submodus-modification-button-holder">
              <div class="submodus-modification-button">
                Törlés
              </div>
              <div class="submodus-modification-button">
                HOZZÁADÁS
              </div>
            </div>
          </div>
        </template>
        <template #second>
          <SelectInput
            id="8"
            :label="'selectinput'"
            validation-rules="VAL_100"
          />
        </template>
        <template #third>
          <SelectInput
            id="8"
            :label="'selectinput'"
            validation-rules="VAL_100"
          />
        </template>
        <template #fourth>
          <SelectInput
            id="8"
            :label="'selectinput'"
            validation-rules="VAL_100"
          />
        </template>
        <template #fifthIcon>
          <PhIconManager
            ph-icon="ph-x"
            :size="20"
            class="submodus-cross"
          />
        </template>
      </MFODesktopPackageRowLayoutPOC>
    </q-expansion-item>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const accordion = ref(false)
</script>
<style lang="scss" scoped>
.insurance-package {
  .submodus-modification-button-holder {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    cursor: pointer;
    .submodus-modification-button {
      color: #b4b9cb;
      &.active {
        color: #4e95fb;
      }
    }
  }
  .package-accordion {
    :deep(.q-item) {
      padding: 0;
    }
    :deep(.package-accordion-header) {
      .package-accordion-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .package-accordion-icon {
        display: none;
      }
    }
    :deep(.q-expansion-item__content) {
      .submodus-name-holder {
        background: #f7f7fb;

      }
    }
  }
  .submodus-icon-holder {
    .submodus-check {
      color: #10b981;
    }
    .submodus-cross {
      color: #ed4353;
    }
  }
  :deep(.disabled-package) {
    .package-name {
      color: #8a91ab;
    }
    .package-price-holder {
      background: #eef0f7;
      color: #8a91ab;
    }
    .package-radio-input-holder {
      background: #eef0f7;
      .q-radio__label {
        color: #8a91ab;
      }
    }
    .submodus-input-holder,
    .submodus-icon-holder {
      color: #8a91ab;
      background: #eef0f7;
      .submodus-check,
      .submodus-cross {
        color: #b4b9cb;
      }
    }
  }
  :deep(.highlighted) {
    .package-price-holder,
    .package-radio-input-holder,
    .submodus-input-holder,
    .submodus-icon-holder {
      background: #f7f7fb;
    }
  }

  :deep(.col-2) {
    width: 19.5rem;
  }
}
.first-submodus-row{
  border-radius: .25rem 0rem 0rem 0rem;
  :deep(.submodus-name-holder){
    border-radius: .25rem 0rem 0rem 0rem;
  }
}
</style>
