<template>
  <div class="container-xxl">
    <div class="minicalculator-holder mobile-minicalculator-holder">
      <div class="minicalculator-title-holder">
        <Title
          htag="h2"
          class="small mt-3 text-center"
        >
          {{ miniKalkulatorConfigs.title }}
        </Title>
        <div class="minicalculator-title-tooltip-holder">
          <Tooltip
            :icon-size="24"
            :tooltip-content="miniKalkulatorConfigs.tooltip"
          />
        </div>
      </div>
      <div
        id="mobile-minicalculator-accordion"
        class="d-md-none d-xxl-none"
      >
        <div
          v-for="minikalkulator in miniKalkulatorConfigs.calculators.filter(
            (calculator) => (calculator.order > 0 && calculator.isVisibleOnHomePage)
          )"
          :key="minikalkulator.order"
          class="card"
        >
          <div
            :id="'heading' + minikalkulator.order"
            class="minicalculator-accordion card-header btn btn-link collapsed"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + minikalkulator.type"
            aria-expanded="false"
            :aria-controls="'collapse' + minikalkulator.type"
          >
            <div class="title">
              <div class="btn-icon">
                <PhIconManager :ph-icon="`ph-${minikalkulator.icon}`" />
              </div>
              <Title
                htag="h2"
                class="btn-title"
              >
                {{ minikalkulator.title }}
              </Title>

              <div
                v-if="
                  minikalkulator.discountLink &&
                    (minikalkulator.discountLink.internalLink ||
                      minikalkulator.discountLink.externalLink)
                "
                class="btn-tag bg-purple text-white"
              >
                <PhIconManager
                  :ph-icon="`ph-${minikalkulator.discountIcon}`"
                  :size="16"
                />
              </div>
            </div>
            <span class="caret">
              <PhIconManager
                ph-icon="ph-plus" 
                class="minicalculator-accordion-icon"
                :size="16"
              />
              <PhIconManager
                ph-icon="ph-minus" 
                class="close"
                :size="16"
              />
            </span>
          </div>

          <div
            :id="'collapse' + minikalkulator.type"
            class="collapse"
            :aria-labelledby="'heading' + minikalkulator.order"
            data-bs-parent="#mobile-minicalculator-accordion"
          >
            <div
              v-if="
                minikalkulator.type !== 'lakaseloszto' &&
                  minikalkulator.type !== 'kotelezo' &&
                  minikalkulator.type !== 'casco'                  
              "
              class="card-body"
            >
              <Row12>
                <div
                  v-if="
                    !isGamePage &&
                      minikalkulator &&
                      minikalkulator.discountLink &&
                      (minikalkulator.discountLink.internalLink ||
                        minikalkulator.discountLink.externalLink)
                  "
                  class="tag-long w-auto bg-purple"
                  @click="clickInnerNavigableLink"
                >
                  <div class="icon-holder bg-white">
                    <PhIconManager
                      :ph-icon="`ph-${minikalkulator.discountIcon}`"
                      :size="20"
                      color="purple"
                    />
                  </div>
                  <NavigableLink
                    :link-object="minikalkulator.discountLink"
                    class="tag-text text-white text-bold t-normal"
                  >
                    {{ minikalkulator.discountLink.title }}
                  </NavigableLink>
                  <PhIconManager
                    ph-icon="ph-arrow-right" 
                    :size="16"
                    color="white"
                  />
                </div>
                <div v-else>
                  <DiscountBarTooltip
                    v-if="minikalkulator.discountLink"
                    :tooltip-content="minikalkulator.discountLink.tooltip"
                    :title="minikalkulator.discountLink.title"
                    :discount-icon="minikalkulator.discountIcon"
                  />
                </div>
              </Row12>
              <Row12 class="btn-row">
                <q-btn
                  class="btn btn-secondary btn-dense"
                  @click="redirectWhenValidationIsUnnecessary(minikalkulator)"
                >
                  {{ minikalkulator.calculateLink.title }}
                </q-btn>
                <NavigableLink
                  :link-object="minikalkulator.productLink"
                  class="text-secondary"
                >
                  {{ minikalkulator.productLink.title }}
                </NavigableLink>
              </Row12>
            </div>

            <div
              v-if="minikalkulator.type === 'lakaseloszto'"
              class="card-body"
              :class="
                miniKalkulatorDefaultSelectedType === 'lakaseloszto' ? 'show active' : ''
              "
            >
              <Row12>
                <div
                  v-if="
                    !isGamePage &&
                      minikalkulator.discountLink &&
                      (minikalkulator.discountLink.internalLink ||
                        minikalkulator.discountLink.externalLink)
                  "
                  class="tag-long w-auto bg-purple"
                  @click="clickInnerNavigableLink"
                >
                  <div class="icon-holder bg-white">
                    <PhIconManager
                      :ph-icon="`ph-${minikalkulator.discountIcon}`"
                      :size="20"
                      color="purple"
                    />
                  </div>
                  <NavigableLink
                    :link-object="minikalkulator.discountLink"
                    class="tag-text text-white text-bold t-normal"
                  >
                    {{ minikalkulator.discountLink.title }}
                  </NavigableLink>
                  <PhIconManager
                    ph-icon="ph-arrow-right"
                    :size="16"
                    color="white"
                  />
                </div>
                <div v-else>
                  <DiscountBarTooltip
                    v-if="minikalkulator.discountLink"
                    :tooltip-content="minikalkulator.discountLink.tooltip"
                    :title="minikalkulator.discountLink.title"
                    :discount-icon="minikalkulator.discountIcon"
                  />
                </div>
              </Row12>
              <q-form ref="lakasForm">
                <SelectInput
                  id="MINI_103"
                  v-model:model-value="lakasFormModel.biztositasTargya"
                  :options="lakasFormModel.biztositastargyaOpciok"
                  :label="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_103'
                    ).label
                  "
                  :tooltip-content="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_103'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
                <SelectInput
                  id="MINI_104"
                  v-model:model-value="lakasFormModel.ingatlanTipusa"
                  :options="lakasFormModel.ingatlantipusaOpciok"
                  :label="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_104'
                    ).label
                  "
                  :tooltip-content="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_104'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT"
                />
                <ZipCodeInput
                  id="MINI_105"
                  v-model:model-value="lakasFormModel.iranyitoSzam"
                  :label="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_105'
                    ).label
                  "
                  :tooltip-content="
                    lakasCalculator.fields.find(
                      (field) => field.name === 'MINI_105'
                    ).tooltip
                  "
                  validation-rules="VAL_100_SELECT|VAL_111"
                />
                <Row12 class="btn-row">
                  <q-btn
                    class="btn btn-secondary btn-dense gtmMainPageCalculationStartLakas"
                    @click="validate('lakasForm')"
                  >
                    {{ lakasCalculator.calculateLink.title }}
                  </q-btn>
                  <NavigableLink
                    :link-object="lakasCalculator.productLink"
                    class="text-secondary"
                  >
                    {{ lakasCalculator.productLink.title }}
                  </NavigableLink>
                </Row12>
              </q-form>
            </div>

            <div
              v-if="minikalkulator.type === 'kotelezo'"
              class="card-body"
              :class="
                miniKalkulatorDefaultSelectedType === 'kotelezo' ? 'show active' : ''
              "
            >
              <Row12>
                <div
                  v-if="
                    !isGamePage &&
                      minikalkulator.discountLink &&
                      (minikalkulator.discountLink.internalLink ||
                        minikalkulator.discountLink.externalLink)
                  "
                  class="tag-long w-auto bg-purple"
                  @click="clickInnerNavigableLink"
                >
                  <div class="icon-holder bg-white">
                    <PhIconManager
                      :ph-icon="`ph-${minikalkulator.discountIcon}`"
                      :size="20"
                      color="purple"
                    />
                  </div>
                  <NavigableLink
                    :link-object="minikalkulator.discountLink"
                    class="tag-text text-white text-bold t-normal"
                  >
                    {{ minikalkulator.discountLink.title }}
                  </NavigableLink>
                  <PhIconManager
                    ph-icon="ph-arrow-right" 
                    :size="16"
                    color="white"
                  />
                </div>
                <div v-else>
                  <DiscountBarTooltip
                    v-if="minikalkulator.discountLink"
                    :tooltip-content="minikalkulator.discountLink.tooltip"
                    :title="minikalkulator.discountLink.title"
                    :discount-icon="minikalkulator.discountIcon"
                  />
                </div>                
              </Row12>
              <q-form ref="kotelezoForm">
                <Row66 class="mb-2">
                  <template #first>
                    <SelectInput
                      id="MINI_106"
                      v-model:model-value="kotelezoFormModel.szerzodeskotesOka"
                      :options="kotelezoFormModel.szerzodeskotesOkaOpciok"
                      :label="
                        kotelezoCalculator.fields.find(
                          (field) => field.name === 'MINI_106'
                        ).label
                      "
                      :tooltip-content="
                        kotelezoCalculator.fields.find(
                          (field) => field.name === 'MINI_106'
                        ).tooltip
                      "
                      validation-rules="VAL_100_SELECT"
                    />
                  </template>
                  <template #second>
                    <TextInput
                      id="MINI_107"
                      :key="kotelezoFormModel.frszNincs"
                      v-model:model-value="kotelezoFormModel.rendszam"
                      :is-disabled="kotelezoFormModel.frszNincs === true"
                      :label="
                        kotelezoCalculator.fields.find(
                          (field) => field.name === 'MINI_107'
                        ).label
                      "
                      :tooltip-content="
                        kotelezoCalculator.fields.find(
                          (field) => field.name === 'MINI_107'
                        ).tooltip
                      "
                      :validation-rules="
                        kotelezoFormModel.frszNincs === true
                          ? ''
                          : 'VAL_100|VAL_191|VAL_345|VAL_346'
                      "
                      :max-length="8"
                    />
                  </template>
                </Row66>
                <Row12>
                  <CheckboxInput
                    v-if="kotelezoFormModel.szerzodeskotesOka === '0'"
                    id="MINI_117"
                    v-model="kotelezoFormModel.frszNincs"
                    :tooltip-content="
                      kotelezoCalculator.fields.find(
                        (field) => field.name === 'MINI_117'
                      ).tooltip
                    "
                  >
                    {{ kotelezoCalculator.fields.find(
                      (field) => field.name === 'MINI_117'
                    ).label }}
                  </CheckboxInput>
                </Row12>
                <Row12 class="btn-row">
                  <q-btn
                    class="btn btn-secondary btn-dense gtmMainPageCalculationStartKotelezo"
                    @click="validate('kotelezoForm')"
                  >
                    {{ minikalkulator.calculateLink.title }}
                  </q-btn>
                  <NavigableLink
                    :link-object="minikalkulator.productLink"
                    class="text-secondary"
                  >
                    {{ minikalkulator.productLink.title }}
                  </NavigableLink>
                </Row12>
              </q-form>
            </div>
            <div
              v-if="minikalkulator.type === 'casco'"
              class="card-body"
              :class="
                miniKalkulatorDefaultSelectedType === 'casco' ? 'show active' : ''
              "
            >
              <Row12
                v-if="
                  activeCascoCalculator &&
                    activeCascoCalculator.discountLink &&
                    (activeCascoCalculator.discountLink.internalLink ||
                      activeCascoCalculator.discountLink.externalLink)
                "
              >
                <div
                  v-if="!isGamePage"
                  class="tag-long w-auto bg-purple"
                  @click="clickInnerNavigableLink"
                >
                  <div class="icon-holder bg-white">
                    <PhIconManager
                      :ph-icon="`ph-${activeCascoCalculator.discountIcon}`"
                      :size="20"
                      color="purple"
                    />
                  </div>
                  <NavigableLink
                    :link-object="activeCascoCalculator.discountLink"
                    class="tag-text text-white text-bold t-normal"
                  >
                    {{ activeCascoCalculator.discountLink.title }}
                  </NavigableLink>
                  <PhIconManager
                    ph-icon="ph-arrow-right" 
                    :size="16"
                    color="white"
                  />
                </div>
                <div v-else>
                  <DiscountBarTooltip
                    v-if="activeCascoCalculator.discountLink"
                    :tooltip-content="activeCascoCalculator.discountLink.tooltip"
                    :title="activeCascoCalculator.discountLink.title"
                    :discount-icon="activeCascoCalculator.discountIcon"
                  />
                </div>
              </Row12>
              <q-tabs
                v-model="tab"
                dense
                class="text-dark-300 text-left justify-content-start"
                active-color="dark"
                indicator-color="dark"
              >
                <q-tab
                  class="col"
                  name="casco"
                >
                  <Title
                    htag="h3"
                    class="q-tab__label mb-0"
                  >
                    {{ cascoCalculator.title }}
                  </Title>
                  <Tooltip
                    class="q-tab-tooltip"
                    :tooltip-content="cascoCalculator.homePageTooltip"
                  />
                </q-tab>
                <q-tab
                  class="col"
                  name="minicasco"
                >
                  <Title
                    htag="h3"
                    class="q-tab__label mb-0"
                  >
                    {{ miniCascoCalculator.title }}
                  </Title>

                  <Tooltip
                    class="q-tab-tooltip"
                    :tooltip-content="miniCascoCalculator.homePageTooltip"
                  />
                </q-tab>
                <q-tab
                  class="col"
                  name="lopascasco"
                >
                  <Title
                    htag="h3"
                    class="q-tab__label mb-0"
                  >
                    {{ lopasCascoCalculator.title }}
                  </Title>

                  <Tooltip
                    class="q-tab-tooltip"
                    :tooltip-content="lopasCascoCalculator.homePageTooltip"
                  />
                </q-tab>
              </q-tabs>

              <q-tab-panels
                v-model="tab"
                animated
              >
                <q-tab-panel name="casco">
                  <q-form ref="cascoForm">
                    <Row444 class="mb-2">
                      <template #first>
                        <SelectInput
                          id="MINI_108"
                          v-model:model-value="cascoFormModel.szerzodeskotesOka"
                          :options="cascoFormModel.szerzodeskotesOkaOpciok"
                          :label="
                            cascoCalculator.fields.find(
                              (field) => field.name === 'MINI_108'
                            ).label
                          "
                          :tooltip-content="
                            cascoCalculator.fields.find(
                              (field) => field.name === 'MINI_108'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT"
                        />
                      </template>
                      <template #second>
                        <TextInput
                          id="MINI_107"
                          :key="cascoFormModel.frszNincs"
                          v-model:model-value="cascoFormModel.rendszam"
                          :is-disabled="cascoFormModel.frszNincs === true"
                          :label="
                            cascoCalculator.fields.find(
                              (field) => field.name === 'MINI_107'
                            ).label
                          "
                          :tooltip-content="
                            cascoCalculator.fields.find(
                              (field) => field.name === 'MINI_107'
                            ).tooltip
                          "
                          :validation-rules="
                            cascoFormModel.frszNincs === true
                              ? ''
                              : 'VAL_100|VAL_191|VAL_345|VAL_346'
                          "
                          :max-length="8"
                        />
                      </template>
                      <template #third>
                        <SelectInput
                          id="MINI_109"
                          v-model:model-value="cascoFormModel.felhasznalasmodja"
                          :options="cascoFormModel.felhasznalasmodjaOpciok"
                          :label="
                            cascoCalculator.fields.find(
                              (field) => field.name === 'MINI_109'
                            ).label
                          "
                          :tooltip-content="
                            cascoCalculator.fields.find(
                              (field) => field.name === 'MINI_109'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT|VAL_238_SELECT"
                        />
                      </template>
                    </Row444>
                    <Row12 v-if="cascoFormModel.szerzodeskotesOka === '1'">
                      <CheckboxInput
                        id="MINI_117"
                        v-model="cascoFormModel.frszNincs"
                        :tooltip-content="
                          cascoCalculator.fields.find(
                            (field) => field.name === 'MINI_117'
                          ).tooltip
                        "
                      >
                        {{ cascoCalculator.fields.find(
                          (field) => field.name === 'MINI_117'
                        ).label }}
                      </CheckboxInput>
                    </Row12>
                    <Row12 class="btn-row">
                      <q-btn
                        class="btn btn-secondary btn-dense gtmMainPageCalculationStartCasco"
                        @click="validate('cascoForm')"
                      >
                        {{ cascoCalculator.calculateLink.title }}
                      </q-btn>
                      <NavigableLink
                        :link-object="cascoCalculator.productLink"
                        class="text-secondary"
                      >
                        {{ cascoCalculator.productLink.title }}
                      </NavigableLink>
                    </Row12>
                  </q-form>
                </q-tab-panel>

                <q-tab-panel name="minicasco">
                  <q-form ref="miniCascoForm">
                    <Row444 class="mb-2">
                      <template #first>
                        <SelectInput
                          id="MINI_110"
                          v-model:model-value="
                            miniCascoFormModel.szerzodoErdekeltseg
                          "
                          :options="
                            miniCascoFormModel.szerzodoErdekeltsegOpciok
                          "
                          :label="
                            miniCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_110'
                            ).label
                          "
                          :tooltip-content="
                            miniCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_110'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT"
                        />
                      </template>
                      <template #second>
                        <SelectInput
                          id="MINI_111"
                          v-model:model-value="miniCascoFormModel.forgalmiNev"
                          :options="miniCascoFormModel.forgalmiNevOpciok"
                          :label="
                            miniCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_111'
                            ).label
                          "
                          :tooltip-content="
                            miniCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_111'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT"
                        />
                      </template>
                      <template #third>
                        <SelectInput
                          id="MINI_112"
                          v-model:model-value="miniCascoFormModel.elozoKgfb"
                          :options="miniCascoFormModel.elozoKgfbOpciok"
                          :label="
                            miniCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_112'
                            ).label
                          "
                          :tooltip-content="
                            miniCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_112'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT"
                        />
                      </template>
                    </Row444>
                    <Row12 class="btn-row">
                      <q-btn
                        class="btn btn-secondary btn-dense gtmMainPageCalculationStartMiniCasco"
                        @click="validate('miniCascoForm')"
                      >
                        {{ miniCascoCalculator.calculateLink.title }}
                      </q-btn>
                      <NavigableLink
                        :link-object="miniCascoCalculator.productLink"
                        class="text-secondary"
                      >
                        {{ miniCascoCalculator.productLink.title }}
                      </NavigableLink>
                    </Row12>
                  </q-form>
                </q-tab-panel>

                <q-tab-panel name="lopascasco">
                  <q-form ref="lopasCascoForm">
                    <Row444 class="mb-2">
                      <template #first>
                        <SelectInput
                          id="MINI_110"
                          v-model:model-value="
                            lopasCascoFormModel.szerzodoErdekeltseg
                          "
                          :options="
                            lopasCascoFormModel.szerzodoErdekeltsegOpciok
                          "
                          :label="
                            lopasCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_110'
                            ).label
                          "
                          :tooltip-content="
                            lopasCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_110'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT"
                        />
                      </template>
                      <template #second>
                        <SelectInput
                          id="MINI_111"
                          v-model:model-value="lopasCascoFormModel.forgalmiNev"
                          :options="lopasCascoFormModel.forgalmiNevOpciok"
                          :label="
                            lopasCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_111'
                            ).label
                          "
                          :tooltip-content="
                            lopasCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_111'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT"
                        />
                      </template>
                      <template #third>
                        <SelectInput
                          id="MINI_113"
                          v-model:model-value="
                            lopasCascoFormModel.gepjarmuFajtaja
                          "
                          :options="lopasCascoFormModel.gepjarmuFajtajaOpciok"
                          :label="
                            lopasCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_113'
                            ).label
                          "
                          :tooltip-content="
                            lopasCascoCalculator.fields.find(
                              (field) => field.name === 'MINI_113'
                            ).tooltip
                          "
                          validation-rules="VAL_100_SELECT"
                        />
                      </template>
                    </Row444>
                    <Row12 class="btn-row">
                      <q-btn
                        class="btn btn-secondary btn-dense gtmMainPageCalculationStartLopasCasco"
                        @click="validate('lopasCascoForm')"
                      >
                        {{ lopasCascoCalculator.calculateLink.title }}
                      </q-btn>
                      <NavigableLink
                        :link-object="lopasCascoCalculator.productLink"
                        class="text-secondary"
                      >
                        {{ lopasCascoCalculator.productLink.title }}
                      </NavigableLink>
                    </Row12>
                  </q-form>
                </q-tab-panel>
              </q-tab-panels>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, inject } from 'vue'
import RadioInput from '../inputs/RadioInput.vue'
import optionValues from '../../utils/minicalculatorOptionsValues/optionValues'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { useGtm } from '@gtm-support/vue-gtm'
import { focusFirstValidation } from '../../utils/validations/validationFocus'
import { useCalculatorStore } from '../../stores/calculatorStore'

const props = defineProps({
  minicalculatorData: {
    type: Object,
    required: true,
  },

  isGamePage: {
    type: Boolean,
    default: false,
  }
})

const toFormRefs = (refs) => ({
  ...refs,
  formRefs: {
    ...refs,
  },
})
const pushDataLayer = inject('pushDataLayer')
const showLoadingIndicator = inject('showLoadingIndicator')
const calculatorStore = useCalculatorStore()
const cascoForm = ref(null)
const lopasCascoForm = ref(null)
const miniCascoForm = ref(null)
const kotelezoForm = ref(null)
const lakasForm = ref(null)
const router = useRouter()
const activeCascoCalculator = ref(null)

const lakasFormModel = ref(optionValues.getHomeValues())

const kotelezoFormModel = ref(optionValues.getKgfbValues())

const cascoFormModel = ref(optionValues.getCascoValues())

const miniCascoFormModel = ref(optionValues.getMinicascoValues())

const lopasCascoFormModel = ref(optionValues.getTheftCasco())

const miniKalkulatorConfigs = ref(props.minicalculatorData)

const kotelezoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'kotelezo'
)
const cascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'casco'
)
const miniCascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'minicasco'
)
const lopasCascoCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'lopascasco'
)
const lakasCalculator = miniKalkulatorConfigs.value.calculators.find(
  (kalkulator) => kalkulator.type === 'lakaseloszto'
)

function miniKalkulatorConfigDataSortComparer(a, b) {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

miniKalkulatorConfigs.value.calculators.sort(
  miniKalkulatorConfigDataSortComparer
)
const miniKalkulatorDefaultSelectedType = ref(
  miniKalkulatorConfigs.value.calculators.filter(
    (calculator) => calculator.order > 0
  )[0].type
)

const descriptionObject = ref(null)

onMounted(async () => {  
  if (
    cascoCalculator.discountLink &&
    (cascoCalculator.discountLink.internalLink ||
      cascoCalculator.discountLink.externalLink)
  ) {
    setCascoCalculator(cascoCalculator)
  }

    await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Contents/GetCustomerServicePhonePageData'
    )
    .then((response) => {
      descriptionObject.value = response.data.descriptionModule[0]     
    })

})

function clickInnerNavigableLink(element) {
const currentTarget = element.currentTarget
const navigableLink = currentTarget.getElementsByClassName('tag-text text-white text-bold t-normal')
navigableLink[0].click()
}

function setCascoCalculator(calculator) {
  activeCascoCalculator.value = calculator
}

function redirectWhenValidationIsUnnecessary(minicalculator) {
  const mobileLink = minicalculator.calculateLink.internalLink.includes(
    'baleset'
  )
    ? `${
        import.meta.env.VITE_MOBILEPORTAL_BASEURL
      }balesetbiztositas/balesetbiztositas-kalkulator`
    : `${
        import.meta.env.VITE_MOBILEPORTAL_BASEURL
      }utasbiztositas/utasbiztositas-kalkulator`
  window.location.href = mobileLink + '?calculator=mini'
}

function validate(name) {
  switch (name) {
    case 'lakasForm':

      lakasForm.value[0].validate().then((success) => {
        if (success) {
          submitFormToNew(
            lakasForm,
            props.minicalculatorData.calculators.find(
              (field) => field.type === 'lakaseloszto'
            ).calculateLink.internalLink,
            lakasFormModel
          )
          pushDataLayer(
            'calculation_click',
            lakasCalculator.type + 'biztositas',
            lakasFormModel.value.biztositastargyaOpciok.find(
              (element) =>
                element.value == lakasFormModel.value.biztositasTargya
            ).label,
            lakasFormModel.value.ingatlantipusaOpciok.find(
              (element) => element.value == lakasFormModel.value.ingatlanTipusa
            ).label,
            lakasFormModel.value.iranyitoSzam,
            null,
            'mobil'
          )
        } else {
        }
      })
      break
    case 'kotelezoForm':
      kotelezoForm.value[0].validate().then((success) => {
        if (success) {
          pushDataLayer('calculation_click',
                     kotelezoCalculator.type+"biztositas",
                      (kotelezoFormModel.value.szerzodeskotesOkaOpciok.find(
                        (element) => element.value == kotelezoFormModel.value.szerzodeskotesOka).label
                      ),
                      kotelezoFormModel.value.rendszam,
                      kotelezoFormModel.value.frszNincs,
                      null,
                         'mobil')
          window.location.href =
            kotelezoCalculator.calculateLink.internalLink +
            '?MINI_106=' +
            kotelezoFormModel.value.szerzodeskotesOka +
            '&MINI_107=' +
            `${kotelezoFormModel.value.frszNincs === true ? null : kotelezoFormModel.value.rendszam}` +
            '&MINI_117=' +
            kotelezoFormModel.value.frszNincs
        } else {
        }
      })
      break
    case 'cascoForm':
      cascoForm.value[0].validate().then((success) => {
        if (success) {
          pushDataLayer('calculation_click',
                     cascoCalculator.type+"biztositas",
                      (cascoFormModel.value.szerzodeskotesOkaOpciok.find(
                        (element) => element.value == cascoFormModel.value.szerzodeskotesOka).label
                      ),
                      cascoFormModel.value.rendszam,
                      (cascoFormModel.value.felhasznalasmodjaOpciok.find(
                        (element) => element.value == cascoFormModel.value.felhasznalasmodja).label
                      ),
                      cascoFormModel.value.frszNincs,
                         'mobil')
          window.location.href =
            cascoCalculator.calculateLink.internalLink +
            '?MINI_108=' +
            cascoFormModel.value.szerzodeskotesOka +
            '&MINI_107=' +
            `${cascoFormModel.value.frszNincs === true ? null : cascoFormModel.value.rendszam}` +
            '&MINI_109=' +
            cascoFormModel.value.felhasznalasmodja +
            '&MINI_117=' +
            cascoFormModel.value.frszNincs
        } else {
        }
      })
      break
    case 'miniCascoForm':
      miniCascoForm.value[0].validate().then((success) => {
        if (success) {
            pushDataLayer('calculation_click',
                     miniCascoCalculator.type+"biztositas",
                      (miniCascoFormModel.value.szerzodoErdekeltsegOpciok.find(
                        (element) => element.value == miniCascoFormModel.value.szerzodoErdekeltseg).label
                      ),
                      (miniCascoFormModel.value.forgalmiNevOpciok.find(
                        (element) => element.value == miniCascoFormModel.value.forgalmiNev).label
                      ),
                      (miniCascoFormModel.value.elozoKgfbOpciok.find(
                        (element) => element.value == miniCascoFormModel.value.elozoKgfb).label
                      ),
                      null,
                         'mobil')
          window.location.href =
            miniCascoCalculator.calculateLink.internalLink +
            '?MINI_110=' +
            miniCascoFormModel.value.szerzodoErdekeltseg +
            '&MINI_111=' +
            miniCascoFormModel.value.forgalmiNev +
            '&MINI_112=' +
            miniCascoFormModel.value.elozoKgfb +
            '&MINI_112_text=' +
            miniCascoFormModel.value.elozoKgfbOpciok.find(
              (element) => element.value == miniCascoFormModel.value.elozoKgfb
            ).label
        } else {
        }
      })
      break
    case 'lopasCascoForm':
      lopasCascoForm.value[0].validate().then((success) => {
        if (success) {
            pushDataLayer('calculation_click',
                     lopasCascoCalculator.type+"biztositas",
                      (lopasCascoFormModel.value.szerzodoErdekeltsegOpciok.find(
                        (element) => element.value == lopasCascoFormModel.value.szerzodoErdekeltseg).label
                      ),
                      (lopasCascoFormModel.value.forgalmiNevOpciok.find(
                        (element) => element.value == lopasCascoFormModel.value.forgalmiNev).label
                      ),
                      (lopasCascoFormModel.value.gepjarmuFajtajaOpciok.find(
                        (element) => element.value == lopasCascoFormModel.value.gepjarmuFajtaja).label
                      ),
                      null,
                         'mobil')
          window.location.href =
            lopasCascoCalculator.calculateLink.internalLink +
            '?MINI_110=' +
            lopasCascoFormModel.value.szerzodoErdekeltseg +
            '&MINI_111=' +
            lopasCascoFormModel.value.forgalmiNev +
            '&MINI_113=' +
            lopasCascoFormModel.value.gepjarmuFajtaja
        } else {
        }
      })
      break

    default:
      console.log('form invalid')
      alert('Váratlan hiba történt!')
      throw new Error(form)
  }

  focusFirstValidation()
}

function submitFormToNew(inputRef, calculateLink, inputs) {
  inputRef.value[0].validate().then(
    (success) => {
      if (success) {
        calculatorStore.sethousehold(
          inputs.value.biztositastargyaOpciok.find(
            (field) => field.value === inputs.value.biztositasTargya
          ),
          inputs.value.ingatlantipusaOpciok.find(
            (field) => field.value === inputs.value.ingatlanTipusa
          ),
          inputs.value.iranyitoSzam
        )
        router.push({ path: calculateLink })
      } else {
        focusFirstValidation()
      }
    },
    (error) => {
      alert('Váratlan hiba történt!')
      throw new Error(error)
    }
  )
}

const tab = ref('casco')

watch(tab, (newValue, oldValue) => {
  if (
    newValue === 'casco' 
  ) {
    setCascoCalculator(cascoCalculator)
  } else if (
    newValue === 'minicasco' 
  ) {
    setCascoCalculator(miniCascoCalculator)
  } else if (
    newValue === 'lopascasco'
  ) {
    setCascoCalculator(lopasCascoCalculator)
  }
})

watch(
  () => kotelezoFormModel.value.frszNincs,
  (newValue) => {
    if (!newValue) {
      kotelezoFormModel.value.rendszam = null
    }
  }
)

watch(
  () => kotelezoFormModel.value.szerzodeskotesOka,
  (newValue) => {
    if (newValue !== '0' && kotelezoFormModel.value.frszNincs) {
      kotelezoFormModel.value.frszNincs = false
    }
  }
)

watch(
  () => cascoFormModel.value.frszNincs,
  (newValue) => {
    if (!newValue) {
      cascoFormModel.value.rendszam = null
    }
  }
)

watch(
  () => cascoFormModel.value.szerzodeskotesOka,
  (newValue) => {
    if (newValue !== '1' && cascoFormModel.value.frszNincs) {
      cascoFormModel.value.frszNincs = false
    }
  }
)

</script>

<script>
export default {
  name: 'MobileMinicalCulator',
}
</script>

<style lang="scss" scoped>
.minicalculator-accordion > .caret > .close {
  display: inline;
}
.minicalculator-accordion.collapsed > .caret > .close,
.minicalculator-accordion:not(.collapsed)
  > .caret
  > .minicalculator-accordion-icon {
  display: none;
}
.container-xxl.d-flex.d-sm-none {
  display: flex;
  align-items: center;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  text-align: center;
}

.minicalculator-holder {
  width: 100% !important;
  background-color: $N-100;
  padding: 1rem 0;
  & > h2.small {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
  .minicalculator-title-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  :deep(.q-checkbox) {
    .t-xs {
      color: #111a37;
    }
    svg {
      margin-left: 0.5rem;
    }
    .q-item__label{
      color: $D-700;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem !important;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  :deep(.minicalculator-holder) {
    & > .minicalculator-title-holder > h2.small {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2.25rem;
      margin-bottom: 1.5rem;
    }
  }
  .minicalculator-holder{
    :deep(.q-checkbox) {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  }
  
}

@media screen and (max-width: $breakpoint-xs-max) {
  :deep(.minicalculator-holder) {
    width: 100% !important;
    background-color: $N-50;
    padding: 1rem 0;
    & > .minicalculator-title-holder > h2.small {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 1.5rem;
    }
  }
  :deep(.minicalculator-holder){
  .q-checkbox {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
}
}


:deep(#mobile-minicalculator-accordion) {
  .q-field__bottom.row.items-start.q-field__bottom--animated {
    min-height: $half !important;
  }

  border-radius: 0.625rem;
  .card {
    background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
    border-radius: 0.625rem;
    margin-bottom: $half;
    border-width: 0;
  }

  .btn-link {
    text-decoration: none;
    text-transform: uppercase;
  }
  .collapse,
  .collapsing {
    border-right: 1px solid #e6e8f3;
    border-left: 1px solid #e6e8f3;
    border-bottom: 1px solid #e6e8f3;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
  .card-header {
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $dark;
    border-width: 0;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    background-color: transparent;
    border-top: 1px solid #e6e8f3;
    border-right: 1px solid #e6e8f3;
    border-left: 1px solid #e6e8f3;
    .title {
      display: flex;
      align-items: center;
      .btn-icon {
        margin-right: $half;
      }
      .btn-title {
        font-weight: $font-weight-bold;
        font-size: $font-size-md;
        line-height: $line-height-md;
        margin-right: $half;
        margin-bottom: 0;
      }
      .btn-tag {
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
    }
    &.btn.collapsed {
      background-color: #ecebef;
      border-radius: 0.625rem;
      border: 1px solid transparent;
    }
  }
  .tag-long {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: $small 0.75rem $small $small;
    border-radius: 0.75rem;
    max-width: fit-content;
    margin-bottom: $normal;
    .icon-holder {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $normal;
    }
    .tag-text {
      margin-right: $normal;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    &:hover {
      background: #811d8a !important;
    }
  }

  .btn-row {
    .btn-dense {
      padding: 0.75rem 1.25rem;
      box-shadow: 0rem 0.625rem 0.9375rem rgba(78, 149, 251, 0.1);
      border-radius: 0.75rem;
      font-size: $normal;
      line-height: $line-height-base;
      margin-right: 0;
      width: 100%;
      color: white;
      text-transform: none;
      font-weight: $font-weight-medium;
      margin-bottom: 1.5rem;
      margin-top: 0.5rem;
      &::before {
        box-shadow: none;
      }
    }
    a.text-secondary {
      width: 100%;
      font-size: $font-size-sm;
      line-height: $normal;
      font-weight: $font-weight-medium;
      text-transform: uppercase;
    }
    a:hover {
      color: #3e77c9 !important;
    }
    a.q-btn:hover{
      color:white !important
    }
  }

  .q-tab-panels {
    background: transparent;

    .q-tab-panel {
      padding: 1.5rem 0 0;
      background: transparent;
      .col-12 .q-field__bottom.row.items-start.q-field__bottom--animated {
        min-height: $normal !important;
      }
      .col-12:last-child
        .q-field__bottom.row.items-start.q-field__bottom--animated {
        min-height: $half !important;
      }
    }
  }
  .q-tab {
    max-width: 33% !important;
    &__content {
      padding: 0.25rem 0.75rem;
      .q-tab-tooltip {
        height: 1rem !important;
        margin-left: 0.25rem;
        a {
          font-size: 0.8125rem;
          line-height: 1rem;
        }
      }
    }

    &__indicator {
      margin-right: 0.125rem;
      margin-left: 0.125rem;
    }

    &__label {
      display: flex;
      color: #77809d !important;
      font-weight: 500 !important;
      font-size: 0.8125rem;
      line-height: 1rem;
      word-break: break-word;
      max-width: 100%;
      overflow: hidden;
      white-space: break-spaces;
      width: calc(100% - 1.5rem);
      text-align: center;
    }

    &--active {
      background: #d5d9eb;
      border-radius: 0.25rem 0.25rem 0rem 0rem;

      .q-tab__label {
        color: #1d2c5b;
      }
      .q-tab__indicator {
        color: #d5d9eb !important;
      }
    }

    &--inactive {
      .q-tab__indicator {
        color: #d5d9eb !important;
      }
    }
  }
  .pre-dial {
    margin-bottom: 0.25rem;
  }
  .caret {
    pointer-events: none;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  :deep(#mobile-minicalculator-accordion) {
    .btn-row {
      .col-12 {
        text-align: center !important;
      }
      .btn-dense {
        font-size: 0.875rem;
      }
    }
  }
}
.cursor-pointer.all-pointer-events {  
  justify-content: start;
  height: auto !important;
  margin-left: 0;
}
</style>
