const statementOptions = [
  {
    label: 'Igen',
    value: true,
  },
  {
    label: 'Nem',
    value: false,
  },
]

const optionsObject = {
    statementOptions,
}

export default optionsObject