import { defineStore } from 'pinia'

export const useGlobalValuesStore = defineStore('globalValues', {
    state: () => {
        return {
            customerServiceThankYouUserDisplayData: null,
            showCompactHeaderOnProductPages: false,
        }
      },
      getters: {
        getCustomerServiceThankYouUserDisplayData(state) {
          return state.customerServiceThankYouUserDisplayData
        },
        getShowCompactHeaderOnProductPages(state) {
          return state.showCompactHeaderOnProductPages
        }
      },
      actions: {
        setCustomerServiceThankYouUserDisplayData(value){
            this.customerServiceThankYouUserDisplayData = value;
        },
        setShowCompactHeaderOnProductPages(value){
            this.showCompactHeaderOnProductPages = value;
        }
      },
});