<template>
  <div class="col col-12 col-lg-6">
    <div class="card module-services">
      <div class="card-header p-0 b-0 bg-white">
        <div class="icon-holder">
          <img
            :src="`${imageUrlHelper.getImageUrl(
              '/HourglassHigh.svg',
              componentUrl
            )}`"
            alt="alt"
            width="56"
            height="56"
          >
        </div>
        <div class="card-title">
          <Title
            class="small"
            htag="h2"
          >
            {{ title }}
          </Title>
          <div class="administration-text-holder">
            <p class="text-dark t-sm p-0 m-0">
              {{ subTitle }}
            </p>
          </div>
        </div>
      </div>
      <ul class="reset-list">
        <template
          v-for="(item, index) in items"
          :key="index"
        >
          <li
            v-if="index < itemToggle"
            class="btn-holder"
          >
            <q-btn
              :to="item.internalLink ? item.internalLink : null"
              :href="item.externalLink ? item.externalLink : none"
              class="btn btn-service shadow-none w-100 gtmAdministrationMenuItem"
            >
              <div class="icon-holder">
                <PhIconManager :ph-icon="`ph-${item.icon}`" />
              </div>
              <div class="btn-content">
                <Title
                  htag="h3"
                  class="btn-text"
                >
                  {{ item.title }}
                </Title>
                <div class="btn-caret">
                  <PhIconManager
                    ph-icon="ph-caret-right"
                    :size="16"
                  />
                </div>
              </div>
            </q-btn>
          </li>
        </template>
      </ul>
      <div
        v-if="!showAllitems"
        class="read-more d-flex text-secondary text-bold align-self-end cursor-pointer"
        @click="showItems"
      >
        <div class="text-sm gtmAdministrationAllElementsButton">
          Minden elem megtekintése
        </div>
        <div>
          <PhIconManager ph-icon="ph-caret-down" />
        </div>
      </div>
      <div
        v-if="showAllitems"
        class="read-more d-flex text-secondary text-bold align-self-end cursor-pointer"
        @click="showItems"
      >
        <div class="text-sm">
          Kis méret
        </div>
        <div>
          <PhIconManager ph-icon="ph-caret-up" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { VueScreenSizeMixin } from 'vue-screen-size'
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper'

const props = defineProps({
  items: {
    type: Object,
    required: true,
  },

  title: {
    type: String,
    required: true,
  },

  subTitle: {
    type: String,
    required: true,
  },
})

const showAllitems = ref(false)
const componentUrl = import.meta.url

const itemToggle = computed(() => {
  if (showAllitems.value) {
    return props.items.length
  } else if (VueScreenSizeMixin.$vssWidth <= 991) {
    return 3
  } else return 5
})

function showItems() {
  showAllitems.value = !showAllitems.value
}
</script>

<script>
export default {
  name: 'ServicesModule',
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: $breakpoint-md-max) {
  .module-services {
    a.read-more {
      width: 100%;
      justify-content: space-between;
    }
    h2.small {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 0.25rem;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .administration-section {
    h2.small {
      font-weight: 600 !important;
      font-size: 1.125rem !important;
      line-height: 1.75rem !important;
    }
  }
}
</style>
