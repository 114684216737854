<template>
  <footer v-if="isReady">
    <div v-if="Screen.width > 375" class="container-fluid text-dark footer-bottom">
      <div class="container-xxl content-holder">
        <nav class="navbar footer-bottom-nav">
          <ul>
            <li class="nav-item">
              <a
                :href="footerData.generaliCom"
                target="_blank"
                class="nav-link"
              >
                generali.com
              </a>
            </li>
            <li class="nav-item">
              <a
                :href="footerData.generaliHu"
                target="_blank"
                class="nav-link"
              >
                generali.hu
              </a>
            </li>
            <li class="nav-item">
              <router-link
                :to="footerData.honlapTerkep"
                class="nav-link"
              >
                Honlaptérkép
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="footerData.impresszum"
                class="nav-link"
              >
                Impresszum
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="footerData.jogiNyilatkozat"
                class="nav-link"
              >
                Jogi nyilatkozat
              </router-link>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          © Genertel Biztosító Zrt. 2007-{{ new Date().getFullYear() }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { Screen } from 'quasar'

const footerData = ref(null)
const isReady = ref(false)


onMounted(async () => {
  let response = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Contents/Footer'
  )
  footerData.value = response.data
  isReady.value = true
})
</script>

<script>
export default {
  name: 'FooterComponent',
}
</script>

<style lang="scss" scoped>
.footer-cart-visible{
  margin-bottom: 4.75rem;
}
.footer-cart-annual-fee{
  margin-bottom: 6.75rem;
}

:deep(.container-xxl) {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
:deep(.container.footer-left-bar) {
  max-width: 9.75rem;
  margin-right: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .social-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    svg {
      fill: $B-200;
      color: $B-200;
    }
  }
}
:deep(.container-xxl.footer-content) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .nav-title {
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-bold;
    color: $gray;
    margin-bottom: $normal;
  }
  .footer-nav {
    padding: 0;
    margin: 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li.nav-item {
        padding: 0;
        margin: 0 0 1rem 0;
        a.nav-link {
          padding: 0;
          margin: 0;
          color: $B-200;
          font-size: $font-size-md;
          line-height: $line-height-md;
          font-weight: $font-weight-base;
        }
        a.nav-link:hover {
          color: $B-400;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .footer-menu .col {
    padding: 0 2.5rem 0 0;
  }
}
:deep(.footer-bottom) {
  .copyright{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  background-color: $N-300;
  .navbar {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .footer-bottom-nav {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      li.nav-item {
        padding: 0;
        margin: 0;
        a.nav-link {
          padding: 0;
          margin: 0 2.5rem 0 0;
          color: $secondary;
          font-size: $font-size-md;
          line-height: $line-height-md;
          font-weight: $font-weight-base;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .content-holder {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: $large $double;
  }
}
:deep(#footer-menu-accordion) {
  width: 100%;
  margin-bottom: $large;
  .card {
    border: 0 solid $D-400;
    border-radius: $half;
    margin-bottom: $small;
    padding: 0;
    background-color: unset;
    .card-header {
      background-color: $D-400;
      border-top-left-radius: $half;
      border-top-right-radius: $half;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: $B-50;
      font-size: $font-size-md;
      line-height: $line-height-md;
      font-weight: 600;
      margin-bottom: 0;
      padding: 0.75rem $normal;
      &.collapsed {
        border-radius: $half;
      }
      &:hover {
        background-color: $D-300;
      }
    }
    .card-body {
      background-color: $B-50;
      padding: 0;
      border-bottom-left-radius: $half;
      border-bottom-right-radius: $half;
      .footer-nav {
        ul {
          width: 100%;
          li {
            margin-bottom: 0;
            border-bottom: .0625rem solid $N-300;
            width: 100%;
            &:last-child {
              border-bottom: 0 solid $N-300;
            }
            a {
              color: $dark;
              font-size: $font-size-sm;
              line-height: $line-height-sm;
              padding: $half $normal;
              width: 100%;
              display: flex;
            }
          }
        }
      }
    }
  }
  .caret {
    pointer-events: none;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
.footer-cart-visible{
  margin-bottom: 4.5rem;
}
.footer-cart-annual-fee{
  margin-bottom: 5.75rem;
}
  :deep(.container.footer-left-bar) {
    max-width: 100%;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    .social-bar {
      justify-content: center;
      a {
        margin: 0 1rem;
      }
    }
  }
  :deep(.footer-bottom) {
    .content-holder{
      padding: 1.5rem;
    }
    .footer-bottom-nav {
      ul {
        li.nav-item {
          a.nav-link {
            margin: 0 1rem 0 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .footer-cart-visible{
  margin-bottom: 5rem;
}
.footer-cart-annual-fee{
  margin-bottom: 5.25rem;
}
  :deep(.footer-bottom) {
    .content-holder {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .copyright {
      margin-top: 1rem;
    }
    .footer-bottom-nav {
      ul {
        flex-direction: column;
        justify-content: center;
        li.nav-item {
          margin-bottom: 1rem;
          a.nav-link {
            margin: 0;
            text-align: center;
          }
        }
      }
    }
  }
  :deep(.container-xxl) {
    padding: 1rem;
  }
}
@media screen and (max-width: 23.4375rem) {
  .footer-cart-visible{
    margin-bottom: 0;
  }
}
</style>
