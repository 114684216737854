const ACCEPT_FILE_TYPES = {
  IMAGE: ['png', 'jpg', 'jpeg', 'tif', 'tiff'],
  DOCUMENT: [
    'xls',
    'xlsx',
    'wmv',
    'wav',
    'pdf',
    'txt',
    'doc',
    'docx',
    'html',
    'htm',
  ],
}

function getFileFormat(file) {
  const fileParts = file.name.split('.')
  return fileParts.length > 0 ? fileParts[fileParts.length - 1].toLowerCase() : ''
}

function createFileFromBase64(base64String, filename) {
  // Remove data URL prefix (e.g., "data:image/jpeg;base64,")
  const dataUrlPrefix = "data:";
  const base64Index = base64String.indexOf(dataUrlPrefix) + dataUrlPrefix.length;
  const pureBase64String = base64String.substring(base64Index);

  // Convert base64 string to bytes
  const byteCharacters = atob(pureBase64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create file blob from byte array
  const blob = new Blob([byteArray], { type: "application/octet-stream" });

  return blob;
}

export {createFileFromBase64, getFileFormat, ACCEPT_FILE_TYPES }
