<template>
  <main class="container-fluid"> 
    <router-view v-if="showPhoneCallback" />
    <MinicalculatorIFrame v-if="showMinicalculator" />
    <LoadingOverlay :is-loading="showLoadingIndicator" />
  </main>
</template>

<script setup>
import { ref, onMounted, onUpdated, provide } from '@vue/runtime-core'
import { useRoute } from 'vue-router'

const showPhoneCallback = ref(false)
const showMinicalculator = ref(false)
const callbackComponentName = ref(null)
const route = useRoute()

const showLoadingIndicator = ref(false)
const showCallbackThankYouPage = ref(false)
const DateTime = window.luxon.DateTime

function getFormattedNumber(value, showZero = false) {
  if (!value) {
    return showZero ? '0' : ''
  }

  let valueString = value.toString().replace(/\s+/g, '')

  var parts = valueString.split(',')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  if (parts[1]) {
    let decPart = parseInt(parts[1])

    if (decPart) {
      parts[1] = decPart.toFixed(2)
    }
  }

  return parts.join(',')
}
function getDateFormater(dateValue) {
  let date = DateTime.fromISO(dateValue).toFormat('yyyy.MM.dd')
  return date
}
provide('showLoadingIndicator', showLoadingIndicator);
provide('util', {
  getFormattedNumber,
  getDateFormater,
})
onMounted(() => {
  if(window.location.pathname.split('/')[2] === 'visszahivast-kerek'){
    callbackComponentName.value = route.path.split('/')[1]
    showPhoneCallback.value = true
  }
  else {
    showMinicalculator.value = true
  }
})

</script>

<style></style>
