<template>
  <q-expansion-item
    :duration="100"
    :expand-icon="getExpandedIconImg('/caret-down.svg')"
    :expanded-icon="getExpandedIconImg('/caret-up.svg')"
    :default-opened="isDefaultOpened"
    header-class="accordion-header"
    class="calculator-accordion"
  >
    <template #header>
      <div class="accordion-header-inner">
        <div class="holder-left">
          <div class="accordion-icon-holder">
            <slot name="accordion-icon" />
          </div>
          <div class="accordion-text-holder">
            <h3 class="accordion-title">
              <slot name="accordion-title" />
            </h3>
          </div>
        </div>
      </div>
    </template>
    <div class="inner-item">
      <slot name="inner" />
    </div>
  </q-expansion-item>
</template>
<script setup>
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper'

const componentUrl = import.meta.url

const props = defineProps({
  isDefaultOpened: {
    type: Boolean,
    default: false,
  },
})

function getExpandedIconImg(img) {
  return 'img:' + imageUrlHelper.getImageUrl(img, componentUrl) + ''
}
</script>
<style lang="scss" scoped>
.calculator-accordion {
  :deep(.q-expansion-item__container) {
    padding: 2.5rem;
    background: #f7f7fb;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
  :deep(.accordion-header) {
    display: flex;
    flex-direction: row;
    flex-wrap: unset;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    .accordion-icon-holder {
      display: flex;
      align-items: center;
    }
    .accordion-header-inner {
      display: flex;
      width: unset;
      width: calc(100% - 1.5rem);
      .holder-left {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        .accordion-title {
          margin: 0;
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1.75rem;
        }
      }
    }
    .q-item__section {
      height: unset;
    }
  }
  .inner-item {
    display: flex;
    justify-content: right;
    gap: 1.5rem;
    align-items: end;
    width: auto;
    flex-direction: column;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .calculator-accordion {
    :deep(.q-expansion-item__container) {
      padding: 2rem;
    }
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .calculator-accordion {
    :deep(.q-expansion-item__container) {
      gap: 2rem;
      padding: 1rem;
    }
  }
}
</style>
