<template>
  <section 
    id="discounts"
    class="discounts-section"
  >
    <div class="container-xxl">
      <Swiper
        :modules="modules"
        class="mySwiper"
        :slides-per-view="1"
        :free-mode="false"
        :centered-slides="false"
        :breakpoints="breakpoints"
      >
        <SwiperSlide
          v-for="(slide, index) in slides"
          :key="index"
          class="card-discount"
        >
          <div
            class="wrapperzg"
            :class="{ [highlighted]: Indices.includes(index) }"
          >
            <div
              v-if="slide.percentage && slide.percentage !== ''"
              class="card-percentage"
            >
              {{ slide.percentage }}
            </div>
            <div
              v-if="!slide.percentage"
              class="card-percentage empty"
            >
              {{ slide.percentage }}
            </div>
            <div class="card-content">
              <div>
                <div class="card-title h2">
                  {{ slide.title }}
                </div>
                <div class="card-description">
                  {{ slide.description }}
                </div>
              </div>
              <div
                v-if="Indices.includes(index)"
                class="card-read-more-text"
              >
                {{ slide.details }}
              </div>
              <a
                v-if="
                  slide.details !== '' &&
                    slide.details !== null &&
                    slide.details !== undefined
                "
                class="read-more d-flex text-secondary text-bold"
                @click="showItems(index)"
              >
                <div class="text-sm">Részletek</div>
                <div>
                  <PhIconManager
                    ph-icon="ph-caret-down"
                    :size="20"
                  />
                </div>
              </a>
              <q-btn
                v-if="
                  slide.percentage === undefined && slide.link !== undefined
                "
                :href="slide.link"
                target="_blank"
                class="btn btn-secondary btn-sm"
              >
                {{ slide.inMoreDetail }}
              </q-btn>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/navigation'
// import required modules
import { FreeMode, Navigation } from 'swiper'

const props = defineProps({
  slides: {
    type: Object,
    required: true,
  },

  breakpoints: {
    type: Object,
    required: true,
  },
})

const Indices = ref([])
const highlighted = ref('')
const minHeight = ref(null)
function showItems(index) {
  if (!Indices.value.includes(index)) {
    Indices.value = []
    Indices.value.push(index)
    highlighted.value = 'highlighted'
  } else {
    Indices.value = []
    highlighted.value = ''
  }
}
onMounted(() => {
  getHighest()
})

function getHighest() {
  let biggestHeight = 0

  let elements = document.querySelectorAll('.wrapperzg')

  elements.forEach(function (element, index) {
    biggestHeight =
      element.offsetHeight > biggestHeight
        ? element.offsetHeight
        : biggestHeight
  })
  minHeight.value = biggestHeight + 'px'
}
</script>

<script>
export default {
  name: 'DiscountsModule',
}
</script>

<style lang="scss" scoped>
section.discounts-section {
  padding-bottom: 0 !important;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem !important;

  .swiper,
  .swiper-wrapper {
    overflow: visible;
  }
  .card-discount {
    display: flex;
    height: auto;
    .highlighted.wrapperzg {
      position: absolute;
      background-color: white;
      width: 100%;
      svg {
        transition: transform 0.125s linear;
        transform: rotate(180deg);
      }
    }
    .wrapperzg {
      min-height: v-bind(minHeight);
      background: #f7f7fb;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: auto;
      text-align: center;
      padding: 0rem 1.5rem 2rem 1.5rem;
      align-items: center;
      box-shadow: 0rem 0.625rem 0.9375rem rgba(0, 0, 0, 0.1),
        0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.05);
      border: none;
      border-radius: 0.75rem;
      margin-top: -3.75rem;
      .btn-sm {
        margin-top: 1.5rem;
      }
      a.read-more {
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
        .text-sm {
          font-size: 0.875rem;
          line-height: 1.5rem;
        }
      }
      .card-read-more-text {
        margin-top: 1.5rem;
      }
      svg {
        margin-left: 0.5rem;
        transition: transform 0.125s linear;
        transform: rotate(0deg);
      }
      .card-percentage {
        background: #ecd3ef;
        padding: 0.75rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 600;
        color: #811d8a;
        border-radius: 0.75rem;
        margin-bottom: 0.875rem;
        width: 8.75rem;
        margin-top: -1.625rem;
        &.empty {
          min-height: 3.25rem;
          visibility: hidden;
        }
      }
      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center !important;
        flex: 1;
        .card-title {
          color: #811d8a;
          font-size: 1.25rem;
          line-height: 1.75rem;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .card-description {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 600;
          color: $dark;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  section.discounts-section {
    padding-bottom: 0 !important;

    .container-xxl {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    .card-discount .wrapperzg {
      box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06),
        0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
      margin: 0;
      .card-percentage {
        margin-top: -1.625rem;
      }
    }
  }
}
@media screen and (min-width: $breakpoint-md) {
  .discounts-section {
    margin-bottom: -6.25rem;
    margin-top: -6.25rem;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  section.discounts-section {
    padding-bottom: 0.125rem !important;
    .container-xxl {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  section.discounts-section {
    padding-top: 2.5rem !important;
    .container-xxl {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .card-discount .wrapperzg {
      box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
        0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
      margin-top: 0;
      padding: 0rem 1rem 1rem 1rem;

      .card-percentage {
        font-size: 1.125rem;
        margin-top: -1.625rem;
      }
      .card-description {
        word-break: break-word;
      }
    }

    .btn-sm {
      width: 100%;
    }
  }
}

@media screen and (min-width: 567px) and (max-width: $breakpoint-xs-max) and (orientation: landscape) {
  .discounts-section {
    padding-top: 3rem !important;
    .container-xxl {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .card-discount .wrapperzg {
      width: 22.5rem !important;
      box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
        0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
      margin-top: 0;
      padding: 0rem 1rem 1rem 1rem;

      .card-percentage {
        font-size: 1.125rem;
        margin-top: -1.625rem;
      }
    }
  }
}
</style>
