import { defineStore } from 'pinia'
import axios from 'axios'

export const useSearchStore = defineStore('search', {
    state: () => {
        return {
            searchPhrase: '',
            searchResults: []
        }
    },
    getters: {
        getSearchPhrase(state) {
            return state.searchPhrase
        },
        getSearchResults(state) {
            return state.searchResults
        }
    },
    actions: {
        setSearchPhrase(value) {
            this.searchPhrase = value;
            this.fetchSearchResults();
        },
        async fetchSearchResults(){
            if (this.getSearchPhrase.length >= 2) {
                let searchResponse = await axios.get(
                  import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
                    `/Search?phrase=${this.getSearchPhrase}`
                )  
                this.searchResults = searchResponse.data
              }
        }
    }

});