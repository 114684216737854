<template>
  <!-- Validációnek kell vezérelnie a bindot -->
  <div
    :id="id"
    :class="veeErrors[0] ? 'fix-height-validation' : 'fix-height'"
  >
    <div v-sticky-parent>
      <div v-calculatorsticky>
        <div class="row head-row">
          <div class="col-2" />
          <div
            v-if="viewModel.MNBMode == undefined"
            id="extraPackage"
            class="col field-content first"
            :class="selectedPackage == 3 ? 'highlighted package-selected' : ''"
          >
            <div
              class="package-head first"
              :class="isGTDisabled ? 'disabled-package' : ''"
            >
              <div class="package-name">
                Extra Lakásbiztosítás
              </div>
              <div class="package-price-holder first">
                <div class="package-period-price">
                  {{
                    getFormattedNumber(viewModel.Packages.Extra.FeePart) +
                      ' Ft ' +
                      getPeriodText()
                  }}
                </div>
                <div
                  v-if="
                    viewModel.Packages.Extra.AnnualFee !==
                      viewModel.Packages.Extra.FeePart
                  "
                  class="package-annual-price"
                >
                  {{
                    getFormattedNumber(viewModel.Packages.Extra.AnnualFee) +
                      ' Ft/év '
                  }}
                </div>
              </div>
              <div class="package-radio-input-holder">
                <div class="radio-input">
                  <q-radio
                    v-model="selectedPackage"
                    :disable="isGTDisabled"
                    val="3"
                    :label="
                      isGTDisabled
                        ? 'Nem választható'
                        : selectedPackage != 3
                          ? 'Ezt választom'
                          : 'Kiválasztva'
                    "
                    @update:model-value="packageSelected"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="viewModel.MNBMode == undefined"
            id="komfortPackage"
            class="col field-content"
            :class="selectedPackage == 2 ? 'highlighted package-selected' : ''"
          >
            <div
              class="package-head"
              :class="isGTDisabled ? 'disabled-package' : ''"
            >
              <div
                v-if="!isGTDisabled"
                class="recommended-package"
              >
                AJÁNLOTT
              </div>
              <div class="package-name">
                Komfort Lakásbiztosítás
              </div>
              <div class="package-price-holder">
                <div class="package-period-price">
                  {{
                    getFormattedNumber(viewModel.Packages.Komfort.FeePart) +
                      ' Ft ' +
                      getPeriodText()
                  }}
                </div>
                <div
                  v-if="
                    viewModel.Packages.Komfort.AnnualFee !==
                      viewModel.Packages.Komfort.FeePart
                  "
                  class="package-annual-price"
                >
                  {{
                    getFormattedNumber(viewModel.Packages.Komfort.AnnualFee) +
                      ' Ft/év '
                  }}
                </div>
              </div>
              <div class="package-radio-input-holder">
                <div class="radio-input">
                  <q-radio
                    v-model="selectedPackage"
                    :disable="isGTDisabled"
                    val="2"
                    :label="
                      isGTDisabled
                        ? 'Nem választható'
                        : selectedPackage != 2
                          ? 'Ezt választom'
                          : 'Kiválasztva'
                    "
                    @update:model-value="packageSelected"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="viewModel.MNBMode == undefined"
            id="startPlusPackage"
            class="col field-content"
            :class="selectedPackage == 1 ? 'highlighted package-selected' : ''"
          >
            <div
              class="package-head"
              :class="isGTDisabled ? 'disabled-package' : ''"
            >
              <div class="package-name">
                Start+ Lakásbiztosítás
              </div>
              <div class="package-price-holder">
                <div class="package-period-price">
                  {{
                    getFormattedNumber(viewModel.Packages.StartPlus.FeePart) +
                      ' Ft ' +
                      getPeriodText()
                  }}
                </div>
                <div
                  v-if="
                    viewModel.Packages.StartPlus.AnnualFee !==
                      viewModel.Packages.StartPlus.FeePart
                  "
                  class="package-annual-price"
                >
                  {{
                    getFormattedNumber(viewModel.Packages.StartPlus.AnnualFee) +
                      ' Ft/év '
                  }}
                </div>
              </div>
              <div class="package-radio-input-holder">
                <div class="radio-input">
                  <q-radio
                    v-model="selectedPackage"
                    :disable="isGTDisabled"
                    val="1"
                    :label="
                      isGTDisabled
                        ? 'Nem választható'
                        : selectedPackage != 1
                          ? 'Ezt választom'
                          : 'Kiválasztva'
                    "
                    @update:model-value="packageSelected"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            id="startPackage"
            class="col field-content last"
            :class="
              selectedPackage == 0 && viewModel.MNBMode == undefined
                ? 'highlighted package-selected'
                : ''
            "
          >
            <div
              class="package-head"
              :class="[
                isMFODisabled ? 'disabled-package' : '',
                viewModel.MNBMode != null &&
                  viewModel.MNBMode != undefined &&
                  viewModel.MNBMode == true
                  ? 'mnb-package-head'
                  : '',
              ]"
            >
              <div class="package-name">
                Start Minősített Fogyasztóbarát Otthonbiztosítás
              </div>
              <div class="package-price-holder last">
                <div class="package-period-price">
                  {{
                    getFormattedNumber(viewModel.Packages.Start.FeePart) +
                      ' Ft ' +
                      getPeriodText()
                  }}
                </div>
                <div
                  v-if="
                    viewModel.Packages.Start.AnnualFee !==
                      viewModel.Packages.Start.FeePart
                  "
                  class="package-annual-price"
                >
                  {{
                    getFormattedNumber(viewModel.Packages.Start.AnnualFee) +
                      ' Ft/év '
                  }}
                </div>
              </div>
              <div
                v-if="viewModel.MNBMode == undefined"
                class="package-radio-input-holder last"
              >
                <div class="radio-input">
                  <q-radio
                    v-model="selectedPackage"
                    :disable="isMFODisabled"
                    val="0"
                    :label="
                      isMFODisabled
                        ? 'Nem választható'
                        : selectedPackage != 0
                          ? 'Ezt választom'
                          : 'Kiválasztva'
                    "
                    @update:model-value="packageSelected"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="veeErrors[0]"
          class="row validation-row"
        >
          <div class="col-2" />

          <div class="col validation-col">
            <ValidationMessage
              :messages="['Kérem, válasszon csomagot a továbblépéshez!']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, inject } from 'vue'
const { callCalculate } = inject('wizard')
let viewModel = inject('viewModel')
const texts = inject('texts')
const selectedPackage = ref(null)
const emit = defineEmits(['packageSelected'])
const { getFormattedNumber } = inject('util')
const { GtHouseExclusion, MFOExclusion } = inject('exclusion')
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')
import { useField } from 'vee-validate'
import { useFormChild } from 'quasar'
import { customDefineRule } from '@/utils/validations/customDefineRule'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  warningMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  modelValue: {
    type: Boolean,
    required: false,
    default: null,
  },
})

onMounted(() => {
  setSelectedPackageInitInputValue()
})
function setSelectedPackageInitInputValue() {
  if (viewModel.value.Packages.Extra.Selected.Value == true) {
    selectedPackage.value = '3'
    packageSelected(3)
  }
  if (viewModel.value.Packages.Komfort.Selected.Value == true) {
    selectedPackage.value = '2'
    packageSelected(2)
  }
  if (viewModel.value.Packages.StartPlus.Selected.Value == true) {
    selectedPackage.value = '1'
    packageSelected(1)
  }
  if (viewModel.value.Packages.Start.Selected.Value == true) {
    selectedPackage.value = '0'
    packageSelected(0)
  }
}
watch(
  () => viewModel.value.ContentDiscount.Value,
  (value, oldValue) => {
    if (isMFODisabled.value) {
      if (selectedPackage.value == '0') {
        selectedPackage.value = null
        packageSelected(-1)
      }
    }
  }
)
watch(
  () => isGTDisabled.value,
  (value, oldValue) => {
    if (
      selectedPackage.value == '1' ||
      selectedPackage.value == '2' ||
      selectedPackage.value == '3'
    ) {
      selectedPackage.value = null
      packageSelected(-1)
    }
  }
)
watch(
  () => viewModel.value.Cart.Payment.PaymentMode.Value,
  (value, oldValue) => {
    if (isGTDisabled.value) {
      if (
        (viewModel.value.Cart.Payment.PaymentMode.Value.value === 8 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 7 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 1) &&
        viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 5 &&
        !isMFODisabled.value
      ) {
        if (selectedPackage.value != '0') {
          selectedPackage.value = null
          packageSelected(-1)
        }
      }
    }
  }
)
watch(
  () => viewModel.value.Cart.Payment.PaymentPeriod.Value,
  (value, oldValue) => {
    if (isGTDisabled.value) {
      if (
        (viewModel.value.Cart.Payment.PaymentMode.Value.value === 8 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 7 ||
          viewModel.value.Cart.Payment.PaymentMode.Value.value === 1) &&
        viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 5 &&
        !isMFODisabled.value
      ) {
        if (selectedPackage.value != '0') {
          selectedPackage.value = null
          packageSelected(-1)
        }
      }
    }
  }
)
async function packageSelected(value) {
  emit('packageSelected', value)
  validateQForm()
}
customDefineRule('VAL_MFOPACKAGE', (value) => {
  if (
    viewModel.value.Packages.Start.Selected.Value ||
    viewModel.value.Packages.StartPlus.Selected.Value ||
    viewModel.value.Packages.Komfort.Selected.Value ||
    viewModel.value.Packages.Extra.Selected.Value
  ) {
    return true
  }
  return 'Kérem, válasszon csomagot a továbblépéshez!'
})
const {
  handleChange,
  errors: veeErrors,
  value,
  validate: veeValidate,
} = useField(props.id, 'VAL_MFOPACKAGE', {
  validateOnValueUpdate: false,
  bails: true,
})
function resetValidationQForm() {}

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
async function validateQForm() {
  // run vee-validate validations
  await veeValidate()
  if (veeErrors.value.length > 0) {
    return false
  } else {
    return true
  }
}
function getPeriodText() {
  if (viewModel.value.Cart.Payment.PaymentPeriod.Value) {
    if (
      viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 1 ||
      viewModel.value.Cart.Payment.PaymentPeriod.Value === 1
    ) {
      return '/év'
    } else if (
      viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 2 ||
      viewModel.value.Cart.Payment.PaymentPeriod.Value === 2
    ) {
      return '/félév'
    } else if (
      viewModel.value.Cart.Payment.PaymentPeriod.Value.value === 4 ||
      viewModel.value.Cart.Payment.PaymentPeriod.Value === 4
    ) {
      return '/negyedév'
    } else return '/hónap'
  }
}
watch(
  () => [viewModel.value.Cart.Payment.PaymentMode.Value, viewModel.value.Cart.Payment.PaymentPeriod.Value, viewModel.value.ContentDiscount.Value],
  (value, oldValue) => {
    isMFODisabled.value = MFOExclusion()
    isGTDisabled.value = GtHouseExclusion()
  }
)
</script>

<style lang="scss" scoped>
.sticky-calculator {
  max-width: 71.5rem;

  .col-2 {
    background: white;
  }
  .recommended-package {
    display: none;
  }
}
:deep(.q-radio__inner.relative-position.q-radio__inner--truthy) {
  color: #4e95fb;
}
:deep(.q-radio__inner.relative-position) {
  width: fit-content;
}
:deep(.q-radio__label) {
  flex: 1;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #111a37;
}

:deep(.q-option-group.q-gutter-x-sm) {
  margin-left: 0;
}

:deep(.q-option-group) {
  display: flex;
  flex-direction: row;
}
:deep(.q-gutter-x-sm > *, .q-gutter-sm > *) {
  margin-left: 0;
}

.radio-input {
  .q-field__messages.col {
    line-height: 1.25rem;
  }
  .q-field--with-bottom {
    padding-bottom: 1.5rem;
  }
}
.validation-row {
  .validation-col {
    padding: 0.5rem;
    text-align: center;
    border-left: 0.0625rem solid #dde1ef;
    border-right: 0.0625rem solid #dde1ef;
    border-bottom: 0.0625rem solid #dde1ef;
    background: white;
    :deep(.error) {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
.head-row {
  .first {
    border-radius: 0.25rem 0rem 0rem 0rem;
  }
  .last {
    border-radius: 0rem 0.25rem 0rem 0rem;
  }
}

.col.field-content {
  background: #dde1ef;
}

.package-head {
  .package-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    height: 5.75rem;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1d2c5b;
    border-left: 0.0625rem solid white;
  }
  &.first {
    .package-name {
      border-left: 0.0625rem solid #dde1ef;
      border-radius: 0.25rem 0rem 0rem 0rem;
    }
  }
  .package-price-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #ffffff;
    border-left: 0.0625rem solid #dde1ef;
    &.last {
      border-right: 0.0625rem solid #dde1ef;
    }
    .package-period-price {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25rem;
    }
    .package-annual-price {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .package-radio-input-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #ffffff;
    border-left: 0.0625rem solid #dde1ef;
    border-top: 0.0625rem solid #dde1ef;
    border-bottom: 0.0625rem solid #dde1ef;

    &.last {
      border-right: 0.0625rem solid #dde1ef;
    }
  }
}

.package-head.mnb-package-head {
  .package-price-holder {
    border-bottom: 0.0625rem solid #dde1ef;
  }
}

.col.field-content.package-selected {
  background: #2f5997;
  .package-name {
    color: #ffffff;
  }
}
.recommended-package {
  text-align: center;
  border-left: 0.0625rem solid #fff;
  padding: 0.25rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.6875rem;
  line-height: 0.75rem;
  background: #1d2c5b;
  margin-top: -1.25rem;
}
.fix-height-validation.sticky {
  height: 15rem;
}
.fix-height.sticky {
  height: 12.5rem;
}
.disabled-package {
  .q-radio.cursor-pointer.no-outline.row.inline.no-wrap.items-center.disabled {
    margin-right: 0 !important;
  }
  span.no-outline {
    display: none;
  }
}
</style>
