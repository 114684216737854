/* eslint-disable vue/v-on-event-hyphenation */
<template>
  <Swiper
    :navigation="{
      nextEl: '.btn-next',
      prevEl: '.btn-prev',
    }"
    :modules="modules"
    class="mySwiper"
    :class="[showRedLine ? 'redline' : '']"
    :slides-per-view="1"
    :free-mode="false"
    :centered-slides="false"
    :breakpoints="breakpoints"
    @reachEnd="showRightArrow = false"
    @reachBeginning="showLeftArrow = false"
    @fromEdge="toggleArrows"
  >
    <div
      v-show="showLeftArrow"
      class="btn-swiper btn-prev"
    >
      <img
        :src="`${imageUrlHelper.getImageUrl('/left.svg', componentUrl)}`"
        alt="alt"
      >
    </div>
    <div
      v-show="showRightArrow"
      class="btn-swiper btn-next"
    >
      <img
        :src="`${imageUrlHelper.getImageUrl('/right.svg', componentUrl)}`"
        alt="alt"
      >
    </div>
    <SwiperSlide
      v-for="(slide, index) in slides"
      :key="index"
      class="card p-0"
    >
      <div
        :class="isBlog ? 'card-image gtmBlog' : 'card-image'"
        :style="{
          backgroundImage: `url(${
            isBlog
              ? slide.imageUrl
              : imageUrlHelper.getImageUrl(slide.picture, componentUrl)
          })`,
        }"
      />
      <div class="card-content p-d">
        <Title
          htag="h2"
          :class="isBlog ? 'xsmall gtmBlog' : 'xsmall'"
        >
          {{ slide.title }}
        </Title>
        <p v-if="!isBlog && slide.description">
          {{ slide.description }}
        </p>
        <p
          v-if="isBlog"
          v-sanitize="slide.description"
          class="blogitemdescription"
        />
        <a
          v-if="isBlog"
          :href="slide.link"
          class="d-flex text-secondary gtmBlog"
          target="_blank"
        >
          <div class="text-sm">Részletek</div>
          <PhIconManager ph-icon="ph-arrow-right" />
        </a>
        <a
          v-if="!isBlog && slide.newsUrl"
          :href="slide.newsUrl"
          class="d-flex text-secondary"
          target="_blank"
        >
          <div class="text-sm">Részletek</div>
          <PhIconManager ph-icon="ph-arrow-right" />
        </a>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script setup>
import { ref } from 'vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/navigation'
import { FreeMode, Navigation } from 'swiper'
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper'

const props = defineProps({
  slides: {
    type: Object,
    required: true,
  },

  showRedLine: {
    type: Boolean,
    default: true,
  },

  breakpoints: {
    type: Object,
    required: true,
  },

  isBlog: {
    type: Boolean,
    default: false,
  },
})

const modules = [FreeMode, Navigation]

const showRightArrow = ref(true)
const showLeftArrow = ref(false)
const componentUrl = import.meta.url

function toggleArrows() {
  if (!showRightArrow.value) {
    showRightArrow.value = true
  }
  if (!showLeftArrow.value) {
    showLeftArrow.value = true
  }
}
</script>

<script>
export default {
  name: 'CarouselSwiper',
}
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide.card {
  background-color: white;
  display: flex;
  border-radius: 0.75rem;

  .card-content {
    height: 14.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;

    a {
      justify-content: flex-end;
    }

    a .text-sm {
      font-weight: $font-weight-bold;
      margin-right: $half;
    }

    p {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }

  .card-image {
    background-size: cover;
    height: 11.25rem;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
  }
}

.redline .swiper-slide.card {
  .card-content {
    height: 11.75rem;
    padding: 2rem;

    a {
      justify-content: flex-start;
    }

    a .text-sm {
      font-weight: $font-weight-bold;
      margin-right: $half;
    }
  }

  .card-image {
    height: 19.5rem;
    border-bottom: $small solid $primary;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-swiper {
  position: absolute;
  z-index: 9999;
  top: 50%;
  cursor: pointer;

  &.btn-next {
    right: 0;
  }

  &.btn-prev {
    left: 0;
  }
}

.swiper,
.swiper-wrapper {
  overflow: visible;
}

@media screen and (max-width: $breakpoint-lg-max) {
  .swiper-slide {
    h3.xsmall {
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: $small;
    }

    p {
      max-height: 6.25rem;
      overflow: hidden;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .swiper-slide {
    .card-image {
      height: 11.25rem !important;
    }

    .card-content {
      padding: $normal;
      height: 15.75rem !important;
    }
  }

  .btn-swiper {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .swiper-slide {
    .card-image {
      height: 11.25rem !important;
    }

    .card-content {
      padding: $normal;
      height: 17rem !important;
    }

    h3.xsmall {
      font-size: 1.125rem;
      line-height: 1.25rem;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .redline .swiper-slide {
    .card-image {
      height: 11.25rem !important;
    }

    .card-content {
      padding: $normal !important;
      height: 9.75rem !important;
    }

    .xsmall {
      font-size: 1.125rem;
      line-height: 1.25rem;
    }
  }
}
</style>
