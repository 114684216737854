<template>
  <q-select
    :id="id"
    ref="mySelect"
    v-model="displayValue"
    :options="options"
    standout
    :error="!!veeErrors[0]"
    :class="[
      displayValue && isAutocomplete ? 'hasValue ' + customClass : customClass,
      warningMessages?.length > 0 && veeErrors.length == 0 ? 'has-warning' : '',
    ]"
    virtual-scroll-item-size="32"
    bg-color="white"
    :disable="disable"
    label-slot
    emit-value
    map-options
    use-input
    behavior="menu"
    :loading="loadingItems"
    input-debounce="500"
    @filter="filterFn"
    @update:model-value="
      handleChange($event), handleInfoWarningMessages(value), inputChanged()
    "
    @focus="onFocus, setMetaTouched"
    @keydown="keyDown"
    @popup-hide="setSingleResultZip"
    @blur="clearPreventionInput"
  >
    <template #label>
      {{ label }}
      <Tooltip :tooltip-content="tooltipContent" />
    </template>
    <!-- <template #no-option>
      <q-item>
        <q-item-section class="text-grey"> No results </q-item-section>
      </q-item>
    </template> -->
    <template #error>
      <ValidationMessage
        :messages="veeErrors"
        :info-messages="infoMessages"
        :warning-messages="warningMessages"
      />
    </template>
  </q-select>
</template>

<script setup>
import { onMounted, ref, watch, inject } from 'vue'
import axios from 'axios'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  label: {
    type: String,
    required: true,
  },

  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },

  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  warningMessageRules: {
    type: String,
    required: false,
    default: '',
  },

  modelValue: {
    type: String,
    required: false,
    default: '0',
  },
  options: {
    type: Object,
    required: false,
    default: () => {},
  },
  maxLength: {
    type: Number,
    default: -1,
  },
  disable: {
    type: Boolean,
    default: false,
  },
  poZip: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue',
  'settlementName',
  'inputChanged',
])

const removeValueIfNotValid = inject('_removeValueIfNotValid')

const model = ref(null)
const options = ref([])
const loadingItems = ref(false)
const infoMessages = ref(null)
const warningMessages = ref(null)
const displayValue = ref(null)
const data = ref(null)
const iValue = ref(0)
const zipCount = ref(0)
const isSingleResultZipDisabled = ref(true)
const mySelect = ref(null)
onMounted(() => {
  if (props.modelValue !== null && props.modelValue !== '') {
    displayValue.value = props.modelValue
  }
})
function onFocus() {
  displayValue.value = null
}
watch(
  () => props.modelValue,
  (newValue) => {
    displayValue.value = newValue
  }
)

function setSingleResultZip() {
  if (zipCount.value == 1 && !isSingleResultZipDisabled.value) {
    value.value = data.value[0].text
    inputChanged()
    displayValue.value = data.value[0].text
    validateQForm()
  }
  const inputElement = mySelect.value.$el.querySelector('input')

  inputElement.value = ''
  let event = new Event('input')
  inputElement.dispatchEvent(event) // Dispatch an input event to let Vue know about the change
}

async function getZipCode(value) {
  isSingleResultZipDisabled.value = false
  loadingItems.value = true
  let response;
  
  if (props.poZip != undefined && props.poZip) {
    response = await axios.get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Addresses/PostOfficeZipCode',
      {
        params: { filter: value },
      }
    )    
  }
  else {
    response = await axios.get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Addresses/ZipCode',
      {
        params: { zipCode: value },
      }
    )
  }  

  loadingItems.value = false
  options.value.splice(0)
  response.data.zipCodes.forEach((e) => options.value.push(e.text))
  data.value = response.data.zipCodes
  zipCount.value = data.value.length
}

function filterFn(value, update, abort) {
  if (isNaN(value)) {
    if (value.length < 3) {
      abort()
      return
    }
  } else if (value.length < 4) {
    abort()
    return
  }
  update(async () => {
    await getZipCode(value)
  })
}

async function validateQForm() {
  // run vee-validate validations

  if (value.value === undefined && displayValue.value !== null) {
    value.value = displayValue.value
  }

  await veeValidate()

  if (veeErrors.value.length > 0) {
    if (removeValueIfNotValid.value && !meta.valid && meta.touched) {
      value.value = null
      inputChanged()
      return true
    }
    return false
  } else {
    return true
  }
}

function inputChanged() {
  inputChangedTrigger.value = true

  let zipData = {}
  if (value.value !== null) {
    let indexOfValue = data.value.findIndex((item) => value.value == item.text)
    displayValue.value =
      value.value === null ? null : data.value[indexOfValue].text

    zipData = {
      settlement: data.value[indexOfValue].settlement,
      zipCode: data.value[indexOfValue].zipCode,
      text: data.value[indexOfValue].text,
    }
  } else {
    displayValue.value = value.value
    zipData = {
      settlement: null,
      zipCode: null,
      text: null,
    }
  }

  emit('update:modelValue', value.value)
  emit('settlementName', zipData)
  emit('inputChanged')
  validateQForm()
  if (value.value === null) {
    isSingleResultZipDisabled.value = true
  }
}

const keyString = ref('')
const inputChangedTrigger = ref(false)

function keyDown(e) {
  e = e || window.event

  const numberRegex = /^[0-9]$/
  const textRegex = /^[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/

  const isDeleteOrBackspace = e.keyCode === 8 || e.keyCode === 46

  if (inputChangedTrigger.value || value.value !== null) {
    if (isDeleteOrBackspace) {
      keyString.value = ''
      return
    }
  }

  if (keyString.value.length > 0) {
    const firstChar = keyString.value[0]
    const isNumber = numberRegex.test(firstChar)
    const isValidTextChar = textRegex.test(e.key)
    const isValidNumberChar = numberRegex.test(e.key)
    if (isDeleteOrBackspace ) {
      keyString.value = keyString.value.slice(1)
    } else if (isNumber && keyString.value.length < 4 && isValidNumberChar && e.key.length == 1) {
      keyString.value += e.key
    } else if (!isNumber && keyString.value.length < 30 && isValidTextChar && e.key.length == 1) {
      keyString.value += e.key
    } else {
      e.preventDefault()
      return
    }
  } else if (
    (textRegex.test(e.key) || numberRegex.test(e.key)) &&
    !isDeleteOrBackspace && e.key.length == 1
  ) {
    keyString.value += e.key
  } else if (!isDeleteOrBackspace) {
    e.preventDefault()
  }

  inputChangedTrigger.value = false
}

function clearPreventionInput(e) {
  keyString.value = ''
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules
  )
}

function setMetaTouched() {
  meta.touched = true
}

const {
  handleChange,
  errors: veeErrors,
  value,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})

watch(
  () => value.value,
  () => {
    inputChangedTrigger.value = true
  }
)

</script>

<script>
export default {
  name: 'ZipCodeInput',
}
</script>

<style lang="scss" scoped>
.hasValue {
  :deep(.q-field__input) {
    display: none !important;
  }
}
:deep(.q-field--standout) {
  &.q-field * {
    border-width: 0 !important;
  }
  .q-field__label {
    color: #4a567c !important;
  }
  .q-field__control:before {
    background-color: transparent;
  }
  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
  }

  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }

  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }

  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }

  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 1px solid $border-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 0.125rem solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }

  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;

  svg,
  svg * {
    color: $N-600 !important;
  }
}

:deep(.q-field__append.q-field__marginal.row.no-wrap.items-center) {
  width: auto;
}

:deep(.q-field__control-container.col.relative-position.row.no-wrap.q-anchor--skip) {
  font-size: 0.875rem;
  span {
    line-height: 1.25rem;
    color: #0c1224;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

:deep(.q-field__label.no-pointer-events.absolute.ellipsis) {
  color: #4a567c !important;
  order: 1;
  position: absolute;
}
:deep(.q-field__native.row.items-center) {
  order: 2;
}
.q-field--standout.q-select.q-field--float:hover {
  :deep(.q-field__control) {
    border: 1px solid $border-normal;
  }
}
.q-field--standout.q-select.q-field--float.q-field--focused:hover {
  :deep(.q-field__control) {
    border: 1px solid $border-active;
  }
}
.q-field--disabled {
  :deep(.q-field__control) {
    background-color: #eef0f7 !important;
    .q-field__label {
      color: #b4b9cb !important;
    }
  }
}
</style>
