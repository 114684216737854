<template>
  <section
    id="faq"
    class="faq-section"
  >
    <div class="container-xxl">
      <Title
        htag="h2"
        class="medium text-center"
      >
        {{ moduleTitle }}
      </Title>
      <template
        v-for="(faq, index) in faqItemsData"
        :key="index"
      >
        <q-expansion-item
          v-if="index < itemToggle"
          :duration="100"
          :expand-icon="getExpandedIconImg('/plus.svg')"
          :expanded-icon="getExpandedIconImg('/minus.svg')"
          header-class="faq-header"
          itemscope 
          itemprop="mainEntity" 
          itemtype="https://schema.org/Question"
        >
          <template #header>
            <h3
              class="xsmall"
              itemprop="name"
            >
              {{ faq.question }}
            </h3>
          </template>
          <q-card 
            itemscope 
            itemprop="acceptedAnswer" 
            itemtype="https://schema.org/Answer"
          >
            <q-card-section
              class="faq-desc-holder" 
              itemprop="text"
            >
              <NestedContent :module-data="faq.answer" />
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </template>

      <div
        v-if="!showAllitems && faqItemsData.length > faqItemsNumber"
        class="read-more d-flex text-secondary justify-content-end cursor-pointer"
        @click="showItems"
      >
        <div class="text-sm">
          minden kérdés megtekintése
        </div>
        <div>
          <PhIconManager
            ph-icon="ph-caret-down"
            :size="20"
          />
        </div>
      </div>
      <a
        v-if="showAllitems"
        class="read-more d-flex text-secondary justify-content-start"
        @click="showItems"
      >
        <div class="text-sm">Kevesebb elem megtekintése</div>
        <div>
          <PhIconManager
            ph-icon="ph-caret-up"
            :size="20"
          />
        </div>
      </a>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, inject, onBeforeUnmount } from 'vue'
import axios from 'axios'
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  moduleTitle: {
    type: String,
    required: true,
  },
  faqCategory: {
    type: String,
    required: false,
    default: '',
  },
  faqItemsNumber: {
    type: Number,
    required: false,
    default: 5,
  },
})

const showAllitems = ref(false)
const componentUrl = import.meta.url
const faqData = ref(null)
const faqItemsData = ref({})
const isReady = ref(false)
const intervalScanner = ref(null)

const itemToggle = computed(() => {
  if (showAllitems.value) {
    return faqItemsData.value.length
  } else return props.faqItemsNumber
})

const showLoadingIndicator = inject('showLoadingIndicator')

onMounted(async () => {

  // Fail safe for the case when faqmodules position changed because of other elements
  let intervalCounter = 0;

  if(props.faqCategory != null && props.faqCategory != ''){
  await axios
    .get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Contents/FaqItems?category=' +
        props.faqCategory
    )
    .then((response) => {
      faqData.value = response.data
      faqItemsData.value = faqData.value.items
      isReady.value = true
      if(router.currentRoute.value.hash === '#faq'){
         intervalScanner.value = setInterval(() => {
          let ret = checkElementPosition('faq');
          if(ret === true || intervalCounter > 20 ){
            clearInterval(intervalScanner.value)
            showLoadingIndicator.value = false
          }
          intervalCounter++;
        }, 100)
      }
    })
  }
})

onBeforeUnmount(() => {
  if(intervalScanner.value != null){
      clearInterval(intervalScanner.value)
  }
})

function checkElementPosition(elementId) {
  const element = document.getElementById(elementId);
  
  if (element) {
    const elementTop = element.getBoundingClientRect().top;
    const scrollbarHeight = window.innerHeight - document.documentElement.clientHeight;
  
    if (Math.abs(elementTop - scrollbarHeight) <= 150) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function showItems() {
  showAllitems.value = !showAllitems.value
}

function getExpandedIconImg(img) {
  return 'img:' + imageUrlHelper.getImageUrl(img, componentUrl) + ''
}
</script>

<script>
export default {
  name: 'FAQModule',
}
</script>

<style lang="scss" scoped>
.faq-section {
  :deep(a) {
    color: $secondary;
  }
  :deep(.faq-header) {
    background-color: #ecebef;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.75rem 1rem;
    margin-bottom: 0.25rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f7f7f9 !important;
    }
    h3 {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0;
      flex: 1;
    }
  }
  :deep(.q-item .q-item__label:hover) {
    background-color: unset !important;
    color: #1d2c5b !important;
  }

  :deep(.q-expansion-item__toggle-icon) {
    font-size: 1rem;
    color: #37519b;
  }

  :deep(.q-expansion-item__content) {
    height: 100%;
  }
  :deep(.q-item__section){
    margin-left: 0.5rem !important;
  }
  :deep(.q-card) {
    background: #f6f6f6;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $dark;
  }

  :deep(.q-card__section) {
    padding: unset;
  }

  :deep(.faq-desc-holder) {
    margin-bottom: 0.5rem;
  }

  .medium {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 4rem;
  }

  a.read-more {
    width: 100%;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;

    .text-sm {
      margin-right: 0.5rem;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .faq-section {
    .medium {
      font-size: 1.75rem;
      line-height: 2.25rem;
      margin-bottom: 2rem;
    }

    a.read-more {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .faq-section {
    .medium {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }

    a.read-more {
      font-size: 1rem;
    }
  }
}
</style>
