<template>
  <div
    class="alert"
    :class="`alert-${color}`"
    role="alert"
  >
    <div class="d-flex">
      <PhIconManager
        v-if="color === 'success'"      
        ph-icon="ph-circle-wavy-check"
        :size="20"
      />
      <PhIconManager
        v-else      
        ph-icon="ph-warning-circle"
        :size="20"
      />
      <span class="alert-title"> {{ title }} </span>
    </div>

    <p
      v-if="content"
      v-sanitize="content"
      class="alert-content mt-8px"
    />
  </div>
</template>

<script setup>

const props = defineProps({
  color: {
    type: String,
    default: 'success',
    validator(value) {
      return ['success', 'warning', 'error'].includes(value)
    },
  },

  title: {
    type: String,
    default: '',
  },

  content: {
    type: String,
    default: '',
  },
})
</script>

<script>
export default {
  name: 'AlertComponent',
}
</script>

<style lang="scss" scoped>
.alert {
  max-height: 100%;
  border-width: 0;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border-bottom: 0.5rem solid $white;
  margin-bottom: 0;

  svg {
    margin-right: 0.5rem;
  }
}

.alert-title {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.alert-content {
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.alert-success {
  color: $G-800;
  background-color: $G-50;
  border-color: $G-500;

  .alert-content {
    color: $G-800;
  }
}

.alert-warning,
.alert-error {
  color: $V-600;
  background-color: $V-50;
  border-color: $V-500;

  .alert-content {
    color: $V-600;
  }
}
</style>
