<template>
  <div
    v-if="!isLoading"
    class="outhouse-repeater-container"
  >
    <Row66Xl48Lg
      v-for="(item, index) in steadingList"
      :key="index"
      class="outhouse-block"
    >
      <template #first>
        <div class="outhouse-title-holder">
          <Title
            htag="h4"
            class="outhouse-title"
          >
            {{ index + 1 }}. Melléképület
          </Title>
          <div
            v-if="index > 0"
            class="delete-outhouse-holder"
          >
            <q-btn
              class="delete-outhouse"
              :ripple="false"
              :unelevated="true"
              @click="removeSteadingBlock(item, index)"
            >
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-trash"
              />
              Blokk törlése
            </q-btn>
          </div>
        </div>
      </template>
      <template #second>
        <div class="input-holder">
          <TextInput
            id="KALK_121"
            :key="steadingAreaSizeSum > 150"
            v-model="item.SteadintAreaSize.Value"
            :label="'Melléképület alapterülete'"
            mask="###"
            :init-input-value="
              item.SteadintAreaSize.Value == 0
                ? null
                : item.SteadintAreaSize.Value
            "
            :postfix="'m<sup>2</sup>'"
            :validation-rules="`VAL_100|VAL_359:${steadingAreaSizeSum}`" 
            @update:model-value="updateAreaSize(item.SteadintAreaSize.Value, index)"           
          />
        </div>
        <div
          v-if="isApartment"
          class="input-holder"
        >
          <SelectInput
            id="KALK_122"
            :key="item.SteadintTypeOfWall.Value.value"
            v-model="item.SteadintTypeOfWall.Value.value"
            label="Melléképület falazata"
            :maxlength="20"
            validation-rules="VAL_100_SELECT"
            :init-option-value="item.SteadintTypeOfWall.Value.value"
            :options="item.SteadintTypeOfWall.Values"
            @update:model-value="updateSteading"
          />
        </div>
        <div 
          v-if="isApartment"
          class="input-holder"
        >
          <SelectInput
            id="KALK_123"
            :key="item.SteadingTypeOfRoof.Value.value"
            v-model="item.SteadingTypeOfRoof.Value.value"
            label="Melléképület tetőzete"
            :maxlength="20"
            validation-rules="VAL_100_SELECT"
            :init-option-value="item.SteadingTypeOfRoof.Value.value"
            :options="item.SteadingTypeOfRoof.Values"
            @update:model-value="updateSteading"
          />
        </div>
        <div
          v-if="index === steadingList.length - 1 && index < 4"
          class="plus-outhouse"
        >
          <q-btn
            class="more-outhouse-btn"
            :ripple="false"
            :unelevated="true"
            @click="addNewSteading"
          >
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-plus"
            />
            Melléképület hozzáadása
          </q-btn>
        </div>
      </template>
    </Row66Xl48Lg>
  </div>
</template>
<script setup>
import { onBeforeMount, ref, watch, inject, computed } from 'vue'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { customDefineRule, setSkipEmptyFields } from '@/utils/validations/customDefineRule'
import { useHouseholdWizardStore } from '@/stores/householdWizardStore'

const { callPreCalculate, callValidate } = inject('wizard')
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  steadintTypeOfWall: {
    type: Object,
    required: true,
  },
  steadingTypeOfRoof: {
    type: Object,
    required: true,
  },
  isApartment: {
    type: Boolean,
    required: false,
  },
})

const removeCounter = ref(0)
const householdWizardStore = useHouseholdWizardStore()
const fromSave = ref(householdWizardStore.getFromSavedModeOuthouseRepeater)
const isLoading = ref(true)
const emits = defineEmits(['update:viewModelSteadings'])

const steadingList = ref([])

watch(() => steadingList.value.length, (value, oldValue) => {  
  emits('update:viewModelSteadings', steadingList.value)
})


onBeforeMount(() => {
  if (props.items.length === 0) {
    steadingList.value.push(createSteadingListModel())
  }
  else if(props.items.length > 0){
    steadingList.value = props.items
  }
  if(fromSave.value){
    isLoading.value = true 
  }else{
    isLoading.value = false
  }
})

function addNewSteading() {
  if (steadingList.value.length < 5) {
    steadingList.value.push(createSteadingListModel())
  }
}
function updateSteading(params) {
  emits('update:viewModelSteadings', steadingList.value) 

}
function removeSteadingBlock(item, index) {
  if (index > 0 && index < steadingList.value.length) {
    steadingList.value.splice(index, 1)
  }

}

watch(() => householdWizardStore.getSteadingList, (value, oldValue) => {  
  if(fromSave.value && householdWizardStore.getSteadingList.length > 0){
    if(householdWizardStore.getSteadingList[0].SteadintAreaSize.Value >= 0){
      steadingList.value = householdWizardStore.getSteadingList
      fromSave.value = false
      householdWizardStore.setFromSavedModeOuthouseRepeater(false)
      isLoading.value = false
    }
  }
})

function createSteadingListModel() {
  let steading = {
    SteadintAreaSize: {
      IsDisabled: false,
    },
    SteadintTypeOfWall: JSON.parse(JSON.stringify(props.steadintTypeOfWall)),
    SteadingTypeOfRoof: JSON.parse(JSON.stringify(props.steadingTypeOfRoof)),
    SteadingValue: {
      IsDisabled: false,
    },
    SteadingRecommendedValue: {
      IsDisabled: false,
    },
  }
  return steading
}
async function updateAreaSize(value, index) {
  setSkipEmptyFields(true);
  const isValid = await callValidate()

  setSkipEmptyFields(false);
  if(isValid){
   steadingList.value[index].SteadintAreaSize.Value = value
   emits('update:viewModelSteadings', steadingList.value)
   await callPreCalculate(true,2000)
  }  
}

const steadingAreaSizeSum = computed(() => {
  let sumValue = 0
  steadingList.value.forEach(element => {
    if(element.SteadintAreaSize.Value > 0){
      sumValue += Number(element.SteadintAreaSize.Value)
    }
  });
return sumValue
})

customDefineRule('VAL_359', (value) => {
   if(steadingAreaSizeSum.value > 150 ){
    return ValidationMessages.VAL_359
   }
   return true
})

</script>
<style lang="scss" scoped>
.outhouse-repeater-container {
  .outhouse-block {
    padding-bottom: 1rem;
    border-bottom: 1px solid $D-100;
    margin-bottom: 2.5rem;
  }
  .outhouse-block:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }
  .outhouse-title-holder {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  background: #f7f7fb;
  border-radius: 0.75rem;
  padding: 2.5rem;
  .input-holder {
    padding-bottom: 1.5rem;
  }
  .outhouse-title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0;
  }
  .plus-outhouse {
    .more-outhouse-btn {
      padding: 0.5rem 0 0 0;
    }
    :deep(.q-btn__content) {
      display: flex;
      flex-direction: row;
      color: $secondary;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
      width: auto;
      gap: 0.5rem;
      align-items: left;
      justify-content: left;
      text-transform: none;
      svg {
        width: auto;
      }
    }
  }
  .delete-outhouse-holder {
    padding: 0;
    .delete-outhouse {
      padding: 0;
      min-height: unset;
    }
    :deep(.q-btn__content) {
      display: flex;
      flex-direction: row;
      color: $secondary;
      gap: 0.5rem;
      text-transform: none;
      svg {
        width: auto;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .outhouse-repeater-container {
    padding: 2rem;
    .outhouse-block {
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
    }
    .outhouse-block:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .outhouse-repeater-container {
    padding: 1rem;
  }
  .outhouse-title-holder {
    margin-bottom: 2rem;
  }
}
</style>
