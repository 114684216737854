<template>
  <p class="paragraph-description-holder p-0 m-0 text-left mb-24px">
    <span v-sanitize="moduleContent" />
    <span
      v-if="showMoreInformation"
      v-sanitize="moreInformationText"
    /><span>
      <span v-if="!showMoreInformation && moreInformationText.length > 0">...</span><div
        v-if="showMoreInformationButton"
        class="d-block mt-0 text-secondary cursor-pointer"
        @click="showMoreInformation = !showMoreInformation"
      >
        {{ moreInformationLabel }}
      </div></span>
  </p>
</template>

<script setup>
import { ref, computed } from 'vue'
const props = defineProps({
  moduleContent: {
    type: String,
    required: true,
  },

  showMoreInformationButton: {
    type: Boolean,
    required: false,
    default: false,
  },

  moreInformationText: {
    type: String,
    required: false,
  },
})

const showMoreInformation = ref(false)

const moreInformationLabel = computed(() => {
  if (!showMoreInformation.value) {
    return 'Tovább'
  } else {
    return 'Kevesebb'
  }
})
</script>

<script>
export default {
  name: 'ParagraphReadMore',
}
</script>

<style lang="scss" scoped>
.paragraph-description-holder {
  padding: 0 !important;
  font-weight: 400;
  font-size: 0.75rem !important;
  line-height: 1.25rem !important;
  margin-bottom: 1.5rem;
  color: #77809D;
  :deep(a) {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: #4e95fb;
    cursor: pointer;
    &:hover {
      color: #3e77c9;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
}

@media screen and (max-width: $breakpoint-xs-max) {
}
</style>
