import 'bootstrap'
import { createApp, provide, ref } from 'vue'
import App from './App.vue'
import IframeManager from './IFrameManager.vue'
import { Quasar, Cookies } from 'quasar'
import router from './router'
import store from './utils/store'
import axios from 'axios'
import { loadFonts } from './plugins/webfontloader'
import 'quasar/src/css/index.sass'
import './styles/style.scss'
import componentRegister from './utils/infrastructure/componentRegister.js'
import { loadVeeValidateRules } from './utils/validations/customValidator.js'
import { createPinia } from 'pinia'
import { createGtm } from '@gtm-support/vue-gtm'
import googleTagManagerTracker from './plugins/googleTagManagerTracker'
import PhIconManager from './components/elements/phicons/PhIconManager.vue'
import LoadingOverlay from './components/modules/LoadingOverlay.vue'

loadFonts()
loadVeeValidateRules()

const pinia = createPinia()

let isSalesPortal = window.location.href.indexOf('ertekesites.genertel.hu') > 0

const app = isSalesPortal === false ? createApp(App) : createApp(IframeManager)

app.config.errorHandler = async (err) => {
  console.error('App error handled: ')
  console.error(err)

  try {
    let response = await axios.post(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Logger/Log?error=' +
        err
    )
  } catch (e) {
    console.error('Unable to post error to server!')
  }
}

// Production configs

if (import.meta.env.PROD) {
  console.warn = () => {}
  console.error = () => {}
  console.log = () => {}
  console.info = () => {}

  app.config.devtools = false
  app.config.debug = false
  app.config.silent = true
} else {
  app.config.warnHandler = (warn) => {
    console.warn(warn)
  }
}

componentRegister(app)

app.component('PhIconManager', PhIconManager)
app.component('LoadingOverlay', LoadingOverlay)

const _removeValueIfNotValid = ref(false)

app.provide('_removeValueIfNotValid', _removeValueIfNotValid)

app.use(Quasar, {
  plugins: {
    Cookies,
  },
})
app.use(googleTagManagerTracker)
app.use(router)
app.use(store)
app.use(pinia)

app.use(
  createGtm({
    id: import.meta.env.VITE_GTM_CODE,
  })
)

app.directive('calculatorsticky', {
  mounted(el) {
    const element = el
    const body = document.getElementsByClassName("container-fluid")
    const bodytop = body[0].getBoundingClientRect().top
    let elementOffsetTop = element.getBoundingClientRect().top
    if(bodytop < 0){
      elementOffsetTop = elementOffsetTop - bodytop
    }
    const updatePosition = () => {
      const scrollPosition = document.documentElement.scrollTop
      if (scrollPosition >= elementOffsetTop) {
        element.classList.add('sticky-calculator')
        element.parentElement.classList.add('sticky-calculator-parent')
      } else {
        element.style.maxWidth = ''
        element.classList.remove('sticky-calculator')
        element.parentElement.classList.remove('sticky-calculator-parent')
      }
    }

    // Call updatePosition on mount and whenever the window is resized
    updatePosition()
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition)
  },
})
app.directive('sticky-parent', {
  mounted(el) {
    const element = el
    const body = document.getElementsByClassName("container-fluid")
    const bodytop = body[0].getBoundingClientRect().top
    let elementOffsetTop = element.getBoundingClientRect().top
    if(bodytop < 0){
      elementOffsetTop = elementOffsetTop - bodytop
    }
    const updatePosition = () => {
      const scrollPosition = document.documentElement.scrollTop
      if (scrollPosition >= elementOffsetTop) {
        if (window.innerWidth >= 1200) {
          element.style.position = 'fixed'
          element.style.top = `0`
          element.style.zIndex = '9999'
          element.style.boxShadow =
            '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10)'
          element.style.minWidth = window.innerWidth + 'px'
          element.style.marginRight = '-100px'
          element.style.paddingRight = ''
          element.style.paddingLeft = 'calc((100% - 71.5rem)/2)'
          element.style.marginLeft = 'calc((-1 * (100% - 71.5rem)) / 2)'
          element.style.backgroundColor = 'white'
          element.parentElement.classList.add('sticky')
        } else if (window.innerWidth >= 991) {
          element.style.position = 'fixed'
          element.style.top = `0`
          element.style.zIndex = '9999'
          element.style.boxShadow =
            '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10)'
          element.style.minWidth = window.innerWidth + 'px'
          element.style.paddingRight = '40px'
          element.style.marginLeft = '-40px'
          element.style.marginRight = ''
          element.style.paddingRight = '40px'
          element.style.paddingLeft = '40px'
          element.style.backgroundColor = 'white'
          element.parentElement.classList.add('sticky')
        } else {
          element.style.position = 'fixed'
          element.style.top = `0`
          element.style.zIndex = '9999'
          element.style.boxShadow =
            '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.10)'
          element.style.minWidth = window.innerWidth + 'px'
          element.style.paddingRight = '16px'
          element.style.marginLeft = '-16px'
          element.style.marginRight = ''
          element.style.paddingLeft = '16px'
          element.style.backgroundColor = 'white'
          element.parentElement.classList.add('sticky')
        }
      } else {
        element.style.position = ''
        element.style.top = ''
        element.style.zIndex = ''
        element.style.boxShadow = 'none'
        element.style.maxWidth = ''
        element.style.minWidth = ''
        element.classList.remove('sticky-calculator')
        element.style.paddingRight = ''
        element.style.paddingLeft = ''
        element.style.marginLeft = ''
        element.style.marginRight = ''
        element.style.backgroundColor = ''
        element.parentElement.classList.remove('sticky')
      }
    }

    // Call updatePosition on mount and whenever the window is resized
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition)
  },
})
// Define the v-sanitize directive
app.directive('sanitize', {
  beforeMount(el, binding) {
    const sanitizeHTML = (html) => {
      // Remove <script> tags
      let cleanHTML = html.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      // Remove <iframe> tags
      cleanHTML = cleanHTML.replace(/<iframe[^>]*>([\S\s]*?)<\/iframe>/gmi, '');
      return cleanHTML;
    };

    if (binding.value) {
      el.innerHTML = sanitizeHTML(binding.value);
    }
  },
  updated(el, binding) {
    const sanitizeHTML = (html) => {
      // Remove <script> tags
      let cleanHTML = html.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      // Remove <iframe> tags
      cleanHTML = cleanHTML.replace(/<iframe[^>]*>([\S\s]*?)<\/iframe>/gmi, '');
      return cleanHTML;
    };

    if (binding.value !== binding.oldValue) { // Only update the content if it has changed
      el.innerHTML = sanitizeHTML(binding.value);
    }
  }
});
app.mount('#app')

document.documentElement.lang="hu"
// log env variables to console in nonProd environment
if (!import.meta.env.PROD) {
  console.log('Environment: ' + import.meta.env.VITE_ENV)
  console.log('Environment variables:')
  Object.entries(import.meta.env).forEach((element) => {
    console.log(element)
  })
}
