<template>
  <q-btn-toggle
    v-model="value"
    class="insurance-type-choice-input"
    :class="!!veeErrors[0] ? 'q-field--error' : ''"
    toggle-color="choice"
    :error="!!veeErrors[0]"
    :options="[
      { value: choiceOptions[0], slot: 'one' },
      { value: choiceOptions[1], slot: 'two' },
    ]"
    @update:model-value="
      handleChange($event),
      handleInfoWarningMessages(value),
      inputChanged(value)
    "
    @focus="setMetaTouched"
  >
    <template #one>
      <div class="choice-btn">
        <div class="btn-icon">
          <PhIconManager
            width="40px"
            height="40px"
            ph-icon="ph-house-line"
          />
        </div>
        <Title
          :htag="'h4'"
          class="btn-title"
        >
          Családi ház, sorház, ikerház
        </Title>
      </div>
    </template>

    <template #two>
      <div class="choice-btn">
        <div class="btn-icon">
          <PhIconManager
            width="40px"
            height="40px"
            ph-icon="ph-buildings"
          />
        </div>
        <Title
          :htag="'h4'"
          class="btn-title"
        >
          Társasházi lakás
        </Title>
      </div>
    </template>
    <div class="q-btn placeholder-div" />
  </q-btn-toggle>
  <div
    v-if="veeErrors[0]"
    class="q-field__inner q-field--with-bottom"
  >
    <div class="q-field__bottom row items-start q-field__bottom--animated">
      <div class="q-field__messages col">
        <ValidationMessage
          :messages="veeErrors"
          :info-messages="infoMessages"
          :warning-messages="warningMessages"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, watch } from 'vue'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const texts = inject('texts')

const removeValueIfNotValid = inject('_removeValueIfNotValid')

const emit = defineEmits(['update:modelValue'])

const infoMessages = ref(null)
const warningMessages = ref(null)

const props = defineProps({
  choiceOptions: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Object,
  },
  initInputValue: {
    type: Object,
    default: null,
  },
  infoMessageRules: {
    type: String,
    required: false,
    default: '',
  },
  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  warningMessageRules: {
    type: String,
    default: '',
  },
})
function resetValidationQForm() {}
const {
  handleChange,
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: true,
})

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules
  )
}

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})

function inputChanged() {
  emit('update:modelValue', value.value)
  validateQForm()
}

function setMetaTouched() {
  meta.touched = true
}

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()
  if (veeErrors.value.length > 0) {
    if (removeValueIfNotValid.value && !meta.valid && meta.touched) {
      value.value = null
      inputChanged()
      return true
    }
    return false
  } else {
    return true
  }
}

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
  }
)

onMounted(() => {
  if (props.initInputValue !== null || props.initInputValue !== undefined) {
    if (props.initInputValue.value !== undefined) {
      const id = props.choiceOptions.find(
        (field) => field.value === props.initInputValue.value
      )
      value.value = id
    } else {
      value.value = props.initInputValue
    }
    emit('update:modelValue', value.value)
  }
})
</script>

<style lang="scss" scoped>
.insurance-type-choice-input {
  display: flex !important;
  flex-direction: row !important;
  gap: 1rem;
  :deep(.q-btn) {
    border-radius: 0.75rem;
    padding: 0;
    flex: 1 0 0;
    min-width: 9rem;
  }
  :deep(.placeholder-div){
    min-height: 0px;
    display: block;
    @media screen and (max-width: $breakpoint-md-max) {
      display: none;
    }
  }
  box-shadow: none;
  .choice-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    gap: 1rem;
    height: 8rem;
    width: 100%;
    background: $white;
    border: $white 0.125rem solid;
    box-shadow: $box-shadow;
    border-radius: 0.75rem;

    .btn-title {
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $dark;
      margin: 0;
      letter-spacing: normal;
      text-transform: none;
      text-align: left;
    }
  }
  :deep(.bg-choice) {
    background: transparent;
    .choice-btn {
      color: $blue;
      border: 0.125rem solid $blue;
    }
  }
}
</style>
