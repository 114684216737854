<template>
  <section
    :id="sectionId"
    class="description-section"
  >
    <div class="container-xxl">
      <div class="title-holder">
        <Title
          :htag="htag"
          class="medium text-center m-0"
        >
          {{ moduleTitle }}
        </Title>
      </div>
      <div class="description-holder">
        <span>
          <p v-sanitize="moduleContent" />
        </span>
        <span v-if="showMoreInformation">
          <p v-sanitize="moreInformationText" />
        </span>
        <div
          v-if="showMoreInformationButton"
          class="d-block mt-4 text-secondary cursor-pointer"
          @click="showMoreInformation = !showMoreInformation"
        >
          {{ moreInformationLabel }}
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
const props = defineProps({
  moduleTitle: {
    type: String,
    required: true,
  },

  moduleContent: {
    type: String,
    required: true,
  },

  showMoreInformationButton: {
    type: Boolean,
    required: false,
    default: false,
  },

  moreInformationText: {
    type: String,
    required: false,
  },

  htag: {
    type: String,
    required: false,
    default: 'h2',
  },
  sectionId: {
    type: String,
    required: false,
  }
})

const showMoreInformation = ref(false)
const moreInformationLabel = computed(() => {
  if (!showMoreInformation.value) {
    return 'Több információ'
  } else {
    return 'Kevesebb információ'
  }
})
</script>

<script>
export default {
  name: 'DescriptionModule',
}
</script>
<style lang="scss" scoped>
.description-section {
  .title-holder {
    margin-bottom: 4rem;
    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  .description-holder {
    padding: 2rem;
    border-radius: 0.75rem;
    background-color: #f7f7fb;
    height: auto !important;
    :deep(h3) {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 1.5rem;
    }
    :deep(h3.margin-top) {
      margin-top: 2.5rem;
    }

    :deep(p) {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: unset;
      margin-bottom: 1.5rem;
      a {
        color: #4e95fb;
        cursor: pointer;
      }
    }
    :deep(ul) {
      list-style: none;
      padding-left: 0.75rem;
      margin-bottom: 1.5rem;
      color: #1d2c5b;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      li {
        color: #1d2c5b;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    :deep(ul li:before) {
      content: '•';
      font-size: 1rem;
      margin-left: -0.75rem;
      position: absolute;
      color: #1d2c5b;
    }
  }

  a {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1rem;
    color: #4e95fb;
    cursor: pointer;
    &:hover {
      color: #3e77c9;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .description-section {
    margin-bottom: 0;
    .title-holder {
      margin-bottom: 2rem;
      h2 {
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 2.25rem;
      }
    }
    .description-holder {
      :deep(h3) {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .description-section {
    .title-holder {
      margin-bottom: 2rem;
      h2 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    .description-holder {
      padding: 2rem;
      :deep(h3) {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 600;
      }

      :deep(p) {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
