<template>
  <a
    :id="`navbarMobile${index}`"
    class="nav-link dropdown-toggle t-sm"
    href="#"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    @click="headerToggler"
  >
    {{ headerItem.title }}
    <span class="caret">
      <PhIconManager
        v-if="headerOpen" 
        ph-icon="ph-plus"
        :size="16"
      /><PhIconManager
        v-else 
        ph-icon="ph-minus"
        :size="16"
      />
    </span>
  </a>
  <ul
    class="dropdown-menu"
    :aria-labelledby="`navbarMobile${index}`"
  >
    <li
      v-for="dropdownItem in headerItem.children"
      :key="dropdownItem.name"
    >
      <NavigableLink
        :link-object="dropdownItem"
        class="dropdown-item"
      >
        <div>{{ dropdownItem.title }}</div>
        <PhIconManager
          ph-icon="ph-caret-right"
          :size="16"
        />
      </NavigableLink>
      <ul
        v-if="isDropdownOrSubmenu(dropdownItem)"
        class="submenu"
      >
        <li
          v-for="submenuItem in dropdownItem.children"
          :key="submenuItem.name"
        >
          <NavigableLink
            :link-object="submenuItem"
            class="t-xs"
          >
            <div>{{ submenuItem.title }}</div>
            <PhIconManager
              ph-icon="ph-caret-right"
              :size="16"
            />
          </NavigableLink>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  headerItem: {
    type: Object,
    required: true,
    default: () => null,
  },

  index: {
    type: Number,
    required: true,
  },

  clickedHeaderItem: {
    type: Number,
    required: true,
  },

  isDropdownOrSubmenu: {
    type: Function,
    required: true,
  },
})

const emit = defineEmits(['toggleHeader'])

const headerOpen = ref(true)

function headerToggler() {
  headerOpen.value = !headerOpen.value
  emit('toggleHeader', props.index)
}

watch(
  () => props.clickedHeaderItem,
  (value, prevValue) => {
    if (value !== props.index) {
      headerOpen.value = true
    }
  }
)
</script>

<script>
export default {
  name: 'MobileHeaderItem',
}
</script>