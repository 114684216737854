<template v-if="isReady">
  <main
    id="accessible-page"
    :class="{
      normal: accessibleStore.getIsActiveNormal,
      medium: accessibleStore.getIsActiveMedium,
      large: accessibleStore.getIsActiveLarge,
    }"
  >
    <div class="content">
      <!-- accessible-header-component -->
      <div class="content-header">
        <div class="row">
          <div class="col-12 col-md-4">
            <router-link to="/">
              {{ header.backButtonLabel }}
            </router-link>
          </div>
          <div class="col-12 col-md-8">
            <div class="content-align">
              <p>{{ header.fontSizeText }}</p>
              <div class="q-gutter-sm margin-space buttons-group">
                <q-btn
                  class="set-size-btn"
                  label="A"
                  :class="{ 'active-btn': accessibleStore.getIsActiveNormal }"
                  @click="myFilterNormal"
                />
                <q-btn
                  class="set-size-btn"
                  label="A+"
                  :class="{ 'active-btn': accessibleStore.getIsActiveMedium }"
                  @click="myFilterMedium"
                />
                <q-btn
                  class="set-size-btn"
                  label="A++"
                  :class="{ 'active-btn': accessibleStore.getIsActiveLarge }"
                  @click="myFilterLarge"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-subheader">
        <ul class="nav">
          <li
            v-for="(item, index) in header.items"
            :key="index"
            class="nav-item"
          >
            <router-link
              :to="item.buttonLink"
              class="nav-link"
              :class="index == 0 ? 'ps-0' : ''"
            >
              {{ item.buttonLabel }}
            </router-link>
          </li>
        </ul>
      </div>

      <router-view :key="$route.fullPath" />

      <div
        v-if="!('accessible' == $route.name) && isReady"
        class="content-navigator"
      >
        <router-link :to="footer.backButtonLink">
          {{ footer.backButtonLabel }}
        </router-link>
      </div>
      <!-- accessible-footer-component -->
      <div class="content-footer">
        <p v-sanitize="footer.content" />
      </div>
    </div>
  </main>
</template>

<script setup>
import './../styles/accessible.scss'
import { useAccessibleStore } from '../stores/accessibleStore.js'
import { ref, onMounted } from 'vue'
import axios from 'axios'

const accessibleStore = useAccessibleStore()

function myFilterNormal() {
  accessibleStore.setIsActiveNormal(true)
  accessibleStore.setIsActiveMedium(false)
  accessibleStore.setIsActiveLarge(false)
}

function myFilterMedium() {
  accessibleStore.setIsActiveNormal(false)
  accessibleStore.setIsActiveMedium(true)
  accessibleStore.setIsActiveLarge(false)
}

function myFilterLarge() {
  accessibleStore.setIsActiveNormal(false)
  accessibleStore.setIsActiveMedium(false)
  accessibleStore.setIsActiveLarge(true)
}

const footer = ref({})

const header = ref({})

const isReady = ref(false)

onMounted(async () => {
  let footerResponse = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
      '/Contents/AccessibleFooter'
  )

  footer.value = footerResponse.data

  let headerResponse = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
      '/Contents/AccessibleHeader'
  )

  header.value = headerResponse.data

  isReady.value = true
})
</script>
<style lang="scss" scoped>
.nav-item:last-child .nav-link {
  border-right: 0 !important;
}
@media screen and (max-width: $breakpoint-sm)  {
.content-subheader {
  border-bottom: none !important;
}
}
</style>
