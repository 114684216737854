<template>
  <div
    v-for="(item, index) in petList"
    :key="index"
    class="pet-container"
  >
    <Row66Xl48Lg>
      <template #first>
        <div class="pet-title-holder">
          <Title
            htag="h4"
            class="pet-title"
          >
            {{ index + 1 }}. Kisállat adatai
          </Title>
          <div
            v-if="index != 0"
            class="delete-pet-holder"
          >
            <q-btn
              class="delete-pet"
              :ripple="false"
              :unelevated="true"
              @click="removePetBlock(item, index)"
            >
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-trash"
              />
              Blokk törlése
            </q-btn>
          </div>
        </div>
      </template>
      <template #second>
        <div class="input-holder">
          <!-- KIZÁRÁSOK KELLENEK INTEGRÁCIÓ UTÁN -->
          <DatePickerMonth
            v-if="!isMobile"
            id="KALK_223"
            :key="item.BirthDate.Value"
            v-model:model-value="item.BirthDate.Value"
            :init-input-value="item.BirthDate.Value"
            :start-date="Date7YearsAgo(DateTime.now())"
            :end-date="DateTime.now()"
            :tooltip-content="''"
            years-in-month-view
            :label="'Születési év és hónap'"
            validation-rules="VAL_100|VAL_330_MONTH|VAL_434|VAL_456"
          />
          <DatePickerMobileMonth
            v-if="isMobile"
            :id="'KALK_223_'+index"
            :key="item.BirthDate.Value"
            v-model:model-value="item.BirthDate.Value"
            :init-input-value="item.BirthDate.Value"
            :start-date="Date7YearsAgo(DateTime.now())"
            :end-date="DateTime.now()"
            :tooltip-content="''"
            years-in-month-view
            :label="'Születési év és hónap'"
            validation-rules="VAL_100|VAL_330_MONTH|VAL_434|VAL_456"
            breakpoint="992"
          />
          <TextInput
            id="KALK_224"
            :key="item.Name.Value"
            v-model:model-value="item.Name.Value"
            :init-input-value="item.Name.Value"
            :label="'Kisállat neve'"
            :max-length="20"
            validation-rules="VAL_100"
          />
          <div class="pet-type">
            <div class="pet-type-title">
              Kisállat típusa
            </div>
            <div class="pet-type-options">
              <YesNoInput
                id="KALK_225"
                :key="item.Type.Value"
                v-model="item.Type.Value"
                :options="petOptions"
                validation-rules="VAL_100_RADIO"
                :init-input-value="
                  item.Type.Value.value == item.Type.Values[0].value
                    ? true
                    : item.Type.Value.value == item.Type.Values[1].value
                      ? false
                      : null
                "
                @update:model-value="(v) => petTypeChanged(v, index)"
              />
            </div>
          </div>
          <SelectInput
            id="KALK_226"
            :key="item.Type.Value.value"
            v-model:model-value="item.Breed.Value.value"
            :init-option-value="item.Breed.Value.value"
            :label="'Kisállat fajtája'"
            :options="item.Breed.Values"
            validation-rules="VAL_100_SELECT"
            @update:model-value="(v) => petBreedChanged(v, index)"
          />
          <div class="pet-type">
            <div class="pet-type-title">
              Ivartalanított a kisállat?
            </div>
            <div class="pet-type-options">
              <YesNoInput
                id="KALK_227"
                :key="item.IsSpayed.Value"
                v-model:model-value="item.IsSpayed.Value"
                validation-rules="VAL_100_RADIO"
                :init-input-value="
                  item.IsSpayed.Value.value == item.IsSpayed.Values[0].value
                    ? true
                    : item.IsSpayed.Value.value == item.IsSpayed.Values[1].value
                      ? false
                      : null
                "
                @update:model-value="(v) => petIsSpayedChanged(v, index)"
              />
            </div>
          </div>
          <div
            v-if="index === petList.length - 1 && index < 2"
            class="plus-pet"
          >
            <q-btn
              class="more-pet"
              :ripple="false"
              :unelevated="true"
              @click="addNewPet"
            >
              <PhIconManager
                height="1.25rem"
                width="1.25rem"
                ph-icon="ph-plus"
              />
              Kisállat hozzáadása
            </q-btn>
          </div>
        </div>
      </template>
    </Row66Xl48Lg>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeMount, inject, watch } from 'vue'
import axios from 'axios'
let isMobile = inject('isMobile')
const DateTime = window.luxon.DateTime
const petOptions = ref([])
const statementOptions = ref([])
const { callAdditionals } = inject('wizard')
const emits = defineEmits(['update:viewModelSteadings'])
onMounted(() => {
  petOptions.value.push(
    {
      label: 'Kutya',
      value: true,
    },
    {
      label: 'Macska',
      value: false,
    }
  )
  statementOptions.value.push(
    {
      label: 'Igen',
      value: true,
    },
    {
      label: 'Nem',
      value: false,
    }
  )
})
const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  petType: {
    type: Object,
    required: true,
  },
  petSpayed: {
    type: Object,
    required: true,
  },
})
const removeCounter = ref(0)
const petList = ref([])

onBeforeMount(() => {
  if (props.items.length === 0) {
    petList.value.push(createPetListModel())
  } else if (props.items.length > 0) {
    petList.value = props.items
  }
})
watch(petList.value, (newpetList) => {
  emits('update:viewModelPets', newpetList)
})

function createPetListModel() {
  let pet = {
    BirthDate: {
      IsDisabled: false,
    },
    Name: {
      IsDisabled: false,
    },
    Type: {
      Values: props.petType,
      IsDisabled: false,
      Value: {
        IsDisabled: false,
      },
    },
    Breed: {
      Values: [],
      IsDisabled: false,
      Value: {
        IsDisabled: false,
      },
    },
    IsSpayed: {
      Values: props.petSpayed,
      IsDisabled: false,
      Value: {
        Id: 1,
        Text: 'vtxt_true_label',
        IsDisabled: false,
      },
    },
  }
  return pet
}

async function petTypeChanged(value, index) {
  if (value != null || value != undefined) {
    if (value == true) {
      petList.value[index].Type.Value = petList.value[index].Type.Values[0]
    } else {
      petList.value[index].Type.Value = petList.value[index].Type.Values[1]
    }
    petList.value[index].Breed = {
      Values: [],
      IsDisabled: false,
      Value: {
        IsDisabled: false,
      },
    }
    await getPetBreedList(index, petList.value[index].Type.Value.value)
      emits('update:viewModelPets', petList.value)

  }
}

async function petIsSpayedChanged(value, index) {
  if (value != null || value != undefined) {
    if (value == true) {
      petList.value[index].IsSpayed.Value =
        petList.value[index].IsSpayed.Values[0]
    } else {
      petList.value[index].IsSpayed.Value =
        petList.value[index].IsSpayed.Values[1]
    }
  }
    emits('update:viewModelPets', petList.value)

  await callAdditionals(true,2000)
}

async function petBreedChanged(value, index) {
  if (value != null || value != undefined) {
    petList.value[index].Breed.Value = petList.value[index].Breed.Values.find(
      (element) => element.value === value
    )
  }
    emits('update:viewModelPets', petList.value)

  await callAdditionals(true,2000)
}

function addNewPet() {
  if (petList.value.length < 3) {
    petList.value.push(createPetListModel())
  }
}

async function removePetBlock(item, index) {
  if (index >= 0 && index < petList.value.length) {
    petList.value.splice(index, 1)
    removeCounter.value++
  }
  await callAdditionals()
}
function Date7YearsAgo(d) {
  let date = DateTime.fromISO(d).minus({ years: 7 })
  return date
}

async function getPetBreedList(index, typeId) {
  if (typeId != 0 && typeId != 1) {
    return
  }
  const result = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Wizard/GetPetBreedList',
    {
      params: { petType: typeId },
    }
  )

  petList.value[index].Breed.Values = result.data.ResponseData
}
</script>
<style lang="scss" scoped>
.pet-container {
  width: 100%;
  border-top: 0.0625rem #d2d5de solid;
  margin-top: 1rem;
  padding-top: 2.5rem;
}
.input-holder {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.pet-title-holder {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.pet-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}

.pet-type-title {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $dark;
  padding-bottom: 1rem;
}
.radio-input {
  :deep(.q-option-group) {
    gap: 1rem;
  }
  :deep(.q-option-group > div) {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: calc(50% - 0.5rem);
    height: 3.25rem;
    box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
      0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    .q-radio.cursor-pointer.no-outline.row.inline.no-wrap.items-center {
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      margin-right: 0 !important;
      .q-radio__label {
        width: 100%;
      }
    }
  }
}
.delete-pet-holder {
  padding: 0;
  .delete-pet {
    padding: 0;
    min-height: unset;
  }
  :deep(.q-btn__content) {
    display: flex;
    flex-direction: row;
    color: $secondary;
    gap: 0.5rem;
    text-transform: none;
    svg {
      width: auto;
    }
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .pet-container {
    margin-top: 0.5rem;
    padding-top: 2rem;
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .pet-title-holder {
    margin-bottom: 2rem;
  }
}
</style>
