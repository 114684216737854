import { defineStore } from 'pinia'
import axios from 'axios'

export const useTravelDestionationsStore = defineStore('travelDestinations', {
  state: () => {
    return {
      destinations: [],
    }
  },
  getters: {
    getDestinations(state) {
      return state.destinations
    },
  },
  actions: {
    async setTravelDestinations() {
      if (this.destinations.length === 0) {
        await axios
          .get(
            import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
              '/Travels/Destinations'
          )
          .then((response) => {
            response.data.destinations.forEach((element) => {
              let destination = {}
              destination.label = element.text
              destination.value = element.id
              this.destinations.push(destination)
            })
          })
      }
    },
  },
})
