<template>
  <div class="card">
    <div
      :id="headingId"
      class="card-header btn btn-link collapsed"
      data-bs-toggle="collapse"
      :data-bs-target="`#${collapseId}`"
      aria-expanded="false"
      :aria-controls="collapseId"
      @mousedown.left="footerToggler"
    >
      <div class="title">
        {{ columnItem.title }}
      </div>
      <span class="caret">
        <PhIconManager
          v-if="footerOpen" 
          ph-icon="ph-plus"
          :size="16"
        /><PhIconManager
          v-else 
          ph-icon="ph-minus"
          :size="16"
        />
      </span>
    </div>

    <div
      :id="collapseId"
      class="collapse"
      :aria-labelledby="headingId"
      data-bs-parent="#footer-menu-accordion"
    >
      <div class="card-body">
        <nav class="navbar footer-nav">
          <ul>
            <li
              v-for="item in columnItem.items"
              :key="item.title"
              class="nav-item"
            >
              <router-link
                v-if="item.internalLink"
                :to="item.internalLink"
                :target="item.newWindow ? '_blank' : ''"
                class="nav-link"
                :class="{ gtmClaimMenuItem : item.internalLink == '/karbejelentes' }"
              >
                {{ item.title }}
              </router-link>
              <a
                v-else-if="item.externalLink"
                :href="item.externalLink"
                :target="item.newWindow ? '_blank' : ''"
                class="nav-link"                
              >
                {{ item.title }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from '@vue/runtime-core'

const props = defineProps({
  columnItem: {
    type: Object,
    required: true,
    default: () => null,
  },

  index: {
    type: Number,
    required: true,
  },

  clickedFooterItem: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['toggleFooter'])

const footerOpen = ref(true)

const headingId = computed(() => `heading_${props.index}`)

const collapseId = computed(() => `collapse_${props.index}`)

function footerToggler() {
  const footerEl = document.getElementById(collapseId.value)
  if (footerEl.classList.contains('collapsing')) {
    footerOpen.value = footerOpen.value
  } else {
    footerOpen.value = !footerOpen.value
  }
  emit('toggleFooter', props.index)
}

watch(
  () => props.clickedFooterItem,
  (value, prevValue) => {
    if (value !== props.index) {
      footerOpen.value = true
    }
  }
)
</script>

<script>
export default {
  name: 'MobileFooterItem',
}
</script>
