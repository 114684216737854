import { defineRule } from 'vee-validate'

let skipEmptyFields = false

export function setSkipEmptyFields(value) {
  skipEmptyFields = value
}

export function getSkipEmptyFields() {
  return skipEmptyFields
}

export function customDefineRule(
  id,
  ruleFunction,
  allowCrossFieldValidation = false,
) {

  if (allowCrossFieldValidation) {
    defineRule(id, (value, [target]) => {
      if (
        skipEmptyFields &&
        (value === null || value === undefined || value.toString().length === 0)
      ) {
        return true
      }
      if (typeof ruleFunction === 'function') {
        return ruleFunction(value, target)
      }
    })
  } else {
    defineRule(id, (value) => {      
      if (
        skipEmptyFields &&
        (value === null || value === undefined || value.toString().length === 0)
      ) {
        return true
      }
      if (typeof ruleFunction === 'function') {
        return ruleFunction(value)
      }
    })
  }
}
