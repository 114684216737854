export function objectToValidationJSONString(obj){
    let str = JSON.stringify(obj)
    str = str.replaceAll(',', ';')
    return str
}

export function ValidationJSONStringToObject(str){
    str = str.replaceAll(';', ',')
    let obj = JSON.parse(str)
    return obj
}