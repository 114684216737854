<template>
  <header
    id="sticky-header"
    class="container-fluid bg-primary"
  >
    <div class="container-xxl">
      <nav
        id="navbarDesktop"
        class="navbar navbar-dark"
      >
        <div>
          <div
            class="navbar-brand"
          >
            <img
              :src="`${imageUrlHelper.getImageUrl(
                '/genertel-logo.svg',
                componentUrl
              )}`"
              @click="backToHome"
            >
          </div>
        </div>

        <div
          v-if="
            !isSavePage &&
              !(householdWizardStore.getCurrentPage === '/alapadatok') &&
              !(householdWizardStore.getCurrentPage === '/adatellenorzes')
          "
          class="navbar-buttons"
        >
          <q-btn
            class="btn btn-lg btn-light text-dark"
            @click="save"
          >
            <span>Később folytatom</span>
          </q-btn>
        </div>
      </nav>
    </div>
  </header>
  <Breadcrumb v-if="!isThankYouPage" />
  <div class="calculator-container">
    <slot />
  </div>
  <StickyCart v-if="showCart && Screen.width > 375"/>
</template>

<script setup>
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper.js'
import { useCalculatorStore } from '../../stores/calculatorStore'
import { useHouseholdWizardStore } from '../../stores/householdWizardStore'
import { computed, ref, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { Screen } from 'quasar'

const router = useRouter()
const calculatorStore = useCalculatorStore()
const showCart = computed(() => {
  return calculatorStore.getShowCart
})
const emit = defineEmits(['saveViewModel'])
const householdWizardStore = useHouseholdWizardStore()

const props = defineProps({
  isSavePage: {
    type: Boolean,
    default: false,
  },
  isThankYouPage: {
    type: Boolean,
    default: false,
  },
  viewModel: {
    type: Object,
    default: () => ({}),
  },
})

function save() {
  emit('saveViewModel')
  householdWizardStore.setFromCalculator(true)
  if (
    props.viewModel.MNBMode != null &&
    props.viewModel.MNBMode != undefined &&
    props.viewModel.MNBMode == true
  ) {
    router.push({ name: `MFOlakasbiztositascalculatorsave` })
  } else {
    router.push({ name: `lakasbiztositascalculatorsave` })
  }
}

const backtohome = ref(false)

onBeforeUnmount(() => {
  if(backtohome.value){
    calculatorStore.setShowCart(false)
    householdWizardStore.setCurrentPage("alapadatok")
    householdWizardStore.setViewModel(null)
    householdWizardStore.setIsGTDisabled(false)
    householdWizardStore.setIsMFODisabled(false)
  }
})

function backToHome(){
  backtohome.value = true
  router.push("/")
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 4.5rem;
}
.navbar-brand{
  cursor: pointer;
}
:deep(.navbar-buttons) {
  .btn {
    box-shadow: 0rem 0.625rem 0.9375rem #d5525e;
    border-radius: $half;
    letter-spacing: 0;
    font-weight: $font-weight-medium;
    text-transform: none;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 2.5rem;
    background-color: $N-200;
    svg {
      margin-right: $half;
    }
    span {
      letter-spacing: 0 !important;
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
  }
  .btn-icon {
    min-width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $normal + $small;
  }
}
.calculator-container {
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  max-width: 71.5rem;
  width: 100%;
  margin-bottom: 5rem;
}

@media screen and (max-width: $breakpoint-lg-max) {
  .calculator-container {
    width: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-bottom: 2.5rem;
    margin-right: 0;
    margin-left: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .calculator-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 23.4375rem) {
  .container-xxl{
    padding: 0 1rem;
  }
}
</style>
