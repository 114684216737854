<template>
  <div class="text-input text-area">
    <q-input
      :id="id"
      v-model="value"
      standout
      :error="!!veeErrors[0]"
      :label="label"
      bg-color="white"
      type="textarea"
      :counter="showCounter"
      :maxlength="maxLength"
      :rows="5"
      autogrow
      :class="showCounter ? 'counter' : ''"
      @blur="handleChange($event), handleInfoWarningMessages(value)"
      @change="inputChanged"
    >
      <template #label>
        {{ label }}
        <Tooltip :tooltip-content="tooltipContent" />
      </template>
      <template #error>
        <ValidationMessage
          :messages="veeErrors"
          :info-messages="infoMessages"
          :warning-messages="warningMessages"
        />
      </template>
    </q-input>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useField } from 'vee-validate'
import { computed, onMounted } from '@vue/runtime-core'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  label: {
    type: String,
    required: true,
  },

  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },

  validationRules: {
    type: String,
    required: false,
    default: '',
  },

  maxLength: {
    type: String,
    required: false,
    default: '500',
  },

  showCounter: {
    type: Boolean,
    required: false,
    default: true,
  },
  initInputValue: {
    type: String,
    default: null,
  },
  infoMessageRules: {
    type: String,
    default: '',
  },

  warningMessageRules: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])

const infoMessages = ref(null)
const warningMessages = ref(null)

onMounted(() => {
  if (props.initInputValue !== null) {
    value.value = props.initInputValue
  }
})

const showing = ref(false)

const showTooltip = computed(() => {
  if (props.tooltipContent === '') {
    return false
  } else return true
})

function inputChanged() {
  emit('update:modelValue', value.value)
}

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()

  if (veeErrors.value.length > 0) {
    return false
  } else {
    return true
  }
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules
  )
}

const {
  handleChange,
  errors: veeErrors,
  value,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
</script>

<script>
export default {
  name: 'TextAreaInput',
}
</script>
<style lang="scss" scoped>
.text-input.text-area {
  :deep(.q-field__control.relative-position.row.no-wrap.bg-white) {
    min-height: 7.5rem;
  }
  :deep(.q-field--with-bottom .q-field__bottom) {
    padding: 0.25rem 0 0 !important;
  }
  .q-field--standout .q-field__bottom {
    justify-content: space-between;
    .q-field__messages.col {
      display: inline-flex;
      flex-direction: row;
      width: fit-content;
      max-width: fit-content;
      min-width: fit-content;
      flex: 1;
      line-height: 1.25rem !important;
    }
    .q-field__messages > div {
      word-break: unset;
      word-wrap: unset;
      overflow-wrap: unset;
    }
  }
}
:deep(.q-field--standout) {
  &.q-field * {
    color: $dark !important;
    border-width: 0 !important;
  }

  .q-field__control:before {
    background-color: transparent;
  }
  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: 0.75rem !important;
    padding: 0.5rem 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
  }
  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $font-weight-normal;
    color: #4a567c !important;
  }
  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }
  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
  .q-field__counter {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #111a37;
    max-width: 100%;
    text-align: right;
    display: inline-flex;
    width: fit-content;
  }
}
:deep(.counter) {
  .q-field__label {
    color: #111a37 !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 1px solid $border-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 1px solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }
  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;
  svg,
  svg * {
    color: $N-600 !important;
  }
}

:deep(.q-textarea.q-field--labeled .q-field__control-container) {
  padding-top: 1.375rem;
  .q-field__label.no-pointer-events.absolute.ellipsis {
    margin-top: -0.625rem;
  }
}
</style>
