<template>
  <q-btn-toggle
    v-model="model"
    class="insurance-type-choice-input"
    toggle-color="choice"
    :options="[
      { value: 'one', slot: 'one' },
      { value: 'two', slot: 'two' },
      { value: 'three', slot: 'three' },
    ]"
  >
    <template #one>
      <div class="choice-btn">
        <div class="btn-icon">
          <component :is="'IconPropertyChattel'" />
        </div>
        <Title
          :htag="'h4'"
          class="btn-title"
        >
          {{ 'Ingatlan + ingóság' }}
        </Title>
      </div>
    </template>

    <template #two>
      <div class="choice-btn">
        <div class="btn-icon">
          <component :is="'IconProperty'" />
        </div>
        <Title
          :htag="'h4'"
          class="btn-title"
        >
          {{ 'Csak ingatlan' }}
        </Title>
      </div>
    </template>

    <template #three>
      <div class="choice-btn">
        <div class="btn-icon">
          <component :is="'IconChattel'" />
        </div>
        <Title
          :htag="'h4'"
          class="btn-title"
        >
          {{ 'Csak ingóság' }}
        </Title>
      </div>
    </template>
  </q-btn-toggle>
</template>

<script setup>
import { ref, onMounted } from 'vue'
const model = ref(null)
</script>

<style lang="scss" scoped>
.insurance-type-choice-input {
  display: flex !important;
  flex-direction: row !important;
  gap: 0.5rem;
  :deep(button.q-btn) {
    border-radius: 0.75rem;
    padding: 0;
    min-width: 6.875rem;
    width: calc(((100% - 1rem) / 3));
  }
  box-shadow: none;
  .choice-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    gap: 1rem;
    height: 8rem;
    width: 100%;
    background: $white;
    border: $white 0.125rem solid;
    box-shadow: $box-shadow;
    border-radius: 0.75rem;

    .btn-title {
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $dark;
      margin: 0;
      letter-spacing: normal;
      text-transform: none;
      text-align: left;
    }
  }
  :deep(.bg-choice) {
    background: transparent;
    .choice-btn {
      color: $blue;
      border: 0.125rem solid $blue;
    }
  }
}
</style>
