<template>
  <div class="container-xxl">
    <nav
      id="navbarDesktop"
      class="navbar navbar-expand-lg navbar-dark"
    >
      <div
        v-if="searchBar"
        class="col-2 col-xl-3 px-0"
      >
        <router-link
          :to="'/'"
          class="navbar-brand justify-content-start"
        >
          <img
            :src="`${imageUrlHelper.getImageUrl(
              '/genertel-logo.svg',
              componentUrl
            )}`"
            :class="{ 'change-logo-size-on-scroll': showCompact }"
            width="112"
            height="32"
            alt="Genertel logo"
          >
        </router-link>
      </div>
      <div
        v-else
        class="branding-with-searchbar px-0"
      >
        <router-link
          :to="'/'"
          class="navbar-brand justify-content-start"
        >
          <img
            :src="`${imageUrlHelper.getImageUrl(
              '/genertel-logo.svg',
              componentUrl
            )}`"
            :class="{ 'change-logo-size-on-scroll': showCompact }"
            width="112"
            height="32"
            alt="Genertel logo"
          >
        </router-link>
      </div>
      <div
        class="navbar-buttons-mobile d-flex d-lg-none align-items-center justify-content-end col-xl-3 px-0"
      >
        <a
          :href="clientZoneLoginUrl"
          class="btn-icon text-white t-sm"
        ><PhIconManager ph-icon="ph-user-circle" />
        </a>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMobile"
          aria-controls="navbarMobile"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="menuOpenToggler"
        >
          <div class="navbarMobile-toggler-icon icon-holder">
            <PhIconManager
              ph-icon="ph-list"
              :size="20"
              weight="fill"
            />
          </div>
          <div class="icon-holder close">
            <PhIconManager
              ph-icon="ph-x"
              :size="20"
              weight="fill"
              color="white"
            />
          </div>
        </button>
      </div>
      <div
        v-if="searchBar"
        class="col-xl-6 collapse navbar-collapse justify-content-center px-0"
      >
        <ul class="navbar-nav">
          <li
            v-for="(headerItem, index) in headerItems.children"
            :key="headerItem.name"
            :class="getNavbarClass(headerItem)"
          >
            <NavigableLink
              v-if="!isDropdownOrSubmenu(headerItem)"
              :link-object="headerItem"
              class="nav-link t-sm gtmMenuItemButton"
            >
              {{ headerItem.title }}
            </NavigableLink>
            <a
              v-if="isDropdownOrSubmenu(headerItem)"
              :id="`navbar${index}`"
              class="nav-link dropdown-toggle t-sm gtmMenuItemButton"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ headerItem.title }}
            </a>
            <ul
              v-if="isDropdownOrSubmenu(headerItem)"
              class="dropdown-menu"
              :aria-labelledby="`navbar${index}`"
            >
              <li
                v-for="dropdownItem in headerItem.children"
                :key="dropdownItem.name"
              >
                <NavigableLink
                  :link-object="dropdownItem"
                  class="dropdown-item"
                >
                  <div>{{ dropdownItem.title }}</div>
                  <PhIconManager
                    ph-icon="ph-caret-right"
                    :size="16"
                  />
                </NavigableLink>
                <ul
                  v-if="isDropdownOrSubmenu(dropdownItem)"
                  class="submenu"
                >
                  <li
                    v-for="submenuItem in dropdownItem.children"
                    :key="submenuItem.name"
                  >
                    <NavigableLink
                      :link-object="submenuItem"
                      class="t-xs"
                    >
                      <div>{{ submenuItem.title }}</div>                      
                      <PhIconManager
                        ph-icon="ph-caret-right"
                        :size="16"
                      />
                    </NavigableLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="search-bar d-none d-lg-block"
      >
        <q-input
          v-model="searchPhrase"
          standout
          :label="searchLabelText"
          label-color="dark"
          bg-color="white"
          @keyup.enter="onEnter"
        />
      </div>
      <div
        v-if="searchBar"
        class="navbar-buttons d-none d-lg-flex justify-content-end col-3 px-0"
      >
        <a
          href="#"
          class="btn-icon text-dark t-sm"
          @click="searchBar = !searchBar"
        >
          <div
            v-if="searchBar"
            class="icon-holder text-white"
          >
            <PhIconManager ph-icon="ph-magnifying-glass" />
          </div>
          <div
            v-else
            class="icon-holder close"
          >
            <PhIconManager
              ph-icon="ph-x"
              weight="fill"
            />
          </div>
        </a>
        <router-link
          to="/akadalymentes"
          class="btn-icon text-white t-sm"
        >
          <PhIconManager ph-icon="ph-circle-half-tilt" />
        </router-link>
        <q-btn
          :href="clientZoneLoginUrl"
          class="btn btn-lg btn-light text-dark"
          :class="{ 'change-btn-size-on-scroll': showCompact }"
        >          
          <PhIconManager
            ph-icon="ph-user-circle"
            :size="20"
          />
          <span>Ügyfélportál</span>
        </q-btn>
      </div>
      <div
        v-else
        class="navbar-buttons with-searchbar d-none d-lg-flex justify-content-end px-0"
      >
        <a
          href="#"
          class="btn-icon text-dark t-sm"
          @click="searchBar = !searchBar"
        >
          <div
            v-if="searchBar"
            class="icon-holder text-white"
          >            
            <PhIconManager ph-icon="ph-magnifying-glass" />
          </div>
          <div
            v-else
            class="icon-holder close"
          >            
            <PhIconManager
              ph-icon="ph-x"
              weight="fill"
              color="white"
            />
          </div>
        </a>
        <a
          href="/akadalymentes"
          class="btn-icon text-white t-sm"
        ><PhIconManager ph-icon="ph-circle-half-tilt" /></a>
        <q-btn
          :href="clientZoneLoginUrl"
          class="btn btn-lg btn-light text-dark"
        >          
          <PhIconManager
            ph-icon="ph-user-circle"
            :size="20"
          />
          <span>Ügyfélportál</span>
        </q-btn>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref, watch, computed, inject } from 'vue'
import imageUrlHelper from '../utils/infrastructure/imageUrlHelper.js'
import { useRouter } from 'vue-router'
import { useSearchStore } from  '../stores/searchStore.js'

const clientZoneLoginUrl = import.meta.env.VITE_CLIENTZONE_BASEURL + "bejelentkezes";

const searchStore = useSearchStore()

const props = defineProps({
  headerItems: {
    type: Object,
    required: true,
    default: () => {},
  },
  showCompactHeader: {
    type: Boolean,
    required: true,
    default: false,
  },
})

const router = useRouter()

const searchPhrase = ref('')
const searchLabelText = ref('Kereséshez kezdjen el gépelni....')
const menuOpen = ref(true)
const searchBar = ref(true)
const componentUrl = import.meta.url
const showLoadingIndicator = inject('showLoadingIndicator')

const emit = defineEmits(['togglemenu'])

const showCompact = ref(false)

watch(
  () => props.showCompactHeader,
  (value) => {
    showCompact.value = value
  }
)

watch(
  () => router.currentRoute.value.path,
  (value, oldValue) => {
    if(value !== oldValue && value !== '/keresesi-eredmenyek'){
      searchBar.value = true;
    }
  }
) 

function menuOpenToggler() {
  emit('togglemenu', menuOpen.value)
  menuOpen.value = !menuOpen.value
}

function isDropdownOrSubmenu(item) {
  return item.children.length > 0
}

function getNavbarClass(item) {
  return isDropdownOrSubmenu(item) ? 'nav-item dropdown' : 'nav-item'
}

function onEnter(){
  if(searchPhrase.value.length < 2){
    searchLabelText.value = "Legalább 2 karaktert írj be!"
  }
  else{
    showLoadingIndicator.value = true
    searchStore.setSearchPhrase(searchPhrase.value)
    if(router.currentRoute.value.path !== '/keresesi-eredmenyek'){
      router.push({ path: '/keresesi-eredmenyek' })
    }    
  }
}
</script>

<script>
export default {
  name: 'DesktopHeader',
}
</script>

<style lang="scss" scoped>
$height-transition: 0.4s;

:deep(.btn-light) {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
:deep(.q-field--standout) {
  &.q-field * {
    color: $dark !important;
    border-width: 0 !important;
  }

  .q-field__control:before {
    background-color: transparent;
  }
  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
  }
  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }
  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }
  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 1px solid $border-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 0.125rem solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }
  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;
  svg,
  svg * {
    color: $N-600 !important;
  }
}
:deep(.nav-item a) {
  border-radius: 0.375rem;
}

:deep(li.nav-item) {
  & > a:hover {
    background-color: $R-400;
    border-radius: 0.375rem;
  }
}
:deep(div.dropdown-item:hover) {
  color: $D-500 !important;
}
:deep(.nav-item.dropdown a.show) {
  background-color: $R-600;
  border-radius: 0.375rem;
}

:deep(.with-searchbar) {
  min-width: 17.25rem;
}

@media screen and (max-width: $breakpoint-lg-max) {
  :deep(.with-searchbar) {
    min-width: 16.25rem;
  }
}
:deep(.search-bar) {
  max-width: 49.5625rem;
}

:deep(.branding-with-searchbar) {
  flex: 1;
}

.navbar-toggler > .close {
  display: flex;
}
.navbar-toggler.collapsed > .close,
.navbar-toggler:not(.collapsed) > .navbarMobile-toggler-icon {
  display: none;
}

@media screen and (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl-max) {
  .change-logo-size-on-scroll {
    height: 1.75rem;
    transition: height $height-transition;
  }
  .change-btn-size-on-scroll {
    max-height: 2rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    align-self: center;
    min-height: unset;
  }
}
</style>
