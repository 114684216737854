<template>
  <div class="game-input-holder">
    <div class="game-blog-holder">
      <div class="blog-picture-holder">
        <img
          class="blog-picture"
          src=""
        >
      </div>
      <div class="blog-title-holder">
        <TitleTooltip
          htag="h3"
          class="blog-title"
        >
          Balesetbiztosítás akció
        </TitleTooltip>
      </div>
    </div>
    <div class="game-paragraph-holder">
      <p class="game-paragraph">
        A <a href="">balesetbiztosítás akciónkban</a> való részvételhez -
        melynek keretében 5.000 Ft-os Decathlon ajándékkártyát kaphat, a
        checkbox kipipálásával kérjük, fogadja el annak feltételeit: szeretnék
        részt venni az akcióban és hozzájárulok ahhoz, hogy a részvételhez
        megadott adataimat a Genertel Biztosító e célból kezelje. Az adatok
        kezeléséről társaságunk
        <a href="">Adatkezelési tájékoztatójában</a> talál bővebb információt.
      </p>
      <div class="game-checkbox">
        <CheckboxInput>
          Megadom a hozzájárulásomat és részt veszek a
          <a href="">promócióban.</a>
        </CheckboxInput>
      </div>
    </div>
  </div>
</template>
<script>
import CheckboxInput from './CheckboxInput.vue'
</script>
<style lang="scss" scoped>
.game-blog-holder {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.game-input-holder {
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06),
    0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .blog-picture-holder {
    .blog-picture {
      width: 12.25rem;
      height: 8rem;
    }
  }
  .blog-title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: $D-500;
    word-break: break-word;
  }
  .game-paragraph-holder{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .game-paragraph {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #4a567c;
    margin: 0;
    a {
      color: $secondary;
      text-decoration: underline;
    }
  }
  .game-checkbox {
    :deep(.q-checkbox) {
      margin: 0 !important;
      .q-checkbox__label {
        display: flex;
        align-items: center;
      }
      .q-item__label {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem !important;
        color: $D-700;
        a {
          color: $secondary;
          text-decoration: underline;
        }
      }
    }
  }
}
@media screen and (max-width: $breakpoint-md-max){
  .game-input-holder{
    gap: .5rem;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .game-input-holder {
    gap: 0;
  }
  .game-blog-holder {
    flex-direction: column;
    gap: 0.5rem;
  }
  .blog-picture-holder {
    display: flex;
    justify-content: center;
  }
}
</style>
