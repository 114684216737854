<template>
  <div class="container-fluid mobile-menu p-0 d-flex d-lg-none">
    <nav class="navbar navbar-light py-0 w-100">
      <div
        id="navbarMobile"
        class="collapse navbar-collapse justify-content-center px-0"
      >
        <ul class="navbar-nav">
          <li
            v-for="(headerItem, index) in headerItems.children"
            :key="headerItem.name"
            :class="getNavbarClass(headerItem)"
          >
            <NavigableLink
              v-if="!isDropdownOrSubmenu(headerItem)"
              :link-object="headerItem"
              class="nav-link t-sm gtmMenuItemButton"
              @click="closeMenu()"
            >
              {{ headerItem.title }}
            </NavigableLink>
            <a
              v-if="isDropdownOrSubmenu(headerItem)"
              :id="`navbar${index}`"
              class="nav-link dropdown-toggle t-sm gtmMenuItemButton"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              :aria-expanded="
                headerItem.title == 'Biztosítások' ? 'true' : 'false'
              "
              :class="headerItem.title == 'Biztosítások' ? 'show' : ''"
            >
              {{ headerItem.title }}
              <span class="caret">
                <PhIconManager
                  ph-icon="ph-plus"
                  class="close"
                  :size="16"
                /><PhIconManager
                  ph-icon="ph-minus"
                  class="mobileHeader-dropdown-icon"
                  :size="16"
                />
              </span>
            </a>
            <ul
              v-if="isDropdownOrSubmenu(headerItem)"
              class="dropdown-menu"
              :class="headerItem.title == 'Biztosítások' ? 'show' : ''"
              :aria-labelledby="`navbar${index}`"
              :data-bs-popper="headerItem.title == 'Biztosítások' ? 'none' : ''"
            >
              <li
                v-for="dropdownItem in headerItem.children"
                :key="dropdownItem.name"
              >
                <NavigableLink
                  :link-object="dropdownItem"
                  class="dropdown-item"
                  @click="closeMenu()"
                >
                  <div>{{ dropdownItem.title }}</div>
                  <PhIconManager
                    ph-icon="ph-caret-right"
                    :size="16"
                  />
                </NavigableLink>
                <ul
                  v-if="isDropdownOrSubmenu(dropdownItem)"
                  class="submenu"
                >
                  <li
                    v-for="submenuItem in dropdownItem.children"
                    :key="submenuItem.name"
                  >
                    <NavigableLink
                      :link-object="submenuItem"
                      class="t-xs"
                      @click="closeMenu()"
                    >
                      <div>{{ submenuItem.title }}</div>
                      <PhIconManager
                        ph-icon="ph-caret-right"
                        :size="16"
                      />
                    </NavigableLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li class="nav-item bg-black">
            <a
              class="nav-link t-sm text-white"
              href="/akadalymentes"
            >
              <div>Akadálymentes változat</div>
              <PhIconManager ph-icon="ph-circle-half-tilt" />
            </a>
          </li>
        </ul>
        <div class="search-bar">
          <q-input
            v-model="searchPhrase"
            standout
            :placeholder="searchLabelText"
            :dense="dense"
            bg-color="white"
            @keyup.enter="onEnter"
          >
            <template #append>
              <PhIconManager
                class="search-icon"
                ph-icon="ph-magnifying-glass"
                :size="20"
                @click="onEnter"
              />
            </template>
          </q-input>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref, watch, watchEffect, inject } from 'vue'
import { Collapse } from 'bootstrap'
import { useRouter } from 'vue-router'
import { useSearchStore } from '../stores/searchStore.js'

const props = defineProps({
  menuState: {
    type: Boolean,
  },

  headerItems: {
    type: Object,
    required: true,
    default: () => {},
  },
})

const router = useRouter()
const searchStore = useSearchStore()
const biztositasokOpen = ref(true)
const ugyintezesOpen = ref(true)
const text = ref('')
const searchPhrase = ref('')
const searchLabelText = ref('Kereséshez kezdjen el gépelni....')
const dense = ref(false)
const clickedHeaderItem = ref(-1)
const emit = defineEmits(['togglemenu'])
const showLoadingIndicator = inject('showLoadingIndicator')

watch(
  () => props.menuState,
  (value, prevValue) => {
    if (value === false) {
      biztositasokOpen.value = true
      ugyintezesOpen.value = true
    }
  }
)
function headerItemToggler(index) {
  clickedHeaderItem.value = index
}

function isDropdownOrSubmenu(item) {
  return item.children.length > 0
}

function closeMenu() {
  var myCollapse = document.getElementById('navbarMobile')
  var bsCollapse = new Collapse(myCollapse)
  emit('togglemenu', false)
  bsCollapse.hide()
}

function getNavbarClass(item) {
  return isDropdownOrSubmenu(item) ? 'nav-item dropdown' : 'nav-item'
}

function onEnter() {
  if (searchPhrase.value.length < 2) {
    searchLabelText.value = 'Legalább 2 karaktert írj be!'
  } else {
    showLoadingIndicator.value = true
    searchStore.setSearchPhrase(searchPhrase.value)
    closeMenu()
    if (router.currentRoute.value.path !== '/keresesi-eredmenyek') {
      router.push({ path: '/keresesi-eredmenyek' })
    }
  }
}
</script>

<script>
export default {
  name: 'MobileHeader',
}
</script>

<style lang="scss" scoped>
.nav-link > .caret > .close {
  display: inline;
}
.nav-link.show > .caret > .close,
.nav-link:not(.show) > .caret > .mobileHeader-dropdown-icon {
  display: none;
}
:deep(.q-field--standout) {
  &.q-field * {
    color: $dark !important;
    border-width: 0 !important;
  }

  .q-field__control:before {
    background-color: transparent;
  }
  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
  }
  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }
  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }
  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 1px solid $border-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 0.125rem solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}
:deep(div.dropdown-item:hover) {
  color: $D-500 !important;
}
:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }
  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;
  svg,
  svg * {
    color: $N-600 !important;
  }
}
:deep(.nav-link.dropdown-toggle.show) {
  background-color: $N-100 !important;
}

.search-bar {
  .search-icon {
    width: unset;
  }
}
</style>
