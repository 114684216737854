<template>
  <div class="row submodus-row">
    <div class="col-2 submodus-name-holder">
      <div
        v-if="isGift"
        class="submodus-gift-holder"
      >
        <div class="submodus-gift">
          <PhIconManager
            height=".9375rem"
            color="white"
            width=".9375rem"
            ph-icon="ph-gift"
          />
        </div>
      </div>
      <slot name="first" />
    </div>
    <div
      v-if="viewModel.MNBMode == undefined"
      class="col"
      :class="[
        viewModel.Packages.Extra.Selected.Value ? 'highlighted' : '',
        isGTDisabled ? 'disabled-package' : '',
      ]"
    >
      <div
        v-if="$slots.second"
        class="submodus-input-holder"
        :class="isExtraTextOnly ? 'text-only' : ''"
      >
        <slot name="second" />
      </div>
      <div
        v-if="$slots.secondIcon"
        class="submodus-icon-holder"
      >
        <slot name="secondIcon" />
      </div>
    </div>
    <div
      v-if="viewModel.MNBMode == undefined"
      class="col"
      :class="[
        viewModel.Packages.Komfort.Selected.Value ? 'highlighted' : '',
        isGTDisabled ? 'disabled-package' : '',
      ]"
    >
      <div
        v-if="$slots.third"
        class="submodus-input-holder"
        :class="isKomfortTextOnly ? 'text-only' : ''"
      >
        <slot name="third" />
      </div>
      <div
        v-if="$slots.thirdIcon"
        class="submodus-icon-holder"
      >
        <slot name="thirdIcon" />
      </div>
    </div>
    <div
      v-if="viewModel.MNBMode == undefined"
      class="col"
      :class="[
        viewModel.Packages.StartPlus.Selected.Value ? 'highlighted' : '',
        isGTDisabled ? 'disabled-package' : '',
      ]"
    >
      <div
        v-if="$slots.fourth"
        class="submodus-input-holder"
        :class="isStartPlusTextOnly ? 'text-only' : ''"
      >
        <slot name="fourth" />
      </div>
      <div
        v-if="$slots.fourthIcon"
        class="submodus-icon-holder"
      >
        <slot name="fourthIcon" />
      </div>
    </div>
    <div
      class="col"
      :class="[
        viewModel.Packages.Start.Selected.Value &&
          viewModel.MNBMode == undefined
          ? 'highlighted'
          : '',
        isMFODisabled ? 'disabled-package' : '',
      ]"
    >
      <div
        v-if="$slots.fifth"
        class="submodus-input-holder"
        :class="[
          isStartTextOnly ? 'text-only' : '',
          viewModel.MNBMode != null &&
            viewModel.MNBMode != undefined &&
            viewModel.MNBMode == true
            ? 'mnb-submodus-input-holder'
            : '',
        ]"
      >
        <slot name="fifth" />
      </div>
      <div
        v-if="$slots.fifthIcon"
        class="submodus-icon-holder"
        :class="
          viewModel.MNBMode != null &&
            viewModel.MNBMode != undefined &&
            viewModel.MNBMode == true
            ? 'mnb-submodus-input-holder'
            : ''
        "
      >
        <slot name="fifthIcon" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
let viewModel = inject('viewModel')
let { isMFODisabled } = inject('isMFODisabled')
let { isGTDisabled } = inject('isGTDisabled')
const props = defineProps({
  isGift: {
    type: Boolean,
    default: false,
  },
  isExtraTextOnly: {
    type: Boolean,
    default: false,
  },
  isKomfortTextOnly: {
    type: Boolean,
    default: false,
  },
  isStartPlusTextOnly: {
    type: Boolean,
    default: false,
  },
  isStartTextOnly: {
    type: Boolean,
    default: false,
  },
})
</script>

<style lang="scss" scoped>
.submodus-name-holder {
  background: #e6e8f3;
  border-left: 0.0625rem solid #dde1ef;
  border-bottom: 0.0625rem solid #dde1ef;
  border-right: 0.0625rem solid #dde1ef;
  padding: 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #1d2c5b;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  .submodus-gift-holder {
    position: absolute;
    background: #a124ad;
    transform: rotate(-45deg);
    right: -0.375rem;
    top: -1rem;
    width: 1.6875rem;
    height: 2.9375rem;
    .submodus-gift {
      transform: rotate(45deg);
      top: 0.9375rem;
      position: absolute;
      right: 0.5625rem;
    }
  }
}

.submodus-icon-holder {
  background: #ffffff;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 0.0625rem solid #dde1ef;
  border-bottom: 0.0625rem solid #dde1ef;
  height: 100%;
}
.submodus-input-holder {
  height: 100%;
  background: #ffffff;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 0.0625rem solid #dde1ef;
  border-bottom: 0.0625rem solid #dde1ef;
  &.text-only {
    justify-content: center;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    align-items: start;
    :deep(div) {
      padding-left: 0.75rem;
    }
  }
  :deep(.q-select) {
    width: 100%;
  }
}

.submodus-input-holder.mnb-submodus-input-holder {
  align-items: stretch;
  :deep(.text-input) {
    width: 100%;
  }
}
</style>
