<template>
  <div class="modal-btn-holder">
    <q-btn
      id="KALK_125_MODAL"
      class="btn btn-secondary btn-outline btn-back"
      @click="dialogOpen = true"
    >
      Kiszámolom részletesen
    </q-btn>
  </div>

  <Dialog
    :key="dialogOpen"
    class="calculator-modal"
    :open="dialogOpen"
  >
    <q-card-section>
      <div class="dialogtitle-holder">
        <div class="dialog-title">
          Háztartási ingóságok
        </div>
        <div class="dialog-close-button">
          <q-btn
            v-close-popup
            @click="closeModal"
          >
            <PhIconManager
              ph-icon="ph-x"
              color="#828BA8"
            />
          </q-btn>
        </div>
      </div>
      <div
        v-if="false"
        class="dialog-text"
      />
      <q-form 
        ref="inputRefEstatePersonalytyModal"
        :key="sumEstatePersonalityValues > 25000000"
      >
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Háztartási gépek eszközök
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_126"
                v-model:model-value="localHouseholdAppliancesValue"
                label="Kérjük, adja meg a becsült összeget!"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Szerszámok, nem beépített lámpák
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_283"
                v-model:model-value="localToolsAndNonIntegratedLampsValue"
                :label="'Kérjük, adja meg a becsült összeget!'"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Telekommunikációs eszközök
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_127"
                v-model:model-value="localTelecommunicationDevicesValue"
                :label="'Kérjük, adja meg a becsült összeget!'"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Bútorok
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_128"
                v-model:model-value="localFurnitureValue"
                :label="'Kérjük, adja meg a becsült összeget!'"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Ruhanemű és lábbeli
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_129"
                v-model:model-value="localClothesValue"
                :label="'Kérjük, adja meg a becsült összeget!'"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Konyhai és fürdőszobai felszerelések (pl.: edények, vegyszerek,
                kozmetikumok)
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_130"
                v-model:model-value="localKitchenAndBathroomEquipmentValue"
                :label="'Kérjük, adja meg a becsült összeget!'"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Textilek (pl.: ágynemű, függöny, konyharuha, törölköző)
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_131"
                v-model:model-value="localTextileValue"
                :label="'Kérjük, adja meg a becsült összeget!'"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
        <Row66Sm class="input-container modal-input-container">
          <template #first>
            <div class="calculator-description-holder">
              <TitleTooltip
                htag="h1"
                class="input-title"
              >
                Egyéb használati tárgyak (pl.: könyvek, hangszerek, játékok,
                dekoráció)
              </TitleTooltip>
            </div>
          </template>
          <template #second>
            <div class="input-holder">
              <NumberInput
                id="KALK_132"
                v-model:model-value="localMiscellaneousItemsValue"
                :label="'Kérjük, adja meg a becsült összeget!'"
                :postfix="'Ft'"
                mask="### ### ### ### ### ### ###"
                :reverse-fill-mask="true"
                validation-rules="VAL_363_MODULE|VAL_445"
              />
            </div>
          </template>
        </Row66Sm>
      </q-form>
      <ModalButtons
        class="modal-btns"
        @close-modal="closeModal"
        @apply-changes="applyChanges"
      />
    </q-card-section>
  </Dialog>
</template>
<script setup>
import { ref, inject, computed, onMounted, watch } from 'vue'
import { focusFirstValidation } from '@/utils/validations/validationFocus'
import { customDefineRule } from '@/utils/validations/customDefineRule';
import * as ValidationMessages from '@/utils/validations/validationMessages.js'

const { getFormattedNumber } = inject('util')
const viewModel = inject('viewModel')
const dialogOpen = ref(false)
const emits = defineEmits(['updateEstatePersonalytySumValue'])

const localHouseholdAppliancesValue = ref(0)
const localToolsAndNonIntegratedLampsValue = ref(0)
const localTelecommunicationDevicesValue = ref(0)
const localFurnitureValue = ref(0)
const localClothesValue = ref(0)
const localKitchenAndBathroomEquipmentValue = ref(0)
const localTextileValue = ref(0)
const localMiscellaneousItemsValue = ref(0)

const props = defineProps({
  targetNumber: {
    type: Number,
    required: true,
  },
})

function closeModal() {
  dialogOpen.value = false
}

const applyBtnClicked = ref(false)

async function applyChanges() {
  applyBtnClicked.value = true
  const isValid = await inputRefEstatePersonalytyModal.value.validate()
  if (isValid) {
    updateEstatePersonalytySumValue()
    applyBtnClicked.value = false
    closeModal()    
  } else {
    focusFirstValidation()
  }
  
  applyBtnClicked.value = false
}

const inputRefEstatePersonalytyModal = ref(null)

const sumEstatePersonalityValues = computed(() => {
  let sum =
    Number(localHouseholdAppliancesValue.value) +
    Number(localToolsAndNonIntegratedLampsValue.value) +
    Number(localTelecommunicationDevicesValue.value) +
    Number(localFurnitureValue.value) +
    Number(localClothesValue.value) +
    Number(localKitchenAndBathroomEquipmentValue.value) +
    Number(localTextileValue.value) +
    Number(localMiscellaneousItemsValue.value)  
    return sum;
})

  customDefineRule('VAL_363_MODULE', (value) => {
      if (value === 0) return true

      if (sumEstatePersonalityValues.value > 25000000) {
        return ValidationMessages.VAL_363
      }
      return true
  })


customDefineRule('VAL_445', (value) => {
  if(!applyBtnClicked.value) return true
  let sum = sumEstatePersonalityValues.value
  if (value != 0 && sum < props.targetNumber) {
    return ValidationMessages.VAL_445.replace('{0}', getFormattedNumber(props.targetNumber))
  }
  return true
})

watch(
  () => dialogOpen.value,
  (value) => {
    if (value) {
      localHouseholdAppliancesValue.value =
        viewModel.value.HouseholdAppliancesValue.Value ?? 0
      localToolsAndNonIntegratedLampsValue.value =
        viewModel.value.ToolsAndNonIntegratedLampsValue.Value ?? 0
      localTelecommunicationDevicesValue.value =
        viewModel.value.TelecommunicationDevicesValue.Value ?? 0
      localFurnitureValue.value = viewModel.value.FurnitureValue.Value ?? 0
      localClothesValue.value = viewModel.value.ClothesValue.Value ?? 0
      localKitchenAndBathroomEquipmentValue.value =
        viewModel.value.KitchenAndBathroomEquipment.Value ?? 0
      localTextileValue.value = viewModel.value.TextileValue.Value ?? 0
      localMiscellaneousItemsValue.value =
        viewModel.value.MiscellaneousItemsValue.Value ?? 0
    }
  }
)

function updateEstatePersonalytySumValue(value) {
  viewModel.value.HouseholdAppliancesValue.Value =
    localHouseholdAppliancesValue.value
  viewModel.value.ToolsAndNonIntegratedLampsValue.Value =
    localToolsAndNonIntegratedLampsValue.value
  viewModel.value.TelecommunicationDevicesValue.Value =
    localTelecommunicationDevicesValue.value
  viewModel.value.FurnitureValue.Value = localFurnitureValue.value
  viewModel.value.ClothesValue.Value = localClothesValue.value
  viewModel.value.KitchenAndBathroomEquipment.Value =
    localKitchenAndBathroomEquipmentValue.value
  viewModel.value.TextileValue.Value = localTextileValue.value
  viewModel.value.MiscellaneousItemsValue.Value =
    localMiscellaneousItemsValue.value
  emits('updateEstatePersonalytySumValue')
}
</script>
<style lang="scss" scoped>
.dialog-close-button{
  width: 1.5rem;
}
</style>
