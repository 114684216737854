<template>
  <q-checkbox
    :id="id"
    v-model="value"
    class="m-0 p-0"
    :class="[veeErrors[0] ? 'error' : '',(warningMessages?.length > 0 && veeErrors.length == 0)?'has-warning':'']"
    :error="!!veeErrors[0] || warningMessages?.length > 0"
    @update:model-value="inputChanged"
    @focus="setMetaTouched"
  >
    <q-item-label>
      <slot />
    </q-item-label>
  </q-checkbox>
  <div
    v-if="veeErrors[0]"
    class="q-field__inner q-field--with-bottom"
  >
    <div class="q-field__bottom row items-start q-field__bottom--animated">
      <div class="q-field__messages col">
        <ValidationMessage
          :messages="veeErrors"
          :info-messages="infoMessages"
          :warning-messages="warningMessages"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, inject } from 'vue'
import { useField } from 'vee-validate'
import {
  getInfoMessagesForValue,
  getWarningMessagesForValue,
} from '@/utils/validations/customValidator.js'
import { useFormChild } from 'quasar'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  validationRules: {
    type: String,
    default: '',
  },

  infoMessageRules: {
    type: String,
    default: '',
  },

  warningMessageRules: {
    type: String,
    default: '',
  },

  modelValue: {
    type: Boolean,
    default: false,
  },

  isDisabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

const removeValueIfNotValid = inject('_removeValueIfNotValid')

const infoMessages = ref(null)
const warningMessages = ref(null)

async function validateQForm() {
  // run vee-validate validations
  await veeValidate()
  if (veeErrors.value.length > 0) {
    if(removeValueIfNotValid.value && !meta.valid){
      value.value = null;
      inputChanged();
      return true;
    }
  } else {
    return true
  }
}

function inputChanged() {
  emit('update:modelValue', value.value)
  validateQForm()
}

function resetValidationQForm() {}

function handleInfoWarningMessages(value) {
  infoMessages.value = getInfoMessagesForValue(value, props.infoMessageRules)
  warningMessages.value = getWarningMessagesForValue(
    value,
    props.warningMessageRules
  )
}
watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
    validateQForm()
  }
)

onMounted(() => {
  value.value = props.modelValue
});

function setMetaTouched() {
  meta.touched = true
}

const {
  errors: veeErrors,
  value,
  meta,
  validate: veeValidate,
} = useField(props.id, props.validationRules, {
  validateOnValueUpdate: false,
  bails: true,
})

useFormChild({
  validate: validateQForm, // Function; Can be async; // Should return a Boolean (or a Promise resolving to a Boolean)
  resetValidation: resetValidationQForm, // Optional function which resets validation
  requiresQForm: false, // should it error out if no parent QForm is found?
})
</script>

<script>
export default {
  name: 'CheckboxInput',
}
</script>

<style lang="scss">
.q-checkbox__inner.relative-position.non-selectable {
  margin-right: 0.75rem;
  &.q-checkbox__inner--falsy {
    width: 1.25rem;
    height: 1.25rem;
  }
  &.q-checkbox__inner--truthy {
    color: $secondary !important;
    width: 1.25rem;
    height: 1.25rem;
  }
  &.q-checkbox__inner--indet {
    width: 1.25rem;
    height: 1.25rem;
    color: white;
  }
}
.error {
  .q-checkbox__bg{
    box-shadow: unset;
    border: 1px solid $V-500;
  }
  line-height: 1.25rem;
}
.q-checkbox__label.q-anchor--skip {
  width: auto;
}

.q-item__label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.q-focus-helper {
  display: none;
}

.q-item__section.column.q-item__section--side.justify-center.q-focusable.relative-position.cursor-pointer {
  width: auto;
}

.q-item__section.column.q-item__section--main.justify-center {
  color: $secondary;
}

.q-checkbox .q-icon {
  display: none;
}

.q-checkbox__inner {
  font-size: 1.25rem;
}
.q-checkbox__bg {
  top: 0;
  left: 0;
  width: calc(1.25rem + 0.125rem);
  height: calc(1.25rem + 0.125rem);
  background: white;
  border: 1px solid $border-normal;
  box-shadow: $box-shadow-inset;
  border-radius: $small;
  display: flex;
  align-items: center;
  justify-content: center;
  svg.fit {
    width: $normal !important;
    height: $normal !important;
    top: 0.125rem;
    left: 0.125rem;
  }
}

.q-checkbox__label.q-anchor--skip {
  width: calc(100% - 2rem);
}
.q-checkbox {
  align-items: flex-start;
}

.checkbox-read-more {
  margin-top: -0.5rem;
  margin-bottom: $large;
}
</style>
