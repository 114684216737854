<template>
  <q-dialog
    v-model="dialogOpen"
    persistent
    class="dialog-holder"
    @show="$emit('dialogShown')"
  >
    <q-card>
      <slot />
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, onMounted, watch } from '@vue/runtime-core'
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['setDialogOpen'])
const dialogOpen = ref(false)

onMounted(() => {
  dialogOpen.value = props.open
})

watch(dialogOpen, (value, prevValue) => {
  emit('setDialogOpen', value)
})

</script>

<script>
export default {
  name: 'DialogElement',
}
</script>

<style lang="scss" scoped>
:deep(.q-card__section--vert) {
  padding: 0;
}
:deep(.q-card__actions) {
  padding: 0;
}
.q-card {
  width: 57rem;
  padding: 2.5rem;
  background: #fbfbfd;

  box-shadow: 0rem 1.5625rem 3.125rem rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  text-align: center;
  max-height: 100% !important;
}
.q-dialog__inner--minimized > div {
  max-width: 57rem;
}

@media screen and (max-width: $breakpoint-md-max) {
  .q-card {
    width: 42.125rem;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .q-card {
    width: 21.4375rem;
    padding: 1rem;
  }
}
</style>
