<template>
  <q-select
    v-model="model"
    standout="bg-white text-dark"
    :options="options"
    label="Standout"
  />
</template>

<script setup>
import { ref } from 'vue'

const model = ref(null)
const options = ['Google', 'Facebook', 'Twitter', 'Apple', 'Oracle']
</script>

<script>
export default {
  name: 'SelectInputEmpty',
}
</script>

<style lang="scss" scoped>
:deep(.q-field--standout) {
  &.q-field * {
    color: $dark !important;
    border-width: 0 !important;
  }

  .q-field__control:before {
    background-color: transparent;
  }
  &.q-field--highlighted .q-field__control {
    box-shadow: none;
  }

  .q-field__control {
    border-radius: $half !important;
    padding: 0 1rem !important;
    box-shadow: inset 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.06) !important;
    border: 1px solid #f5f4f8 !important;
  }
  .q-field__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
  .q-field--float {
    .q-field__label {
      transform: translateY(-40%) scale(10 / 12) !important;
    }
  }
  .q-field__native,
  .q-field__prefix,
  .q-field__suffix,
  .q-field__input {
    font-weight: $font-weight-bold !important;
  }
  .q-field__append {
    background: transparent !important;
    width: auto !important;
  }
}

:deep(.q-field--standout:hover) {
  .q-field__control {
    border: 1px solid $border-hover !important;
  }
}

:deep(.q-field--standout.q-field--focused) {
  .q-field__control {
    border: 1px solid $border-active !important;
  }
}

:deep(.q-field--focused:hover) {
  .q-field__control {
    background-color: white !important;
  }
}

:deep(.q-field--float:hover) {
  .q-field__control {
    border: 0.125rem solid $border-data-hover !important;
    background-color: $input-data-bg-hover !important;
  }
}

:deep(.q-field--float:hover) {
  &.q-field--focused {
    .q-field__control {
      border: 1px solid $border-active !important;
    }
  }
}

:deep(.q-field--error) {
  & * {
    color: $input-text-validation !important;
  }
  .q-field__control {
    border: 1px solid $border-validation !important;
    background-color: white !important;
  }
}

:deep(.q-field--standout) {
  &.q-field.q-field--error * {
    color: $input-text-validation !important;
  }
}

:deep(.q-field--standout.q-field .q-field__append) {
  background: transparent !important;
  width: auto !important;
  svg,
  svg * {
    color: $N-600 !important;
  }
}
</style>
