<template>
  <div>
    <VueRecaptcha
      :sitekey="siteKey"
      :load-recaptcha-script="true"
      @verify="handleSuccess"
      @error="handleError"
    />
    <div
      v-if="submitForm && !recaptchaValid"
      class="recaptcha-error"
    >
      Igazolja, hogy Ön nem robot.
    </div>
  </div>
</template>

<script setup>
import { VueRecaptcha } from 'vue-recaptcha'
import { ref } from 'vue'

const props = defineProps({
  recaptchaValid: {
    type: Boolean,
    required: true,
    default: false,
  },
})

const emit = defineEmits(['recaptchaSuccess', 'recaptchaError'])
const siteKey = import.meta.env.VITE_RECAPTCHA_KEY

const submitForm = ref(false)

function onSubmit() {
  submitForm.value = true
}

function handleError() {
  emit('recaptchaError')
}

function handleSuccess(response) {
  emit('recaptchaSuccess', response)
}

defineExpose({
  onSubmit,
})
</script>

<style scoped lang="scss">
.recaptcha-error {
  color: #dc2626;
  font-size: 0.875rem;
  font-weight: 25rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
}
</style>
