
let navigationHelper = {
  handleAnchor(route) 
  {
    if(!route || !route.hash) return;
    let sectionId = route.hash.substring(1);
    if(!sectionId) return;
    let navigationElement = null
    let pageHeightNotChangedCounter = 0
    let documentElementPreviousHeight = 0;
    let checkLoadTimer = setInterval(() =>
                  {
                    if(navigationElement === null) 
                    {
                      navigationElement = document.getElementById(sectionId);
                      if(navigationElement === null) return;//has not rendered yet or does not exist
                    }

                    if(documentElementPreviousHeight === document.body.scrollHeight) 
                    {
                      //equal to previous height
                      pageHeightNotChangedCounter++;
                      this.scrollPage(navigationElement);
                      //clearing timeout
                      if(pageHeightNotChangedCounter > 3) clearTimeout(checkLoadTimer);
                    }
                    else
                    {
                      //not equal to previous height, still loading...
                      documentElementPreviousHeight = document.body.scrollHeight;
                      pageHeightNotChangedCounter = 0;
                    }
                  }, 
                  500);
  },
  scrollPage(navigationElement)
  {
    window.scrollTo({
        //todo: calculate header height
        top: navigationElement.offsetTop - 72,
        behavior: 'smooth',
      })
  }
}
export default navigationHelper
