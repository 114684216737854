<template>
  <section class="administration-section">
    <div class="container-xxl">
      <div class="row">
        <ServicesModule
          :title="services.title"
          sub-title="Honlapunkon és ügyfélportálunkon keresztül online, sorban állás nélkül intézheti biztosítással kapcsolatos teendőit."
          :items="services.items"
        />
        <ClaimsModule
          :title="claims.title"
          sub-title="Online vagy telefonos kárbejelentéshez válassza ki, milyen biztosításhoz kapcsolódik a kár!"
          :items="claims.items"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  administrationData: {
    type: Object,
    required: true,
  },
})

const services = computed(() => {
  return props.administrationData.columns[0]
})
const claims = computed(() => {
  return props.administrationData.columns[1]
})

onMounted(() => {
  getHighest()
})

onBeforeMount(() => {
  window.addEventListener('resize', getHighest)
})

onUnmounted(() => {
  window.removeEventListener('resize', getHighest)
})

function getHighest() {
  let biggestHeight = 0

  let elements = document.querySelectorAll('.administration-text-holder')

  elements.forEach(function (element, index) {
    biggestHeight =
      element.offsetHeight > biggestHeight
        ? element.offsetHeight
        : biggestHeight
  })

  elements.forEach(function (element, index) {
    element.setAttribute('style', 'height:' + biggestHeight + 'px')
  })
}
</script>

<script>
export default {
  name: 'AdministrationModule',
}
</script>

<style lang="scss" scoped>
#home-page{
	.administration-section{
		:deep(.q-btn__content){
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.administration-section {
  background-color: $N-100;
  h2.small {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.25rem;
  }
}
:deep(.read-more) {
  margin-top: 2rem;
  svg {
    margin-left: $half;
  }
}

:deep(.reset-list) {
  li:last-child {
    margin-bottom: 0;
  }
}
:deep(.reset-list) {
  list-style: none;
  padding: 0;
  margin: 0;
}
:deep(ul li.btn-holder) {
  margin-bottom: $small;
  padding-left: 0 !important;
}

:deep(.card) {
  border-width: 0 !important;
  border-radius: 0.75rem;
  box-shadow: $box-shadow !important;
  .card-header {
    display: flex;
    border-bottom: 0 solid white !important;
    margin: 0 0 2rem 0 !important;
    .icon-holder {
      width: 3.5rem;
      height: 3.5rem;
    }
    .card-title {
      padding: 0 !important;
      margin: 0 0 0 1rem !important;
    }
  }
}
:deep(.btn-service.q-btn) {
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: $half;
  background-color: $N-50;
  border: 1px solid $N-200;
  padding: $normal;
  .icon-holder {
    width: 1.5rem;
    padding: 0;
    margin: 0;
    color: $primary;
  }
  .btn-content {
    width: calc(100% - 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark;
    height: 1rem;
    padding: 0 0 0 0.75rem;
    h3.btn-text {
      padding: 0;
      display: flex;
      align-items: center;
      margin-top: 0.125rem;
      text-align: left;
      font-size: $font-size-lg;
      line-height: $font-size-lg;
      font-weight: $font-weight-bold;
      text-transform: none;
      margin-bottom: 0;
    }
    .btn-icon {
      display: flex;
      align-items: center;
    }
    .btn-caret {
      padding: 0;
      margin: 0;
    }
  }
  &:before {
    box-shadow: none;
  }
  &:hover {
    background-color: #f7f7fb;
  }
  .q-btn__content {
    width: 100%;
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .administration-section {
    padding: 2rem 1rem;
    .container-xxl {
      .row .col:first-child {
        margin-bottom: 1.5rem !important;
      }
    }
    h2.small {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 0.25rem;
    }
  }
  :deep(.t-sm) {
    font-size: 0.75rem !important;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .administration-section {
    padding: 0;
    .container-xxl {
      padding: 0;
      .row .col:first-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  :deep(.card) {
    border-radius: 0 !important;
  }
}
</style>
