<template>
  <component :is="markup">
    <slot />
    <span
      v-if="tooltipContent.length"
      class="cursor-pointer all-pointer-events title-tooltip"
      :class="alternateContentClass !== '' ? alternateContentClass : ''"
    >
      <div
        :data-bs-custom-class="customClass"
        data-bs-html="true"
      >
        <PhIconManager
          ph-icon="ph-info"
          :size="iconSize"
          @click="dialogOpen = true"
        />
      </div>
    </span>
    <Dialog
      :key="dialogOpen"
      :open="dialogOpen"
      @dialog-shown="updateElementHeight()"
    >
      <q-card-section>
        <div class="dialogtitle-holder">
          <div class="dialog-title">
            {{ tooltipTitle }}
          </div>
          <div class="dialog-close-button">
            <q-btn
              v-close-popup
              @click="dialogOpen = false"
            >
              <PhIconManager
                ph-icon="ph-x"
                color="#828BA8"
              />
            </q-btn>
          </div>
        </div>
        <div
          ref="tooltipContentHolderRef"
          class="tooltip-content-holder"
        >
          <div 
            ref="tooltipContentRef" 
            v-sanitize="formattedTooltipContent"
            class="tooltip-content"
            :class="tooltipContentheight > tooltipContentHolderheight ?'scroll-shown':''"
          />
        </div>
      </q-card-section>
      <q-btn
        class="btn btn-secondary btn-outline btn-back tooltip-close-btn"
        @click="closeModal"
      >
        Vissza
      </q-btn>
    </Dialog>
  </component>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
const dialogOpen = ref(false)

function closeModal() {
  dialogOpen.value = false
}
let formattedTooltipContent = props.tooltipContent.replace(/\r\n/g,'<br/>')
formattedTooltipContent = props.tooltipContent.replace(/\n/g,'<br/>')
watch(
  () => dialogOpen.value,
  (value, oldValue) => {
    updateElementHeight()
  }
)
const tooltipContentHolderRef = ref(null);
const tooltipContentHolderheight = ref(0);
const tooltipContentRef = ref(null);
const tooltipContentheight = ref(0);
function updateElementHeight() {
      if (tooltipContentHolderRef.value) {
        tooltipContentHolderheight.value = tooltipContentHolderRef.value.clientHeight - 32;
      }
      if (tooltipContentRef.value) {
        tooltipContentheight.value = tooltipContentRef.value.scrollHeight;
      }
  };
window.addEventListener('resize', updateElementHeight)
const props = defineProps({
  tooltipContent: {
    type: String,
    required: false,
    default: '',
  },
  tooltipTitle: {
    type: String,
    required: false,
    default: '',
  },

  iconSize: {
    type: Number,
    required: false,
    default: 20,
  },

  tooltipPlacement: {
    type: String,
    required: false,
    default: 'right',
  },

  customClass: {
    type: String,
    required: false,
    default: 'tooltip',
  },

  alternateContent: {
    type: String,
    required: false,
    default: '',
  },

  alternateContentClass: {
    type: String,
    required: false,
    default: '',
  },
  htag: {
    type: String,
    required: true,
  },
})

const markup = ref(props.htag)

onMounted(() => {
  if (tooltip.value) {
    new Tooltip(tooltip.value)
  }
  updateElementHeight();
})
const tooltip = ref(null)
</script>

<style lang="scss" scoped>
.tooltip-content-holder {
    flex: 1;
}

.cursor-pointer.all-pointer-events {
  margin: 0;
}
.cursor-pointer.all-pointer-events.title-tooltip{
  margin-left: .25rem;
}

.tooltip-content-holder{
  padding: 1rem;
  background-color: $N-100;
  text-align: start;
  border-radius: 0.75rem;
}
.tooltip-content{
  font-size: 0.75rem;
  font-weight: 500; 
  line-height: 1.25rem;
  color:  $D-400;
  overflow: auto;
  max-height: calc(100vh - 21rem);
}
.tooltip-close-btn{
  margin-top: 2rem;
  padding: 0.75rem 1.25rem;
}
.dialog-close-button{
  position: static;
  width: 1.5rem;
}
.scroll-shown{
  padding-right: 1rem;
}
@media screen and (max-width: $breakpoint-md-max){
  .tooltip-close-btn{
    width: 100%;
  }
}
</style>
