<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="breadcrumbcalculator">
    <Title
      htag="h1"
      class="breadcrumb-title"
    >
      {{ texts.vtxt_householdCalculatorTitle }}
    </Title>
    {{}}
    <div
      v-if="householdWizard.getpagePathsCounter + 1 === 1"
      class="breadcrumb-subtext"
    >
      {{ texts.vtxt_kalkFirstPageSubtitle_label }}
    </div>
    <div
      v-if="householdWizard.getpagePathsCounter + 1 === 6"
      class="breadcrumb-subtext"
    >
      {{ texts.vtxt_dataReviewInformation_content }}
    </div>
    <q-breadcrumbs class="breadcrumbcalculator-steps">
      <div
        v-for="(page, index) in householdWizard.getPagePaths"
        :key="index"
        class="line"
        :class="
          householdWizard.getpagePathsCounter >= index ? 'line-active' : ''
        "
      >
        <q-breadcrumbs-el />
      </div>
    </q-breadcrumbs>

    <div class="breadcrumb-label">
      <span>{{ householdWizard.getpagePathsCounter + 1 }}/{{
        householdWizard.getPagePaths.length
      }}</span>
    </div>
    <div class="breadcrumb-subtitle">
      <h2>{{ getTitle(householdWizard.getpagePathsCounter + 1) }}</h2>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useHouseholdWizardStore } from '../../stores/householdWizardStore'

const householdWizard = useHouseholdWizardStore()
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
})

const texts = householdWizard.getTexts

function getTitle(id) {
  switch (id) {
    case 1:
      return texts.vtxt_kalkFirstPageTitle_label
    case 2:
      return texts.vtxt_kalkSecondPageTitle_label
    case 3:
      return texts.vtxt_kalkThirdPageTitle_label
    case 4:
      return texts.vtxt_kalkFourthPageTitle_label
    case 5:
      return texts.vtxt_kalkFifthPageTitle_label
    case 6:
      return texts.vtxt_kalkSixthPageTitle_label
    default:
      return ''
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbcalculator {
  padding-bottom: 5rem;
  padding-top: 5rem;
  align-self: center;

  .line {
    background-color: #d5d9eb;
    border-color: #d5d9eb;
    height: 0.25rem;
    width: 2.5rem;
    border-radius: 0.5rem;
    margin: 0;
    margin-left: 0.375rem;
  }
  .line-active {
    background-color: #ed4353;
    border: 0.0625rem solid #ed4353;
  }
  .breadcrumbcalculator-steps {
    width: 100%;
    display: flex;
    justify-content: center;
    :deep(.flex.items-center.justify-start.q-gutter-sm) {
      padding-top: 2.5rem;
    }
  }
  .breadcrumb-title {
    font-size: 2rem;
    line-height: 2.5rem;
    color: #1d2c5b;
    font-weight: 700;
    align-self: center;
    text-align: center;
    margin: 0;
  }
  .breadcrumb-subtext {
    text-align: center;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #1d2c5b;
    padding-top: 0.5rem;
    max-width: 71.25rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .breadcrumb-label {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    span {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: #6b7789;
      align-content: center;
    }
  }
  .breadcrumb-subtitle {
    display: flex;
    justify-content: center;
    h2 {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #0c1224;
      margin: 0;
    }
  }
  @media screen and (max-width: $breakpoint-lg-max) {
    padding: 2.5rem 0;
    margin: 0;
    .breadcrumb-title {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }
    .breadcrumb-subtext {
      padding: 0 2.5rem;
      padding-top: 0.5rem;
    }
  }

  @media screen and (max-width: $breakpoint-md-max) {
    padding: 2rem 0;
    .breadcrumb-title {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    :deep(.flex.items-center.justify-start.q-gutter-sm) {
      padding-top: 2rem;
    }
    .breadcrumb-subtext {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}
</style>
