<template>
  <div
    :class="true ? 'fix-height-validation' : 'fix-height'"
    class="insurance-package-tablet-mobile"
  >
    <div v-sticky-parent>
      <div v-calculatorsticky>
        <div class="row head-row">
          <q-tabs v-model="tab">
            <q-tab
              id="extraPackage"
              name="extraPackage"
              content-class="package-tab"
              class="col field-content first"
              :class="false ? 'package-selected' : ''"
            >
              <div
                class="package-head first"
                :class="false ? 'disabled-package' : ''"
              >
                <div class="package-name d-none d-lg-flex">
                  {{ 'Extra LAKÁSBIZTOSÍTÁS' }}
                </div>
                <div class="package-name d-flex d-lg-none">
                  {{ 'Extra' }}
                </div>
                <div class="package-price-holder first">
                  <div class="package-period-price">
                    {{ '34 717 Ft /félév' }}
                  </div>
                  <div class="package-annual-price">
                    {{ '69 434 Ft /év' }}
                  </div>
                </div>
                <div class="package-radio-input-holder">
                  <div class="radio-input">
                    <q-radio
                      v-model="tab"
                      val="extraPackage"
                      label="Ezt választom"
                    />
                  </div>
                </div>
              </div>
            </q-tab>
            <q-tab
              id="komfortPackage"
              name="komfortPackage"
              content-class="package-tab"
              class="col field-content highlighted"
              :class="true ? 'package-selected' : ''"
            >
              <div
                class="package-head"
                :class="false ? 'disabled-package' : ''"
              >
                <div class="recommended-package">
                  AJÁNLOTT
                </div>
                <div class="package-name d-none d-lg-flex">
                  {{ 'Komfort LAKÁSBIZTOSÍTÁS' }}
                </div>
                <div class="package-name d-flex d-lg-none">
                  {{ 'Komfort' }}
                </div>
                <div class="package-price-holder">
                  <div class="package-period-price">
                    {{ '34 717 Ft /félév' }}
                  </div>
                  <div class="package-annual-price">
                    {{ '69 434 Ft /év' }}
                  </div>
                </div>
                <div class="package-radio-input-holder">
                  <div class="radio-input">
                    <q-radio
                      v-model="tab"
                      val="komfortPackage"
                      label="Ezt választom"
                    />
                  </div>
                </div>
              </div>
            </q-tab>
            <q-tab
              id="startPlusPackage"
              name="startPlusPackage"
              content-class="package-tab"
              class="col field-content"
              :class="false ? 'package-selected' : ''"
            >
              <div
                class="package-head"
                :class="false ? 'disabled-package' : ''"
              >
                <div class="package-name d-none d-lg-flex">
                  {{ 'Start + LAKÁSBIZTOSÍTÁS' }}
                </div>
                <div class="package-name d-flex d-lg-none">
                  {{ 'Start +' }}
                </div>
                <div class="package-price-holder">
                  <div class="package-period-price">
                    {{ '34 717 Ft /félév' }}
                  </div>
                  <div class="package-annual-price">
                    {{ '69 434 Ft /év' }}
                  </div>
                </div>
                <div class="package-radio-input-holder">
                  <div class="radio-input">
                    <q-radio
                      v-model="tab"
                      val="startPlusPackage"
                      label="Ezt választom"
                    />
                  </div>
                </div>
              </div>
            </q-tab>
            <q-tab
              id="startPackage"
              name="startPackage"
              content-class="package-tab"
              class="col field-content last"
              :class="false ? 'package-selected' : ''"
            >
              <div
                class="package-head"
                :class="true ? 'disabled-package' : ''"
              >
                <div class="package-name d-none d-lg-flex">
                  {{ 'Start minősített fogyasztóbarát otthonbiztosítás' }}
                </div>
                <div class="package-name package-name-mfo d-flex d-lg-none">
                  {{ 'Start' }}
                  <Tooltip
                    :tooltip-content="'Start minősített fogyasztóbarát otthonbiztosítás'"
                    alternate-content="(MFO)"
                  />
                </div>
                <div class="package-price-holder last">
                  <div class="package-period-price">
                    {{ '34 717 Ft /félév' }}
                  </div>
                  <div class="package-annual-price">
                    {{ '69 434 Ft /év' }}
                  </div>
                </div>
                <div class="package-radio-input-holder last">
                  <div class="radio-input">
                    <q-radio
                      v-model="tab"
                      val="startPackage"
                      label="Ezt választom"
                    />
                  </div>
                </div>
              </div>
            </q-tab>
          </q-tabs>
        </div>
        <div class="row validation-row">
          <div
            v-if="true"
            class="col validation-col"
          >
            <ValidationMessage
              :messages="['Kérem, válasszon csomagot a továbblépéshez!']"
              :info-messages="infoMessages"
              :warning-messages="warningMessages"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row submodus-row">
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
            :tooltip-title="'Title'"
          >
            20 alapkockázat
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="tab"
        class="col"
        animated
      >
        <q-tab-panel
          name="extraPackage"
          class="submodus-control-holder"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
        <q-tab-panel
          name="komfortPackage"
          class="submodus-control-holder highlighted"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
        <q-tab-panel
          name="startPlusPackage"
          class="submodus-control-holder"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
        <q-tab-panel
          name="startPackage"
          class="submodus-control-holder disabled-package"
        >
          <PhIconManager
            class="submodus-check"
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-check"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <div class="row submodus-row">
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
          >
            Ingatlan alapfedezet
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="tab"
        class="col"
        animated
      >
        <q-tab-panel
          name="extraPackage"
          class="submodus-control-holder"
        >
          <TextInput
            id="1"
            :label="'textinput'"
            :maxlength="20"
            validation-rules="VAL_100"
          />
        </q-tab-panel>
        <q-tab-panel
          name="komfortPackage"
          class="submodus-control-holder highlighted"
        >
          <TextInput
            id="1"
            :label="'textinput'"
            :maxlength="20"
            validation-rules="VAL_100"
          />
        </q-tab-panel>
        <q-tab-panel
          name="startPlusPackage"
          class="submodus-control-holder"
        >
          <TextInput
            id="1"
            :label="'textinput'"
            :maxlength="20"
            validation-rules="VAL_100"
          />
        </q-tab-panel>
        <q-tab-panel
          name="startPackage"
          class="submodus-control-holder disabled-package"
        >
          <TextInput
            id="1"
            :label="'textinput'"
            :maxlength="20"
            validation-rules="VAL_100"
            :is-disabled="true"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <div class="row submodus-row">
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
          >
            Felelősségbiztosítás
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="tab"
        class="col"
        animated
      >
        <q-tab-panel
          name="extraPackage"
          class="submodus-control-holder"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="komfortPackage"
          class="submodus-control-holder highlighted"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="startPlusPackage"
          class="submodus-control-holder"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="startPackage"
          class="submodus-control-holder disabled-package"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :is-disabled="true"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <div class="row submodus-row">
      <div class="col submodus-name-holder">
        <div
          v-if="true"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
          >
            Balesetbiztosítás
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="tab"
        class="col"
        animated
      >
        <q-tab-panel
          name="extraPackage"
          class="submodus-control-holder"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="komfortPackage"
          class="submodus-control-holder highlighted"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="startPlusPackage"
          class="submodus-control-holder"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
        <q-tab-panel
          name="startPackage"
          class="submodus-control-holder disabled-package"
        >
          <div class="submodus-input-holder">
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
              :is-disabled="true"
            />
          </div>
          <div class="submodus-modification-button-holder">
            <PhIconManager
              height="1.25rem"
              width="1.25rem"
              ph-icon="ph-trash"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>

    <q-expansion-item
      ref="myMobileItem"
      v-model="accordion"
      header-class="package-accordion-header"
      class="package-accordion"
      expand-icon-class="package-accordion-icon"
      expand-icon-toggle
    >
      <template #header>
        <div class="row submodus-row">
          <div class="col submodus-name-holder">
            <div
              v-if="isGift"
              class="submodus-gift-holder"
            >
              <div class="submodus-gift">
                <PhIconManager
                  height=".9375rem"
                  color="white"
                  width=".9375rem"
                  ph-icon="ph-gift"
                />
              </div>
            </div>
            <div class="package-accordion-name">
              <div class="left-holder submodus-title-holder">
                <SubmodusTitle
                  :icon-size="16"
                  :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
                >
                  Relax csomag
                </SubmodusTitle>
              </div>
              <div class="right-holder">
                <q-btn
                  :ripple="false"
                  flat
                  @click.stop="$refs.myMobileItem.toggle()"
                >
                  <PhIconManager
                    v-if="accordion"
                    height="1rem"
                    width="1rem"
                    ph-icon="ph-caret-down"
                  />
                  <PhIconManager
                    v-if="!accordion"
                    height="1rem"
                    width="1rem"
                    ph-icon="ph-caret-up"
                  />
                </q-btn>
              </div>
            </div>
          </div>
          <q-tab-panels
            v-model="tab"
            class="col"
            animated
          >
            <q-tab-panel
              name="extraPackage"
              class="submodus-control-holder"
            >
              <SelectInput
                id="8"
                :label="'selectinput'"
                validation-rules="VAL_100"
              />
            </q-tab-panel>
            <q-tab-panel
              name="komfortPackage"
              class="submodus-control-holder highlighted"
            >
              <SelectInput
                id="8"
                :label="'selectinput'"
                validation-rules="VAL_100"
              />
            </q-tab-panel>
            <q-tab-panel
              name="startPlusPackage"
              class="submodus-control-holder"
            >
              <SelectInput
                id="8"
                :label="'selectinput'"
                validation-rules="VAL_100"
              />
            </q-tab-panel>
            <q-tab-panel
              name="startPackage"
              class="submodus-control-holder disabled-package"
            >
              <SelectInput
                id="8"
                :label="'selectinput'"
                validation-rules="VAL_100"
                :is-disabled="true"
              />
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </template>
      <div class="row submodus-row">
        <div class="col submodus-name-holder">
          <div
            v-if="isGift"
            class="submodus-gift-holder"
          >
            <div class="submodus-gift">
              <PhIconManager
                height=".9375rem"
                color="white"
                width=".9375rem"
                ph-icon="ph-gift"
              />
            </div>
          </div>
          <div class="submodus-title-holder">
            <SubmodusTitle
              :icon-size="16"
              :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
            >
              Dugulás- elhárítás költségei
            </SubmodusTitle>
          </div>
        </div>
        <q-tab-panels
          v-model="tab"
          class="col"
          animated
        >
          <q-tab-panel
            name="extraPackage"
            class="submodus-control-holder"
          >
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </q-tab-panel>
          <q-tab-panel
            name="komfortPackage"
            class="submodus-control-holder highlighted"
          >
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </q-tab-panel>
          <q-tab-panel
            name="startPlusPackage"
            class="submodus-control-holder"
          >
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
            />
          </q-tab-panel>
          <q-tab-panel
            name="startPackage"
            class="submodus-control-holder disabled-package"
          >
            <SelectInput
              id="8"
              :label="'selectinput'"
              validation-rules="VAL_100"
              :is-disabled="true"
            />
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </q-expansion-item>

    <div class="row submodus-row">
      <div class="col submodus-name-holder">
        <div
          v-if="isGift"
          class="submodus-gift-holder"
        >
          <div class="submodus-gift">
            <PhIconManager
              height=".9375rem"
              color="white"
              width=".9375rem"
              ph-icon="ph-gift"
            />
          </div>
        </div>
        <div class="submodus-title-holder">
          <SubmodusTitle
            :icon-size="16"
            :tooltip-content="'Lorem ipsum dolor sit amet consectetur adipisicing elit.'"
          >
            SOS Autósegély
          </SubmodusTitle>
        </div>
      </div>
      <q-tab-panels
        v-model="tab"
        class="col"
        animated
      >
        <q-tab-panel
          name="extraPackage"
          class="submodus-control-holder"
        >
          <PhIconManager
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-plus"
          />
        </q-tab-panel>
        <q-tab-panel
          name="komfortPackage"
          class="submodus-control-holder highlighted"
        >
          <PhIconManager
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-plus"
          />
        </q-tab-panel>
        <q-tab-panel
          name="startPlusPackage"
          class="submodus-control-holder"
        >
          <PhIconManager
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-plus"
          />
        </q-tab-panel>
        <q-tab-panel
          name="startPackage"
          class="submodus-control-holder disabled-package"
        >
          <PhIconManager
            height="1.25rem"
            width="1.25rem"
            ph-icon="ph-plus"
          />
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const tab = ref('komfortPackage')
const accordion = ref(false)
</script>
<style lang="scss" scoped>
.sticky-calculator {
  width: calc(100% - 5rem);
  .recommended-package {
    display: none;
  }
}
.insurance-package-tablet-mobile {
  .head-row {
    .first {
      border-radius: 0.25rem 0rem 0rem 0rem;
    }
    .last {
      border-radius: 0rem 0.25rem 0rem 0rem;
    }
  }
  
  .col.field-content {
    background: $N-400;
  }

  .package-head {
    width: 100%;
    .package-name {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0.25rem;
      height: 5.75rem;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: #1d2c5b;
      border-left: 0.0625rem solid white;
      white-space: pre-wrap;
    }
    &.first {
      .package-name {
        border-left: 0.0625rem solid $border-hover;
      }
    }
    .package-name-mfo{
      display: flex;
      flex-direction: column;
      .cursor-pointer{
        margin: 0;
        color: $secondary;
      }
    }
    .package-price-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.25rem;
      background: #ffffff;
      border-left: 0.0625rem solid $border-hover;
      &.last {
        border-right: 0.0625rem solid $border-hover;
      }
      .package-period-price {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
      }
      .package-annual-price {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }

    .package-radio-input-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.25rem;
      background: #ffffff;
      border-left: 0.0625rem solid $border-hover;
      border-top: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;

      &.last {
        border-right: 0.0625rem solid $border-hover;
      }
    }
  }

  .col.field-content.package-selected {
    background: #2f5997;
    .package-name {
      color: #ffffff;
    }
  }
  .recommended-package {
    text-align: center;
    border-left: 0.0625rem solid #fff;
    padding: 0.25rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.6875rem;
    line-height: 0.75rem;
    background: $dark;
    margin-top: -1.25rem;
  }

  :deep(.package-tab) {
    width: 100%;
  }
  :deep(.q-tab) {
    text-transform: none;
  }
  :deep(.q-tabs__content) {
    overflow: visible;
  }
  :deep(.q-tab__content) {
    padding: 0;
  }
  :deep(.q-tab__indicator) {
    display: none;
  }
  :deep(.q-tab--inactive) {
    opacity: 1;
  }
  :deep(.q-radio__inner.relative-position.q-radio__inner--truthy) {
    color: #4e95fb;
  }
  :deep(.q-radio__inner.relative-position) {
    width: fit-content;
  }
  :deep(.q-radio__label) {
    flex: 1;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111a37;
    text-transform: none;
  }

  :deep(.q-option-group.q-gutter-x-sm) {
    margin-left: 0;
  }

  :deep(.q-option-group) {
    display: flex;
    flex-direction: row;
  }
  :deep(.q-gutter-x-sm > *, .q-gutter-sm > *) {
    margin-left: 0;
  }

  .radio-input {
    .q-field__messages.col {
      line-height: 1.25rem;
    }
    .q-field--with-bottom {
      padding-bottom: 1.5rem;
    }
  }
  .validation-row {
    .validation-col {
      padding: 0.5rem;
      text-align: center;
      border-left: 0.0625rem solid $border-hover;
      border-right: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;
      background: white;
      :deep(.error) {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }

  .submodus-name-holder {
    word-break: break-word;
    background: #e6e8f3;
    border-left: 0.0625rem solid $border-hover;
    border-bottom: 0.0625rem solid $border-hover;
    border-right: 0.0625rem solid $border-hover;
    padding: 1rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #1d2c5b;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    max-width: 24.2813rem;
    .submodus-gift-holder {
      position: absolute;
      background: #a124ad;
      transform: rotate(-45deg);
      right: -0.375rem;
      top: -1rem;
      width: 1.6875rem;
      height: 2.9375rem;
      .submodus-gift {
        transform: rotate(45deg);
        top: 0.9375rem;
        position: absolute;
        right: 0.5625rem;
      }
    }
    .submodus-title-holder {
      display: flex;
      align-items: center;
    }
  }

  .submodus-control-holder {
    padding: 1rem 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-right: 0.0625rem solid $border-hover;
    border-bottom: 0.0625rem solid $border-hover;
    height: 100%;
    .submodus-check {
      color: #10b981;
    }
    .submodus-cross {
      color: #ed4353;
    }
    :deep(.text-input) {
      width: 100%;
    }
    :deep(.q-select) {
      width: 100%;
    }
    .submodus-input-holder {
      width: 100%;
    }
  }

  .submodus-modification-button-holder {
    margin-left: 0.375rem;
  }
  .package-accordion {
    :deep(.q-item) {
      padding: 0;
    }
    :deep(.package-accordion-header) {
      .package-accordion-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .package-accordion-icon {
        display: none;
      }
    }
    :deep(.q-expansion-item__content) {
      .submodus-name-holder {
        background: #f7f7fb;
      }
    }
  }

  :deep(.disabled-package) {
    .package-name {
      color: #8a91ab;
    }
    .package-price-holder {
      background: #eef0f7;
      color: #8a91ab;
    }
    .package-radio-input-holder {
      background: #eef0f7;
      .q-radio__label {
        color: #8a91ab;
      }
    }
  }
  :deep(.highlighted) {
    .package-price-holder,
    .package-radio-input-holder {
      background: #f7f7fb;
    }
  }

  .q-tab-panel.submodus-control-holder.highlighted {
    background: #f7f7fb;
  }
  .q-tab-panel.submodus-control-holder.disabled-package {
    color: #8a91ab;
    background: #eef0f7;
    .submodus-check,
    .submodus-cross {
      color: #b4b9cb;
    }
  }
}

.sticky-calculator-parent.fix-height-validation {
  margin-top: 7.75rem;
}
.sticky-calculator-parent.fix-height {
  margin-top: 5.25rem;
}

@media screen and (max-width: $breakpoint-md-max) {
  .sticky-calculator {
    width: 100%;
  }
  .insurance-package-tablet-mobile {
    .package-head {
      .package-name {
        padding: 0.25rem;
        height: 3rem;
      }
      .package-name-mfo{
        white-space: unset;
      }
      .package-price-holder {
        padding: 0.5rem 0;

        .package-period-price {
          font-weight: 600;
          font-size: 0.625rem;
          line-height: 1.25rem;
        }
        .package-annual-price {
          font-weight: 400;
          font-size: 0.625rem;
          line-height: 0.75rem;
        }
      }
    }
    .fix-height-validation.sticky {
      margin-top: 108px;
    }
    .fix-height.sticky {
      margin-top: 70px;
    }
    :deep(.q-radio__inner.relative-position.q-radio__inner--truthy) {
      color: #4e95fb;
    }
    :deep(.q-radio__inner.relative-position) {
      width: fit-content;
    }
    :deep(.q-radio__label) {
      font-size: 0.625rem !important;
      line-height: 0.75rem !important;
    }

    :deep(.q-option-group.q-gutter-x-sm) {
      margin-left: 0;
    }

    :deep(.q-option-group) {
      display: flex;
      flex-direction: row;
    }
    :deep(.q-gutter-x-sm > *, .q-gutter-sm > *) {
      margin-left: 0;
    }

    .radio-input {
      :deep(.q-radio.cursor-pointer.no-outline.row.inline.no-wrap.items-center) {
        margin: 0 !important;
        flex-direction: column;
      }
      :deep(.q-radio__inner.relative-position) {
        margin-right: 0 !important;
      }
      .q-field__messages.col {
        line-height: 1.25rem;
      }
      .q-field--with-bottom {
        padding-bottom: 1.5rem;
      }
    }
    .validation-row {
      .validation-col {
        padding: 0.5rem;
        text-align: center;
        border-left: 0.0625rem solid $border-hover;
        border-right: 0.0625rem solid $border-hover;
        border-bottom: 0.0625rem solid $border-hover;
        background: white;
        :deep(.error) {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }

    .submodus-name-holder {
      background: #e6e8f3;
      border-left: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;
      border-right: 0.0625rem solid $border-hover;
      padding: 1rem 1rem 1rem 0.5rem;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #1d2c5b;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      max-width: 9.375rem;
      .submodus-gift-holder {
        position: absolute;
        background: #a124ad;
        transform: rotate(-45deg);
        right: -0.375rem;
        top: -1rem;
        width: 1.6875rem;
        height: 2.9375rem;
        .submodus-gift {
          transform: rotate(45deg);
          top: 0.9375rem;
          position: absolute;
          right: 0.5625rem;
        }
      }
    }

    .submodus-control-holder {
      padding: 1rem 0.75rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border-right: 0.0625rem solid $border-hover;
      border-bottom: 0.0625rem solid $border-hover;
      height: 100%;
      .submodus-check {
        color: #10b981;
      }
      .submodus-cross {
        color: #ed4353;
      }
      :deep(.text-input) {
        width: 100%;
      }
      :deep(.q-select) {
        width: 100%;
      }
      .submodus-input-holder {
        width: 100%;
      }
    }

    .submodus-modification-button-holder {
      margin-left: 0.375rem;
    }
    .package-accordion {
      :deep(.q-item) {
        padding: 0;
      }
      :deep(.package-accordion-header) {
        .package-accordion-name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .package-accordion-icon {
          display: none;
        }
      }
      :deep(.q-expansion-item__content) {
        .submodus-name-holder {
          background: #f7f7fb;
        }
      }
    }

    :deep(.disabled-package) {
      .package-name {
        color: #8a91ab;
      }
      .package-price-holder {
        background: #eef0f7;
        color: #8a91ab;
      }
      .package-radio-input-holder {
        background: #eef0f7;
        .q-radio__label {
          color: #8a91ab;
        }
      }
    }
    :deep(.highlighted) {
      .package-price-holder,
      .package-radio-input-holder {
        background: #f7f7fb;
      }
    }

    .q-tab-panel.submodus-control-holder.highlighted {
      background: #f7f7fb;
    }
    .q-tab-panel.submodus-control-holder.disabled-package {
      color: #8a91ab;
      background: #eef0f7;
      .submodus-check,
      .submodus-cross {
        color: #b4b9cb;
      }
    }
  }
  .sticky-calculator-parent.fix-height-validation {
    margin-top: 0;
  }
  .sticky-calculator-parent.fix-height {
    margin-top: 0;
  }
}
</style>
