<template>
  <component
    :is="phIconComponent"
    :size="size"
    :weight="weight"
    :color="color"
    :mirrored="mirrored"
    :class="customClass"
  />
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent, shallowRef } from 'vue'
import phIconRegister from '../../../utils/infrastructure/phIconRegister'

const props = defineProps({
  phIcon: {
    type: String,
    required: true,
  },
  weight: {
    type: String,
    default: 'regular',
  },
  size: {
    type: Number,
    default: 24,
  },
  color: {
    type: String,
    default: 'currentColor',
  },
  mirrored: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: '',
  },
})

const phIconComponent = shallowRef(null)

onMounted(() => {
  const iconName = convertToIconName(props.phIcon)
  const asyncComponent = phIconRegister(iconName)
  phIconComponent.value = asyncComponent
})

function convertToIconName(iconName) {
  return iconName
    .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
    .replace(/^\w/, (match) => match.toUpperCase())
    .replace(/-/g, '')
}
</script>
