import { defineStore } from 'pinia'

export const useCampaignStore = defineStore('campaign', {
  state: () => {
    return {
      showNavigation: true,
    }
  },

  getters: {
    getShowNavigation(state) {
      return state.showNavigation
    },
  },

  actions: {
    setShowNavigation(value) {
      this.showNavigation = value
    },
  },
})