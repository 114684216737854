<template>
  <router-link
    v-if="isInternal"
    :to="linkObject.internalLink"
    :target="linkObject.newWindow ? '_blank' : ''"
  >
    <slot />
  </router-link>
  <a
    v-else-if="isExternal"
    :href="linkObject.externalLink"
    :target="linkObject.newWindow ? '_blank' : ''"
  >
    <slot />
  </a>

  <a
    v-else-if="isOldPortalInternal"
    :href="respBaseUrl + linkObject.internalLink"
    :target="linkObject.newWindow ? '_blank' : ''"
  >
    <slot />
  </a>

  <div v-else>
    {{ linkObject.title }}
  </div>
</template>

<script setup>
import { computed } from '@vue/runtime-core'
import router from '../../router'

const props = defineProps({
  linkObject: {
    type: Object,
    default: null,
  },

  routes: {
    type: Array,
    default: router.getRoutes(),
  },

  respBaseUrl: {
    type: String,
    default: import.meta.env.VITE_RESP_BASEURL,
  },
})

const isInternal = computed(() => {
  if (internalLinkHasRoute(props.linkObject.internalLink)) {
    return true
  }
  return false
})

const isOldPortalInternal = computed(() => {
  if (
    props.linkObject.internalLink === '' ||
    internalLinkHasRoute(props.linkObject.internalLink)
  ) {
    return false
  }
  return true
})

const isExternal = computed(() => {
  if (props.linkObject.externalLink) {
    return true
  }
  return false
})

function internalLinkHasRoute(internalLink) {
  if (
    internalLink !== null &&
    internalLink !== undefined &&
    internalLink !== ''
  ) {
    let indexOfInternalLinkHashmark = internalLink.indexOf('#')
    if (indexOfInternalLinkHashmark > -1) {
      internalLink = internalLink.substring(0, indexOfInternalLinkHashmark)
    }

    let hasRoute = props.routes.find((route) => route.path === internalLink)
    if (hasRoute) {
      return true
    } else {
      return false
    }
  }
  return false
}
</script>
