<template>
  <MFODesktopPackageRowLayout :is-start-text-only="true">
    <template #first>
      <SubmodusTitle
        :icon-size="16"
        :tooltip-content="texts.vtxt_kalk241_tooltipContent"
        :tooltip-title="'Felelősségbiztosítás'"
      >
        Felelősségbiztosítás
      </SubmodusTitle>
    </template>
    <template #second>
      <SelectInput
        id="KALK_241_Extra"
        :key="viewModel.Packages.Extra.LiabilityInsurance.Value"
        v-model="viewModel.Packages.Extra.LiabilityInsurance.Value"
        :options="viewModel.Packages.Extra.LiabilityInsurance.Values"
        :init-option-value="
          viewModel.Packages.Extra.LiabilityInsurance.Value.value
        "
        :texts="texts"
        :format-number="true"
        suffix="Ft"
        :is-disabled="isGTDisabled"
        @update:model-value="setSubmodusValue"
      />
    </template>
    <template #third>
      <SelectInput
        id="KALK_241_Komfort"
        :key="viewModel.Packages.Komfort.LiabilityInsurance.Value"
        v-model="viewModel.Packages.Komfort.LiabilityInsurance.Value"
        :options="viewModel.Packages.Komfort.LiabilityInsurance.Values"
        :init-option-value="
          viewModel.Packages.Komfort.LiabilityInsurance.Value.value
        "
        :texts="texts"
        :format-number="true"
        suffix="Ft"
        :is-disabled="isGTDisabled"
        @update:model-value="setSubmodusValue"
      />
    </template>
    <template #fourth>
      <SelectInput
        id="KALK_241_StartPlus"
        :key="viewModel.Packages.StartPlus.LiabilityInsurance.Value"
        v-model="viewModel.Packages.StartPlus.LiabilityInsurance.Value"
        :options="viewModel.Packages.StartPlus.LiabilityInsurance.Values"
        :init-option-value="
          viewModel.Packages.StartPlus.LiabilityInsurance.Value.value
        "
        :texts="texts"
        :format-number="true"
        suffix="Ft"
        :is-disabled="isGTDisabled"
        @update:model-value="setSubmodusValue"
      />
    </template>
    <template #fifth>
      {{ getFormattedNumber(liabilityInsuranceText()) + ' Ft' }}
    </template>
  </MFODesktopPackageRowLayout>
</template>
<script setup>
import { inject } from 'vue'

const { getFormattedNumber } = inject('util')
const texts = inject('texts')
let viewModel = inject('viewModel')
const emit = defineEmits(['calculate'])
let { isGTDisabled } = inject('isGTDisabled')

function setSubmodusValue(selectedValue) {
  if (viewModel.value.MNBMode == undefined) {
    let valueToSet =
      viewModel.value.Packages.Extra.LiabilityInsurance.Values.find(
        (value) => value.value === selectedValue
      )

    let valuetoSetObject = {
      Id: valueToSet.value,
      Text: valueToSet.label,
      IsDisabled: false,
    }
    viewModel.value.Packages.Extra.LiabilityInsurance.Value = valuetoSetObject
    viewModel.value.Packages.Komfort.LiabilityInsurance.Value = valuetoSetObject
    viewModel.value.Packages.StartPlus.LiabilityInsurance.Value =
      valuetoSetObject
  }

  emit('calculate')
}

function liabilityInsuranceText() {
  const liabilityValue =
    viewModel.value.Packages.Start.LiabilityInsurance.Values[0]
  if (liabilityValue) {
    return `${liabilityValue.label}`
  }
}
</script>
<style lang="scss" scoped></style>
