<template>
  <div
    :id="id"
    class="card-accordion"
    :class="{ 'card-accordion--bg-transparent': transparent }"
  >
    <div
      v-for="(collapseItem, index) in collapseList"
      :key="index"
      class="card"
    >
      <div
        :id="collapseItem.id"
        class="card-header btn btn-link collapsed"
        data-bs-toggle="collapse"
        :data-bs-target="`#collapse-${collapseItem.name}`"
        aria-expanded="false"
        :aria-controls="`collapse-${collapseItem.name}`"
        @mousedown.left="collapseToggler(collapseItem)"
      >
        <div class="title">
          <Title
            htag="h4"
            class="btn-title"
          >
            {{ collapseItem.title }}
          </Title>
        </div>
        <span class="caret">
          <PhIconManager
            v-if="collapseItem.active"
            ph-icon="ph-minus"
            :size="16"
          />
          <PhIconManager
            v-else
            ph-icon="ph-plus"
            :size="16"
          />
        </span>
      </div>

      <div
        :id="`collapse-${collapseItem.name}`"
        class="collapse"
        :aria-labelledby="collapseItem.id"
        :data-bs-parent="`#${id}`"
      >
        <div class="card-body">
          <template v-if="collapseItem.component">
            <component :is="collapseItem.component" />
          </template>
          <div
            v-else
            v-sanitize="collapseItem.content"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true,
    default: 'accordion',
  },
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
  transparent: {
    type: Boolean,
    default: false,
  },
})

const collapseList = reactive({ ...props.items })

function collapseToggler(collapseItem) {
  collapseItem.active = !collapseItem.active

  for (const collapse in collapseList) {
    if (collapseItem.id !== collapseList[collapse].id) {
      collapseList[collapse].active = false
    }
  }
}
</script>

<script>
export default {
  name: 'AccordionComponent',
}
</script>

<style lang="scss" scoped>
.card-accordion {
  border-radius: 0.625rem;

  .card {
    background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
    border-radius: 0.625rem;
    margin-bottom: $half;
    border-width: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn-link {
    text-align: left;
    text-decoration: none;
  }

  .collapse,
  .collapsing {
    border-right: 1px solid #e6e8f3;
    border-left: 1px solid #e6e8f3;
    border-bottom: 1px solid #e6e8f3;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }

  .card-header {
    padding: 0.5625rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $dark;
    border-width: 0;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    background-color: transparent;
    border-top: 1px solid #e6e8f3;
    border-right: 1px solid #e6e8f3;
    border-left: 1px solid #e6e8f3;

    .title {
      display: flex;
      align-items: center;

      .btn-title {
        font-weight: $font-weight-bold;
        font-size: 0.875rem;
        line-height: $line-height-md;
        margin-right: $half;
        margin-bottom: 0;
      }
    }

    &.btn.collapsed {
      background-color: #ecebef;
      border-radius: 0.625rem;
      border: 1px solid transparent;
    }
  }

  &--bg-transparent {
    @media (max-width: $breakpoint-md) {
      .card {
        background-color: transparent;
      }

      .card-header {
        border-width: 0.125rem;
        border-color: $N-400;
        border-bottom: 0;
        box-shadow: none;

        &.btn.collapsed {
          background-color: transparent;
          border: 0.125rem solid $N-400;
        }
      }

      .collapse,
      .collapsing {
        border-color: $N-400;
        border-width: 0.125rem;
      }
    }
  }

  .caret {
    pointer-events: none;
  }
}
</style>
