<template>
  <main id="home-page">
    <HeroModule
      v-if="isMiniCalculatorReady"
      class="home-hero"
      :background-image="backgroundImageName"
    >
      <Minicalculator
        v-if="$q.screen.lg || $q.screen.xl || $q.screen.xxl"
        product-htag="h2"
        :minicalculator-data="minicalculatorData"
        :background-image-function="setBackgroundImage"
      />
      <TabletMinicalculator
        v-else-if="$q.screen.md"
        :minicalculator-data="minicalculatorData"
      />
      <MobileMinicalculator
        v-else
        :minicalculator-data="minicalculatorData"
      />
      <div class="scroll-icon-holder d-none d-xl-flex">
        <span class="scroll-icon">
          <span class="scroll-icon__wheel-outer">
            <span class="scroll-icon__wheel-inner" />
          </span>
        </span>
      </div>
    </HeroModule>
    <div
      v-else
      class="home-hero"
    >
      <div class="hero-skeleton" />
    </div>
    <AdministrationModule
      v-if="isAdministrationReady"
      :administration-data="administrationData"
    />
    <div v-else>
      <div class="administration-skeleton" />
    </div>
    <section
      v-if="isNewsReady"
      class="swiper-section news-section"
    >
      <div class="container-xxl">
        <div class="h2 medium text-center">
          {{ newsData.title }}
        </div>
        <Carousel
          :breakpoints="{
            '320': {
              slidesPerView: 1.1,
              spaceBetween: 16,
            },
            '576': {
              slidesPerView: 2.1,
              spaceBetween: 16,
            },
            '768': {
              slidesPerView: 3.1,
              spaceBetween: 16,
            },
            '992': {
              slidesPerView: 2.5,
              spaceBetween: 24,
            },
            '1200': {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            '1440': {
              slidesPerView: 3.5,
              spaceBetween: 24,
            },
          }"
          :slides="newsData.items"
        />
      </div>
    </section>
    <section
      v-if="isBenefitReady"
      class="benefits-section bg-white text-center"
    >
      <div class="container-xxl">
        <div class="h2 medium text-center">
          Megéri minket választani
        </div>
        <div class="row">
          <div
            v-for="item in benefitData.items"
            :key="item.name"
            class="col col-12 custom-home-col-sm-4"
          >
            <div class="card card-neutral shadow-none">
              <div class="card-body">
                <div
                  v-if="item.icon"
                  class="icon-holder"
                >
                  <PhIconManager
                    :ph-icon="`ph-${item.icon}`"
                    :size="80"
                  />
                </div>
                <div
                  v-else
                  class="icon-holder"
                >
                  <img
                    :src="`${imageUrlHelper.getImageUrl(
                      item.alternateIcon,
                      componentUrl
                    )}`"
                    alt="alt"
                  >
                </div>
                <div class="card-content">
                  <div class="h3 small">
                    {{ item.title }}
                  </div>
                  <p class="card-text">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <BlogModule
      v-if="isBlogReady"
      :slide-breakpoints="{
        '320': {
          slidesPerView: 1.3,
          spaceBetween: 16,
        },
        '576': {
          slidesPerView: 2.1,
          spaceBetween: 16,
        },
        '768': {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        '992': {
          slidesPerView: 3.05,
          spaceBetween: 16,
        },
        '1200': {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      }"
      module-title="Biztosítási tippek a Genertel Blogon"
      module-sub-title="Biztosítással, életmóddal kapcsolatos további tartalmakért látogasson el blogunkra."
      module-link="https://blog.genertel.hu/"
      module-link-text="Tovább"
      :blog-items="blogData.blogItems"
    />
  </main>
</template>
<script setup>
import axios from 'axios'
import { ref, onMounted, computed, onUnmounted, watch } from 'vue'
import imageUrlHelper from '../utils/infrastructure/imageUrlHelper'
import { useQuasar } from 'quasar'

const $q = useQuasar()
$q.screen.setSizes({
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
})

const minicalculatorData = ref(null)
const isMiniCalculatorReady = ref(false)
const administrationData = ref(null)
const newsData = ref(null)
const benefitData = ref(null)
const blogData = ref(null)
const isAdministrationReady = ref(false)
const isNewsReady = ref(false)
const isBenefitReady = ref(false)
const isBlogReady = ref(false)
const newsletterReadMore = ref(true)
const backgroundImage = ref('')

const scrollPosition = ref(0)

const isNewsLoaded = ref(false)
const isBenefitLoaded = ref(false)
const isBlogLoaded = ref(false)

watch(
  () => scrollPosition.value,
  (value, prevValue) => {
    if (isNewsLoaded.value === false && value > 250) {
      isNewsLoaded.value = true
      loadNewsData()
    }
    if (isBenefitLoaded.value === false && value > 300) {
      isBenefitLoaded.value = true
      loadBenefitData()
    }

    if (isBlogLoaded.value === false && value > 350) {
      isBlogLoaded.value = true
      loadBlogData()
    }
  }
)

onMounted(() => {
  axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Contents/Minicalculators'
  ).then(function (response) {
    minicalculatorData.value = response.data

  if (minicalculatorData.value) {
    isMiniCalculatorReady.value = true
  }
  })

  axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Contents/Administration'
  ).then(function(administrationResponse)
  {
    administrationData.value = administrationResponse.data

    if (administrationData.value) {
      isAdministrationReady.value = true
    }
  }
  )

  window.addEventListener('scroll', handleScroll, { passive: true })
}
)

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

function handleScroll(e) {
  scrollPosition.value = window.scrollY
}

async function loadNewsData() {
  let newsResponse = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Contents/News'
  )

  newsData.value = newsResponse.data
  if (newsData.value) {
    isNewsReady.value = true
  }
}

async function loadBenefitData() {
  let benefitResponse = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Contents/Benefits'
  )

  benefitData.value = benefitResponse.data
  if (benefitData.value) {
    isBenefitReady.value = true
  }
}

async function loadBlogData() {
  let blogResponse = await axios.get(
    import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Blog/blog'
  )

  blogData.value = blogResponse.data
  if (blogData.value) {
    isBlogReady.value = true
  }
}

const backgroundImageName = computed(() => backgroundImage.value)

function setBackgroundImage(value) {
  backgroundImage.value = value
}

function showText() {
  newsletterReadMore.value = newsletterReadMore.value
}

function toggleState(e) {
  if (e) {
    e.preventDefault()
    e.stopPropagation()
  }
}
</script>
<style lang="scss">
.home-hero {
  flex-direction: column;
  align-items: center !important;

  .scroll-icon-holder {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
.news-section {
  background-color: $N-200;
}
.swiper-section {
  overflow: hidden;
  .h2.medium {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 4rem;
  }
}
.benefits-section {
  .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .card-neutral {
    background-color: $N-100;
    border-width: 0;
    border-radius: 0.75rem;
    padding: 2.5rem;
    height: 100%;
    .icon-holder {
      margin-bottom: 2.5rem;
      color: $primary;
      img {
        aspect-ratio: 1;
        height: 5rem;
      }
    }
    .card-title {
      margin-bottom: 1rem;
    }
  }
  .h2.medium {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 4rem;
  }
  .h3.small {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .card-body {
    padding: 0;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .benefits-section {
    .row {
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    }
    .col {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .card-neutral {
      padding: 1rem;
      .icon-holder {
        margin-bottom: 1.5rem;
      }
    }
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
    .small {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    .h2.medium {
      font-size: 1.75rem;
      margin-bottom: $double;
    }
    .h3.small {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: $half;
    }
    p {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
  .swiper-section {
    .h2.medium {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2.25rem;
      margin-bottom: 2rem;
    }
    .card-content {
      .xsmall {
        line-height: 1.75rem !important;
        font-weight: 600 !important;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-lg-max) and (min-width: $breakpoint-md) {
  .news-section {
    .card-content {
      .xsmall {
        font-weight: 700 !important;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-sm-max) {
  .benefits-section {
    .h2.medium {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
    .card-neutral {
      .icon-holder {
        margin-bottom: 1rem;
      }
    }
    .h3.small {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: $half;
    }
  }
  .swiper-section {
    .h2.medium {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
    h2.xsmall {
      font-weight: 600;
      line-height: 1.75rem;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .benefits-section {
    .h2.medium {
      margin-bottom: 2rem;
    }
    .col {
      margin-bottom: $small/2;
    }
    .card-neutral {
      padding: 1rem;
      .icon-holder {
        height: 1.5rem;
        width: 1.5rem;
        font-size: 1.5rem;
        color: $primary;
        margin: 0 1rem 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          font-size: 1.5rem;
          height: 1.5rem;
          width: 1.5rem;
        }
        img {
          height: 1.5rem;
        }
      }
      .card-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
      }
      .card-content {
        text-align: left;
        .h3.small {
          margin-bottom: 0;
        }
        p {
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-xs) {
  .custom-home-col-sm-4 {
    width: calc(100% / 3) !important;
  }
}
@media (min-width: $breakpoint-sm) {
  .col-md-6 {
    width: 50% !important;
  }
}
.newsletter-section {
  padding: 5rem 0 9.5rem;
  .container {
    width: 56.9375rem;
    max-width: calc(100% - 2rem);
    padding: 2.5rem;
    background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
    border: 0.1875rem solid $N-200;
    border-radius: 0.75rem;
    & > .row {
      margin-right: -$normal;
      margin-left: -$normal;
      & > .col {
        padding-right: $normal;
        padding-left: $normal;
      }
    }
  }
  .readmore-link svg {
    margin-left: $half;
  }
  .h2.normal {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .newsletter-section {
    padding: 2.5rem 2rem;
    .container {
      width: 100%;
      max-width: 100%;
      padding: 2.5rem;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .newsletter-section {
    padding: 2rem !important;
    .container {
      width: 100%;
      max-width: 100%;
      padding: 2.5rem;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .newsletter-section {
    padding: 0 !important;
    .container {
      width: 100%;
      max-width: 100%;
      padding: 1.5rem 1rem 2rem;
      background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
      border-width: 0;
      border-radius: 0;
    }
    .normal {
      text-align: center;
      margin-bottom: $small;
    }
    .excerpt {
      text-align: center;
      margin-bottom: $large;
    }
    .icon-holder {
      display: none;
    }
    .h2.normal {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 0.25rem;
    }
    p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
      .col {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
@media screen and (max-height: 700px) and (min-width: $breakpoint-md) {
  .administration-section {
    padding-top: 2.5rem !important;
  }
}

.hero-skeleton {
  height: 45.25rem;
}

.administration-skeleton {
  height: 43.75rem;
  background-color: $N-100;
}

@media screen and (max-width: $breakpoint-md-max) {
  .hero-skeleton {
    height: 44.75rem;
  }
  .administration-skeleton {
    height: 66.25rem;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .hero-skeleton {
    height: 26.875rem;
  }
  .administration-skeleton {
    height: 64.375rem;
  }
}
</style>
