<template>
  <div class="calculator-buttons">
    <q-btn
      v-if="!hideBackButton"
      class="btn btn-secondary btn-outline btn-back"
      @click="goToPrevPage"
    >
      {{ firstbtn }}
    </q-btn>
    <q-btn
      class="btn btn-secondary"
      @click="goToNextPage"
    >
      {{ secondbtn }}
    </q-btn>
  </div>
</template>
<script setup>
const emit = defineEmits(['goToNextPage', 'goToPrevPage'])

function goToNextPage() {
  emit('goToNextPage')
}

function goToPrevPage() {
  emit('goToPrevPage')
}
const props = defineProps({
  firstbtn: {
    type: String,
    default: 'Vissza',
  },
  secondbtn: {
    type: String,
    default: 'Tovább',
  },
  hideBackButton:{
    type: Boolean,
    default: false
  }
})
</script>
<style lang="scss" scoped>
.calculator-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 5rem;
  .btn-secondary {
    padding: 0.75rem 1.25rem;

    :deep(.q-btn__content) {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .btn-back {
    padding: 0.625rem 1.125rem;
  }
}

@media screen and (max-width: $breakpoint-lg-max) {
  .calculator-buttons {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  .calculator-buttons {
    margin-top: 2rem;
    flex-direction: column-reverse;
    .btn-secondary {
      width: 100%;
    }
  }
}
</style>
