import { defineRule } from 'vee-validate'
import * as ValidationMessages from '@/utils/validations/validationMessages.js'
import { isInt } from '../miscHelpers'
import axios from 'axios'
import { ValidationJSONStringToObject } from './crossFieldValueConverter'
import { customDefineRule } from './customDefineRule'

const DateTime = window.luxon.DateTime
export function loadVeeValidateRules() {
  defineRule('VAL_POC_CROSS_FIELD', (value, [target]) => {
    if (value === '123' && target === 'asd') {
      return ValidationMessages.VAL_POC_CROSS_FIELD
    }
    return true
  })

  defineRule('VAL_BACKEND_VALIDATION', async (value) => {
    let validationResponse = await axios.get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Validation/PocBackendValidation?value=' +
        value
    )
    if (validationResponse.data.isValid === false) {
      return validationResponse.data.message
    }
    return true
  })

  customDefineRule('VAL_100', (value) => {
    if (!value) {
      return ValidationMessages.VAL_100
    }
    return true
  })

  customDefineRule('VAL_100_SELECT', (value) => {
    if (value !== null && value !== undefined && value !== '') {
      return true
    }

    if (!value) {
      return ValidationMessages.VAL_100
    }
    return true
  })

  customDefineRule('VAL_100_CHECKBOX', (value) => {
    if (value == false || value == null) {
      return ValidationMessages.VAL_100
    }
    return true
  })

  customDefineRule('VAL_100_TEXTAREA', (value) => {
    if (!value || !value.length) {
      return ValidationMessages.VAL_100
    }
    return true
  })

  customDefineRule('VAL_100_RADIO', (value) => {
    if (value != true && value != false) {
      return ValidationMessages.VAL_100
    }
    return true
  })

  customDefineRule('VAL_103', (value) => {
    // regex: legalább 1 szóköz, amit követ legalább 1 nem szóköz karakter
    if (value !== undefined && value !== '') {
      const regexName = /\s(?=\S)/
      const name = value.trim()
      if (!regexName.test(name)) {
        return ValidationMessages.VAL_103
      }
    }
    return true
  })

  customDefineRule('VAL_104', (value) => {
    const regex = /^([a-zA-Z-áäéűőúüóöíÁÄÉŰŐÚÜÓÖÍ.\- ])+$/
    if (!String(value).match(regex)) {
      return ValidationMessages.VAL_104
    }
    return true
  })

  defineRule('VAL_111', async (value, [target]) => {
    let parts = value.split(' - ')
    let postalCode = parts[0]
    let settlementName = parts[1]
    let response

    if (isNaN(settlementName)) {
      if (settlementName.length < 3) {
        return true
      }
    } else if (postalCode.length < 4) {
      return true
    }
    if (postalCode.length == 4) {
      if (target === '2') {
        response = await axios.get(
          import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Addresses/PostOfficeZipCode',
          {
            params: { filter: postalCode },
          }
        )
        if (response.data.zipCodes != undefined) {
          if (response.data.zipCodes.length > 0) {
            return true
          } else {
            return ValidationMessages.VAL_111
          }
        }
      }
      else {
        response = await axios.get(
          import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Addresses/ZipCode',
          {
            params: { zipCode: postalCode },
          }
        )
        if (response.data.zipCodes != undefined) {
          if (response.data.zipCodes.length > 0) {
            return true
          } else {
            return ValidationMessages.VAL_111
          }
        }
      }
    }
    if (settlementName.length > 3) {
      if (target === '2') {
        response = await axios.get(
          import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Addresses/PostOfficeZipCode',
          {
            params: { filter: settlementName },
          }
        )
        if (response.data.zipCodes != undefined) {
          if (response.data.zipCodes.length > 0) {
            return true
          } else {
            return ValidationMessages.VAL_111
          }
        }
      }
      else {
        response = await axios.get(
          import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL + '/Addresses/ZipCode',
          {
            params: { zipCode: settlementName },
          }
        )
        if (response.data.zipCodes != undefined) {
          if (response.data.zipCodes.length > 0) {
            return true
          } else {
            return ValidationMessages.VAL_111
          }
        }
      }
    }

    return true
  })

  defineRule('VAL_128', (value) => {
    if (parseInt(value) > 10) {
      return ValidationMessages.VAL_128
    }
    return true
  })

  defineRule('VAL_131', (value) => {
    if (parseInt(value) > 9) {
      return ValidationMessages.VAL_131
    }
    return true
  })

  defineRule('VAL_178', (value) => {
    if (value < 1) {
      return ValidationMessages.VAL_178
    }
    return true
  })

  customDefineRule('VAL_191', (value) => {
    if (value !== undefined) {
      if (value.length < 6) {
        return ValidationMessages.VAL_191
      }
    }
    return true
  })

  defineRule('VAL_206', (value) => {
    const EMAIL_REGEX =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidEmail = String(value).toLowerCase().match(EMAIL_REGEX)
    if (!isValidEmail) {
      return ValidationMessages.VAL_206
    }
    return true
  })

  customDefineRule('VAL_106', (value) => {
    const EMAIL_REGEX =
      /^([a-zA-Z0-9_\\-\\\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/
    const isValidEmail = String(value).toLowerCase().match(EMAIL_REGEX)
    if (!isValidEmail) {
      return ValidationMessages.VAL_106
    }
    return true
  })

  defineRule('VAL_238_SELECT', (value) => {
    const nemCascozhato = ['1', '2', '3', '14']
    if (nemCascozhato.includes(value)) {
      return ValidationMessages.VAL_238
    }
    return true
  })

  defineRule('VAL_322', (value) => {
    if (value > 9) {
      return ValidationMessages.VAL_322
    }
    return true
  })

  defineRule('VAL_345', (value) => {
    if (value !== undefined) {
      if (!value.match(/^[a-zA-Z0-9]+$/)) {
        return ValidationMessages.VAL_345
      }
    }
    return true
  })

  defineRule('VAL_346', (value) => {
    if (value !== undefined) {
      if (!value.match(/^[A-Za-z].*[0-9]$/)) {
        return ValidationMessages.VAL_346
      }
    }
    return true
  })

  defineRule('VAL_101', (value) => {
    if (!value || !value.length) {
      return true
    }

    if (!/^(.+)([ ].+)/.test(String(value))) {
      return ValidationMessages.VAL_101
    }
    return true
  })

  defineRule('VAL_001', (value) => {
    if (value > 10) return ValidationMessages.VAL_001
    return true
  })

  defineRule('VAL_002', (value) => {
    if (value > 100) return ValidationMessages.VAL_002
    return true
  })

  defineRule('VAL_003', (value) => {
    if (value > 1000) return ValidationMessages.VAL_003
    return true
  })

  defineRule('VAL_004', (value) => {
    if (!isInt(value)) {
      return ValidationMessages.VAL_004
    }
    return true
  })

  customDefineRule('VAL_108', (value) => {
    if (value != null && value.length !== 11) {
      return ValidationMessages.VAL_108
    }
    return true
  })

  customDefineRule('VAL_108_LOYALTY', (value) => {
    if (value != null && value.length !== 11) {
      return ValidationMessages.VAL_108
    }
    return true
  })

  defineRule('VAL_183', (value) => {
    if (value != null && value.length !== 6) {
      return ValidationMessages.VAL_183
    }
    return true
  })

  customDefineRule('VAL_184', (value) => {
    if (value != null && value.length !== 4) {
      return ValidationMessages.VAL_184
    }
    return true
  })

  defineRule('VAL_284', (value) => {
    return true
  })

  customDefineRule('VAL_467', (value) => {
    if (value !== undefined) {
      if (!/^[a-záéíóöőúüűäA-ZÁÉÍÓÖŐÚÜŰÄ0-9.\/\- ]+$/.test(String(value))) {
        return ValidationMessages.VAL_467
      }
    }
    return true
  })

  defineRule('VAL_169', async (value) => {
    var result = await axios.get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Validation/PolicyNumberValidate_VAL_169?policyNumber=' +
        value
    )
    if (result.data.hasError) {
      return result.data.message
    }
    return true
  })

  defineRule('VAL_196', async (value, [target]) => {
    var result = await axios.get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Validation/PolicyTypeValidate_VAL_196?policyNumber=' +
        target +
        '&value=' +
        value
    )
    if (result.data.hasError) {
      return result.data.message
    }
    return true
  })

  defineRule('VAL_351', async (value) => {
    var result = await axios.get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Validation/PolicyLiveStatus_VAL_351?policyNumber=' +
        value
    )
    if (result.data.hasError) {
      return result.data.message
    }
    return true
  })

  defineRule('VAL_352', async (value) => {
    var result = await axios.get(
      import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
        '/Validation/PolicyIsOffer_VAL_352?policyNumber=' +
        value
    )
    if (result.data.hasError) {
      return result.data.message
    }
    return true
  })

  defineRule('VAL_197_ZIP', async (value, [target]) => {
    const splitedTarget = target.split(';')

    if (splitedTarget.length == 2) {
      var result = await axios.get(
        import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
          '/Validation/PolicyTypeValidate_VAL_197_ZIP?policyNumber=' +
          splitedTarget[0] +
          '&policyType=' +
          splitedTarget[1] +
          '&zip=' +
          value
      )
      if (result.data.hasError) {
        return result.data.message
      }
    }
    return true
  })

  defineRule('VAL_197_PLATE', async (value, [target]) => {
    const splitedTarget = target.split(';')

    if (splitedTarget.length == 2) {
      var result = await axios.get(
        import.meta.env.VITE_PORTALBUSINESSAPI_BASEURL +
          '/Validation/PolicyTypeValidate_VAL_197_PLATE?policyNumber=' +
          splitedTarget[0] +
          '&policyType=' +
          splitedTarget[1] +
          '&plate=' +
          value
      )
      if (result.data.hasError) {
        return result.data.message
      }
    }
    return true
  })
  defineRule('VAL_100_FAQ', (value) => {
    if (!value || (value.trim().length === 0 && value != -1)) {
      return ValidationMessages.VAL_100
    }
    return true
  })
  customDefineRule('VAL_406', (value) => {
    if (!value) {
      return ValidationMessages.VAL_406
    }
    return true
  })
  customDefineRule('VAL_123', (value) => {
    if (Number(value) >= 10 && Number(value) <= 60000) {
      return true
    }
    return ValidationMessages.VAL_123
  })
  customDefineRule('VAL_329', (value) => {
    const regexName = /^[1-9][0-9]{3}$/
    const name = String(value).trim()
    if (!regexName.test(String(name))) {
      return ValidationMessages.VAL_329
    }
    return true
  })
  customDefineRule('VAL_357', (value) => {
    if (value < 1800) {
      return ValidationMessages.VAL_357
    }
    return true
  })
  customDefineRule('VAL_408', (value) => {
    if (value == 0) {
      return ValidationMessages.VAL_408
    }
    return true
  })
  customDefineRule('VAL_409', (value) => {
    if (value == 0) {
      return ValidationMessages.VAL_409
    }
    return true
  })
  defineRule('VAL_410', (value) => {
    if (value > 9) {
      return ValidationMessages.VAL_410
    }
    return true
  })
  customDefineRule('VAL_403', (value) => {
    if (value == 3) {
      return ValidationMessages.VAL_403
    }
    return true
  })
  customDefineRule('VAL_362', (value) => {
    if (value > 5) {
      return ValidationMessages.VAL_362
    }
    return true
  })
  customDefineRule('VAL_361', (value) => {
    if (value > 10000000) {
      return ValidationMessages.VAL_361
    }
    return true
  })
  customDefineRule('VAL_413', (value) => {
    if (!value) {
      return ValidationMessages.VAL_413
    }
    return true
  })
  customDefineRule('VAL_432,', (value) => {
    if (value > 3) {
      return ValidationMessages.VAL_432
    }
    return true
  })
  customDefineRule('VAL_333_MONTH,', (value) => {
    const datePattern = /\d{4}\.\d{2}\./
    const hasFormat = datePattern.test(value)
    if (!hasFormat) {
      return ValidationMessages.VAL_333_MONTH
    }
    return true
  })
  customDefineRule('VAL_261_LOYALTY', (value) => {
    const regexName = /^[1]{1}[0-9]{10}/
    if (value != null && value.length == 1 && value.startsWith('1')) {
      return true
    } else if (!regexName.test(String(value))) {
      return ValidationMessages.VAL_261
    }
    return true
  })
  customDefineRule('VAL_261', (value) => {
    const regexName = /^[1]{1}[0-9]{10}/
    if (!regexName.test(String(value))) {
      return ValidationMessages.VAL_261
    }
    return true
  })
  defineRule('VAL_109', (value) => {
    const regexName = /^[0-9]{8}$/
    if (!regexName.test(String(value))) {
      return ValidationMessages.VAL_109
    }
    return true
  })
  defineRule('VAL_110', (value) => {
    const regexName = /^[0-9]{10}$/
    if (!regexName.test(String(value))) {
      return ValidationMessages.VAL_110
    }
    return true
  })
  defineRule('VAL_134', (value) => {
    if (value ? true : false) {
      return ValidationMessages.VAL_134
    }
    return true
  })
  defineRule('VAL_136', (value, args) => {
    const underConstruction = args[0]
    if (args && !value && !underConstruction) {
      return ValidationMessages.VAL_136
    }
    return true
  })
  defineRule('VAL_137,', (value) => {
    if (value == 3) {
      return ValidationMessages.VAL_137
    }
    return true
  })

  defineRule('VAL_185', (value) => {
    const regexName = /^[a-záéíóöőúüűA-ZÁÉÍÓÖŐÚÜŰ0-9.,!?\s]{3,}$/
    if (!regexName.test(String(value))) {
      return ValidationMessages.VAL_185
    }
    return true
  })
  defineRule('VAL_212', (value) => {
    if (!(Number(value) > 0)) {
      return ValidationMessages.VAL_212
    }
    return true
  })
  defineRule('VAL_219', (value) => {
    if (!(Number(value) > 0)) {
      return ValidationMessages.VAL_219
    }
    return true
  })
  defineRule('VAL_220', (value) => {
    if (Number(value) >= 10 && Number(value) <= 350) {
      return true
    }
    return ValidationMessages.VAL_220
  })
  customDefineRule('VAL_379', (value) => {
    if (Number(value) > 200000000) {
      return ValidationMessages.VAL_379
    }
    return true
  })

  customDefineRule('VAL_330', (value) => {
    const currentDate = DateTime.now().toFormat('yyyy.MM.dd')
    if (value != undefined && value.length == 10) {
      if (value > currentDate) {
        return ValidationMessages.VAL_330
      }
    }
    return true
  })
  customDefineRule('VAL_330_YEAR', (value) => {
    const currentDate = DateTime.now().toFormat('yyyy.MM.dd')

    if (value != undefined && value.length == 4) {
      const dateInput = DateTime.fromISO(value).toFormat('yyyy')

      if (dateInput > currentDate) {
        return ValidationMessages.VAL_330
      }
    }
    return true
  })
  customDefineRule('VAL_330_MONTH', (value) => {
    const currentDate = DateTime.now().toFormat('yyyy.MM.')
    if (value != undefined && value.length == 8) {
      if (value > currentDate) {
        return ValidationMessages.VAL_330
      }
    }
    return true
  })

  customDefineRule('VAL_411', (value) => {
    const birthYear = parseInt(value, 10)
    const currentYear = DateTime.now().year

    if (currentYear - birthYear > 110) {
      return ValidationMessages.VAL_411
    }

    return true
  })

  customDefineRule('VAL_114', (value) => {
    const currentYear = DateTime.now()
    const inputValue = DateTime.fromFormat(value, 'yyyy.MM.dd')
    const diffInYears = currentYear.diff(inputValue, 'years').as('years')
    if (diffInYears < 18) {
      return ValidationMessages.VAL_114
    }

    return true
  })
  customDefineRule('VAL_114_YEAR', (value) => {
    const currentYear = DateTime.now()
    const inputValue = DateTime.fromFormat(String(value), 'yyyy')
    const diffInYears = currentYear.diff(inputValue, 'years').as('years')
    if (diffInYears < 18) {
      return ValidationMessages.VAL_114
    }

    return true
  })

  customDefineRule('VAL_363_MAIN', (value) => {
    if(isNaN(value)){
      value = value.replace(/\s/g, '')
    }
     if (Number(value) > 25000000) {
       return ValidationMessages.VAL_363
     }
    return true
  })

  customDefineRule('VAL_364_MAIN', (value) => {
    const valueNumber = parseInt(value, 10)
    if (valueNumber > 5000000) {
      return ValidationMessages.VAL_364
    }
    return true
  })

  customDefineRule(
    'VAL_364_MODULE',
    (value, target) => {
      const targetObj = ValidationJSONStringToObject(target)
      const valueNumber = parseInt(value, 10)

      if (valueNumber === 0) return true

      const sum =
        parseInt(targetObj.val1, 10) +
        parseInt(targetObj.val2, 10) +
        parseInt(targetObj.val3, 10) +
        parseInt(targetObj.val4, 10)

      if (sum > 5000000) {
        return ValidationMessages.VAL_364
      }
      return true
    },
    true
  )

  defineRule('VAL_ACCIDENT_MAX', (value, [target]) => {
    if (Number(value) + Number(target) > 10) {
      return ValidationMessages.VAL_ACCIDENT_MAX
    }
    return true
  })

  customDefineRule('VAL_107', (value) => {
    const regexName = /^[0-9]{24}$/
    if (regexName.test(String(value)) && isBankAccountFormatValid(value)) {
      return true
    } else {
      return ValidationMessages.VAL_107
    }
  })

  customDefineRule('VAL_393', (value, target) => {
    const targetObj = ValidationJSONStringToObject(target)
    const marketingStatement3 = targetObj.marketingStatement3
    const marketingstatementGame = targetObj.marketingstatementGame
    if (marketingStatement3 != true && marketingstatementGame == true) {
      return ValidationMessages.VAL_393
    } else {
      return true
    }
  })

  customDefineRule(
    'VAL_372',
    (value, target) => {
      const targetObj = ValidationJSONStringToObject(target)
      if (
        targetObj.hasAcceptConditionOpened === undefined ||
        targetObj.hasAcceptConditionOpened === false ||
        targetObj.hasProductGuideOpened === undefined ||
        targetObj.hasProductGuideOpened === false
      ) {
        return ValidationMessages.VAL_372
      } else {
        return true
      }
    },
    true
  )

  defineRule('VAL_ACCIDENT_MAX', (value, [target]) => {
    if (Number(value) + Number(target) > 10) {
      return ValidationMessages.VAL_ACCIDENT_MAX
    }
    return true
  })

  defineRule('VAL_100_PHONE', (value, [target]) => {
    const splitedTarget = target.split(';')
    const dialingCode = splitedTarget[0]
    const chunkPhoneNumber = splitedTarget[1]
    const isFirst = splitedTarget[2]
    if (isFirst == 1) {
      return true
    }   
    if (    
      !dialingCode ||
      !dialingCode.length ||
      !chunkPhoneNumber ||
      dialingCode == 'null' ||
      chunkPhoneNumber == 'null' ||
      dialingCode == 'null' ||
      chunkPhoneNumber == 'null' ||
      !chunkPhoneNumber.length
    ) {
      return ValidationMessages.VAL_100
    }
    return true
  })

  defineRule('VAL_105', (value, [target]) => {
    const splitedTarget = target.split(';')
    const dialingCode = splitedTarget[0]
    const chunkPhoneNumber = splitedTarget[1]
    const isFirst = splitedTarget[2]
    const chunkPhoneNumberBlured = splitedTarget[3]
    if(chunkPhoneNumberBlured == 'true'){
      let isValid = false
      const regex =
        /^(?=([237][01]|1|50)\d{7}$)|^(?=(2[2-9]|3[2-7]|4[24-9]|5[234679]|6[23689]|7[2-9]|8[2-9]|9[2-69])\d{6}$)/
      let dialingAndchunk = dialingCode + chunkPhoneNumber
      isValid = regex.test(dialingAndchunk)
      return isValid ? true : ValidationMessages.VAL_105
    }else{
      return true
    }
  })

  customDefineRule('VAL_146', (value) => {
    if (value != '70800067') {
      return ValidationMessages.VAL_146
    }
    return true
  })

  customDefineRule('VAL_434', (value) => {
    const date7YearsAgo = DateTime.now()
      .minus({ years: 7 })
      .toFormat('yyyy.MM.')

    if (value < date7YearsAgo) {
      return ValidationMessages.VAL_434
    }
    return true
  })

  customDefineRule('VAL_456', (value) => {
    const date6MonthsAgo = DateTime.now()
      .minus({ month: 6 })
      .toFormat('yyyy.MM.')

    if (value > date6MonthsAgo) {
      return ValidationMessages.VAL_456
    }
    return true
  })
}

export function getInfoMessagesForValue(value, infoMessageCode, target) {
  var infoMessages = []

  if (infoMessageCode.includes('INFO_001')) {
    if (value.length < 5) {
      infoMessages.push(ValidationMessages.INFO_001)
    }
  }

  if (infoMessageCode.includes('INFO_002')) {
    if (value.charAt(0) !== value.charAt(0).toUpperCase()) {
      infoMessages.push(ValidationMessages.INFO_002)
    }
  }

  return infoMessages
}

export function getWarningMessagesForValue(value, warningMessageCode, target) {
  var warningMessages = []

  if (warningMessageCode.includes('VAL_459')) {
    if (value == true && target[0] == 0) {
      warningMessages.push(ValidationMessages.VAL_459)
    }
  }
  if (warningMessageCode.includes('VAL_460')) {
    if (value == true && target[0] == 0) {
      warningMessages.push(ValidationMessages.VAL_460)
    }
  }
  if (warningMessageCode.includes('VAL_466')) {
    if (value == true) {
      warningMessages.push(ValidationMessages.VAL_466)
    }
  }
  if (warningMessageCode.includes('WARN_001')) {
    if (value.length > 5) {
      warningMessages.push(ValidationMessages.WARN_001)
    }
  }

  if (warningMessageCode.includes('WARN_002')) {
    const punctuationMarks = ['.', '!', '?']

    var check = punctuationMarks.some(function (character) {
      return value.endsWith(character)
    })

    if (!check) {
      warningMessages.push(ValidationMessages.WARN_002)
    }
  }

  if (warningMessageCode.includes('VAL_420')) {
    if (value > 200000) {
      warningMessages.push(ValidationMessages.VAL_420)
    }
  }

  if (warningMessageCode.includes('VAL_465')) {
    let isMFODisabled = target[1]
    let coverageStartDate = target[0]
    let validateDate = DateTime.fromISO('2023-07-01').toFormat('yyyy-MM-dd')
    if (
      coverageStartDate.valueOf() >= validateDate.valueOf() &&
      (value === 5 || value === 6 || value === 7) &&
      !isMFODisabled
    ) {
      warningMessages.push(ValidationMessages.VAL_465)
    }
  }

  if (warningMessageCode.includes('VAL_414')) {
    if (target[3] == undefined) {
      let isMFODisabled = target[0]

      let testSystemDate = target[2]
      let currentDate

      if (testSystemDate != null) {
        currentDate = DateTime.fromFormat(target[2], 'yyyy.MM.dd')
      } else {
        currentDate = DateTime.now()
      }

      if (value != null) {
        value = value.replaceAll('.', '-')
      }

      let coverageStartDatePlus60Days = DateTime.fromISO(currentDate).plus({
        day: 60,
      })

      let coverageStartDatePlus180Days = DateTime.fromISO(currentDate).plus({
        day: 180,
      })

      value = DateTime.fromISO(value).toFormat('yyyy-MM-dd')

      coverageStartDatePlus60Days =
        coverageStartDatePlus60Days.toFormat('yyyy-MM-dd')

      coverageStartDatePlus180Days =
        coverageStartDatePlus180Days.toFormat('yyyy-MM-dd')

      if (
        coverageStartDatePlus60Days.valueOf() < value.valueOf() &&
        value.valueOf() < coverageStartDatePlus180Days.valueOf() &&
        !isMFODisabled
      ) {
        warningMessages.push(ValidationMessages.VAL_414)
      }
    }
  }

  if (warningMessageCode.includes('VAL_458')) {
    if (target[1] != null) {
      target[1] = target[1].replaceAll('/', '-')
    }
    if (value != null) {
      value = value.replaceAll('.', '-')
    }

    const targetDate = DateTime.fromISO(target[1])
    value = DateTime.fromISO(value)
    if (value.invalid != null) {
      let day = targetDate.get('day')
      if (Number(day) !== 1) {
        let indexOfMessage = warningMessages.indexOf(ValidationMessages.VAL_458)
        if (indexOfMessage == -1) {
          warningMessages.push(ValidationMessages.VAL_458)
        }
      } else {
        let indexOfMessage = warningMessages.indexOf(ValidationMessages.VAL_458)
        if (indexOfMessage != -1) {
          warningMessages.splice(indexOfMessage, 1)
        }
      }
    } else {
      let day = value.get('day')
      if (Number(day) !== 1) {
        let indexOfMessage = warningMessages.indexOf(ValidationMessages.VAL_458)
        if (indexOfMessage == -1) {
          warningMessages.push(ValidationMessages.VAL_458)
        }
      } else {
        let indexOfMessage = warningMessages.indexOf(ValidationMessages.VAL_458)
        if (indexOfMessage != -1) {
          warningMessages.splice(indexOfMessage, 1)
        }
      }
    }
  }

  if (warningMessageCode.includes('VAL_447')) {
    let ChangedFromModal = target[0]
    let valuechanged = target[1]
    if (ChangedFromModal && valuechanged) {
      warningMessages.push(ValidationMessages.VAL_447)
    }
  }

  if (warningMessageCode.includes('VAL_433')) {
    if (value) {
      warningMessages.push(ValidationMessages.VAL_433)
    }
  }
  if (warningMessageCode.includes('VAL_365')) {
    if (value === 1) {
      warningMessages.push(ValidationMessages.VAL_365)
    }
  }
  if (warningMessageCode.includes('VAL_418_PAYMENTMODE')) {
    const paymentperiod = target[0]
    const isMFODisabled = target[1]
    const MNBMode = target[2]
    if (
      (value === 8 || value === 7 || value === 1) &&
      paymentperiod === 5 &&
      !isMFODisabled.value &&
      !MNBMode
    ) {
      warningMessages.push(ValidationMessages.VAL_418)
    }
  }
  if (warningMessageCode.includes('VAL_418_PAYMENTPERIOD')) {
    const paymentmode = target[0]
    const isMFODisabled = target[1]
    const MNBMode = target[2]
    if (
      (paymentmode === 8 || paymentmode === 7 || paymentmode === 1) &&
      value === 5 &&
      !isMFODisabled.value &&
      !MNBMode
    ) {
      warningMessages.push(ValidationMessages.VAL_418)
    }
  }
  if (warningMessageCode.includes('VAL_417')) {
    let isGTDisabled = target[0]
    if (value && !isGTDisabled) {
      warningMessages.push(ValidationMessages.VAL_417)
    }
  }

  if (warningMessageCode.includes('VAL_454')) {
    if (
      value == false &&
      target[0] != null &&
      target[0] != undefined &&
      target[0] == true
    ) {
      warningMessages.push(ValidationMessages.VAL_454)
    }
  }

  return warningMessages
}

function isBankAccountFormatValid(bankAccountNumber) {
  let nCheck = [9, 7, 3, 1]
  function isNullOrWhitespace(input) {
    return !input || !input.trim()
  }

  if (
    isNullOrWhitespace(bankAccountNumber) ||
    bankAccountNumber.length > 24 ||
    bankAccountNumber.length < 16 ||
    bankAccountNumber.length % 8 !== 0 ||
    bankAccountNumber.startsWith('00000000')
  ) {
    return false
  }

  let summa = 0
  for (let i = 0; i < bankAccountNumber.length - 1; i++) {
    summa += Number(bankAccountNumber[i]) * nCheck[i % 4]
  }

  return (
    Number(bankAccountNumber[bankAccountNumber.length - 1]) ===
    (10 - (summa % 10)) % 10
  )
}
function nextDay(d) {
  let date = DateTime.fromISO(d).plus({ day: 1 })
  return date.toFormat('yyyy/MM/dd')
}