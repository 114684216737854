<template>
  <section class="hero m-0 p-0">
    <slot />
  </section>
</template>

<script setup>
import { ref, watch, watchEffect } from '@vue/runtime-core'
import imageUrlHelper from '../../utils/infrastructure/imageUrlHelper'

const props = defineProps({
  backgroundImage: {
    type: String,
    required: true,
  },
})

const heroBackgroundImage = ref(
  `url(${imageUrlHelper.getImageUrl(
    props.backgroundImage,
    import.meta.url
  )})`
)

watch(
  () => props.backgroundImage,
  (value, prevValue) => {
    heroBackgroundImage.value = `url(${imageUrlHelper.getImageUrl(
      props.backgroundImage,
      import.meta.url
    )})`
  }
)
</script>

<script>
export default {
  name: 'HeroModule',
}
</script>

<style lang="scss" scoped>
.product-page-calculator {
  section.hero {
    height: auto !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .product-page, #game-page{
    section.hero {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 50%
      ),
      v-bind(heroBackgroundImage);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    min-height: 42.375rem;
    display: flex;
    align-items: flex-start;
    }
  }
}

@media screen and (min-width: $breakpoint-lg)  {
  #home-page{
    section.hero {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 50%
      ),
      v-bind(heroBackgroundImage);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    display: flex;
    align-items: flex-start;
    }
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  section.hero {
    background-color: $N-200;
    overflow: hidden !important;
  }
  
}

@media screen and (max-width: $breakpoint-md-max) {
  .product-page-calculator {
    section.hero {
      padding-bottom: 0 !important;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  section.hero {
    background-color: $N-100;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  section.hero {
    background-color: $N-50;
  }
}
@media screen and (max-height: 700px) and (min-width: $breakpoint-md) {
  .product-page.product-page-calculator{
    section.hero{
      padding-bottom: 2.5rem !important;
      height: unset !important;
      :deep(.google-score-holder .container){
        margin-top: 2.5rem;
      }
      :deep(.minicalculator-holder){
        margin-top: 2.5rem !important;
        margin-bottom: 0 !important;
      }
    }
  }

  #utasbiztositas-page.product-page.product-page-calculator{
    section.hero{
      padding-bottom: 2.5rem !important;
      height: unset !important;
      :deep(.google-score-holder .container){
        margin-top: 2.5rem;
      }
      :deep(.minicalculator-holder){
        margin-top: 2.5rem !important;
        margin-bottom: 0 !important;
      }
    }
  }

  #home-page{
    section.hero{
      padding-bottom: 2.5rem !important;
      height: unset !important;
      :deep(.google-score-holder .container){
        margin-top: 2.5rem;
      }
      :deep(.minicalculator-holder){
        margin-top: 2.5rem !important;
        margin-bottom: 0 !important;
      }
      :deep(.scroll-icon-holder){
        display: none !important;
      }
    }
  }
  #bankkartyas-dijfizetes-page{
    section.hero{
      :deep(.container-xxl){
        padding: unset !important;
        h1{
          margin-bottom: 2.5rem;
        }
      }
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
      height: unset !important;
      :deep(.scroll-icon-holder){
        display: none !important;
      }
    }
  }
}
</style>
