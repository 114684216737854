<template>
  <div class="authorization-input-holder">
    <div>
      <p
        v-if="$q.screen.width > 991"
        class="authorization-paragraph"
      >
        Felhatalmazom a Biztosítót, hogy:
        <ul>
          <li>
            a biztosított épületet ért, káresemények által okozott és 300.000 Ft-ot meghaladó mértékű értékcsökkenésekre tekintettel esedékessé vált szolgáltatási kötelezettségéről minden esetben értesítse a szerződésre bejegyzett Finanszírozót, kifizetést kizárólag csak a Finanszírozó kifejezett hozzájárulásával teljesítse a Biztosított részére, ennek hiányában a szolgáltatást (kifizetést) a Finanszírozónak teljesítse,
          </li>
          <li>
            a biztosított épület megsemmisülését, vagy a teljes épületbiztosítási összeg kimerülését eredményező káresemény bekövetkezése esetén, a valós körülmények figyelembevételével megállapított – a szolgáltatás teljesítésekor fennálló tartozás (tőke, kamat és járulékok) erejéig, de legfeljebb a biztosítási összeg mértékéig terjedő – szolgáltatási összeget közvetlenül a Finanszírozó részére teljesítse,
          </li>
          <li>
            amennyiben a lentebb megjelölt biztosítási szerződés nem jön létre, úgy ennek tényéről a biztosítási ajánlat elutasításával egyidejűleg tájékoztatja a Finanszírozót;
          </li>
          <li>
            a Finanszírozótól eltérő személy részére a fenti összeghatár felett és esetben csak abban az esetben teljesítsen kifizetést, továbbá a jelen okiratban foglaltakat csak abban az esetben tekinti hatálytalannak, amennyiben ehhez a Finanszírozó is kifejezett hozzájárulását adta.
          </li>
          <li>
            a biztosítási szerződés
            <ul>
              <li>
                díjának elmaradásáról az esedékességet követő 60 napon belül tájékoztassa a          
                Finanszírozót, illetve
              </li>
              <li>
                bármely okú megszűnéséről, a megszűnést követő 8 napon belül tájékoztassa a 
                Finanszírozót.
              </li>
            </ul>
          </li>
        </ul>
      </p>
      <p
        v-else
        class="authorization-paragraph"
      >
        Felhatalmazom a Biztosítót, hogy:
        <ul>
          <li v-if="!open">
            a biztosított épületet ért, káresemények által okozott és 300.000 Ft-ot meghaladó mértékű értékcsökkenésekre tekintettel esedékessé vált szolgáltatási kötelezettségéről minden esetben értesítse a szerződésre bejegyzett Finanszírozót, kifizetést...
          </li>
          <li v-if="open">
            a biztosított épületet ért, káresemények által okozott és 300.000 Ft-ot meghaladó mértékű értékcsökkenésekre tekintettel esedékessé vált szolgáltatási kötelezettségéről minden esetben értesítse a szerződésre bejegyzett Finanszírozót, kifizetést kizárólag csak a Finanszírozó kifejezett hozzájárulásával teljesítse a Biztosított részére, ennek hiányában a szolgáltatást (kifizetést) a Finanszírozónak teljesítse,
          </li>

          <li v-if="open">
            a biztosított épület megsemmisülését, vagy a teljes épületbiztosítási összeg kimerülését eredményező káresemény bekövetkezése esetén, a valós körülmények figyelembevételével megállapított – a szolgáltatás teljesítésekor fennálló tartozás (tőke, kamat és járulékok) erejéig, de legfeljebb a biztosítási összeg mértékéig terjedő – szolgáltatási összeget közvetlenül a Finanszírozó részére teljesítse,
          </li>
          <li v-if="open">
            amennyiben a lentebb megjelölt biztosítási szerződés nem jön létre, úgy ennek tényéről a biztosítási ajánlat elutasításával egyidejűleg tájékoztatja a Finanszírozót;
          </li>
          <li v-if="open">
            a Finanszírozótól eltérő személy részére a fenti összeghatár felett és esetben csak abban az esetben teljesítsen kifizetést, továbbá a jelen okiratban foglaltakat csak abban az esetben tekinti hatálytalannak, amennyiben ehhez a Finanszírozó is kifejezett hozzájárulását adta.
          </li>
          <li v-if="open">
            a biztosítási szerződés
            <ul>
              <li>
                díjának elmaradásáról az esedékességet követő 60 napon belül tájékoztassa a          
                Finanszírozót, illetve
              </li>
              <li>
                bármely okú megszűnéséről, a megszűnést követő 8 napon belül tájékoztassa a 
                Finanszírozót.
              </li>
            </ul>
          </li>
        </ul>
      </p>
      <div class="more-button d-block d-lg-none">
        <q-btn
          :ripple="false"
          :unelevated="true"
          @click="open = !open"
        >
          <div v-if="open">
            Részletek bezárása
            <PhIconManager
              height="1rem"
              width="1rem"
              ph-icon="ph-arrow-up"
            />
          </div>
          <div v-else>
            Részletek megtekintése
            <PhIconManager
              height="1rem"
              width="1rem"
              ph-icon="ph-arrow-down"
            />
          </div>
        </q-btn>
      </div>
    </div>
    <div class="authorization-checkbox">
      <CheckboxInput
        :id="id"
        v-model:model-value="viewModel.FundingContribution.Value"
        validation-rules="VAL_100_CHECKBOX"
      >
        Felhatalmazom a Biztosítót
      </CheckboxInput>
    </div>
  </div>
</template>
  <script setup>
  const viewModel = inject('viewModel')
  import CheckboxInput from './CheckboxInput.vue'
  import { inject,ref, onMounted } from 'vue'

  const props = defineProps({
    id: {
    type: Number,
    required: true,
  },
  checkboxTitle: {
    type: String,
    required: false,
    default: '',
  },
  model: {
    type: Boolean,
    required: false,
  },
})

onMounted(() => {

})

const open = ref(false)

  </script>
  <style lang="scss" scoped>
  ul{
    margin-bottom: 0;
  }
  li > ul{
    padding: 0;
    margin-bottom: 0;
    li{
      list-style-type: "\2010";
    padding-left: .25rem;
    }
  }
  .authorization-input-holder {
    width: 100%;
    padding: 1rem;
    background: #ffffff;
    box-shadow: $box-shadow;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .authorization-paragraph {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: #4a567c;
    }
    .authorization-checkbox {
      :deep(.q-checkbox) {
        margin: 0 !important;
        .q-checkbox__label {
          height: 1.25rem;
          display: flex;
          align-items: center;
        }
        .q-item__label {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.25rem !important;
          color: $D-700;
        }
      }
    }
  }
  .more-button {
    margin-top: .25rem;
    :deep(.q-btn__content) {
      font-weight: 600;
      font-size: .75rem;
      line-height: 1rem;
      color: $secondary;
      text-transform: none;
      svg {
        width: auto;
        margin-left: 0.5rem;
      }
    }
    .q-btn {
      min-height: unset;
    }
  }
  </style>
  